import React from "react";
import PropTypes from "prop-types";

const Breadcrumb = ({ crumbs }) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {crumbs.map((data, index) => (
          <li
            key={index}
            className={
              data.active ? "breadcrumb-item active" : "breadcrumb-item"
            }
          >
            {!data.active && (
              <a href={data.link} aria-current="page">
                {data.name}
              </a>
            )}
            {data.active && <span>{data.name}</span>}
          </li>
        ))}
      </ol>
    </nav>
  );
};

Breadcrumb.propTypes = {
  /**
   * The crumbs to show
   */
  crumbs: PropTypes.array
};

Breadcrumb.defaultProps = {
  crumbs: [{ link: "dashboard", name: "Home", active: true }]
};

export default Breadcrumb;
