import React from "react";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import { client } from "../../../apolloClient";
import {
  Card,
  Elevation,
  Button,
  Spinner,
  AnchorButton,
  Menu,
  MenuItem,
  MenuDivider,
  Position,
  Popover,
  Intent,
  Label,
} from "@blueprintjs/core";
import { Navigate } from "react-router-dom";

import Breadcrumb from "../../../components/Breadcrumb";
import {
  GET_FILLED_VISIT,
  GET_INPUT_MONITORIZATION,
  GET_PATIENTS_ON_BRANCH,
  COUNT_PATIENT_WITH_VALUE,
  GET_FIELD_VALUE_PATIENT,
} from "../../../queries";
import {
  UPDATE_FILLED_VISIT,
  CREATE_INPUT_MONITORIZATION,
  ADD_INPUT_FIX,
  CHANGE_BRANCH_PATIENT,
} from "../../../mutations";
import { Form, Components } from "react-formio";
import components from "../../../formioCustom";
import {
  FILLED_VISIT_STATUS_COMPLETED,
  FILLED_VISIT_STATUS_MONITORING,
  INPUTS_TYPES,
  MONITORIZATION_TYPE_INCIDENCE,
  MONITORIZATION_TYPE_CHECKED,
  MONITORIZATION_TYPE_COMMENT,
  ROLE_ADMIN,
  ROLE_DATAMANAGER,
  ROLE_INVESTIGATOR,
  ROLE_SYSADMIN,
  ROLE_MONITOR,
  ROLE_LEAD_MONITOR,
  ROLE_READ_ONLY,
} from "../../../config";
import ModalMonitorization from "../../../components/ModalMonitorization/ModalMonitorization";
import ModalTimeline from "../../../components/ModalTimeline/ModalTimeline";
import ModalAction from "../../../components/ModalAction/ModalAction";
import ModalActionCallback from "../../../components/ModalAction/ModalActionCallback";
import { AppToaster } from "../../../components/Toaster";
// import UploadFileToS3 from "../../../components/UploadFileToS3";
// import DownloadFileFromS3 from "../../../components/DownloadFileFromS3";
//import VisitForm from "../../../components/VisitForm/VisitForm";
import { connect } from "react-redux";
import {
  getItemInForm,
  getValueInSubmission,
  setPatientToReferenceType,
} from "../../../utils/utils";
import { isEqual } from "lodash";
import html2pdf from "html2pdf.js";

import "./_FillVisit.scss";

Components.setComponents(components);

class FillVisit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filledVisit: {
        filledSheets: [],
        user: { username: "" },
        versionDate: "",
        filledVisit: {
          visit: { name: "" },
          versions: { edges: [] },
          patient: { name: "", crf: { name: "", id: "" } },
        },
      },
      loading: false,
      redirect: false,
      selectedSheet: 0,
      submissions: [],
      oldSubmissions: [],
      modalMonitorizationOpened: false,
      fromNotificationInput: null,
      monitorizationId: "",
      monitorizationType: "",
      monitorizationRef: "",
      monitorizationInputType: "",
      mutation: CREATE_INPUT_MONITORIZATION,
      monitorizationTarget: null,
      modalTimelineOpened: false,
      modalTime: null,
      fixesElement: { status: "", fixes: { edges: [] } },
      monitorizationField: "",
      monitorizationValue: "",
      modalActionOpened: false,
      modalActionLoading: false,
      modalActionText: "",
      modalActionHeader: "",
      modalActionCallbackOpened: false,
      savedChanges: false,
      showAll: false,
      branch: "",
      hideRandomize: false,
    };

    this.saveRoles = [
      ROLE_ADMIN,
      ROLE_SYSADMIN,
      ROLE_DATAMANAGER,
      ROLE_INVESTIGATOR,
    ];

    this.incidenceCheckButtonRoles = [
      ROLE_ADMIN,
      ROLE_SYSADMIN,
      ROLE_MONITOR,
      ROLE_LEAD_MONITOR,
    ];

    this.commentButtonRoles = [
      ROLE_ADMIN,
      ROLE_SYSADMIN,
      ROLE_INVESTIGATOR,
      ROLE_DATAMANAGER,
    ];

    document.fillVisitComponent = this;
    document.validatorVal = null;
    this.toggleActionModal = this.toggleActionModal.bind(this);
  }

  async componentDidMount() {
    try {
      if (window.location.href.split("/")[4]) {
        await this.fetch();
        // If user clicks on notifications redirects to notification
        if (this.props.location.state.entity) {
          const variables = { id: this.props.location.state.entity.id };
          client
            .query({
              variables: variables,
              query: GET_INPUT_MONITORIZATION,
              fetchPolicy: "network-only",
            })
            .then((res) => {
              let filledSheet = res.data.inputMonitorization.filledSheet.id;
              this.state.filledVisit.filledSheets.forEach((value, index) => {
                if (value.id == filledSheet)
                  this.setState({
                    selectedSheet: index,
                    fromNotificationInput: res.data.inputMonitorization,
                  });
              });
            });
        }
      }
    } catch (err) {}
  }

  componentWillUpdate() {
    if (this.state.savedChanges === false) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  async componentWillReceiveProps() {
    if (this.props.location.state && this.props.location.state.entity.fetch) {
      this.props.location.state.entity.fetch = false;

      window.location.reload(false);
    }
  }

  fetch = async () => {
    this.setState({ loading: true });
    const variables = { id: window.location.href.split("/")[4] };
    await client
      .query({
        variables: variables,
        query: GET_FILLED_VISIT,
        fetchPolicy: "network-only",
      })
      .then((res) => {
        const organizedFilledVisit = res.data.filledVisit.filledSheets.sort((a, b) => parseInt(a.id) - parseInt(b.id));
        const submissions = organizedFilledVisit.map(
          (filledSheet) => {
            const filledForm = filledSheet.uncriptedFilledForm
              ? JSON.parse(filledSheet.uncriptedFilledForm)
              : {};
            // set patients to reference type fields
            let form = JSON.parse(filledSheet.sheet.formBuild);

            setPatientToReferenceType(
              res.data.filledVisit.filledVisit.patient.id,
              form
            );
            filledSheet.sheet.formBuild = JSON.stringify(form);

            return filledForm;
          }
        );

        this.setState({
          loading: false,
          filledVisit: res.data.filledVisit,
          submissions: submissions,
          oldSubmissions: JSON.stringify(submissions),
          branch: res.data.filledVisit.filledVisit.patient.branch,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        this.showMessage(
          this.props.t("session.error"),
          this.props.t("session.error.message")
        );
      });
  };

  handleMonitorizationChange = () => {
    this.showToast(this.props.t("monitorization.saved"));

    this.fetch();
  };

  handleSheetChange = async (next, save = true) => {
    const submissions = this.state.submissions.slice();
    const oldSubmissions = JSON.parse(this.state.oldSubmissions);
    const index = this.state.selectedSheet;
    await this.refs[`sheet-${index}`].formio.submit();
    const mysubmission = this.refs[`sheet-${index}`].formio.submission;
    await this.setState({
      selectedSheet: next,
      submissions: submissions,
    });

    if (!isEqual(oldSubmissions[index], mysubmission) && save) {
      submissions[index] = mysubmission;
      this.setState({
        oldSubmissions: JSON.stringify(submissions),
      });
      this.handleSend(false);
    }

    //this.refs[`sheet-${next}`].formio.submission = submissions[next];
  };

  handleNextClick = async () => {
    if (
      this.state.filledVisit.filledSheets.length - 1 ===
      this.state.selectedSheet
    ) {
      const index = this.state.selectedSheet;
      await this.refs[`sheet-${index}`].formio.submit();
      const mysubmission = this.refs[`sheet-${index}`].formio.submission;
      const submissions = this.state.submissions.slice();
      submissions[index] = mysubmission;
      this.setState({ submissions: submissions });
      this.state.filledVisit.last
        ? this.handleSend()
        : this.toggleActionCallbackModal();
    } else {
      this.handleSheetChange(this.state.selectedSheet + 1);
    }
  };

  handleBackClick = () => {
    this.state.selectedSheet === 0
      ? this.setState({ redirect: true })
      : this.handleSheetChange(this.state.selectedSheet - 1);
  };

  applyCode = async () => {
    try {
      const index = this.state.selectedSheet;
      await this.refs[`sheet-${index}`].formio.submit();
      const mysubmission = this.refs[`sheet-${index}`].formio.submission;
      const submissions = this.state.submissions.slice();
      submissions[index] = mysubmission;
      this.setState({ submissions: submissions });
      await this.handleSend();
      var _this = this;
      await eval(this.state.filledVisit.filledVisit.visit.codeBranch);
      this.setState({ hideRandomize: true });
      this.fetch();
    } catch (e) {
      console.log(e);
      this.showMessage("Error", "Error al procesar script de vísita");
    }
  };

  handleSend = async (last = true) => {
    if (this.saveRoles.includes(this.props.role)) {
      if (last) this.setState({ loading: true });

      const submissions = this.state.submissions.map((value, index) => {
        let sheet = {
          id: this.state.filledVisit.filledSheets[index].id,
          formFilled: JSON.stringify(value),
        };

        return sheet;
      });

      const mutation = UPDATE_FILLED_VISIT;

      const variables = {
        id: this.state.filledVisit.id,
        input: { submissions: submissions, last: last },
      };

      client
        .mutate({
          variables,
          mutation,
        })
        .then((res, err) => {
          if (err) console.log(err);
          if (res.data.updateFilledVisit.ok) {
            this.showToast(this.props.t("filledVisit.saved"));
            this.setState({ loading: false, saveChanges: true });
          } else {
            this.showToast(this.props.t("filledVisit.not_saved"), "danger");
            this.setState({ loading: false, saveChanges: false });
          }
          window.onbeforeunload = null;
        });
    }
  };

  // Renders monitorization buttons, using DOM because it's builded by formio and don't have access to refs.
  handleRender = () => {
    const node = ReactDOM.findDOMNode(this);
    if (
      node &&
      this.state.filledVisit.filledVisit.status ==
        FILLED_VISIT_STATUS_MONITORING
    ) {
      // Loop through monitorizations of every sheet
      this.state.filledVisit.filledSheets.forEach((filledSheet, index) => {
        const sheetNode = node.querySelector(`#form-${index}`);
        if (sheetNode) {
          // Loop trouhg monitorizations
          filledSheet.monitorizations.edges.forEach((monitorization) => {
            let input = null;
            if (
              monitorization.node.inputType == "textarea" ||
              monitorization.node.inputType == "textarea_custom"
            ) {
              input = sheetNode.querySelector(
                `textarea[name='${monitorization.node.inputRef}']`
              );
            } else if (
              monitorization.node.inputType == "radio" ||
              monitorization.node.inputType == "radio_custom"
            ) {
              input = sheetNode.querySelector(
                `input[name^='${monitorization.node.inputRef}']`
              );
            } else if (
              monitorization.node.inputType == "selectboxes" ||
              monitorization.node.inputType == "selectboxes_custom"
            ) {
              input = sheetNode.querySelector(
                `input[name^='${monitorization.node.inputRef}']`
              );
            } else if (
              monitorization.node.inputType == "select" ||
              monitorization.node.inputType == "select_custom"
            ) {
              input = sheetNode.querySelector(
                `select[name='${monitorization.node.inputRef}']`
              );
            } else {
              input = sheetNode.querySelector(
                `input[name='${monitorization.node.inputRef}']`
              );
            }

            if (input) {
              let container = null;
              switch (monitorization.node.inputType) {
                case "datetime_custom":
                  container = input.parentElement.parentElement.parentElement;
                  break;
                case "radio_custom":
                  container =
                    input.parentElement.parentElement.parentElement
                      .parentElement;
                  break;
                case "selectboxes_custom":
                  container =
                    input.parentElement.parentElement.parentElement
                      .parentElement;
                  break;
                case "select_custom":
                  if (sheetNode.querySelector(".formio-choices")) {
                    container = input.parentElement.parentElement;
                  } else {
                    container = input.parentElement;
                  }

                  break;
                default:
                  if (input.parentElement.getAttribute("ref") == "element")
                    container = input.parentElement.parentElement;
                  else
                    container = input.parentElement.parentElement.parentElement;
                  break;
              }

              const label = container.querySelector(".col-form-label");
              if (!container.querySelector(".fa-flag") && label != null)
                label.innerHTML += '<i class="fa fa-flag"></i>';

              // Set text color based on monitorization type
              if (label) {
                switch (monitorization.node.status) {
                  case MONITORIZATION_TYPE_INCIDENCE:
                    label.className = "col-form-label w-100 red-text";
                    break;
                  case MONITORIZATION_TYPE_COMMENT:
                    label.className = "col-form-label w-100 orange-text";
                    break;
                  case MONITORIZATION_TYPE_CHECKED:
                    label.className = "col-form-label w-100 green-text";
                    if (
                      monitorization.node.inputType == "radio_custom" ||
                      monitorization.node.inputType == "selectboxes_custom"
                    ) {
                      const inputs = sheetNode.querySelectorAll(
                        `input[name^='${monitorization.node.inputRef}']`
                      );
                      inputs.forEach((value, index) => {
                        value.setAttribute("disabled", "disabled");
                      });
                    } else if (
                      monitorization.node.inputType == "datetime_custom"
                    ) {
                      const inputs = container.querySelectorAll(`input`);
                      inputs.forEach((value, index) => {
                        value.setAttribute("disabled", "disabled");
                      });
                    } else {
                      input.setAttribute("disabled", "disabled");
                    }

                    break;
                  default:
                    break;
                }
              }
              if (
                container.querySelector(".form-monitorization-container") ===
                null
              ) {
                this.appendMonitorizationButtons(
                  container,
                  monitorization.node.id,
                  monitorization.node.inputType
                );
              }
            }
          });
        }
      });

      INPUTS_TYPES.forEach((inputType) => {
        const childs = node.querySelectorAll(`.formio-component-${inputType}`);
        childs.forEach((child) => {
          if (
            child &&
            child.querySelector(".form-monitorization-container") === null &&
            child.querySelector(".form-monitorization-container-textarea") ===
              null
          ) {
            const monitorizationContainer = document.createElement("div");
            if (this.incidenceCheckButtonRoles.includes(this.props.role)) {
              const incidenceButton = document.createElement("button");
              incidenceButton.setAttribute("type", "button");
              incidenceButton.className = "form-fill-inputs-button";
              incidenceButton.innerHTML = '<i class="fa fa-question"></i>';
              incidenceButton.onclick = (e) => {
                this.handleIncidenceOpen(
                  e,
                  this.state.filledVisit.filledSheets[this.state.selectedSheet]
                    .id,
                  MONITORIZATION_TYPE_INCIDENCE,
                  inputType,
                  CREATE_INPUT_MONITORIZATION
                );
              };

              monitorizationContainer.append(incidenceButton);
            }
            if (inputType == "textarea") {
              monitorizationContainer.className =
                "form-monitorization-container-textarea";
              const label = child.querySelector(".col-form-label");
              if (label) {
                label.className = "col-form-label w-100";
                label.append(monitorizationContainer);
              }
            } else {
              monitorizationContainer.className =
                "form-monitorization-container";
              const label = child.querySelector(".col-form-label");
              if (label) {
                label.className = "col-form-label w-100";
                label.append(monitorizationContainer);
              }
            }

            // disable inputs in monitorization proccess, unless it's being monitored
            switch (inputType) {
              case "select_custom":
                child.querySelectorAll("select").forEach((input) => {
                  input.setAttribute("disabled", "disabled");
                });

                break;
              case "textarea_custom":
                child.querySelectorAll("textarea").forEach((input) => {
                  input.setAttribute("disabled", "disabled");
                });
                break;
              default:
                child.querySelectorAll("input").forEach((input) => {
                  input.setAttribute("disabled", "disabled");
                });
                break;
            }
          }
        });
      });
    }
  };

  toggleMonitorizationModal = (
    monitorizationId,
    ref,
    type,
    inputType,
    value,
    field,
    mutation
  ) => {
    this.setState({
      monitorizationId: monitorizationId,
      modalMonitorizationOpened: !this.state.modalMonitorizationOpened,
      monitorizationType: type,
      monitorizationRef: ref,
      monitorizationInputType: inputType,
      monitorizationValue: value,
      monitorizationField: field,
      mutation: mutation,
    });
  };

  toggleTimelineModal = (monitorizationId, field) => {
    if (monitorizationId)
      client
        .query({
          variables: { id: monitorizationId },
          query: GET_INPUT_MONITORIZATION,
          fetchPolicy: "network-only",
        })
        .then((res) => {
          if (res.data.inputMonitorization !== null)
            this.setState((prevState) => ({
              ...prevState,
              monitorizationId: monitorizationId,
              modalTimelineOpened: !this.state.modalTimelineOpened,
              fixesElement: res.data.inputMonitorization,
              monitorizationField: field,
            }));
        });
    else
      this.setState((prevState) => ({
        ...prevState,
        monitorizationId: monitorizationId,
        modalTimelineOpened: !this.state.modalTimelineOpened,
      }));
  };

  appendMonitorizationButtons = (
    container,
    id,
    inputType = "textfield",
    value
  ) => {
    const monitorizationContainer = document.createElement("div");
    if (this.incidenceCheckButtonRoles.includes(this.props.role)) {
      const incidenceButton = document.createElement("button");
      incidenceButton.setAttribute("type", "button");
      incidenceButton.className = "form-fill-inputs-button";
      incidenceButton.innerHTML = '<i class="fa fa-question"></i>';
      incidenceButton.onclick = (e) => {
        this.handleIncidenceOpen(
          e,
          id,
          MONITORIZATION_TYPE_INCIDENCE,
          inputType,
          ADD_INPUT_FIX
        );
      };
      monitorizationContainer.append(incidenceButton);
    }

    if (this.commentButtonRoles.includes(this.props.role)) {
      const commentButton = document.createElement("button");
      commentButton.setAttribute("type", "button");
      commentButton.className = "form-fill-inputs-button";
      commentButton.innerHTML = '<i class="fa fa-comment"></i>';
      commentButton.onclick = (e) => {
        this.handleIncidenceOpen(
          e,
          id,
          MONITORIZATION_TYPE_COMMENT,
          inputType,
          ADD_INPUT_FIX
        );
      };
      monitorizationContainer.append(commentButton);
    }
    if (this.incidenceCheckButtonRoles.includes(this.props.role)) {
      const checkButton = document.createElement("button");
      checkButton.setAttribute("type", "button");
      checkButton.className = "form-fill-inputs-button";
      checkButton.innerHTML = '<i class="fa fa-check"></i>';
      checkButton.onclick = (e) => {
        this.handleIncidenceOpen(
          e,
          id,
          MONITORIZATION_TYPE_CHECKED,
          inputType,
          ADD_INPUT_FIX
        );
      };
      monitorizationContainer.append(checkButton);
    }

    if (this.props.role != ROLE_READ_ONLY) {
      const historyButton = document.createElement("button");
      historyButton.setAttribute("type", "button");
      historyButton.className = "form-fill-inputs-button";
      historyButton.innerHTML = '<i class="fa fa-history"></i>';
      historyButton.onclick = (e) => {
        const label = container.querySelector(".col-form-label").textContent;
        this.toggleTimelineModal(id, label);
      };

      monitorizationContainer.append(historyButton);
    }

    monitorizationContainer.className = "form-monitorization-container";
    const label = container.querySelector(".col-form-label");

    if (label) {
      label.append(monitorizationContainer);
    }
  };

  handleIncidenceOpen = (e, id, type, inputType, mutation) => {
    let ref = "";
    const container = e.target.className.includes("fa")
      ? e.target.parentElement.parentElement.parentElement.parentElement
      : e.target.parentElement.parentElement.parentElement;

    let value = "";
    let references;
    const label = container.querySelector(".col-form-label").textContent;

    //  get all kinds of value
    switch (inputType) {
      case "select_custom":
        ref = container.querySelector("select").getAttribute("name");
        value = container.querySelector("select").value;
        break;
      case "textarea_custom":
        if (container.querySelector("textarea")) {
          ref = container.querySelector("textarea").getAttribute("name");
        }
        value = container.querySelector("textarea").value;
        break;
      case "textarea":
        if (container.querySelector("textarea")) {
          ref = container.querySelector("textarea").getAttribute("name");
        }
        value = container.querySelector("textarea").value;
        break;
      case "radio_custom":
        ref = container.querySelector("input").getAttribute("name");
        // eliminates random id generated on name
        references = ref.replace(/]/g, "").split("[");
        ref = references[0];
        for (let i = 1; i < references.length - 1; i++) {
          ref = `${ref}[${references[i]}]`;
        }
        value = container.querySelector(".radio-selected span")
          ? container.querySelector(".radio-selected span").textContent
          : "";

        break;
      case "selectboxes_custom":
        ref = container.querySelector("input").getAttribute("name");
        let values = container.querySelectorAll("input:checked");
        values.forEach((checkbox, index) => {
          value =
            index == 0 ? `${checkbox.value}` : `${value}, ${checkbox.value}`;
        });
        references = ref.replace(/]/g, "").split("[");
        ref = references[0];
        for (let i = 1; i < references.length - 1; i++) {
          ref = `${ref}[${references[i]}]`;
        }
        break;
      default:
        ref = container.querySelector("input").getAttribute("name");
        value = container.querySelector("input").value;
        break;
    }

    this.setState({
      monitorizationTarget: container,
    });

    this.toggleMonitorizationModal(
      id,
      ref,
      type,
      inputType,
      value,
      label,
      mutation
    );
  };

  showToast = (message, intent = "success") => {
    // create toasts in response to interactions.
    // in most cases, it's enough to simply create and forget (thanks to timeout).
    AppToaster.show({
      message: message,
      intent: intent,
      timeout: 10000,
    });
  };

  renderMenu = () => {
    /* data.node.user.username */
    return (
      <Menu>
        {this.state.filledVisit.filledVisit.versions.edges.map(
          (data, index) => (
            <MenuItem
              key={index}
              text={`${
                this.state.filledVisit.filledVisit.patient.code
              } - ${new Date(data.node.versionDate).toLocaleString()}`}
              icon={
                data.node.id === this.state.filledVisit.id
                  ? "tick"
                  : "git-branch"
              }
              label={data.node.last ? this.props.t("actual") : ""}
              href={`/filled-visit/${data.node.id}`}
            />
          )
        )}
        <MenuDivider />
        <MenuItem text="Exit" icon="cross" />
      </Menu>
    );
  };

  ////////////// Functions for branching /////////////
  countPatientsOnBranch = async (branch = null, centre = null) => {
    const variables = {
      id: this.state.filledVisit.filledVisit.patient.crf.id,
      branch: branch,
      centre: centre,
    };

    let res = await client.query({
      variables: variables,
      query: GET_PATIENTS_ON_BRANCH,
      fetchPolicy: "network-only",
    });

    if (typeof res.data.patientsOnBranch.count !== "undefined") {
      return res.data.patientsOnBranch.count;
    } else {
      return null;
    }
  };

  countPatientsOnCentre = async (centre) => {
    const variables = centre
      ? {
          id: this.state.filledVisit.filledVisit.patient.crf.id,
          centre: centre,
        }
      : {
          id: this.state.filledVisit.filledVisit.patient.crf.id,
        };
    let res = await client.query({
      variables: variables,
      query: GET_PATIENTS_ON_BRANCH,
      fetchPolicy: "network-only",
    });

    if (typeof res.data.patientsOnBranch.count !== "undefined") {
      return res.data.patientsOnBranch.count;
    } else {
      return null;
    }
  };

  countPatientsWithValue = async (value, key, branch = null, centre = null) => {
    const variables = {
      id: this.state.filledVisit.filledVisit.patient.crf.id,
      value,
      key,
      visitId: this.state.filledVisit.filledVisit.visit.id,
      branch: branch,
      centre: centre,
    };

    let res = await client.query({
      variables: variables,
      query: COUNT_PATIENT_WITH_VALUE,
      fetchPolicy: "network-only",
    });

    if (typeof res.data.valuesOnCrf.count !== "undefined") {
      return res.data.valuesOnCrf.count;
    } else {
      return null;
    }
  };

  setBranch = async (branch) => {
    const variables = {
      id: this.state.filledVisit.filledVisit.patient.id,
      branch: branch,
    };

    let res = await client.mutate({
      variables,
      mutation: CHANGE_BRANCH_PATIENT,
    });

    if (typeof res.data.changeBranchPatient.ok) {
      // set the branch to the state
      /*this.setState({
        branch: branch,
      });*/
      return true;
    } else {
      return false;
    }
  };

  getItemValueByLabel = (label) => {
    let item;
    let value = null;

    // find field on form

    this.state.filledVisit.filledSheets.forEach((filledSheet, index) => {
      let form = JSON.parse(filledSheet.sheet.formBuild);
      let res = getItemInForm(label, form);
      if (res) item = res;
    });

    if (item) {
      this.state.submissions.forEach((submission) => {
        let res = getValueInSubmission(item, submission);
        if (res) value = res;
      });
    }

    return value;
  };

  getItemValueByLabelOnServer = (label) => {
    let item;
    let value = null;

    // find field on form

    this.state.filledVisit.filledSheets.forEach((filledSheet, index) => {
      let form = JSON.parse(filledSheet.sheet.formBuild);
      let res = getItemInForm(label, form);
      if (res) item = res;
    });

    if (item) {
      let res = getValueInSubmission(
        item,
        JSON.parse(
          this.state.filledVisit.filledSheets[this.state.selectedSheet]
            .uncriptedFilledForm
        )
      );
      if (res) value = res;
    }

    return value;
  };

  getValue = async (key, visit = null) => {
    const variables = {
      patient: this.state.filledVisit.filledVisit.patient.id,
      field: key,
      visit: visit,
    };

    let res = await client.query({
      variables: variables,
      query: GET_FIELD_VALUE_PATIENT,
      fetchPolicy: "network-only",
    });

    if (typeof res.data.fieldValuePatient.value !== "undefined") {
      return res.data.fieldValuePatient.value;
    } else {
      return null;
    }
  };

  getItem = async (key, visit = null) => {
    const variables = {
      patient: this.state.filledVisit.filledVisit.patient.id,
      field: key,
      visit: visit,
    };

    let res = await client.query({
      variables: variables,
      query: GET_FIELD_VALUE_PATIENT,
      fetchPolicy: "network-only",
    });

    if (typeof res.data.fieldValuePatient !== "undefined") {
      return res.data.fieldValuePatient;
    } else {
      return null;
    }
  };

  showMessage = (header, message, time = null, loading = false) => {
    if (!this.state.modalActionOpened) {
      this.setState({
        modalActionHeader: header,
        modalActionText: message,
        modalTime: time,
        modalActionOpened: true,
        modalActionLoading: loading,
      });
    }
  };

  setFieldFeedback = (id, type, text) => {
    let message = document.getElementById(`${id}-feedback`);
    if (!message) {
      message = document.createElement("div");
      message.className = `formio-errors ${type}-feedback`;
      message.id = `${id}-feedback`;
    }
    message.innerHTML = `<div class="form-text">${text}</div>`;
    const element = document.getElementById(id);
    element.appendChild(message);
    element.classList.add("has-error");
  };

  removeFieldFeedback = (id) => {
    const element = document.getElementById(`${id}-feedback`);
    if (element) element.parentNode.removeChild(element);
  };

  getInstanceValueWeight = (values, search) => {
    const found = values.find((val) => {
      return val.value == search;
    });

    return found && search ? found.weight : null;
  };

  findFormElement = (key, form) => {
    if (Array.isArray(form)) {
      let found = false;
      form.forEach((component, index) => {
        const element = this.findFormElement(key, component);
        if (element) found = element;
      });

      return found;
    } else if (typeof form === "object") {
      if (form.input === true && !form.hasOwnProperty("components")) {
        return form.key == key ? form : false;
      } else if (form.hasOwnProperty("components")) {
        return form.key == key
          ? form
          : this.findFormElement(key, form.components);
      } else if (form.hasOwnProperty("columns")) {
        return form.key == key ? form : this.findFormElement(key, form.columns);
      } else if (form.hasOwnProperty("rows")) {
        return form.key == key ? form : this.findFormElement(key, form.rows);
      }
    }

    return false;
  };
  // End branching functions

  toggleActionModal = () => {
    this.setState({ modalActionOpened: false });
  };

  toggleActionCallbackModal = () => {
    this.setState({
      modalActionCallbackOpened: !this.state.modalActionCallbackOpened,
    });
  };

  insertHandlerDataToPDF = (elementHtml) => {
    var elemChild = elementHtml.firstChild;

    let date = new Date();
    let day =
      date.getDate().toString().length == 1
        ? "0" + date.getDate().toString()
        : date.getDate().toString();
    let month =
      (date.getMonth() + 1).toString().length == 1
        ? "0" + (date.getMonth() + 1).toString()
        : (date.getMonth() + 1).toString();
    let hours =
      date.getHours().toString().length == 1
        ? "0" + date.getHours().toString()
        : date.getHours().toString();
    let minutes =
      date.getMinutes().toString().length == 1
        ? "0" + date.getMinutes().toString()
        : date.getMinutes().toString();
    let seconds =
      date.getSeconds().toString().length == 1
        ? "0" + date.getSeconds().toString()
        : date.getSeconds().toString();

    var newDivElem = document.createElement("div");
    newDivElem.id = "date-codes-to-pdf";
    newDivElem.style.cssText = "display:flex; justify-content:space-between;";
    newDivElem.innerHTML =
      "<p>" +
      day +
      "/" +
      month +
      "/" +
      date.getFullYear() +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds +
      "</p><p>" +
      this.state.filledVisit.filledVisit.patient.code +
      " / " +
      this.state.filledVisit.filledVisit.patient.centre.name +
      "</p>";
    elementHtml.insertBefore(newDivElem, elemChild);
  };

  handleDownloadPdf = () => {
    this.setState((prevState) => ({ ...prevState, showAll: true }));
    this.showMessage(
      this.props.t("generating.pdf"),
      this.props.t("generating.pdf"),
      null,
      true
    );

    setTimeout(() => {
      var element = document.getElementById(`visit-form`);
      var lengt = element.childNodes.length;

      // Se hacen grupos de 10 secciones para incluirlos en los distintos pdfs a descargar
      for (var i = 0; i < lengt % 10; i++) {
        var lastDoc = this.createNewDivToDownloadPdf(i, lengt, element);
        // Si es el ultimo pdf a descargar sale del for
        if (lastDoc) {
          break;
        }
      }
    }, 2000);
  };

  createNewDivToDownloadPdf = (index, len, element) => {
    // Se crea un div con el unico elemento a mostrar en el pdf
    if (element.hasChildNodes()) {
      var downloadLastDoc = false;
      var leng = index == 0 ? 0 : index.toString() + "0";
      var children = element.childNodes;
      var newDivElem = document.createElement("div");
      newDivElem.id = "visit-form-pdf";

      for (var i = parseInt(leng); i < parseInt(leng) + 10; i++) {
        if (i < children.length && children[i].style.display !== "none") {
          const cloned = children[i].cloneNode(true);
          newDivElem.appendChild(cloned);
        }
        if (i == children.length) {
          downloadLastDoc = true;
          break;
        }
      }

      this.insertHandlerDataToPDF(newDivElem);

      // Se descargan tantos pdfs como grupos de elementos se hayan formado
      if (index == (len % 10) - 1 || downloadLastDoc) {
        this.downloadPdf(newDivElem, index, true);
        return downloadLastDoc;
      } else {
        this.downloadPdf(newDivElem, index, false);
        return downloadLastDoc;
      }
    } else {
      return false;
    }
  };

  downloadPdf = (newElem, numPdf, close) => {
    let _this = this;
    var opt = {
      margin: 0.5,
      filename: `${this.state.filledVisit.filledVisit.visit.name}-${
        this.state.filledVisit.filledVisit.patient.code
      } (Parte ${numPdf + 1}).pdf`,
      image: { type: "png", quality: 0.98 },
      html2canvas: { scale: 1.5, logging: true, scrollX: 0, scrollY: 0 },
      jsPDF: { unit: "cm", format: "a4", orientation: "portrait" },
    };

    html2pdf()
      .from(newElem)
      .set(opt)
      .save()
      .then(() => {
        _this.setState((prevState) => ({ ...prevState, showAll: false }));
        _this.handleSheetChange(_this.state.selectedSheet, false);
        if (close) {
          _this.toggleActionModal();
        }
      });
  };

  render() {
    const capitalize = (s) => {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    };

    if (this.state.loading) {
      return (
        <div className="container-fluid">
          <div className="spinner-loader-listing">
            <Spinner
              size={80}
              spinnerColor={"#007BFF"}
              spinnerWidth={2}
              visible={true}
            />
          </div>
        </div>
      );
    }

    if (this.state.redirect) {
      this.setState({
        redirect: false,
      });
      return <Navigate to={`/patient/${this.state.filledVisit.patient.id}`} />;
    }
    return (
      <div className="container-fluid" style={{ width: "99%" }}>
        <Breadcrumb
          crumbs={[
            { link: "/", name: "Home", active: false },
            {
              link: "/crfs",
              name: capitalize(this.props.t("crfs.title")),
              active: false,
            },
            {
              link: `/patients/${this.state.filledVisit.filledVisit.patient.crf.id}`,
              name: this.state.filledVisit.filledVisit.patient.crf.name,
              active: false,
            },
            {
              link: `/patient/${this.state.filledVisit.filledVisit.patient.id}`,
              name: this.state.filledVisit.filledVisit.patient.code,
              active: false,
            },
            {
              link: `/filled-visit/${window.location.href.split("/")[4]}`,
              name: this.state.filledVisit.filledVisit.visit.name,
              active: true,
            },
          ]}
        />

        <div className="row mb17">
          {(this.props.role === ROLE_ADMIN ||
            this.props.role === ROLE_SYSADMIN ||
            this.props.role === ROLE_INVESTIGATOR ||
            this.props.role === ROLE_DATAMANAGER ||
            this.props.role === ROLE_MONITOR ||
            this.props.role === ROLE_LEAD_MONITOR) && (
            <div className="col flex-right-center">
              {this.state.filledVisit.filledVisit.patient.branch && (
                <h4 className="float-right mr-5">
                  {this.state.filledVisit.filledVisit.patient.branch}
                </h4>
              )}

              {(this.props.role === ROLE_ADMIN ||
                this.props.role === ROLE_SYSADMIN) && (
                <>
                  <Button
                    className="mr-2 float-right"
                    type="button"
                    intent="success"
                    onClick={(e) => this.handleDownloadPdf()}
                  >
                    {this.props.t("download.pdf")}
                  </Button>

                  <Popover
                    className="float-right mb-3"
                    content={this.renderMenu()}
                    position={Position.BOTTOM_LEFT}
                  >
                    {" "}
                    {/* this.state.filledVisit.user.username */}
                    <Button
                      rightIcon="caret-down"
                      intent={Intent.DEFAULT}
                      text={`${
                        this.state.filledVisit.filledVisit.patient.code
                      } - ${new Date(
                        this.state.filledVisit.versionDate
                      ).toLocaleString()}`}
                    />
                  </Popover>

                  <AnchorButton
                    className="ml-2 float-right"
                    icon="changes"
                    title={this.props.t("visit.diff")}
                    href={`/filled-visit-diff/${
                      window.location.href.split("/")[4]
                    }`}
                  >
                    {this.props.t("visit.diff")}
                  </AnchorButton>
                </>
              )}
            </div>
          )}
        </div>

        <Card interactive={false} elevation={Elevation.TWO}>
          <h4 className="mb-3 w-100">
            {this.state.filledVisit.filledVisit.visit.name}
          </h4>

          <div className="row mt-2">
            <div className="col">
              {this.state.filledVisit.filledSheets
                .sort((a, b) => parseInt(a.id) - parseInt(b.id))
                .map((filledSheet, index) => (
                <Button
                  key={`sheet-${index}`}
                  className="mr-3 mb-2"
                  intent={
                    this.state.selectedSheet === index ? "none" : "primary"
                  }
                  large={false}
                  onClick={(e) => this.handleSheetChange(index)}
                >
                  {filledSheet.sheet.name}
                </Button>
              ))}
            </div>
          </div>

          <div id="visit-form">
            {this.state.filledVisit.filledSheets
              .sort((a, b) => parseInt(a.id) - parseInt(b.id))
              .map((filledSheet, index) => {
              return (
                <div
                  className={
                    index < this.state.filledVisit.filledSheets.length - 1
                      ? "row mt-2 page-break"
                      : "row mt-2"
                  }
                  id={`form-${index}`}
                  style={
                    this.state.showAll === true ||
                    this.state.selectedSheet === index
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <div class="flatpickr"></div>

                  <div className="col" id={`col-form-${index}`}>
                    <h4
                      id={`title-hidden-${index}`}
                      className={`title-hidden mb-3`}
                      style={
                        this.state.showAll === true
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      {filledSheet.sheet.name}
                    </h4>

                    {/* VISITA 3 */}
                    {/*(this.state.filledVisit && this.state.filledVisit.filledVisit.patient.crf.name==="SHORTEN2" && ((this.state.filledVisit.filledVisit.visit.name).toLowerCase()).includes("visita 3"))
                      ? <VisitForm elemIndex={index} data={JSON.parse(filledSheet.sheet.formBuild)}
                          uncriptedData={JSON.parse(filledSheet.uncriptedFilledForm)}
                          parentId='ei8q9om' elementToChangeId='exsml4' />
                  : console.log("")*/}

                    {/* VISITA 4 */}
                    {/*(this.state.filledVisit && this.state.filledVisit.filledVisit.patient.crf.name==="SHORTEN2" && ((this.state.filledVisit.filledVisit.visit.name).toLowerCase()).includes("visita 4"))
                      ? <VisitForm elemIndex={index} data={JSON.parse(filledSheet.sheet.formBuild)}
                          uncriptedData={JSON.parse(filledSheet.uncriptedFilledForm)}
                          parentId='ei8q9om' elementToChangeId='ekm4a1s' />
                : console.log("")*/}

                    <Form
                      key={index}
                      ref={`sheet-${index}`}
                      form={JSON.parse(filledSheet.sheet.formBuild)}
                      onRender={this.handleRender}
                      options={
                        this.state.filledVisit.filledVisit.status ==
                          FILLED_VISIT_STATUS_COMPLETED ||
                        !this.saveRoles.includes(this.props.role)
                          ? { readOnly: true }
                          : {}
                      }
                      submission={
                        this.state.submissions[index]
                          ? this.state.submissions[index]
                          : {}
                      }
                    />
                  </div>
                </div>
              );
            })}
          </div>

          <div className="row">
            <div className="col">
              {this.state.filledVisit.filledSheets.length > 0 &&
              this.state.selectedSheet === 0 ? (
                <AnchorButton
                  large={true}
                  href={`/patient/${this.state.filledVisit.filledVisit.patient.id}`}
                >
                  {this.props.t("exit")}
                </AnchorButton>
              ) : (
                <Button
                  large={true}
                  type="submit"
                  intent="none"
                  onClick={(e) => this.handleBackClick()}
                >
                  {this.state.filledVisit.filledSheets.length > 0 &&
                  this.state.selectedSheet === 0
                    ? this.props.t("exit")
                    : this.props.t("back")}
                </Button>
              )}
            </div>
            <div className="col text-right">
              <Button
                className="mr-2"
                type="button"
                intent="success"
                large={true}
                onClick={(e) => this.handleDownloadPdf()}
              >
                {this.props.t("download.pdf")}
              </Button>
              {this.state.filledVisit.filledVisit.visit.codeBranch &&
                this.saveRoles.includes(this.props.role) &&
                !this.state.hideRandomize &&
                !this.state.filledVisit.filledVisit.patient.branch &&
                this.state.filledVisit.filledVisit.visit.codeBranch != "" && (
                  <Button
                    className="mr-2"
                    minimal
                    large={true}
                    icon="code"
                    title={this.props.t("randomize")}
                    onClick={() => this.applyCode()}
                  >
                    {this.props.t("randomize")}
                  </Button>
                )}
              <Button
                className="primary-button"
                type="submit"
                intent="primary"
                onClick={(e) => this.handleNextClick()}
                style={
                  this.state.filledVisit.filledSheets.length > 0 &&
                  this.state.filledVisit.filledSheets.length - 1 ===
                    this.state.selectedSheet &&
                  !this.saveRoles.includes(this.props.role)
                    ? { display: "none" }
                    : {}
                }
              >
                {this.state.filledVisit.filledSheets.length > 0 &&
                this.state.filledVisit.filledSheets.length - 1 ===
                  this.state.selectedSheet
                  ? this.props.t("submit")
                  : this.props.t("next")}
              </Button>
            </div>
          </div>
        </Card>
        <ModalMonitorization
          isOpen={this.state.modalMonitorizationOpened}
          monitorizationId={this.state.monitorizationId}
          type={this.state.monitorizationType}
          monitorizationRef={this.state.monitorizationRef}
          inputType={this.state.monitorizationInputType}
          toggleFunction={this.toggleMonitorizationModal}
          refetchFunction={this.handleMonitorizationChange}
          mutation={this.state.mutation}
          value={this.state.monitorizationValue}
          label={this.state.monitorizationField}
          t={this.props.t}
        />
        <ModalTimeline
          isOpen={this.state.modalTimelineOpened}
          entityId={this.state.monitorizationId}
          toggleFunction={this.toggleTimelineModal}
          t={this.props.t}
          monitorization={this.state.fixesElement}
          field={this.state.monitorizationField}
        />
        <ModalAction
          isOpen={this.state.modalActionOpened}
          isLoading={this.state.modalActionLoading}
          toggleFunction={this.toggleActionModal}
          customText={this.state.modalActionText}
          customHeader={this.state.modalActionHeader}
          time={this.state.modalTime}
          t={this.props.t}
        />
        <ModalActionCallback
          isOpen={this.state.modalActionCallbackOpened}
          isLoading={this.state.modalActionLoading}
          toggleFunction={this.toggleActionCallbackModal}
          customText={this.props.t("filledVisit.isLast")}
          customHeader={this.props.t("filledVisit.isLastHeader")}
          callback={this.handleSend}
          secondCallback={this.handleSend}
          secondaryButtonText={this.props.t("filledVisit.saveAsLast")}
          primaryButtonText={this.props.t("filledVisit.save")}
          t={this.props.t}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  username: state.user.username,
  name: state.user.name,
  lastname: state.user.lastname,
  role: state.user.role,
  avatar: state.user.avatar,
});

const bindActions = (dispatch) => ({});

export default withTranslation()(
  connect(mapStateToProps, bindActions)(FillVisit)
);