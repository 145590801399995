import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import routes from "./routes";
import AuthRequired from "./authRequired";
import "./assets/scss/style.scss";

export default () => (
  <BrowserRouter basename={""}>
    <div className="container-fluid">
      <Routes>
      {routes.map((route, index) => {
        return (
          <Route
          key={index}
          path={route.path}
          exact={route.exact}
          element={
            <AuthRequired
              render={(props) => (
                <route.layout>
                  <route.component {...props} {...route.props} />
                </route.layout>
              )}
            />
          }
        />
        );
      })}
      </Routes>
    </div>
  </BrowserRouter>
);
