import React from "react";
import { withTranslation } from "react-i18next";
import { client } from "../../../apolloClient";
import {
  Card,
  Elevation,
  Button,
  FormGroup,
  InputGroup,
  Icon,
  Spinner,
  Dialog,
  Classes,
  Intent
} from "@blueprintjs/core";
import { Navigate  } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { UPDATE_TEMPLATE, CREATE_TEMPLATE } from "../../../mutations";
import { TEMPLATE_SHEET, TEMPLATE_CONTAINER } from "../../../config";
import { GET_TEMPLATE } from "../../../queries";
import { FormBuilder } from "react-formio";
import options from "../../../data/formio-options.json";
import { AppToaster } from "../../../components/Toaster";
import utils from "formiojs/utils";

import "./_Edit.scss";

class TemplateForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      template: {
        id: "",
        name: "",
        templateType: "",
        formBuild: { display: "form" },
        crf: { id: "" }
      },
      newTemplate: {
        name: "",
        templateType: ""
      },
      isEdit: false,
      loading: true,
      redirect: false,
      toDeleteName: "",
      toDeleteIndex: "",
      toDeleteId: "",
      deleteFunc: "",
      formioKey: ""
    };
  }

  componentDidMount() {
    try {
      if (window.location.href.split('/')[5]) {
        this.setState({ loading: true });
        const variables = { id: window.location.href.split('/')[5] };
        client
          .query({
            variables: variables,
            query: GET_TEMPLATE,
            fetchPolicy: "network-only"
          })
          .then(res => {
            // Sets the crf id to the options schema so it only gets templates from that crf and saves templates on that CRF
            options.builder.template.components.crf.schema.crf =
              res.data.template.crf.id;
            options.builder.containers.components.container_custom.schema.crf =
              res.data.template.crf.id;
            options.builder.containers.components.columns_custom.schema.crf =
              res.data.template.crf.id;
            options.builder.containers.components.table_custom.schema.crf =
              res.data.template.crf.id;
            options.builder.containers.components.panel_custom.schema.crf =
              res.data.template.crf.id;

            if (res.data.template.formBuild) {
              if (
                res.data.template.templateType.toLowerCase() ==
                TEMPLATE_CONTAINER
              ) {
                try {
                  res.data.template.formBuild = JSON.parse(
                    res.data.template.formBuild
                  );
                  res.data.template.formBuild.display = "form";
                } catch (err) {}
              } else {
                try {
                  res.data.template.formBuild = JSON.parse(
                    res.data.template.formBuild
                  );
                } catch (err) {}
              }
            }

            this.setState(prevState => ({
              template: {
                ...prevState.template,
                ...res.data.template
              },
              isEdit: true,
              loading: false,
              loading: false
            }));
          });
      }
    } catch (err) {}
  }

  handleSubmit = async e => {
    e.preventDefault();

    const mutation = UPDATE_TEMPLATE;
    const variables = {
      name: this.state.template.name,
      id: window.location.href.split('/')[5],
      formBuild: JSON.stringify(this.state.template.formBuild)
    };

    client
      .mutate({
        variables,
        mutation
      })
      .then((res, err) => {
        if (err) console.log(err);
        this.setState({ loading: false, redirect: false });
        this.showToast(this.props.t("template.saved"));
      });
  };

  showToast = message => {
    // create toasts in response to interactions.
    // in most cases, it's enough to simply create and forget (thanks to timeout).
    AppToaster.show({
      message: message,
      intent: "success"
    });
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      template: {
        ...prevState.template,
        [name]: value
      }
    }));
  };

  handleSheetFormChange = schema => {
    // Hack for custom template formio
    if (this.state.formioKey !== "") {
      let component = utils.getComponent(
        schema.components,
        this.state.formioKey
      );
      if (typeof component !== "undefined") {
        component.template = "";
        component.type = "container";
      }
    }

    this.setState(prevState => ({
      template: {
        ...prevState.template,
        formBuild: schema
      }
    }));
  };

  handleSheetFormSave = component => {
    if (component.type === "template")
      this.setState({ formioKey: component.key });
    else this.setState({ formioKey: "" });
  };

  /********* Template saving **********/

  handleSaveTemplate = type => {
    this.setState(prevState => ({
      newTemplate: { ...prevState.newTemplate, templateType: type }
    }));
    this.handleOpen();
  };

  handleTemplateSubmit = async e => {
    e.preventDefault();

    let variables = {
      name: this.state.newTemplate.name,
      id: this.state.template.crf.id,
      templateType: this.state.newTemplate.templateType
    };

    variables.formBuild = JSON.stringify(this.state.template.formBuild);

    client
      .mutate({
        variables,
        mutation: CREATE_TEMPLATE
      })
      .then((res, err) => {
        if (err) console.log(err);
        this.setState({ loading: false, redirect: false });
        this.showToast(this.props.t("template.saved"));
        this.handleClose();
      });
  };

  handleOpen = () =>
    this.setState(prevState => ({ ...prevState, isModalOpen: true }));
  handleClose = () =>
    this.setState(prevState => ({ ...prevState, isModalOpen: false }));

  render() {
    const capitalize = s => {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    };

    if (!this.state.loading) {
      if (this.state.redirect) {
        return <Navigate to={`/crf/${this.state.template.crf.id}`} />;
      }
    } else {
      return (
        <div className="container pt-5">
          <div className="spinner-loader-listing pt-5">
            <Spinner
              size={160}
              spinnerColor={"#007BFF"}
              spinnerWidth={10}
              visible={true}
            />
          </div>
        </div>
      );
    }
    return (
      <div className="container">
        <Breadcrumb
          crumbs={[
            { link: "//", name: "Home", active: false },
            {
              link: "/crfs",
              name: this.props.t("crfs.title"),
              active: false
            },
            {
              link: `/crf/${this.state.template.crf.id}`,
              name: capitalize(this.state.template.crf.name),
              active: false
            },
            {
              link: "",
              name: this.props.t(this.props.title),
              active: true
            }
          ]}
        />
        <Card className="mt-4" interactive={true} elevation={Elevation.TWO}>
          <form onSubmit={this.handleSubmit}>
            <div className="row pt-2 pb-3">
              <div className="col-sm-9">
                <InputGroup
                  id="name"
                  name="name"
                  onChange={this.handleChange}
                  value={this.state.template.name}
                  placeholder={this.props.t("name")}
                  large={true}
                />
              </div>
              <div className="col-sm-3 text-right">
                <Button
                  className="mr-2"
                  intent="success"
                  large={true}
                  icon="floppy-disk"
                  title={this.props.t("save")}
                  type="submit"
                />
                <Button
                  className="mr-2"
                  intent="default"
                  large={true}
                  icon="duplicate"
                  title={this.props.t("convert.template")}
                  onClick={() => this.handleSaveTemplate(TEMPLATE_SHEET)}
                />
              </div>
            </div>
          </form>
          <FormBuilder
            form={this.state.template.formBuild}
            options={options}
            onChange={this.handleSheetFormChange}
            onSaveComponent={this.handleSheetFormSave}
          />
        </Card>

        <Dialog
          icon="info-sign"
          onClose={this.handleClose}
          title={this.props.t("templateSave")}
          isOpen={this.state.isModalOpen}
        >
          <form onSubmit={this.handleTemplateSubmit}>
            <div className={Classes.DIALOG_BODY}>
              <FormGroup
                label={this.props.t("name")}
                labelFor="name"
                labelInfo="(required)"
              >
                <InputGroup
                  required={true}
                  id="name"
                  name="name"
                  onChange={event => {
                    const { name, value } = event.target;
                    this.setState(prevState => ({
                      newTemplate: {
                        ...prevState.newTemplate,
                        [name]: value
                      }
                    }));
                  }}
                  value={this.state.newTemplate.name}
                  placeholder={this.props.t("name")}
                  large={true}
                />
              </FormGroup>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button onClick={() => this.handleClose()}>
                  {this.props.t("cancel")}
                </Button>

                <Button intent={Intent.PRIMARY} type="submit">
                  {this.props.t("save")}
                </Button>
              </div>
            </div>
          </form>
        </Dialog>
      </div>
    );
  }
}

export default withTranslation()(TemplateForm);
