import gql from "graphql-tag";

export const CREATE_USER = gql`
  mutation createUser(
    $username: String!
    $avatar: Upload
    $password: String!
    $firstName: String
    $lastName: String
    $email: String!
    $role: String
    $centres: [String]
    $crfs: [String]
    $recieveNotifications: Boolean
    $recieveNotificationsPatients: Boolean
    $active: Boolean
  ) {
    createUser(
      input: {
        username: $username
        avatar: $avatar
        password: $password
        firstName: $firstName
        lastName: $lastName
        email: $email
        role: $role
        centres: $centres
        crfs: $crfs
        recieveNotifications: $recieveNotifications
        recieveNotificationsPatients: $recieveNotificationsPatients
        active: $active
      }
    ) {
      ok
      user {
        id
        password
        username
        firstName
        lastName
        email
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser(
    $id: String!
    $username: String!
    $avatar: Upload
    $password: String!
    $oldPassword: String!
    $firstName: String
    $lastName: String
    $email: String!
    $role: String
    $centres: [String]
    $crfs: [String]
    $recieveNotifications: Boolean
    $recieveNotificationsPatients: Boolean
    $active: Boolean
  ) {
    updateUser(
      input: {
        username: $username
        avatar: $avatar
        password: $password
        oldPassword: $oldPassword
        firstName: $firstName
        lastName: $lastName
        email: $email
        role: $role
        centres: $centres
        crfs: $crfs
        recieveNotifications: $recieveNotifications
        recieveNotificationsPatients: $recieveNotificationsPatients
        active: $active
      }
      id: $id
    ) {
      ok
      user {
        id
        firstName
        avatar
        lastName
        email
        username
        role
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: String!) {
    deleteUser(id: $id) {
      ok
    }
  }
`;

export const RECOVER_PASSWORD = gql`
  mutation recoverPassword($email: String!) {
    recoverPassword(email: $email) {
      ok
      errors
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($password: String!, $token: String!, $uid: String!) {
    resetPassword(password: $password, token: $token, uid: $uid) {
      ok
      errors
    }
  }
`;

export const CREATE_CENTRE = gql`
  mutation createCentre(
    $name: String!
    $code: String!
    $address: String
    $observations: String
    $phone: String
    $inaugurated: Date
    $locality: String
    $province: String
    $postalCode: String
  ) {
    createCentre(
      input: {
        name: $name
        code: $code
        address: $address
        observations: $observations
        phone: $phone
        inaugurated: $inaugurated
        locality: $locality
        province: $province
        postalCode: $postalCode
      }
    ) {
      ok
      centre {
        id
        name
      }
    }
  }
`;

export const UPDATE_CENTRE = gql`
  mutation updateCentre(
    $id: String!
    $name: String!
    $code: String!
    $address: String
    $observations: String
    $phone: String
    $inaugurated: Date
    $locality: String
    $province: String
    $postalCode: String
  ) {
    updateCentre(
      input: {
        name: $name
        code: $code
        address: $address
        observations: $observations
        phone: $phone
        inaugurated: $inaugurated
        locality: $locality
        province: $province
        postalCode: $postalCode
      }
      id: $id
    ) {
      ok
      centre {
        id
        name
      }
    }
  }
`;

export const DELETE_CENTRE = gql`
  mutation deleteCentre($id: String!) {
    deleteCentre(id: $id) {
      ok
    }
  }
`;

export const CREATE_CRF = gql`
  mutation createCrf(
    $name: String!
    $suffix: String
    $description: String
    $preffix: String
    $patientCoding: String
    $fingerprint: String
    $monitorization: Boolean
    $logoImage: Upload
    $templates: [ImportTemplateInput]
  ) {
    createCrf(
      input: {
        name: $name
        suffix: $suffix
        patientCoding: $patientCoding
        description: $description
        preffix: $preffix
        fingerprint: $fingerprint
        monitorization: $monitorization
        logoImage: $logoImage
        templates: $templates
      }
    ) {
      ok
      crf {
        id
        name
      }
    }
  }
`;

export const UPDATE_CRF = gql`
  mutation updateCrf(
    $id: String!
    $name: String!
    $suffix: String
    $description: String
    $preffix: String
    $patientCoding: String
    $fingerprint: String
    $monitorization: Boolean
    $logoImage: Upload
    $templates: [ImportTemplateInput]
  ) {
    updateCrf(
      input: {
        name: $name
        suffix: $suffix
        patientCoding: $patientCoding
        description: $description
        preffix: $preffix
        fingerprint: $fingerprint
        monitorization: $monitorization
        logoImage: $logoImage
        templates: $templates
      }
      id: $id
    ) {
      ok
      crf {
        id
        name
      }
    }
  }
`;

export const DELETE_CRF = gql`
  mutation deleteCrf($id: String!) {
    deleteCrf(id: $id) {
      ok
    }
  }
`;

export const CLOSE_CRF = gql`
  mutation closeCrf($id: String!) {
    closeCrf(id: $id) {
      ok
    }
  }
`;

export const CLONE_CRF = gql`
  mutation cloneCrf($name: String!, $id: String!) {
    cloneCrf(name: $name, id: $id) {
      ok
      crf {
        id
        name
      }
    }
  }
`;

export const IMPORT_CRF = gql`
  mutation importCrf($id: String!, $csvData: Upload!, $rewritePolicy: String!) {
    importCrf(id: $id, csvData: $csvData, rewritePolicy: $rewritePolicy) {
      ok
      message
      crf {
        id
        name
      }
    }
  }
`;

export const CREATE_VISIT = gql`
  mutation createVisit(
    $name: String!
    $observations: String
    $id: String!
    $template: ImportTemplateInput
  ) {
    createVisit(
      input: { name: $name, observations: $observations, template: $template }
      id: $id
    ) {
      ok
      visit {
        id
        name
      }
    }
  }
`;

export const UPDATE_VISIT = gql`
  mutation updateVisit(
    $name: String!
    $observations: String
    $centres: [String]
    $id: String!
    $sheets: [SheetInput]
    $finishingVisit: Boolean
    $branch: String
  ) {
    updateVisit(
      input: {
        name: $name
        observations: $observations
        centres: $centres
        sheets: $sheets
        finishingVisit: $finishingVisit
        branch: $branch
      }
      id: $id
    ) {
      ok
      visit {
        id
        name
      }
    }
  }
`;

export const UPDATE_VISIT_CODE = gql`
  mutation updateVisitCode($code: String!, $id: String!) {
    updateVisitCode(code: $code, id: $id) {
      ok
      visit {
        id
        name
      }
    }
  }
`;

export const DELETE_VISIT = gql`
  mutation deleteVisit($id: String!) {
    deleteVisit(id: $id) {
      ok
    }
  }
`;

export const ORDER_VISIT = gql`
  mutation orderVisit($id: String!, $startIndex: Int!, $endIndex: Int!) {
    orderVisit(id: $id, startIndex: $startIndex, endIndex: $endIndex) {
      ok
    }
  }
`;

export const DELETE_SHEET = gql`
  mutation deleteSheet($id: String!) {
    deleteSheet(id: $id) {
      ok
    }
  }
`;

export const CREATE_TEMPLATE = gql`
  mutation createTemplate(
    $name: String!
    $id: String!
    $sheets: [SheetTemplateInput]
    $templateType: String
    $formBuild: String
  ) {
    createTemplate(
      input: {
        name: $name
        sheets: $sheets
        templateType: $templateType
        formBuild: $formBuild
      }
      id: $id
    ) {
      ok
      template {
        id
        name
      }
    }
  }
`;

export const UPDATE_TEMPLATE = gql`
  mutation updateTemplate(
    $name: String!
    $id: String!
    $sheets: [SheetTemplateInput]
    $formBuild: String
  ) {
    updateTemplate(
      input: { name: $name, sheets: $sheets, formBuild: $formBuild }
      id: $id
    ) {
      ok
      template {
        id
        name
      }
    }
  }
`;

export const DELETE_TEMPLATE = gql`
  mutation deleteTemplate($id: String!) {
    deleteTemplate(id: $id) {
      ok
    }
  }
`;

export const CLONE_TEMPLATE = gql`
  mutation cloneTemplate($name: String!, $id: String!) {
    cloneTemplate(name: $name, id: $id) {
      ok
      template {
        id
        name
      }
    }
  }
`;

export const CREATE_PATIENT = gql`
  mutation createPatient($id: String!, $centreId: String!) {
    createPatient(id: $id, centreId: $centreId) {
      ok
      patient {
        id
        code
      }
    }
  }
`;

export const DELETE_PATIENT = gql`
  mutation deletePatient($id: String!) {
    deletePatient(id: $id) {
      ok
    }
  }
`;

export const CHANGE_STATUS_PATIENT = gql`
  mutation changeStatusPatient($id: String!, $status: String!) {
    changeStatusPatient(id: $id, status: $status) {
      ok
      patient {
        id
        status
      }
    }
  }
`;

export const CHANGE_BRANCH_PATIENT = gql`
  mutation changeBranchPatient($id: String!, $branch: String!) {
    changeBranchPatient(id: $id, branch: $branch) {
      ok
      patient {
        id
        branch
      }
    }
  }
`;

export const UPDATE_FILLED_VISIT = gql`
  mutation updateFilledVisit($id: String!, $input: FilledVisitInput!) {
    updateFilledVisit(id: $id, input: $input) {
      ok
      patient {
        id
        code
      }
    }
  }
`;

export const CHANGE_STATUS_FILLED_VISIT = gql`
  mutation changeStatusFilledVisit($id: String!, $status: String!) {
    changeStatusFilledVisit(id: $id, status: $status) {
      ok
      filledVisit {
        id
        status
      }
    }
  }
`;

export const CREATE_INPUT_MONITORIZATION = gql`
  mutation createInputMonitorization(
    $id: String!
    $text: String!
    $reference: String!
    $fixType: String!
    $inputType: String!
    $inputLabel: String
    $inputValue: String
  ) {
    createInputMonitorization(
      id: $id
      text: $text
      reference: $reference
      fixType: $fixType
      inputType: $inputType
      inputLabel: $inputLabel
      inputValue: $inputValue
    ) {
      ok
      monitorization {
        id
        status
      }
    }
  }
`;

export const ADD_INPUT_FIX = gql`
  mutation addInputFix(
    $id: String!
    $text: String!
    $fixType: String!
    $inputValue: String
  ) {
    addInputFix(
      id: $id
      text: $text
      fixType: $fixType
      inputValue: $inputValue
    ) {
      ok
      monitorization {
        id
        status
      }
    }
  }
`;
