import React from 'react';
import {
  MONITORIZATION_TYPE_CHECKED,
  MONITORIZATION_TYPE_INCIDENCE,
  MONITORIZATION_TYPE_COMMENT
} from "../../config";
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import Comment from '../../assets/comment.png'
import Check from '../../assets/check.png'
import Question from '../../assets/question.png'

const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  header: {
    fontSize: 20,
    paddingTop: 10,
    paddingBottom:10
  },
  subheader: {
    fontSize: 16,
    paddingTop: 10,
    paddingBottom:5
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    '@media max-width: 400': {
      flexDirection: 'column',
    },
  },
  image: {
    marginBottom: 10,
    marginLeft:2,
    width:50,
    height: 50,
  },
  leftColumn: {
    flexDirection: 'column',
    width: 100,
    paddingTop: 30,
    paddingRight: 15,
    '@media max-width: 400': {
      width: '100%',
      paddingRight: 0,
    },
    '@media orientation: landscape': {
      width: 200,
    },
  },
  inputFix: {
    flex: 1,
    paddingTop: 30,
    paddingLeft: 15,
    '@media max-width: 400': {
      paddingTop: 10,
      paddingLeft: 0,
    },
    fontSize: 12,
    width: 800
  },
  footer: {
    fontSize: 12,
    fontFamily: 'Lato Bold',
    textAlign: 'center',
    marginTop: 25,
    paddingTop: 10,
    borderWidth: 3,
    borderColor: 'gray',
    borderStyle: 'dashed',
    '@media orientation: landscape': {
      marginTop: 10,
    },
  },
});

const PatientMonitorizations = props => (
  <Page size="A4" style={styles.page}>
    <View>
      <Text style={styles.header}>{`${props.visit}`}</Text>
      <Text style={styles.header}>{`${props.t("history")} - ${props.code}`}</Text>
    </View>
    {props.monitorizations.map((monitorization, index) => {
      return (
        <View style={styles.section}>
          <Text style={styles.subheader}>{monitorization.filledSheet.filledVisit.filledVisit.visit.name}</Text>
          {monitorization.fixes.edges.map((element, index) => {
            return (
              <View style={styles.container} wrap={false}>
                <View style={styles.leftColumn}>
                  <Image
                    src={(() => {
                      switch (element.node.fixType) {
                        case MONITORIZATION_TYPE_INCIDENCE:
                          return Question;
                        case MONITORIZATION_TYPE_COMMENT:
                          return Comment;
                        case MONITORIZATION_TYPE_CHECKED:
                          return Check;
                        default:
                          return Question;
                      }
                    })()}
                    style={styles.image}
                  />
                </View>
                <View style={styles.inputFix}>
                  <Text>
                    {`${monitorization.inputLabel}`}
                  </Text>
                  <Text>
                    {`${props.t("sheet")}: ${monitorization.filledSheet.sheet.name}`}
                  </Text>
                  <Text>
                    {`${props.t("user")}: ${element.node.user.username}`}
                  </Text>
                  <Text>{`${props.t("message")}: ${
                    element.node.message
                  }`}</Text>
                  <Text>{`${props.t("value")}: ${
                    element.node.inputValue
                    }`}
                  </Text>
                  <Text>{new Date(element.node.created).toLocaleString()}</Text>
                </View>
              </View>
            );
          })}
        </View>
      );
    })}
  </Page>
);



export default PatientMonitorizations;