import React from "react";
import { withTranslation } from "react-i18next";
import { client } from "../../../apolloClient";
import {
  Dialog,
  Button,
  FormGroup,
  InputGroup,
  TextArea,
  Switch,
  Icon,
  Tag,
  MenuItem,
} from "@blueprintjs/core";
import { GET_CRF } from "../../../queries";
import { CREATE_CRF, UPDATE_CRF } from "../../../mutations";
import Dropzone from "react-dropzone";
import { mediaFilesAppend } from "../../../config";
import "./_Create.scss";

class CRFForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      crf: {
        id: "",
        logoImage: [],
        name: "",
        suffix: "",
        preffix: "",
        patientCoding: "",
        description: "",
        fingerprint: "",
        monitorization: true,
        templates: [],
      },
      isEdit: false,
      loading: false,
      redirect: false,
      checked: false,
      tabSelected: "first",
      template: {
        name: "",
        kind: "visit",
        template: [],
        fileName: "",
        error: "",
      },
    };

    this.handleClose = this.handleClose.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.nameInputValidation = React.createRef();
    this.types = [
      { id: "visit", text: this.props.t("visit") },
      { id: "sheet", text: this.props.t("sheet") },
      { id: "container", text: this.props.t("container") },
    ];
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id) {
      try {
        if (this.props.id !== null) {
          this.setState({ loading: true });
          const variables = { id: this.props.id };
          client
            .query({
              variables: variables,
              query: GET_CRF,
              fetchPolicy: "network-only",
            })
            .then((res) => {
              this.setState((prevState) => ({
                crf: {
                  ...prevState.crf,
                  ...res.data.crf,
                },
                isEdit: true,
                loading: false,
              }));
            });
        }
      } catch (err) {}
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({ loading: true });
    const mutation = this.state.isEdit ? UPDATE_CRF : CREATE_CRF;

    const variables = {
      name: this.state.crf.name,
      logoImage:
        typeof this.state.crf.logoImage === "object"
          ? this.state.crf.logoImage[0]
          : this.state.crf.logoImage,
      suffix: this.state.crf.suffix,
      preffix: this.state.crf.preffix,
      patientCoding: this.state.crf.patientCoding,
      description: this.state.crf.description,
      fingerprint: this.state.crf.fingerprint,
      monitorization: this.state.crf.monitorization,
      templates: this.state.crf.templates,
    };

    if (this.state.isEdit) {
      variables.id = this.state.crf.id;
    }
    client
      .mutate({
        variables,
        mutation,
      })
      .then((res, err) => {
        if (err) console.log(err);
        this.setState(
          {
            loading: false,
            redirect: true,
            checked: this.state.crf.monitorization,
          },
          this.props.onCreateSubmit(),
          this.resetForm()
        );
      });
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState((prevState) => ({
      crf: {
        ...prevState.crf,
        [name]: value,
      },
    }));
  };

  handleChangeCheckbox = (event) => {
    let { name, value } = event.target;
    const { checked } = this.state;
    this.setState((prevState) => ({
      crf: {
        ...prevState.crf,
        [name]: !this.state.crf.monitorization,
      },
      checked: !checked,
    }));
  };

  handleClickTab = (event) => {
    const target = event.target.target;

    this.setState({
      tabSelected: target,
    });
  };

  handleNextTab = (event) => {
    let target = event.target.attributes["target"];
    if (!target) {
      target = event.target.parentElement.attributes["target"].nodeValue;
    } else {
      target = target.nodeValue;
    }
    if (this.validateFirstStep()) {
      this.nameInputValidation.current.style.display = "none";
      this.setState({
        tabSelected: target,
      });
    } else this.nameInputValidation.current.style.display = "block";
  };

  handleAddTemplate = () => {
    const fr = new FileReader();
    const that = this;
    fr.onload = function (e) {
      let result = JSON.parse(e.target.result);

      // VALIDATE json format is compatible with formio (For now only checks if the template has an attribute formBuild, improve this validation?)
      if (typeof result.formBuild !== "undefined") {
        let formatted = JSON.stringify(result, null, 2);
        let templates = that.state.crf.templates.slice();
        let template = {
          name: that.state.template.name,
          kind: that.state.template.kind,
          template: formatted,
        };
        templates.push(template);
        that.setState((prevState) => ({
          crf: {
            ...prevState.crf,
            templates: templates,
          },
          template: {
            name: "",
            kind: "visit",
            template: [],
            fileName: "",
            error: "",
          },
        }));
      } else {
        that.setState((prevState) => ({
          template: {
            ...prevState.template,
            error: that.props.t("error.template.add"),
          },
        }));
      }
    };

    fr.readAsText(this.state.template.template[0]);
  };

  onDropImage = (image) => {
    this.setState((prevState) => ({
      crf: { ...prevState.crf, logoImage: image },
    }));
  };

  onDropTemplate = (template) => {
    this.setState((prevState) => ({
      template: {
        ...prevState.template,
        template: template,
        fileName: template[0].name,
      },
    }));
  };

  handleRemoveTemplate = (index) => {
    let templates = this.state.crf.templates.slice();
    templates.splice(index, 1);
    this.setState((prevState) => ({
      crf: {
        ...prevState.crf,
        templates: templates,
      },
    }));
  };

  handleClose() {
    this.resetForm();
    this.props.toggleFunction();
  }

  resetForm() {
    this.setState({
      crf: {
        id: "",
        logoImage: [],
        name: "",
        suffix: "",
        preffix: "",
        patientCoding: "",
        description: "",
        fingerprint: "",
        monitorization: true,
        templates: [],
      },
      tabSelected: "first",
    });
  }

  validateFirstStep = () => {
    return this.state.crf.name !== "" ? true : false;
  };

  renderItemTemplateType = (item, { modifiers, handleClick }) => {
    return (
      <MenuItem
        active={modifiers.active}
        key={item.id}
        onClick={handleClick}
        text={item.text}
        shouldDismissPopover={false}
      />
    );
  };

  areTypesEquals = (a, b) => {
    let equal = a.id === b.id ? true : false;

    return equal;
  };

  handleSelectTypeChange = (type) => {
    this.setState((prevState) => ({
      template: {
        ...prevState.template,
        kind: type,
      },
    }));
  };

  render() {
    return (
      <Dialog
        icon="people"
        onClose={this.handleClose}
        title={this.props.t("createCRD")}
        isOpen={this.props.isOpen}
        style={{ width: 780, height: 570, "padding-bottom": "unset" }}
      >
        <div className="row gen-container">
          <div className="col-4 list-cont">
            <ul className="list">
              <li
                className={
                  this.state.tabSelected === "first"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <a
                  className="nav-link"
                  onClick={this.handleClickTab}
                  target="first"
                >
                  <span className="number">1</span>
                  {this.props.t("CRDInfo")}
                </a>
                <p className="list-info">{this.props.t("first.step.info")}</p>
              </li>
              <li
                className={
                  this.state.tabSelected === "second"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <a
                  className="nav-link"
                  onClick={this.handleClickTab}
                  target="second"
                >
                  <span className="number">2</span>
                  {this.props.t("CRDPatient")}
                </a>
                <p className="list-info">{this.props.t("second.step.info")}</p>
              </li>
              <li
                className={
                  this.state.tabSelected === "third"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <a
                  className="nav-link"
                  onClick={this.handleClickTab}
                  target="third"
                >
                  <span className="number">3</span>
                  {this.props.t("CRDTemplate")}
                </a>
                <p className="list-info">{this.props.t("third.step.info")}</p>
              </li>
            </ul>
          </div>
          <div className="col general-form">
            <h4 className="mb-3 mt-3">
              <a href="#">{this.props.t(this.props.title)}</a>
            </h4>
            <form onSubmit={this.handleSubmit}>
              <div
                id="first"
                style={
                  this.state.tabSelected === "first"
                    ? { display: "block" }
                    : { display: "none" }
                }
                className="first"
              >
                <div className="form-container">
                  <div className="row">
                    <div className="col">
                      <FormGroup
                        label={this.props.t("name")}
                        labelFor="name"
                        labelInfo="(required)"
                      >
                        <InputGroup
                          required={true}
                          id="name"
                          name="name"
                          onChange={this.handleChange}
                          value={this.state.crf.name}
                          placeholder={this.props.t("name")}
                          large={true}
                        />
                        <div
                          class="invalid-feedback"
                          ref={this.nameInputValidation}
                          style={{ display: "none" }}
                        >
                          {this.props.t("name.required")}
                        </div>
                      </FormGroup>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <FormGroup
                        label={this.props.t("description")}
                        labelFor="description"
                      >
                        <TextArea
                          id="description"
                          name="description"
                          fill={true}
                          onChange={this.handleChange}
                          value={this.state.crf.description}
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="row drop-zone-cont">
                    <div className="col">
                      <label className="logo-title">
                        {this.props.t("logo")}
                      </label>
                      <p className="text-secondary">
                        {this.props.t("uploadText")}
                      </p>
                    </div>
                    <div className="col">
                      <Dropzone
                        className="w-100"
                        accept="image/jpeg, image/png"
                        multiple={false}
                        onDrop={this.onDropImage}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section className="drop-zone">
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              {((Array.isArray(this.state.crf.logoImage) &&
                                this.state.crf.logoImage.length === 0) ||
                                !this.state.crf.logoImage) && (
                                <div className="dzi">
                                  <Icon icon="cloud-upload" iconSize={40} />
                                </div>
                              )}
                              {this.state.crf.logoImage.length !== 0 &&
                                typeof this.state.crf.logoImage ===
                                  "object" && (
                                  <img
                                    style={{ width: "50px" }}
                                    src={window.URL.createObjectURL(
                                      new File(this.state.crf.logoImage, {
                                        type: "image/png",
                                      })
                                    )}
                                    alt=""
                                  />
                                )}
                              {typeof this.state.crf.logoImage === "string" && (
                                <img
                                  style={{ width: "50px" }}
                                  src={
                                    mediaFilesAppend + this.state.crf.logoImage
                                  }
                                  alt=""
                                />
                              )}
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <Switch
                        name="monitorization"
                        id="monitorization"
                        className={`switch${
                          this.state.crf.monitorization ? "-checked" : ""
                        }`}
                        large={true}
                        checked={this.state.crf.monitorization}
                        label={this.props.t("formCrf.monitorization")}
                        onChange={this.handleChangeCheckbox}
                      />
                    </div>
                  </div>
                  <div className="row text-right first-button-cont">
                    <div className="col">
                      <hr className="crf-first-footer-line" />
                      <Button
                        className={"first-button"}
                        large={true}
                        intent="success"
                        target="second"
                        onClick={this.handleNextTab}
                      >
                        {this.props.t("next")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="second"
                style={
                  this.state.tabSelected === "second"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <div className="row">
                  <div className="col-sm-12">
                    <FormGroup
                      label={this.props.t("preffix")}
                      labelFor="preffix"
                    >
                      <InputGroup
                        id="preffix"
                        name="preffix"
                        onChange={this.handleChange}
                        value={this.state.crf.preffix}
                        placeholder={this.props.t("preffix")}
                        large={true}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-sm-12">
                    <FormGroup
                      label={this.props.t("crfForm.patientCoding")}
                      labelFor="patientCoding"
                    >
                      <div className="bp3-select bp3-fill bp3-large">
                        <select
                          id="patientCoding"
                          onChange={this.handleChange}
                          name="patientCoding"
                          value={this.state.crf.patientCoding}
                        >
                          <option selected>Choose an item...</option>
                          <option value="CC_GPC">
                            Centre Code + Global Patient Code
                          </option>
                          <option value="CC_CPC">
                            Centre Code + Centre Patient Code
                          </option>
                          <option value="GPC">Global Patient Code</option>
                          <option value="GPC_CC_CPC">
                            Global Patient Code + Centre Code + Centre Patient
                            Code
                          </option>
                        </select>
                      </div>
                    </FormGroup>
                  </div>
                  <div className="col-sm-12">
                    <FormGroup
                      label={this.props.t("crfForm.suffix")}
                      labelFor="suffix"
                    >
                      <InputGroup
                        id="suffix"
                        name="suffix"
                        onChange={this.handleChange}
                        value={this.state.crf.suffix}
                        placeholder={this.props.t("crfForm.suffix")}
                        large={true}
                      />
                    </FormGroup>
                  </div>
                </div>
                <div className="row second-buttons">
                  <hr className="crf-footer-line" />
                  <div className="col-md-3">
                    <Button
                      className={"second-back-button"}
                      intent="primary"
                      large={true}
                      target="first"
                      onClick={this.handleNextTab}
                    >
                      {this.props.t("back")}
                    </Button>
                  </div>
                  <div className="col-md-9 text-right">
                    <Button
                      className={"second-button"}
                      large={true}
                      intent="success"
                      target="third"
                      onClick={this.handleNextTab}
                    >
                      {this.props.t("next")}
                    </Button>
                  </div>
                </div>
              </div>

              <div
                id="third"
                style={
                  this.state.tabSelected === "third"
                    ? { display: "block" }
                    : { display: "none" }
                }
                className="third"
              >
                <div className="row">
                  <div className="col-md-12">
                    <FormGroup
                      label={this.props.t("name")}
                      labelFor="template_name"
                      labelInfo="(required)"
                    >
                      <InputGroup
                        id="template_name"
                        name="template_name"
                        onChange={(event) => {
                          const { name, value } = event.target;
                          this.setState((prevState) => ({
                            template: {
                              ...prevState.template,
                              name: value,
                            },
                          }));
                        }}
                        placeholder={this.props.t("name")}
                        value={this.state.template.name}
                        large={true}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-md-12">
                    <div class="bp3-select bp3-fill bp3-large">
                      <FormGroup
                        label={this.props.t("template.kind")}
                        labelFor="template_kind"
                        labelInfo="(required)"
                      >
                        <select
                          id="template_kind"
                          name="template_kind"
                          onChange={(event) => {
                            const { name, value } = event.target;
                            this.setState((prevState) => ({
                              template: {
                                ...prevState.template,
                                kind: value,
                              },
                            }));
                          }}
                        >
                          {this.types.map((val, index) => {
                            return <option value={val.id}>{val.text}</option>;
                          })}
                        </select>
                      </FormGroup>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-12">
                    <Dropzone
                      className="w-100"
                      accept="application/json, text/plain"
                      multiple={false}
                      onDrop={this.onDropTemplate}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section className="drop-zone">
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            {((Array.isArray(this.state.template.template) &&
                              this.state.template.template.length === 0) ||
                              !this.state.template.template) && (
                              <div>
                                <Icon icon="cloud-upload" iconSize={40} />
                                <p className="dropzone-solid-blue">
                                  {this.props.t("uploadText")}
                                </p>
                              </div>
                            )}
                            {this.state.template.template.length !== 0 &&
                              typeof this.state.template.template ===
                                "object" && (
                                <div>
                                  <p className="dropzone-solid-blue">
                                    {this.state.template.fileName}
                                  </p>
                                </div>
                              )}
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    {this.state.template.template.length !== 0 &&
                      typeof this.state.template.template === "object" && (
                        <div className="mt-2">
                          <Button
                            large={true}
                            intent="success"
                            onClick={this.handleAddTemplate}
                            icon="plus"
                          >
                            {this.props.t("add")}
                          </Button>
                        </div>
                      )}
                  </div>
                </div>
                {this.state.template.error !== "" && (
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div className="alert alert-danger">
                        {this.state.template.error}
                      </div>
                    </div>
                  </div>
                )}
                <div className="row mb-3">
                  <div className="col-md-12">
                    {this.state.crf.templates.map((template, index) => (
                      <Tag
                        key={`template-${index}`}
                        className="mr-3"
                        minimal={true}
                        large={true}
                        round={true}
                        onRemove={() => this.handleRemoveTemplate(index)}
                      >
                        {template.name}
                      </Tag>
                    ))}
                  </div>
                </div>
                <div className="row third-buttons">
                  <hr className="crf-footer-line" />
                  <div className="col-md-3">
                    <Button
                      className={"third-back-button"}
                      intent="primary"
                      large={true}
                      target="second"
                      onClick={this.handleNextTab}
                    >
                      {this.props.t("back")}
                    </Button>
                  </div>
                  <div className="col-sm-9 text-right">
                    <Button
                      type="submit"
                      large={true}
                      intent="success"
                      className={"fourth-button"}
                    >
                      {this.state.isEdit
                        ? this.props.t("form.update")
                        : this.props.t("form.create")}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          {/* </Card> */}
        </div>
      </Dialog>
    );
  }
}

export default withTranslation()(CRFForm);
