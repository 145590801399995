import React from "react";
import PropTypes from "prop-types";
import { NavLink as RouteNavLink } from "react-router-dom";
import "./_SideBarNavItem.scss";
import { Icon } from "@blueprintjs/core";
import { withTranslation } from "react-i18next";

const SidebarNavItem = ({ item, t }) => {
  const { title, to, icon, bottomSeparate, bottomCategory, onlyIcon } = item;
  return (
    <div className={`mt-4 nav-item-color  ${!onlyIcon ? "ml-3" : "ml-3"}`}>
      <RouteNavLink
        className="nav-item-color"
        to={to}
        activeStyle={{ color: "#0da7b4" }}
      >
        <Icon
          iconSize="20px"
          icon={icon}
          className={`${onlyIcon ? "ml-3" : ""}`}
        />
        {t(title) && !onlyIcon && <span className="ml-3">{t(title)}</span>}
      </RouteNavLink>
      {bottomSeparate && (
        <>
          <hr />
          <h5
            className={`nav-item-secondary-color ${onlyIcon ? "d-none" : ""}`}
          >
            {t(bottomCategory)}
          </h5>
        </>
      )}
    </div>
  );
};

SidebarNavItem.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.object
};

export default withTranslation()(SidebarNavItem);
