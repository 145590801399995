import {
  Button,
  Dialog,
  Classes,
  Spinner,
  MenuItem,
  InputGroup,
} from "@blueprintjs/core";
import React, { useState, useEffect } from "react";
import { GET_CRF_FULL, GET_CENTRES } from "../../queries";
import { client } from "../../apolloClient";
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import CheckboxTree from 'react-checkbox-tree';
import { downloadCsvFile, downloadSpsFile } from "../../utils/DownloadFile";
import {
  API_URL,
  EXPORT_TYPE_SECTION_ENCODED,
  EXPORT_TYPE_VARIABLE_ENCODED,
  EXPORT_TYPE_VARIABLE_LABEL,
  EXPORT_SINTAX,
  EXPORT_MAP,
  PATIENT_STATUS_CLOSED,
  PATIENT_STATUS_NEW,
  PATIENT_STATUS_INPROGRESS,
  PATIENT_STATUS_SIGNED,
  PATIENT_STATUS_MONITORING,
} from "../../config";
import { getToken, getSEK } from "../../services";
import "./_ModalExport.scss";
import { Select } from "@blueprintjs/select";
import FieldFilter from './FieldFilter';

const ModalExport = ({ t, isOpen, toggleFunction, entityId, exportType }) => {
  const [state, setState] = useState({
    autoFocus: true,
    canEscapeKeyClose: true,
    canOutsideClickClose: true,
    enforceFocus: true,
    usePortal: true,
    ready: false,
    fetched: false,
    loading: false,
    crf: [],
    tree:  [],
    checked: [],
    expanded: [],
    centres: [],
    search: {
      centre: { id: null, name: t("select.centre") },
      code: "",
      branch: "",
      state: { id: "", text: "All states" },
      fields: []
    },
  });

  const states = [
    { id: "", text: "All States" },
    { id: PATIENT_STATUS_NEW, text: "New" },
    { id: PATIENT_STATUS_INPROGRESS, text: "In Progress" },
    { id: PATIENT_STATUS_MONITORING, text: "Monitoring" },
    { id: PATIENT_STATUS_CLOSED, text: "Closed" },
    { id: PATIENT_STATUS_SIGNED, text: "Signed" },
  ];

  const handleClose = () => {
    toggleFunction();
    setState({
      autoFocus: true,
      canEscapeKeyClose: true,
      canOutsideClickClose: true,
      enforceFocus: true,
      usePortal: true,
      ready: false,
      fetched: false,
      loading: false,
      crf: [],
      tree:  [],
      checked: [],
      expanded: [],
      centres: state.centres,
      search: {
        text: "",
        centre: { id: null, name: t("select.centre") },
        code: "",
        branch: "",
        state: { id: "", text: "All states" },
        fields: []
      },
    });
  }

  const downloadExport = () => {
    setState({...state, loading: true });
    downloadCsv(entityId, state.crf.name, exportType);
  }

  const downloadCsv = async (id, name, type) => {
    fetch(`${API_URL}/crf/export/${id}/${type}`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        authorization: `${getToken()}$_${getSEK()}`,
      },
      
      body: JSON.stringify({ fields:  state.checked, filter: state.search })
    })
      .then((response) => {
        console.log("Se ejecuta la función downloadCsv (1then) en dashboard")
        const contentType = response.headers.get("content-type");
        if (
          !contentType ||
          !(
            contentType.includes("text/csv") ||
            contentType.includes("text/plain")
          )
        ) {
          throw new TypeError("Oops, we haven't got CSV!");
        }
        return response.text();
      })
      .then((data) => {
        console.log("Se ejecuta la función downloadCsv (2then) en dashboard")
        handleClose();
        if (type == EXPORT_SINTAX) {
          const filename = `${name
            .trim()
            .replace(/\s/g, "-")}-SPS-${new Date().getTime()}`;
          downloadSpsFile(data, filename);
        } else if (type == EXPORT_MAP) {
          const filename = `${name
            .trim()
            .replace(/\s/g, "-")}-MAP-${new Date().getTime()}`;
          downloadCsvFile(data, filename);
        } else {
          let filename = name.trim().replace(/\s/g, "-");
          filename =
            type == EXPORT_TYPE_VARIABLE_ENCODED ||
            type == EXPORT_TYPE_VARIABLE_LABEL
              ? filename + "-SPSS"
              : filename;
          filename =
            type == EXPORT_TYPE_VARIABLE_ENCODED ||
            type == EXPORT_TYPE_SECTION_ENCODED
              ? filename + "-ENCODED"
              : filename;
          filename = filename + `-${new Date().getTime()}`;
          downloadCsvFile(data, filename);
        }
      })
      .catch((error) => console.error(error));
  };

  const getFormTree = (form, sheet) => {
    if(Array.isArray(form)) {
      return form.reduce((accumulator, container) => {
        const formTree = getFormTree(container, sheet);
        if (formTree !== null && typeof(formTree) !== 'undefined') {
          accumulator.push(formTree)
        }
        return accumulator;
      }, []);
    } else if(typeof form === 'object') {
      if(form.input === true && !form.hasOwnProperty('components')) {
        const checked = state.checked;
        if(!checked.includes(`${form.key}_${sheet}`))
          checked.push(`${form.key}_${sheet}`)

        return {
          label: form.label,
          value: `${form.key}_${sheet}`,
        }
      } else if(form.hasOwnProperty('components')) {
        let childrens = getFormTree(form.components, sheet)
        if (form.label) {
          return childrens && childrens.length > 0 ? {
            label: form.label,
            value: `${form.id}_${sheet}`,
            children: childrens
          }: null
        } else {
          return childrens && childrens.length > 0 ? childrens: null;
        }
      } else if(form.hasOwnProperty('columns')) {
        let childrens = form.columns.reduce((accumulator, column) => {
          Array.prototype.push.apply(accumulator, getFormTree(column.components, sheet));
          return accumulator;
        }, [])
        if ( childrens && childrens.length > 0) {
          return {
            label: form.label,
            value: `${form.id}_${sheet}`,
            children: childrens && childrens.length > 0 ? childrens: null
          }
        } else {
          return null;
        }
        
      } else if(form.hasOwnProperty('rows')) {
        let childrens = form.rows.reduce((accumulator, row, index) => {
          row.forEach(element => {
            Array.prototype.push.apply(accumulator, getFormTree(element.components, sheet));
          });
          return accumulator;
        }, []);

        return {
          label: form.label,
          value: `${form.id}_${sheet}`,
          children: childrens
        }
      }
    }

    return null;
  }

  if(state.centres.length == 0) {
    client
      .query({
        query: GET_CENTRES,
        variables: { elementsPerPage: 1000, page: 1 },
      })
      .then((res) => {
        if(res.data.centres.objects[0]) {
          if (res.data.centres.objects[0].id !== null) 
            res.data.centres.objects.unshift({
              id: null,
              name: t("select.centre"),
            });
            setState({
              ...state,
              centres: res.data.centres.objects
            });
        }
        
      });
  }

  const removeDuplicateValues = (obj) => {
    let valuesSet = new Set(); 
    function traverse(node) {
      if (node.value) {
        let uniqueValue = node.value;
        let counter = 1;
        // Checks if the value already exists
        while (valuesSet.has(uniqueValue)) {
          
          let parts = node.value.split("_");
          let number = parseInt(parts[0].match(/\d/));
          let incrementedNumber;

          if (isNaN(number)) {
            incrementedNumber = counter;
          } else {
            incrementedNumber = number + counter;
          }

          parts[0] = parts[0].replace(String(number), "");

          uniqueValue = parts[0] + String(incrementedNumber) + "_" + parts[1];
          counter++;
        }

        // Add the single value to the set
        valuesSet.add(uniqueValue);
        // Update the node value to the unique value
        node.value = uniqueValue;

      }
      
      // Recursively calls traverse for each child of the current node
      if (node.children) {
        node.children.forEach((child) => traverse(child));
      }
    }

    obj.forEach((node) => traverse(node));

    return obj;
  }


  if (!state.fetched && entityId)
    client
      .query({
        query: GET_CRF_FULL,
        variables: {id: entityId},
      })
      .then((res) => {
        setState({
          ...state,
          fetched: true,
          loading: false,
          crf: res.data.crf,
          tree: res.data.crf.visits.edges.map(visit => {
            let childrens = visit.node.sheets.edges.map(sheet => {
              const form = JSON.parse(sheet.node.formBuild)

              if(form.components && form.components.length > 0) {
                let sheetComponents = getFormTree(form.components, sheet.node.id);
                if (sheetComponents && sheetComponents.length > 0) {
                  return {
                    label: sheet.node.name,
                    value: sheet.node.id,
                    children: getFormTree(form.components, sheet.node.id)
                  }
                } else {
                    return {
                      label: sheet.node.name,
                      value: sheet.node.id,
                      disabled: true
                    }
                }
              } else {
                return {
                  label: sheet.node.name,
                  value: sheet.node.id,
                  disabled: true
                }
              }
            });
            removeDuplicateValues(childrens);
            return childrens && childrens.length > 0 ? {
              label: visit.node.name,
              value: visit.node.id,
              children: childrens
            }: {
              label: visit.node.name,
              value: visit.node.id,
              disabled: true
            }
          })
        });

      });

  // Centre search options

  const renderItem = (item, { modifiers, handleClick }) => {
    return (
      <MenuItem
        active={modifiers.active}
        key={item.id}
        label={item.code}
        onClick={handleClick}
        text={item.name}
        shouldDismissPopover={false}
      />
    );
  };

  const renderItemState = (item, { modifiers, handleClick }) => {
    return (
      <MenuItem
        active={modifiers.active}
        key={item.id}
        onClick={handleClick}
        text={item.text}
        shouldDismissPopover={false}
      />
    );
  };

  const areCentreEquals = (a, b) => {
    let equal = a.id === b.id ? true : false;

    return equal;
  };

  const areStateEquals = (a, b) => {
    let equal = a.id === b.id ? true : false;

    return equal;
  };

  const handleSelectChange = (centre) => {

    setState((prevState) => ({
      ...prevState,
      search: {
        ...prevState.search,
        centre,
      },
    }));
  };

  const handleSelectStateChange = (state) => {
    setState((prevState) => ({
      ...prevState,
      search: {
        ...prevState.search,
        state,
      },
    }));
  };

  const handleFilterCentre = (query, centre) =>
    centre.name.indexOf(query) >= 0

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      search: {
        ...prevState.search,
        [name]: value,
      },
    }));
  };

  const addFieldFilter = () => {
    const fields = state.search.fields.slice();
    fields.push({visit: "", sheet: "", field: "", comparator: null, value: "" });
    setState((prevState) => ({
      ...prevState,
      search: {
        ...prevState.search,
        fields,
      },
    }));
  };


  const removeFieldFilter = (index) => {
    const fields = state.search.fields.slice();
    fields.splice(index, 1);

    setState((prevState) => ({
      ...prevState,
      search: {
        ...prevState.search,
        fields: fields
      },
    }));
  };

  const handleSelectField = (field, index) => {
    const fields = state.search.fields;
    fields[index] = field;

    setState((prevState) => ({
      ...prevState,
      search: {
        ...prevState.search,
        fields,
      },
    }));
  };

  const renderFields = () => {
    return (state.search.fields.map((field, idx)=> {
      return (
        <FieldFilter crf={state.crf} field={field} index={idx} handleSelectField={handleSelectField} removeFieldFilter={removeFieldFilter} t={t} />
      )
    }));
  }

  return (
    <Dialog
      icon="info-sign"
      onClose={handleClose}
      title={`${t("export")}`}
      isOpen={isOpen}
      className="dialog-big"
    >
      <div className={Classes.DIALOG_BODY}>

        {state.loading && (
          <div className="spinner-loader-listing">
            <Spinner
              size={80}
              spinnerColor={"#007BFF"}
              spinnerWidth={2}
              visible={true}
            />
          </div>
        )}
        {!state.loading && (
          <div>
            <div className="row pt-2 pb-3">
              <div className="col">
                <Select
                  itemsEqual={areCentreEquals}
                  itemRenderer={renderItem}
                  items={state.centres}
                  large={true}
                  itemPredicate={handleFilterCentre}
                  activeItem={state.search.centre}
                  className="w-100"
                  noResults={
                    <MenuItem
                      icon="office"
                      disabled={true}
                      text={t("No results.")}
                    />
                  }
                  onItemSelect={handleSelectChange}
                >
                  <Button
                    icon="office"
                    rightIcon="caret-down"
                    large={true}
                    fill={true}
                    text={
                      state.search.centre
                        ? state.search.centre.name
                        : t("All my centres.")
                    }
                  />
                </Select>
              </div>
              <div className="col">
                <InputGroup
                  id="code"
                  name="code"
                  onChange={handleChange}
                  value={state.search.code}
                  placeholder={t("code")}
                  large={true}
                />
              </div>
              <div className="col">
                <InputGroup
                  id="branch"
                  name="branch"
                  onChange={handleChange}
                  value={state.search.branch}
                  placeholder={t("branch")}
                  large={true}
                />
              </div>
              <div className="col">
                <Select
                  itemsEqual={areStateEquals}
                  itemRenderer={renderItemState}
                  items={states}
                  activeItem={state.search.state}
                  large={true}
                  className="w-100"
                  onItemSelect={(item) => handleSelectStateChange(item)}
                >
                  <Button
                    rightIcon="caret-down"
                    large={true}
                    fill={true}
                    text={
                      state.search.state
                        ? state.search.state.text
                        : t("All states")
                    }
                  />
                </Select>
              </div>
            </div>
            {
              renderFields()
            }
            <Button className="mb-3" intent='primary' onClick={addFieldFilter}>{t('add.fieldFilter')}</Button>
            <CheckboxTree
              nodes={state.tree}
              checked={state.checked}
              expanded={state.expanded}
              onCheck={checked => setState({...state, checked })}
              onExpand={expanded => setState({...state, expanded })}
            />
          </div>
          
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={() => handleClose()}>{t("close")}</Button>
          {!state.loading && (
            <Button onClick={() => downloadExport()}>
                {t("export")}
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default ModalExport;
