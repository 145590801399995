import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import en_translation from "./translations/en_translations";
import es_translation from "./translations/es_translations";
import fr_translation from "./translations/fr_translations";
import de_translation from "./translations/de_translations";
import it_translation from "./translations/it_translations";
import pt_translation from "./translations/pt_translations";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en_translation
      },
      es: {
        translation: es_translation
      },
      de: {
        translation: de_translation
      },
      it: {
        translation: it_translation
      },
      fr: {
        translation: fr_translation
      },
      pt: {
        translation: pt_translation
      }
    },
    debug: false,
    keySeparator: false,

    interpolation: {
      formatSeparator: ","
    },

    // react: {
    //   wait: true
    // }
    react: { useSuspense: true }

  });

export default i18n;
