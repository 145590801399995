export default {
  "login.text": "User access",
  "login.recover": "Recover password",
  "placeholder.username": "Username",
  "username.required": "Username required",
  "password.required": "Password required",
  "placeholder.email": "Email",
  "placeholder.password": "Password",
  "login.button": "Access",
  searchBar: "Search what you need",
  "username.placeholder": "Email or username",
  "username.required": "Username required",
  "password.placeholder": "password",
  "password.required": "Password required",
  login: "Login",
  "table.edit": "Edit",
  "table.delete": "Delete",
  "table.options": "Options",
  "table.name": "Name",
  "table.icon": "Icon",
  "table.actions": "Actions",
  "table.close": "Close",
  "table.clone": "Clone CRF",
  deleteModalHeader: "Delete",
  downloadDicom: "Download DICOM",
  uploadDicom: "Upload DICOM",
  uploadText: "Drag and drop files here or click to select them",
  uploadRequired: "You must submit an icon for the allergen!",
  "form.newAllergen": "New allergen",
  "form.newCountry": "New Country",
  "table.country": "Country",
  "form.editing": "Editing",
  "form.required": "Debe rellenar los campos requeridos",
  name: "Name",
  back: "Back",
  "form.update": "Update",
  "form.create": "Create",
  "users.title": "Users",
  new: "New",
  search: "Search",
  "user.create.title": "Create user",
  "userForm.username": "Username",
  "userForm.password": "Password",
  "userForm.repeatPassword": "Repeat password",
  "userForm.firstName": "First name",
  "userForm.lastName": "Last name",
  "userForm.email": "Email",
  "userForm.avatar": "Avatar",
  confirmDelete: "Are you sure you want to delete the selected item?",
  confirmClose: "Are you sure you want to close the selected item?",
  address: "Address",
  patients: "Patients",
  design: "Design",
  "centreForm.observations": "Observations",
  localization: "Localization",
  "crfs.title": "List CRFs",
  "crf.create.title": "Create CRF",
  "convert.template": "Convert to template",
  "visit.edit.title": "Edit visit",
  "visit.create.title": "Create visit",
  "form.edit": "Edit",
  createCentre: "New Centre",
  modifyCentre: "Modify Centre",
  createUser: "New User",
  description: "Description",
  preffix: "Preffix",
  "crfForm.patientCoding": "Patient Code",
  "crfForm.suffix": "Suffix",
  "crfForm.fingerprint": "Finger Print",
  "crfForm.repeatFingerprint": "Repeat Finger Print",
  logo: "Logo",
  showing: "Showing",
  of: "of",
  totalPages: "results",
  cancel: "Cancel",
  datas: "Data",
  security: "Security",
  permissions: "Permissions",
  passwordsNotEquals: "The passwords need to be the same",
  centres: "Centers",
  crfs: "CRFS",
  modifyUser: "Modify User",
  createCRD: "New CRD",
  createVisit: "New Visit",
  modifyCRD: "Modify CRD",
  CRDInfo: "CDR info",
  CRDPatient: "Id Patient Info",
  CRDTemplate: "Template file",
  CRDDgital: "Digital info",
  "error.template.add":
    "Error adding the template, check that the file has the appropiate format.",
  "formVisit.finishingVisit": "Finishing visit",
  "formVisit.branch": "Branch",
  "template.saved": "Template saved succesfully.",
  about: "About Us",
  info: "Legals",
  help: "Help",
  contact: "Contact",
  "visit.saved": "Visit saved succesfully.",
  templateSave: "Create template",
  "formCrf.monitorization": "Habilitar monitorización",
  changeStatus: "Are you sure to change the status of ",
  changeInProgressMonitorization: "If you confirm, the patient will be blocked and you will not be able to add new data.",
  changeStatusHeader: "Change status",
  "filledVisit.title": "Fill visit",
  exit: "Exit",
  next: "Next and save",
  submit: "Save",
  "patient.title": "Patients",
  "no.notifications":
    "You don't have any notification pending to review in this state",
  "sections.encoded": "Sections coded",
  "sections.label": "Sections not coded",
  "variable.label": "Variables not coded",
  "variable.encoded": "Variables coded",
  "map.visits": "Map of vísits",
  sintaxis: "Sintaxis",
  importing: "Importing...",
  "import.crf": "Import CRF",
  "import.success": "Importation succesfully",
  "import.fail": "Importation failed",
  "table.import": "Import",
  "table.export": "Export",
  "table.crfurl": "Share url",
  "monitorization.saved": "Monitorization saved",
  "leave.page": "Es posible que los cambios no se guarden.",
  "no.history": "There is no inputs fixes for this patient.",
  date: "Date",
  centre: "Centre",
  branch: "Branch",
  pendingMonitorizations: "Pending monitorizations",
  status: "Status",
  patient: "Patient",
  "filledVisit.isLast":
    "This version is not the last version. If you want to save this version as last version click on save as last version. Or you can save it without setting it as last version. ",
  "filledVisit.isLastHeader": "save new version?",
  "filledVisit.saveAsLast": "Save as last version",
  "filledVisit.save": "Save",
  "filledVisit.saved": "Data saved succesfully.",
  "filledVisit.saved.error": "Data wasn't saved, there was an error.",
  usuarios: "Users",
  cuadernos: "CRF",
  centros: "Centres",
  inicio: "Home",
  Gestión: "Management",
  Investigación: "Investigation",
  "table.type": "Type",
  province: "Province",
  city: "City",
  postalCode: "Postal code",
  inaugurated: "Inaugurated",
  phone: "Phone",
  "date.pick": "Choose a date",
  history: "Record",
  visit: "Visit",
  obtain_password: "Recover your password",
  problems_access: "Do you need to recover your password?",
  recover_text: "Type your username to recieve the instructions in your email.",
  send: "Save",
  instruction_recovery: "Hemos enviado un correo de recuperación a",
  spam: "Don't forget to check your spam folder!",
  "login.reset": "Reset password",
  "reset.button": "Reset",
  "name.required": "Name is required",
  required: "Required",
  "table.username": "Username",
  "userTable.role": "Role",
  "template.edit.title": "Edit template",
  "session.error": "Error displaying this patient",
  "session.error.message":
    "Error displaying this patient, please close your user session and log in again, if the error persist please contact us.",
  "visit.error": "Error saving the visit",
  change: "Change",
  "crf.link": "Share CRF",
  "filledVisit.not_saved": "Error saving patient data",
  recieveNotifications: "Recieve notifications",
  recieveNotificationsPatients: "Recieve notifications new patients",
  active: "Activo",
  applyCode: "Apply code",
  "centres.title": "Centres",
  "select.centre": "Select a centre",
  exporting: "Exporting",
  "exporting.crf": "Exporting CRF",
  "import.rewrite.policy": "Repeated patients policy",
  "import.rewrite.policy.text":
    "Repeated patients policy:\n Overwrite: Overwrites the data of the repeated patient with the data of the file.\n Add: Adds tje repeated patient of the file as a new patient, generating a new code.\n Ignore: Ignores the repeated patient.",
  "rewrite.policy": "Select repeated patients policy",
  rewrite: "Overwrite",
  add: "Add",
  ignore: "Ignore",
  "visit.diff": "Audit changes",
  "compare.changes": "Compare",
  "select.compare": "Select to compare",
  "field.changes": "Audit field",
  "select.field": "Select field",
  "audit.field": "Or select a field to view all the history of changes",
  "help.diff":
    "Compare two versions of the visit or select a field to view all the history of changes",
  sheet: "Sheet",
  container: "Container",
  "import.from.template": "Import visit from a template (Optional)",
  visits: "Visits",
  "add.code": "Add code",
  "first.step.info": "Fill basic information of the CRF",
  "second.step.info": "Choose the codification of the patients",
  "third.step.info": "Import templates previously downloaded in JSON format",
  "download.pdf": "Download pdf",
  "download.monitorizations": "Download monitorizations",
  "generating.pdf": "Generating PDF",
  "table.patient": "Pacient",
  "table.crf": "CRF",
  "table.visit": "Visit",
  "table.inputLabel": "Field label",
  "table.fixType": "Monitorization type",
  "select.crf": "filter by CRF",
  "add.fieldFilter": "Add field filter",
  "select.value": "Select value",
  "select.visit": "Select visit",
  "select.sheet": "Select sheet",
  "select.field": "Select field",
  "select.comparator": "Select comparator",
  "equal": "Equal",
  "notEqual": "Not equal",
  "greater": "Greater",
  "greaterEqual": "Greater or equal",
  "lower": "Lower",
  "lowerEqual": "Lower or equal",
  "Notifications.title": "Monitorizations",
  "reopenMonitorization": "Reopen monitorization",
  "sign": "Sign",
  "lock": "Complete",
  "reset": "Reset",
  "FileView.title": "Download file",
  "file.view": "Download file",
  "download": "Download",
  "uploadSuccessfully": "Upload Successfully",
  "uploadSuccessfullyMsg": "The file upload was successful",
  "uploadFailed": "Upload Failed",
  "uploadFailedMsg": "File upload was NOT successful",
  "dicomFiles": "DICOM Files",
  "dicomFilesMsg": "Files ready for download:",
  "wait": "Wait",
  "gettingFilesMsg": "Getting files",
  "fileUploading": "The file is being uploaded",
  "errorGettingFileS3": "ERROR getting file from s3:",
  "notFiles": "No files",
  "notFilesMsg": "No files to download"
};
