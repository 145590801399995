import React, { useState } from "react";
import {
  Icon,
} from "@blueprintjs/core";
import {
  Select,
} from "@blueprintjs/core";
import "./_TableSizeSelector.scss";

const TableSizeSelector = ({
  t,
  onChangePage,
  elementsPage,
  totalElements,
}) => {

    const list = [
        10,
        25,
        50,
        100,
    ]
  

  return (
    <div className="row align-items-center">
        <span className="col col-sm-2 text-right text-secondary">{t("showing")}</span>
        <select
            className="col col-sm-1 custom-select size_selector"
            value={elementsPage}
            onChange={evt => {
                evt.preventDefault();
                onChangePage(evt.target.value);
            }}
        >
            {list.map((item => {
                return (
                    <option>
                        {item}
                    </option>
                )
            }))
            }
        </select>
        <span className="col col-sm-3 text-secondary">{t("of")}&nbsp;{totalElements}&nbsp;{t("totalPages")}</span>
    </div>
  );
};

export default TableSizeSelector;
