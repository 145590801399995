export default {
  "login.text": "Acceso usuario",
  "login.recover": "Recuperar contraseña",
  "placeholder.username": "Nombre usuario",
  "username.required": "Nombre de usuario obligatorio",
  "password.required": "Contraseña obligatoria",
  "placeholder.email": "Email",
  "placeholder.password": "Contraseña",
  "login.button": "Acceder",
  searchBar: "Busca",
  "username.placeholder": "Email/Nombre de usuario",
  "username.required": "Nombre de usuario requerido",
  "password.placeholder": "Contraseña",
  "password.required": "Contraseña requerida",
  login: "Login",
  "table.edit": "Editar",
  "table.delete": "Eliminar",
  "table.options": "Opciones",
  "table.name": "Nombre",
  "table.icon": "Icono",
  "table.actions": "Acciones",
  "table.close": "Cerrar",
  "table.clone": "Clonar CRD",
  deleteModalHeader: "Eliminar",
  downloadDicom: "Descargar DICOM",
  uploadDicom: "Subir DICOM",
  uploadDicom1: "Subir DICOM Hidup",
  uploadText: "Arrastra y suelta aquí o pulsa para seleccionar",
  "form.editing": "Editar",
  "form.required": "Debe rellenar los campos requeridos",
  back: "Atrás",
  "form.update": "Actualizar",
  name: "Nombre",
  new: "Nuevo",
  search: "Buscar",
  "form.create": "Crear",
  "users.title": "Usuarios",
  "user.create.title": "Crear usuario",
  "userForm.username": "Nombre de usuario",
  "userForm.password": "Contraseña",
  "userForm.repeatPassword": "Repetir contraseña",
  "userForm.firstName": "Nombre",
  "userForm.lastName": "Apellidos",
  "userForm.email": "Email",
  "userForm.avatar": "Avatar",
  confirmDelete: "¿Está seguro de que desea eliminar el elemento?",
  confirmClose: "¿Está seguro de que desea cerrar el CRD?",
  patients: "Pacientes",
  design: "Diseño",
  "crfs.title": "Listado CRDs",
  localization: "Localización",
  cancel: "Cancelar",
  datas: "Datos",
  security: "Seguridad",
  permissions: "Permisos",
  passwordsNotEquals: "Las contraseñas deben coincidir",
  centres: "Centros",
  crfs: "CRDS",
  "crf.create.title": "Crear CRD",
  logo: "Logotipo",
  showing: "Mostrando",
  of: "de",
  totalPages: "resultados",
  description: "Descripción",
  preffix: "Prefijo",
  "crfForm.patientCoding": "Código de paciente",
  "crfForm.suffix": "Sufijo",
  "crfForm.fingerprint": "Huella",
  "crfForm.repeatFingerprint": "Repetir Huella",
  "convert.template": "Convertir a plantilla",
  "visit.edit.title": "Edición visita",
  "visit.create.title": "Crear visita",
  "form.edit": "Editar",
  "error.template.add":
    "Error añadiendo plantilla, por favor compruebe que la plantilla tiene un formato correcto.",
  "formVisit.finishingVisit": "Visita de finalización",
  "formVisit.branch": "Rama",
  "template.saved": "Plantilla guardada correctamente.",
  createCentre: "Nuevo Centro",
  modifyCentre: "Actualizar Centro",
  createUser: "Nuevo Usuario",
  modifyUser: "Actualizar Usuario",
  createVisit: "Nueva Visita",
  createCRD: "Nuevo CRD",
  modifyCRD: "Actualizar CRD",
  CRDInfo: "Datos del CRD",
  CRDPatient: "Codificación ID Paciente",
  CRDTemplate: "Fichero de plantilla",
  CRDDigital: "Huella digital",
  "visit.saved": "Visita guardada correctamente.",
  templateSave: "Crear plantilla",
  phone: "Teléfono",
  address: "Dirección",
  inaugurated: "Inaugurado",
  code: "Código",
  required: "Requerido",
  "centre.create.title": "Crear centro",
  "centreForm.observations": "Observaciones",
  "centres.title": "Centros",
  "date.pick": "Seleccione la fecha",
  "formCrf.monitorization": "Habilitar monitorización",
  about: "Sobre Nosotros",
  info: "Aviso Legal",
  help: "Ayuda",
  contact: "Contacto",
  changeStatus: "¿Estas seguro de cambiar el estado de ",
  changeInProgressMonitorization: "Si confirma la acción, el paciente quedará bloqueado y no podrá incluir nuevos datos.",
  changeStatusHeader: "Cambiar estado",
  "filledVisit.title": "Rellenar visita",
  exit: "Salir",
  next: "Siguiente y guardar",
  submit: "Enviar",
  "patient.title": "Pacientes",
  "no.notifications":
    "No tiene notificaciones pendientes de revisar en este estado",
  "sections.encoded": "Secciones codificadas",
  "sections.label": "Secciones no codificadas",
  "variable.label": "Variables no codificadas",
  "variable.encoded": "Variables codificadas",
  "map.visits": "Mapa de vísitas",
  sintaxis: "Sintaxis",
  importing: "Importando...",
  "import.crf": "Importar CRD",
  "import.success": "Importación realizada correctamente",
  "import.fail": "Importación erronea",
  "table.import": "Importar",
  "table.export": "Exportar",
  "table.crfurl": "Compartir url",
  "monitorization.saved": "Monitorización guardada correctamente",
  "leave.page": "Es posible que los cambios no se guarden.",
  "no.history": "No hay historial de arreglos.",
  date: "Fecha",
  centre: "Centro",
  branch: "Rama",
  pendingMonitorizations: "Monitorizaciones pendientes",
  status: "Estado",
  patient: "Paciente",
  "filledVisit.isLast":
    "Está versión de la visita no es la última versión y usada por el resto de usuarios. Si deseas que la versión que has modificado pase a ser la usada pulse guardar como última versión. Sino puede guardarla como una nueva versión. ",
  "filledVisit.isLastHeader": "¿Guardar una nueva versión?",
  "filledVisit.saveAsLast": "Guardar como última versión",
  "filledVisit.save": "Guardar",
  "filledVisit.saved": "Datos guardados correctamente.",
  usuarios: "Usuarios",
  cuadernos: "Cuadernos",
  centros: "Centros",
  inicio: "Inicio",
  "table.type": "Tipo",
  province: "Provincia",
  city: "Ciudad",
  postalCode: "Código postal",
  inaugurated: "Inaugurado",
  phone: "Teléfono",
  "date.pick": "Elija fecha",
  history: "Historial",
  visit: "Visita",
  obtain_password: "Obtenga una nueva contraseña",
  problems_access: "¿Tiene problemas para acceder?",
  recover_text:
    "Indique su nombre de usuario para recibir las instrucciones en su correo.",
  send: "Guardar",
  instruction_recovery: "Hemos enviado un correo de recuperación",
  spam: "¡No olvide comprobar la carpeta de spam!",
  "login.reset": "Cambiar contraseña",
  "reset.button": "Cambiar",
  "name.required": "El nombre es obligatorio",
  required: "Requerido",
  "table.username": "Nombre usuario",
  "userTable.role": "Rol",
  "template.edit.title": "Editar plantilla",
  "session.error": "Error mostrando datos de paciente",
  "session.error.message":
    "Error mostrando datos de paciente, por favor cierre la sesión de usuario y vuelva logearse en el sistema, sí el error persite contacte con nosotros.",
  "visit.error": "Error guardando la visita",
  change: "Cambiar",
  "crf.link": "Compartir CRD",
  "filledVisit.not_saved": "Error guardando datos del paciente",
  recieveNotifications: "Recibir notificaciones",
  recieveNotificationsPatients: "Recibir notificaciones nuevos pacientes",
  active: "Activo",
  applyCode: "Aplicar código",
  "centres.title": "Centros",
  "select.centre": "Seleccione un centro",
  exporting: "Exportando",
  "exporting.crf": "Exportando CRD",
  "import.rewrite.policy": "Comportamiento con pacientes repetidos",
  "import.rewrite.policy.text":
    "Comportamiento con pacientes repetidos:\n Reemplazar: Reemplaza datos del paciente repetido con los datos del fichero.\n Añadir: Añade el paciente repetido como un nuevo paciente, generando un código nuevo.\n Ignorar: Ignora el paciente repetido y continua.",
  "rewrite.policy": "Seleccione comportamiento con repetidos",
  rewrite: "Reemplazar",
  add: "Añadir",
  ignore: "Ignorar",
  "visit.diff": "Auditar cambios",
  "compare.changes": "Comparar",
  "select.compare": "Seleccione y compare",
  "field.changes": "Auditar campo",
  "select.field": "Seleccione campo",
  "audit.field":
    "O seleccione un campo para comprobar todo el historial de cambios",
  "help.diff":
    "Compare dos versiones de la visitia o seleccione un campo para comprobar todo el historial de cambios",
  sheet: "Hoja",
  container: "Contenedor",
  "import.from.template": "Importar visita desde una plantilla (Opcional)",
  visits: "Visitas",
  "add.code": "Añadir código",
  "first.step.info": "Rellena la información básica del cuaderno",
  "second.step.info":
    "Decide la códificación que seguiran los pacientes del cuaderno",
  "third.step.info":
    "Importa plantillas previamente descargadas en formato JSON",
  "download.pdf": "Descargar PDF",
  "download.monitorizations": "Descargar monitorizaciones",
  "generating.pdf": "Generando PDF",
  "table.patient": "Paciente",
  "table.crf": "CRD",
  "table.visit": "Visita",
  "table.inputLabel": "Etiqueta de campo",
  "table.fixType": "Tipo de monitorización",
  "select.crf": "filtrar por CRD",
  "add.fieldFilter": "Añadir filtro de campo",
  "select.value": "Seleccione valor",
  "select.visit": "Seleccione visita",
  "select.sheet": "Seleccione hoja",
  "select.field": "Seleccione campo",
  "select.comparator": "Seleccione comparador",
  "equal": "Igual",
  "notEqual": "No igual",
  "greater": "Mayor",
  "greaterEqual": "Mayor o igual",
  "lower": "Menor",
  "lowerEqual": "Menor o igual",
  "Notifications.title": "Monitorizaciones",
  "reopenMonitorization": "Reabrir monitorización",
  "sign": "Firmar",
  "lock": "Completar",
  "reset": "Resetear",
  "FileView.title": "Descargar fichero",
  "file.view": "Descargar fichero",
  "download": "Descargar",
  "uploadSuccessfully": "Subida exitosa",
  "uploadSuccessfullyMsg": "La subida del archivo se ha realizado con éxito",
  "uploadFailed": "Subida fallida",
  "uploadFailedMsg": "La subida del archivo NO se ha realizado con éxito",
  "dicomFiles": "Archivos DICOM",
  "dicomFilesMsg": "Archivos listos para su descarga:",
  "wait": "Espere",
  "gettingFilesMsg": "Obteniendo archivos",
  "fileUploading": "El archivo se está subiendo",
  "errorGettingFileS3": "ERROR al obtener el archivo de S3:",
  "notFiles": "No hay archivos",
  "notFilesMsg": "No hay archivos para descargar"
};
