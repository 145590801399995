import React from "react";
import { Button, AnchorButton } from "@blueprintjs/core";
import { connect } from "react-redux";

class ButtonAuthorized extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.roles.includes(this.props.role))
      if (this.props.anchor)
        return (
          <AnchorButton
            minimal={this.props.minimal}
            className={this.props.className}
            intent={this.props.intent}
            icon={this.props.icon}
            title={this.props.title}
            href={this.props.href}
          />
        );
      else
        return (
          <Button
            minimal={this.props.minimal}
            className={this.props.className}
            intent={this.props.intent}
            icon={this.props.icon}
            onClick={this.props.onClick}
            title={this.props.title}
          />
        );
    else return "";
  }
}

const mapStateToProps = (state) => ({
  username: state.user.username,
  name: state.user.name,
  lastname: state.user.lastname,
  role: state.user.role,
  avatar: state.user.avatar,
});

const bindActions = (dispatch) => ({});

export default connect(mapStateToProps, bindActions)(ButtonAuthorized);
