import {
  Button,
  MenuItem,
  InputGroup
} from "@blueprintjs/core";
import React, { useState, useEffect } from "react";
import "./_ModalExport.scss";
import { Select } from "@blueprintjs/select";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const FieldFilter = ({ crf, field, index, handleSelectField, removeFieldFilter, t }) => {
  const [state, setState] = useState({
    visits: [],
    sheets: [],
    fields: [],
    index: index,
    /*search: {
      visit: field.visit,
      sheet: field.sheet,
      field: field.field,
      comparator: null,
      value: ""
    }*/
  });

  const comparators = [
    { id: "", text: "All States" },
    { id: "==", text: t("equal") },
    { id: "!=", text: t("notEqual") },
    { id: ">", text: t("greater") },
    { id: ">=", text: t("greaterEqual") },
    { id: "<", text: t("lower") },
    { id: "<=", text: t("lowerEqual") },
  ];

  const selectTypes = [
    "select",
    "select_custom",
    "radio",
    "radio_custom",
  ];

  const dateTypes = [
    "date",
    "date_custom",
    "datetime_custom",
    "datetime"
  ];

  // Visit search options

  const renderItem = (item, { modifiers, handleClick }) => {
    return (
      <MenuItem
        active={modifiers.active}
        key={item.node.id}
        onClick={handleClick}
        text={item.node.name}
        shouldDismissPopover={false}
      />
    );
  };

  const renderItemSheet = (item, { modifiers, handleClick }) => {
    return (
      <MenuItem
        active={modifiers.active}
        key={item.node.id}
        onClick={handleClick}
        text={item.node.name}
        shouldDismissPopover={false}
      />
    );
  };

  const renderItemField = (item, { modifiers, handleClick }) => {
    return (
      <MenuItem
        active={modifiers.active}
        key={item.key}
        onClick={handleClick}
        text={item.label}
        shouldDismissPopover={false}
      />
    );
  };

  const renderItemComparator = (item, { modifiers, handleClick }) => {
    return (
      <MenuItem
        active={modifiers.active}
        key={item.id}
        onClick={handleClick}
        text={item.text}
        shouldDismissPopover={false}
      />
    );
  };

  const areVisitEquals = (a, b) => {
    const aid = a.node ? a.node.id: a.id;
    const bid = b.node ? b.node.id: b.id;

    let equal = aid === bid ? true : false;

    return equal;
  };

  const areSheetEquals = (a, b) => {
    const aid = a.node ? a.node.id: a.id;
    const bid = b.node ? b.node.id: b.id;

    let equal = aid === bid ? true : false;

    return equal;
  };

  const areFieldEquals = (a, b) => {
    let equal = a.key === b.key ? true : false;

    return equal;
  };

  const areComparatorEquals = (a, b) => {
    let equal = a.id === b.id ? true : false;

    return equal;
  };

  const handleSelectChange = (visit) => {
    setState((prevState) => ({
      ...prevState,
      sheets: visit.node.sheets.edges,
      fields: [],
      /*search: {
        ...prevState.search,
        visit: {id: visit.node.id, name: visit.node.name },
        sheet: null,
        field: null,
      },*/
    }));
    field.visit = {id: visit.node.id, name: visit.node.name };
    field.sheet = null;
    field.field = null;
  };

  const handleSelectSheetChange = (sheet) => {
    const fields = []
    
    const form = JSON.parse(sheet.node.formBuild);
    getFormFields(form.components, fields);
    setState((prevState) => ({
      ...prevState,
      fields: fields,
      /*search: {
        ...prevState.search,
        sheet: {id: sheet.node.id, name: sheet.node.name },
        field: null,
      },*/
    }));
    field.sheet = {id: sheet.node.id, name: sheet.node.name };
    field.field = null;
  };

  const handleSelectFieldChange = (selectedField) => {
    setState((prevState) => ({
      ...prevState,
    }));
    field.field = {...selectedField};
    console.log(field);
  };

  const handleSelectComparatorChange = (comparator) => {
    setState((prevState) => ({
      ...prevState,
    }));
    field.comparator = comparator;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
    }));
    field.value = value;
  };

  const renderFieldValue = () => {

    if(field.field) {

      if (selectTypes.includes(field.field.type)) {
        return (
          <select
            id="value"
            name="value"
            onChange={handleChange}
            value={field.value}
            placeholder={t("value")}
            className="bp3-input w-100 select-large"
          >
            <option value="">{t("select.value")}</option>
            {
              field.field.values.map((value, idx)=> {
                return (
                <option value={value.label}>{value.label}</option>
                )
              })
            }
          </select>
        );
      } else if(dateTypes.includes(field.field.type)) {
        return (<DatePicker
          selected={field.value}
          onChange={date => {
            setState((prevState) => ({
              ...prevState,
              /*search: {
                ...prevState.search,
                value: date,
              },*/
            }));
            field.value = date
          } }
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />);
      } else {
        return (<InputGroup
          id="value"
          name="value"
          onChange={handleChange}
          value={field.value}
          placeholder={t("value")}
          large={true}
        />);
      }
    }
    
    return (<InputGroup
      id="value"
      name="value"
      onChange={handleChange}
      value={field.value}
      placeholder={t("value")}
      disabled={true}
      large={true}
    />);
  }

  /*useEffect(() => {
    handleSelectField(field, index);
  }, [state]);*/

  useEffect(() => {
    handleSelectField(field, index);
    renderFieldValue()
  }, [field]);

  const compareFieldState = () => {
    if(field.visit && field.visit.id !== field.visit.id) {
      return false;
    }
    if(field.sheet && field.sheet.id !== field.sheet.id) {
      return false;
    }
    if(field.field && field.field.id !== field.field.id) {
      return false;
    }
    if(field.comparator && field.comparator && (field.comparator.id !== field.comparator.id)) {
      return false;
    }
    if(field.value !== field.value) {
      return false;
    }
    
    return true;
  }

  const getFormFields = (form, fields) => {
    if(Array.isArray(form)) {
      form.forEach(container => {
        getFormFields(container, fields);
      });
     
    } else if(typeof form === 'object') {
      if(form.input === true && !form.hasOwnProperty('components')) {
        fields.push(form);
      } else if(form.hasOwnProperty('components')) {
        getFormFields(form.components, fields);
      } else if(form.hasOwnProperty('columns')) {
        form.columns.forEach(column => {
          getFormFields(column.components, fields);
        });
      }
    }
  }

  const handleFilterVisit = (query, visit) =>
    visit.node.name.indexOf(query) >= 0

  const handleFilterSheet = (query, sheet) =>
    sheet.node.name.indexOf(query) >= 0

  const handleFilterField = (query, field) => {
    return field.label.indexOf(query) >= 0;
  }

  return (
    <div>
      <div className="row pt-2 pb-3">
        <div className="col">
          <Select
            itemsEqual={areVisitEquals}
            itemRenderer={renderItem}
            items={crf.visits.edges}
            large={true}
            itemPredicate={handleFilterVisit}
            activeItem={field.visit}
            className="w-100"
            noResults={
              <MenuItem
                disabled={true}
                text={t("No results.")}
              />
            }
            onItemSelect={handleSelectChange}
          >
            <Button
              rightIcon="caret-down"
              large={true}
              fill={true}
              text={
                field.visit
                  ? field.visit.name
                  : t("select.visit")
              }
            />
          </Select>
        </div>
        <div className="col">
          <Select
            itemsEqual={areSheetEquals}
            itemRenderer={renderItemSheet}
            items={state.sheets}
            large={true}
            itemPredicate={handleFilterSheet}
            activeItem={field.sheet}
            className="w-100"
            onItemSelect={(item) => handleSelectSheetChange(item)}
          >
            <Button
              rightIcon="caret-down"
              large={true}
              fill={true}
              text={
                field.sheet
                  ? field.sheet.name
                  : t("select.sheet")
              }
            />
          </Select>
        </div>
        <div className="col">
          <Select
            itemsEqual={areFieldEquals}
            itemRenderer={renderItemField}
            items={state.fields}
            large={true}
            itemPredicate={handleFilterField}
            activeItem={field.field}
            className="w-100"
            onItemSelect={(item) => handleSelectFieldChange(item)}
          >
            <Button
              rightIcon="caret-down"
              large={true}
              fill={true}
              text={
                field.field
                  ? field.field.label
                  : t("select.field")
              }
            />
          </Select>
        </div>
        <div className="col">
          <Select
            itemsEqual={areComparatorEquals}
            itemRenderer={renderItemComparator}
            items={comparators}
            activeItem={field.comparator}
            large={true}
            className="w-100"
            onItemSelect={(item) => handleSelectComparatorChange(item)}
          >
            <Button
              rightIcon="caret-down"
              large={true}
              fill={true}
              text={
                field.comparator
                  ? field.comparator.text
                  : t("select.comparator")
              }
            />
          </Select>
        </div>
        <div id={`value-${index}`} className="col">
          {renderFieldValue()}
        </div>
        <div style={{width: '5%'}}>
          <Button intent="danger" icon="remove" onClick={(e)=> {
            removeFieldFilter(state.index);
          }}>
            
          </Button>
        </div>
      </div>
    </div>
  );
};

FieldFilter.propTypes = {
  /**
   * The crumbs to show
   */
  crf: PropTypes.object
};

FieldFilter.defaultProps = {
  crf: {}
};

export default FieldFilter;