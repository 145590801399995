import React from "react";
import { client } from "../../../apolloClient";
import { withTranslation } from "react-i18next";
import Paginator from "../../../components/Paginator/Paginator";
import TableSizeSelector from "../../../components/Paginator/TableSizeSelector";
import { mediaFilesAppend, DEFAULT_ELEM_PAGE } from "../../../config";
import {
  Spinner,
  Card,
  Elevation,
  Button,
  Icon,
  InputGroup,
} from "@blueprintjs/core";
import ConfirmDeleteModal from "../../../components/ModalDelete/ConfirmDeleteModal";
import VisitForm from "../../Visit/Create/index";
import CloneTemplateModal from "../../../components/ModalTemplate/ModalTemplate";
import { GET_VISITS } from "../../../queries";
import { DELETE_VISIT, CLONE_TEMPLATE, ORDER_VISIT } from "../../../mutations";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ROLE_SYSADMIN, ROLE_ADMIN } from "../../../config";
import ButtonAuthorized from "../../../components/ButtonAuthorized/ButtonAuthorized";

class VisitList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpened: false,
      modalCloneOpened: false,
      toDeleteName: "",
      toDeleteIndex: "",
      toDeleteId: "",
      cloneId: "",
      data: {
        visits: {
          hasPrev: false,
          hasNext: false,
          pages: 1,
          objects: [],
          total: 0,
        },
      },
      items: [],
      loading: false,
      page: 1,
      orderField: "created",
      elementsPage: 100,
      search: { text: "" },
      modalControl: {
        visit: false,
      },
    };
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.toggleCloneModal = this.toggleCloneModal.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.createOpen = this.createOpen.bind(this);
    this.onCreateSubmit = this.onCreateSubmit.bind(this);
  }

  componentDidMount() {
    this.fetch();
  }

  fetch = () => {
    this.setState({ loading: true });
    const variables = {
      crfId: this.props.crfId,
      elementsPerPage: this.state.elementsPage,
      page: this.state.page,
      orderField: this.state.orderField,
    };
    client
      .query({
        variables: variables,
        query: GET_VISITS,
        fetchPolicy: "network-only",
      })
      .then((res) => {
        this.setState((prevState) => ({
          ...prevState,
          data: res.data,
          items: res.data["visits"]["objects"],
          loading: false,
        }));
      });
  };

  refetch = () => {
    this.fetch();
    this.props.reloadTemplates();
  };

  handlerPageChange = (page) => {
    this.setState({
      page: page,
    });
  };

  handleChange = async (event) => {
    const { name, value } = event.target;
    await this.setState((prevState) => ({
      search: {
        ...prevState.search,
        [name]: value,
      },
    }));
    if (value.length > 3) {
      this.handleSubmit();
    }
  };

  handleSubmit = () => {
    const search = this.state.search;
    this.refetch({
      ...search,
      page: 1,
    });
  };

  handleOrderMutation = (visit, startIndex, endIndex) => {
    let variables = {
      id: visit,
      startIndex: startIndex,
      endIndex: endIndex,
    };

    client
      .mutate({
        variables,
        mutation: ORDER_VISIT,
      })
      .then((res, err) => {
        if (err) console.log(err);
      });
  };

  toggleDeleteModal = (
    toDeleteIndex = "",
    toDeleteId = "",
    toDeleteName = ""
  ) => {
    if (this.state.modalOpened) {
      this.setState({
        modalOpened: !this.state.modalOpened,
      });
    } else {
      this.setState(
        {
          toDeleteIndex,
          toDeleteId,
          toDeleteName,
        },
        () => this.setState({ modalOpened: !this.state.modalOpened })
      );
    }
  };

  toggleCloneModal = (cloneId = "") => {
    this.setState({
      cloneId: cloneId,
      modalCloneOpened: !this.state.modalCloneOpened,
    });
  };

  createOpen(create = true) {
    const { modalControl } = this.state;
    modalControl.visit = create;
    this.setState({ modalControl });
  }

  onCreateSubmit() {
    this.createOpen(false);
    this.refetch();
  }

  orderTable = (field) => {
    if (this.state.orderField === field) {
      this.setState((prevState) => ({
        ...prevState,
        orderField: `-${field}`,
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        orderField: field,
      }));
    }
  };

  // a little function to help us with reordering the result
  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = this.reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.handleOrderMutation(
      result.draggableId,
      result.source.index + 1,
      result.destination.index + 1
    );

    this.setState({
      items: items,
    });
  };
  onChangePage(num) {
    this.setState({ elementsPage: num });
    this.refetch();
  }

  render() {
    this.t = this.props.t;
    const dataKeys = ["name"];

    const capitalize = (s) => {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    };

    return (
      <div>
        <div className="row">
          <div className="col">
            <h3>{this.t("visits")}</h3>
          </div>
          <div className="col">
            <Button
              className="addButton"
              type="button"
              onClick={this.createOpen.bind(this)}
            >
              {capitalize(this.t("new"))}
            </Button>
          </div>
        </div>

        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.handleSubmit();
            }}
            ref={(elem) => (this.form = elem)}
          >
            <div className="row pt-2 pb-3">
              <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 offset-md-6 offset-lg-8 offset-xl-9 text-right">
                <InputGroup
                  id="text"
                  name="text"
                  onChange={this.handleChange}
                  value={this.state.text}
                  placeholder={this.props.t("search")}
                  leftIcon="search"
                />
              </div>
            </div>
          </form>
          {this.state.loading && (
            <div className="spinner-loader-listing">
              <Spinner
                size={80}
                spinnerColor={"#007BFF"}
                spinnerWidth={2}
                visible={true}
              />
            </div>
          )}

          {!this.state.loading && (
            <div>
              <table className="table table-custom table-hover">
                <thead className="bg-light">
                  <tr className="table-hover">
                    <th scope="col" className="border-0">
                      #
                    </th>
                    <th key="visit-name-col" scope="col">
                      {this.t("table.name")}
                    </th>
                    <th scope="col" className="text-right">
                      {this.t("table.actions")}
                    </th>
                  </tr>
                </thead>

                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <tbody
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {this.state.items.map((data, index) => (
                          <Draggable
                            key={data.id}
                            draggableId={data.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <tr
                                ref={provided.innerRef}
                                key={index}
                                className="table-hover"
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <td>{index + 1}</td>
                                {dataKeys.map((key, index) => {
                                  let info = "";
                                  try {
                                    info = key
                                      .split(".")
                                      .reduce(function(c, prop) {
                                        return c[prop];
                                      }, data);
                                  } catch (err) {}
                                  if (
                                    typeof info === "string" &&
                                    info.endsWith("png")
                                  )
                                    return (
                                      <td key={index}>
                                        <img
                                          className="icon"
                                          src={mediaFilesAppend + info}
                                          alt=""
                                        />
                                      </td>
                                    );
                                  else if (key === "created")
                                    return (
                                      <td key={index}>
                                        {new Date(info).toLocaleDateString()}
                                      </td>
                                    );
                                  else return <td key={index}>{info}</td>;
                                })}
                                <td className="text-right">
                                  <ButtonAuthorized
                                    //intent={Intent.PRIMARY}
                                    minimal
                                    href={`/visit/edit/${data.id}`}
                                    icon="edit"
                                    roles={[ROLE_SYSADMIN, ROLE_ADMIN]}
                                    anchor={true}
                                  />
                                  <ButtonAuthorized
                                    minimal
                                    className="ml-2"
                                    icon="duplicate"
                                    onClick={() =>
                                      this.toggleCloneModal(data.id)
                                    }
                                    roles={[ROLE_SYSADMIN, ROLE_ADMIN]}
                                  />
                                  <ButtonAuthorized
                                    minimal
                                    className="ml-2"
                                    icon="trash"
                                    title={this.t("remove")}
                                    roles={[ROLE_SYSADMIN, ROLE_ADMIN]}
                                    onClick={() =>
                                      this.toggleDeleteModal(
                                        index,
                                        data.id,
                                        data.name
                                      )
                                    }
                                  />
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                </DragDropContext>
              </table>
              {/* <div className="row ">
                <div className="col-8">
                  <TableSizeSelector
                    t={this.t}
                    numPages={this.state.elementsPage}
                    onChangePage={this.onChangePage.bind(this)}
                    elementsPage={this.state.elementsPage}
                    totalElements={this.state.data["visits"].total}
                  />
                </div>
                <div className="col-4 text-right">
                  <Paginator
                    data={this.state.data}
                    t={this.t}
                    handlerPageChange={this.handlerPageChange}
                    refetchFunction={this.refetch}
                    tableType="visits"
                    page={this.state.page}
                  />
                </div>
              </div> */}
            </div>
          )}
        </div>

        <ConfirmDeleteModal
          isOpen={this.state.modalOpened}
          toggleFunction={this.toggleDeleteModal}
          toDeleteId={this.state.toDeleteId}
          refetchFunction={this.refetch}
          toDeleteName={this.state.toDeleteName}
          mutation={DELETE_VISIT}
          t={this.t}
        />
        <CloneTemplateModal
          isOpen={this.state.modalCloneOpened}
          cloneId={this.state.cloneId}
          toggleFunction={this.toggleCloneModal}
          refetchFunction={this.refetch}
          mutation={CLONE_TEMPLATE}
          t={this.t}
        />

        <VisitForm
          isOpen={this.state.modalControl.visit}
          t={this.t}
          id={this.props.crfId}
          toggleFunction={this.createOpen.bind(this, false)}
          onCreateSubmit={this.onCreateSubmit.bind(this)}
        />
      </div>
    );
  }
}
export default withTranslation()(VisitList);
