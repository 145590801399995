import Base from "formiojs/components/email/Email";
import * as editForm from "./EmailCustom.form";

export default class EmailCustomComponent extends Base {
  constructor(component, options, data) {
    super(component, options, data);
  }

  /**
   * Render returns an html string of the fully rendered component.
   *
   * @param children - If this class is extendended, the sub string is passed as children.
   * @returns {string}
   */
  render(children) {
    return super.render(children);
  }

  /**
   * After the html string has been mounted into the dom, the dom element is returned here. Use refs to find specific
   * elements to attach functionality to.
   *
   * @param element
   * @returns {Promise}
   */
  attach(element) {
    this.stylesComponent(element);
    this.stylesName(element);
    this.stylesBox(element);

    return super.attach(element);
  }

  /**
   * Attach styles to element component
   */
  stylesComponent(element) {
    let boxShadow = ``;
    let textShadow = ``;
    if (typeof this.component.styleComponent !== "undefined") {
      Object.keys(this.component.styleComponent).forEach((key, index) => {
        if (typeof this.component.styleComponent[key] !== "undefined") {
          // special for box shadow
          if (
            key == "h-offset-box" ||
            key == "v-offset-box" ||
            key == "blur-radius-box" ||
            key == "spread-box"
          ) {
            if (this.component.styleComponent[key] !== "")
              boxShadow += `${this.component.styleComponent[key]} `;
            else boxShadow += "0px ";
          } else if (key == "color-box") {
            if (this.component.styleComponent[key] !== "")
              boxShadow += `${this.component.styleComponent[key]} `;
            else boxShadow += "#000 ";
          } else if (
            key == "h-offset-text" ||
            key == "v-offset-text" ||
            key == "blur-radius-text" ||
            key == "spread-text"
          ) {
            if (this.component.styleComponent[key] !== "")
              textShadow += `${this.component.styleComponent[key]} `;
            else textShadow += "0px ";
          } else if (key == "color-text") {
            if (this.component.styleComponent[key] !== "")
              textShadow += `${this.component.styleComponent[key]} `;
            else textShadow += "#000 ";
          } else {
            if (this.component.styleComponent[key] !== "")
              element.style[key] = this.component.styleComponent[key];
          }
        }
      });

      element.style.boxShadow = boxShadow;
      element.style.textShadow = textShadow;
    }
  }

  /**
   * Attach styles to element label
   */
  stylesName(element) {
    let boxShadow = ``;
    let textShadow = ``;
    const label = element.querySelector("label");
    if (label && typeof this.component.styleName !== "undefined") {
      Object.keys(this.component.styleName).forEach((key, index) => {
        if (typeof this.component.styleName[key] !== "undefined") {
          // special for box shadow
          if (
            key == "h-offset-box" ||
            key == "v-offset-box" ||
            key == "blur-radius-box" ||
            key == "spread-box"
          ) {
            if (this.component.styleName[key] !== "")
              boxShadow += `${this.component.styleName[key]} `;
            else boxShadow += "0px ";
          } else if (key == "color-box") {
            if (this.component.styleName[key] !== "")
              boxShadow += `${this.component.styleName[key]} `;
            else boxShadow += "#000 ";
          } else if (
            key == "h-offset-text" ||
            key == "v-offset-text" ||
            key == "blur-radius-text" ||
            key == "spread-text"
          ) {
            if (this.component.styleName[key] !== "")
              textShadow += `${this.component.styleName[key]} `;
            else textShadow += "0px ";
          } else if (key == "color-text") {
            if (this.component.styleName[key] !== "")
              textShadow += `${this.component.styleName[key]} `;
            else textShadow += "#000 ";
          } else {
            if (this.component.styleName[key] !== "")
              label.style[key] = this.component.styleName[key];
          }
        }
      });

      label.style.boxShadow = boxShadow;
      label.style.textShadow = textShadow;
    }
  }

  /**
   * Attach styles to element input box
   */
  stylesBox(element) {
    let boxShadow = ``;
    let textShadow = ``;
    const input = element.querySelector("input");
    if (input && typeof this.component.styleBox !== "undefined") {
      Object.keys(this.component.styleBox).forEach((key, index) => {
        if (typeof this.component.styleBox[key] !== "undefined") {
          // special for box shadow
          if (
            key == "h-offset-box" ||
            key == "v-offset-box" ||
            key == "blur-radius-box" ||
            key == "spread-box"
          ) {
            if (this.component.styleBox[key] !== "")
              boxShadow += `${this.component.styleBox[key]} `;
            else boxShadow += "0px ";
          } else if (key == "color-box") {
            if (this.component.styleBox[key] !== "")
              boxShadow += `${this.component.styleBox[key]} `;
            else boxShadow += "#000 ";
            // special for text shadow
          } else if (
            key == "h-offset-text" ||
            key == "v-offset-text" ||
            key == "blur-radius-text" ||
            key == "spread-text"
          ) {
            if (this.component.styleBox[key] !== "")
              textShadow += `${this.component.styleBox[key]} `;
            else textShadow += "0px ";
          } else if (key == "color-text") {
            if (this.component.styleBox[key] !== "")
              textShadow += `${this.component.styleBox[key]} `;
            else textShadow += "#000 ";
          } else {
            if (this.component.styleBox[key] !== "")
              input.style[key] = this.component.styleBox[key];
          }
        }
      });

      input.style.boxShadow = boxShadow;
      input.style.textShadow = textShadow;
    }
  }

  /**
   * Set the value of the component into the dom elements.
   *
   * @param value
   * @returns {boolean}
   */
  setValue(value) {
    const random = this.component.randomizer;
    if (random) {
      if (this.isEmpty() === false) {
        let input = this.element.querySelector(`input`);
        if (input) input.setAttribute("disabled", "disabled");
      }
    }

    return super.setValue(value);
  }

  static schema() {
    return Base.schema({
      type: "email_custom",
      label: "Email",
      hideLabel: false,
      key: "email",
      input: true
    });
  }
}

EmailCustomComponent.editForm = editForm.default;
