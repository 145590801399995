export const ABOUT = {
  "es-ES": [
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget felis justo. Aenean scelerisque feugiat finibus. Ut commodo diam at enim sodales volutpat. Sed bibendum lobortis elit sed condimentum. Interdum et malesuada fames ac ante ipsum primis in faucibus. Duis accumsan justo sed velit pharetra bibendum. Nullam consequat ipsum at lobortis vestibulum. Morbi sed tempor diam, eu interdum massa. Nam posuere pellentesque eros sit amet volutpat. Etiam suscipit felis facilisis nisl tristique, tempus cursus arcu mollis. Nam consectetur faucibus magna eu vulputate. Nullam condimentum, mauris quis condimentum aliquet, urna tellus congue eros, et luctus ipsum elit in purus. Aliquam rutrum semper neque id scelerisque.",
    "Phasellus malesuada iaculis velit tincidunt volutpat. Nullam volutpat erat quis faucibus dictum. Vestibulum dolor mauris, porta at finibus id, lobortis vel nibh. Sed bibendum facilisis odio, suscipit scelerisque velit dapibus a. Integer pulvinar diam risus, vel rhoncus ex consequat id. Nulla facilisi. Phasellus dolor leo, cursus sed efficitur nec, pretium in mi. Praesent egestas turpis non nulla semper commodo. Suspendisse imperdiet leo quam, ac bibendum metus malesuada facilisis. Integer et velit sed nisl vestibulum viverra et sed libero. Fusce ornare mollis facilisis. Fusce pulvinar libero eget porta tempor.",
  ],
  "en-EN": [
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget felis justo. Aenean scelerisque feugiat finibus. Ut commodo diam at enim sodales volutpat. Sed bibendum lobortis elit sed condimentum. Interdum et malesuada fames ac ante ipsum primis in faucibus. Duis accumsan justo sed velit pharetra bibendum. Nullam consequat ipsum at lobortis vestibulum. Morbi sed tempor diam, eu interdum massa. Nam posuere pellentesque eros sit amet volutpat. Etiam suscipit felis facilisis nisl tristique, tempus cursus arcu mollis. Nam consectetur faucibus magna eu vulputate. Nullam condimentum, mauris quis condimentum aliquet, urna tellus congue eros, et luctus ipsum elit in purus. Aliquam rutrum semper neque id scelerisque.",
    "Phasellus malesuada iaculis velit tincidunt volutpat. Nullam volutpat erat quis faucibus dictum. Vestibulum dolor mauris, porta at finibus id, lobortis vel nibh. Sed bibendum facilisis odio, suscipit scelerisque velit dapibus a. Integer pulvinar diam risus, vel rhoncus ex consequat id. Nulla facilisi. Phasellus dolor leo, cursus sed efficitur nec, pretium in mi. Praesent egestas turpis non nulla semper commodo. Suspendisse imperdiet leo quam, ac bibendum metus malesuada facilisis. Integer et velit sed nisl vestibulum viverra et sed libero. Fusce ornare mollis facilisis. Fusce pulvinar libero eget porta tempor.",
  ],
  "pt-PT": [
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget felis justo. Aenean scelerisque feugiat finibus. Ut commodo diam at enim sodales volutpat. Sed bibendum lobortis elit sed condimentum. Interdum et malesuada fames ac ante ipsum primis in faucibus. Duis accumsan justo sed velit pharetra bibendum. Nullam consequat ipsum at lobortis vestibulum. Morbi sed tempor diam, eu interdum massa. Nam posuere pellentesque eros sit amet volutpat. Etiam suscipit felis facilisis nisl tristique, tempus cursus arcu mollis. Nam consectetur faucibus magna eu vulputate. Nullam condimentum, mauris quis condimentum aliquet, urna tellus congue eros, et luctus ipsum elit in purus. Aliquam rutrum semper neque id scelerisque.",
    "Phasellus malesuada iaculis velit tincidunt volutpat. Nullam volutpat erat quis faucibus dictum. Vestibulum dolor mauris, porta at finibus id, lobortis vel nibh. Sed bibendum facilisis odio, suscipit scelerisque velit dapibus a. Integer pulvinar diam risus, vel rhoncus ex consequat id. Nulla facilisi. Phasellus dolor leo, cursus sed efficitur nec, pretium in mi. Praesent egestas turpis non nulla semper commodo. Suspendisse imperdiet leo quam, ac bibendum metus malesuada facilisis. Integer et velit sed nisl vestibulum viverra et sed libero. Fusce ornare mollis facilisis. Fusce pulvinar libero eget porta tempor.",
  ],
};

export const INFO = {
  "es-ES": [
    "Con el fin de dar cumplimiento al artículo 10 de la Ley 34/2002 de Servicios de la Sociedad de la Información y del Comercio Electrónico, informamos a los usuarios de nuestros datos:",
    "Denominación Social: Tartessos sistemas y servicios S.L. Domicilio Social: Calle Torrijiano n.23 - Sevilla CIF: B90079500 E-Mail: soporte@hidup.io Sitio Web: https://crf.hidup.io/",
    "En lo que a protección de datos se refiere, el prestador garantiza el cumplimiento íntegro de las obligaciones dispuestas en la normativa vigente en materia de protección de datos de carácter personal. La información proporcionada se conservará mientras se mantenga la relación. No se cederá a terceros salvo en los casos en los que exista una obligación legal.",
    "Al acceder a esta herramienta, consiento el tratamiento que se realice de mis datos personales conforme a lo establecido en el Capítulo II de la Ley Orgánica de Protección de datos Personales y Garantía de Derechos Digitales 3/2018, y a lo recogido en el Reglamento UE 679/2016 en su Capítulo II, sobre los principios de protección del tratamiento de los datos, la licitud del tratamiento y categorías especiales de datos.",
    "Podrá ejercer los derechos de acceso, rectificación, oposición, supresión, limitación del tratamiento, portabilidad y derecho a no ser objeto de decisiones individualizadas, ante el responsable del tratamiento dirigiéndose a la siguiente dirección de correo: soporte@hidup.io",
  ],
  "en-EN": [
    "Con el fin de dar cumplimiento al artículo 10 de la Ley 34/2002 de Servicios de la Sociedad de la Información y del Comercio Electrónico, informamos a los usuarios de nuestros datos:",
    "Denominación Social: Tartessos sistemas y servicios S.L. Domicilio Social: Calle Torrijiano n.23 - Sevilla CIF: B90079500 E-Mail: soporte@hidup.io Sitio Web: https://crf.hidup.io/",
    "En lo que a protección de datos se refiere, el prestador garantiza el cumplimiento íntegro de las obligaciones dispuestas en la normativa vigente en materia de protección de datos de carácter personal. La información proporcionada se conservará mientras se mantenga la relación. No se cederá a terceros salvo en los casos en los que exista una obligación legal.",
    "Al acceder a esta herramienta, consiento el tratamiento que se realice de mis datos personales conforme a lo establecido en el Capítulo II de la Ley Orgánica de Protección de datos Personales y Garantía de Derechos Digitales 3/2018, y a lo recogido en el Reglamento UE 679/2016 en su Capítulo II, sobre los principios de protección del tratamiento de los datos, la licitud del tratamiento y categorías especiales de datos.",
    "Podrá ejercer los derechos de acceso, rectificación, oposición, supresión, limitación del tratamiento, portabilidad y derecho a no ser objeto de decisiones individualizadas, ante el responsable del tratamiento dirigiéndose a la siguiente dirección de correo: soporte@hidup.io",
  ],
  "pt-PT": [
    "Con el fin de dar cumplimiento al artículo 10 de la Ley 34/2002 de Servicios de la Sociedad de la Información y del Comercio Electrónico, informamos a los usuarios de nuestros datos:",
    "Denominación Social: Tartessos sistemas y servicios S.L. Domicilio Social: Calle Torrijiano n.23 - Sevilla CIF: B90079500 E-Mail: soporte@hidup.io Sitio Web: https://crf.hidup.io/",
    "En lo que a protección de datos se refiere, el prestador garantiza el cumplimiento íntegro de las obligaciones dispuestas en la normativa vigente en materia de protección de datos de carácter personal. La información proporcionada se conservará mientras se mantenga la relación. No se cederá a terceros salvo en los casos en los que exista una obligación legal.",
    "Al acceder a esta herramienta, consiento el tratamiento que se realice de mis datos personales conforme a lo establecido en el Capítulo II de la Ley Orgánica de Protección de datos Personales y Garantía de Derechos Digitales 3/2018, y a lo recogido en el Reglamento UE 679/2016 en su Capítulo II, sobre los principios de protección del tratamiento de los datos, la licitud del tratamiento y categorías especiales de datos.",
    "Podrá ejercer los derechos de acceso, rectificación, oposición, supresión, limitación del tratamiento, portabilidad y derecho a no ser objeto de decisiones individualizadas, ante el responsable del tratamiento dirigiéndose a la siguiente dirección de correo: soporte@hidup.io",
  ],
};

export const HELP = {
  "es-ES": [
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget felis justo. Aenean scelerisque feugiat finibus. Ut commodo diam at enim sodales volutpat. Sed bibendum lobortis elit sed condimentum. Interdum et malesuada fames ac ante ipsum primis in faucibus. Duis accumsan justo sed velit pharetra bibendum. Nullam consequat ipsum at lobortis vestibulum. Morbi sed tempor diam, eu interdum massa. Nam posuere pellentesque eros sit amet volutpat. Etiam suscipit felis facilisis nisl tristique, tempus cursus arcu mollis. Nam consectetur faucibus magna eu vulputate. Nullam condimentum, mauris quis condimentum aliquet, urna tellus congue eros, et luctus ipsum elit in purus. Aliquam rutrum semper neque id scelerisque.",
    "Phasellus malesuada iaculis velit tincidunt volutpat. Nullam volutpat erat quis faucibus dictum. Vestibulum dolor mauris, porta at finibus id, lobortis vel nibh. Sed bibendum facilisis odio, suscipit scelerisque velit dapibus a. Integer pulvinar diam risus, vel rhoncus ex consequat id. Nulla facilisi. Phasellus dolor leo, cursus sed efficitur nec, pretium in mi. Praesent egestas turpis non nulla semper commodo. Suspendisse imperdiet leo quam, ac bibendum metus malesuada facilisis. Integer et velit sed nisl vestibulum viverra et sed libero. Fusce ornare mollis facilisis. Fusce pulvinar libero eget porta tempor.",
  ],
  "en-EN": [
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget felis justo. Aenean scelerisque feugiat finibus. Ut commodo diam at enim sodales volutpat. Sed bibendum lobortis elit sed condimentum. Interdum et malesuada fames ac ante ipsum primis in faucibus. Duis accumsan justo sed velit pharetra bibendum. Nullam consequat ipsum at lobortis vestibulum. Morbi sed tempor diam, eu interdum massa. Nam posuere pellentesque eros sit amet volutpat. Etiam suscipit felis facilisis nisl tristique, tempus cursus arcu mollis. Nam consectetur faucibus magna eu vulputate. Nullam condimentum, mauris quis condimentum aliquet, urna tellus congue eros, et luctus ipsum elit in purus. Aliquam rutrum semper neque id scelerisque.",
    "Phasellus malesuada iaculis velit tincidunt volutpat. Nullam volutpat erat quis faucibus dictum. Vestibulum dolor mauris, porta at finibus id, lobortis vel nibh. Sed bibendum facilisis odio, suscipit scelerisque velit dapibus a. Integer pulvinar diam risus, vel rhoncus ex consequat id. Nulla facilisi. Phasellus dolor leo, cursus sed efficitur nec, pretium in mi. Praesent egestas turpis non nulla semper commodo. Suspendisse imperdiet leo quam, ac bibendum metus malesuada facilisis. Integer et velit sed nisl vestibulum viverra et sed libero. Fusce ornare mollis facilisis. Fusce pulvinar libero eget porta tempor.",
  ],
  "pt-PT": [
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget felis justo. Aenean scelerisque feugiat finibus. Ut commodo diam at enim sodales volutpat. Sed bibendum lobortis elit sed condimentum. Interdum et malesuada fames ac ante ipsum primis in faucibus. Duis accumsan justo sed velit pharetra bibendum. Nullam consequat ipsum at lobortis vestibulum. Morbi sed tempor diam, eu interdum massa. Nam posuere pellentesque eros sit amet volutpat. Etiam suscipit felis facilisis nisl tristique, tempus cursus arcu mollis. Nam consectetur faucibus magna eu vulputate. Nullam condimentum, mauris quis condimentum aliquet, urna tellus congue eros, et luctus ipsum elit in purus. Aliquam rutrum semper neque id scelerisque.",
    "Phasellus malesuada iaculis velit tincidunt volutpat. Nullam volutpat erat quis faucibus dictum. Vestibulum dolor mauris, porta at finibus id, lobortis vel nibh. Sed bibendum facilisis odio, suscipit scelerisque velit dapibus a. Integer pulvinar diam risus, vel rhoncus ex consequat id. Nulla facilisi. Phasellus dolor leo, cursus sed efficitur nec, pretium in mi. Praesent egestas turpis non nulla semper commodo. Suspendisse imperdiet leo quam, ac bibendum metus malesuada facilisis. Integer et velit sed nisl vestibulum viverra et sed libero. Fusce ornare mollis facilisis. Fusce pulvinar libero eget porta tempor.",
  ],
};

export const CONTACT = {
  "es-ES": [
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget felis justo. Aenean scelerisque feugiat finibus. Ut commodo diam at enim sodales volutpat. Sed bibendum lobortis elit sed condimentum. Interdum et malesuada fames ac ante ipsum primis in faucibus. Duis accumsan justo sed velit pharetra bibendum. Nullam consequat ipsum at lobortis vestibulum. Morbi sed tempor diam, eu interdum massa. Nam posuere pellentesque eros sit amet volutpat. Etiam suscipit felis facilisis nisl tristique, tempus cursus arcu mollis. Nam consectetur faucibus magna eu vulputate. Nullam condimentum, mauris quis condimentum aliquet, urna tellus congue eros, et luctus ipsum elit in purus. Aliquam rutrum semper neque id scelerisque.",
    "Phasellus malesuada iaculis velit tincidunt volutpat. Nullam volutpat erat quis faucibus dictum. Vestibulum dolor mauris, porta at finibus id, lobortis vel nibh. Sed bibendum facilisis odio, suscipit scelerisque velit dapibus a. Integer pulvinar diam risus, vel rhoncus ex consequat id. Nulla facilisi. Phasellus dolor leo, cursus sed efficitur nec, pretium in mi. Praesent egestas turpis non nulla semper commodo. Suspendisse imperdiet leo quam, ac bibendum metus malesuada facilisis. Integer et velit sed nisl vestibulum viverra et sed libero. Fusce ornare mollis facilisis. Fusce pulvinar libero eget porta tempor.",
  ],
  "en-EN": [
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget felis justo. Aenean scelerisque feugiat finibus. Ut commodo diam at enim sodales volutpat. Sed bibendum lobortis elit sed condimentum. Interdum et malesuada fames ac ante ipsum primis in faucibus. Duis accumsan justo sed velit pharetra bibendum. Nullam consequat ipsum at lobortis vestibulum. Morbi sed tempor diam, eu interdum massa. Nam posuere pellentesque eros sit amet volutpat. Etiam suscipit felis facilisis nisl tristique, tempus cursus arcu mollis. Nam consectetur faucibus magna eu vulputate. Nullam condimentum, mauris quis condimentum aliquet, urna tellus congue eros, et luctus ipsum elit in purus. Aliquam rutrum semper neque id scelerisque.",
    "Phasellus malesuada iaculis velit tincidunt volutpat. Nullam volutpat erat quis faucibus dictum. Vestibulum dolor mauris, porta at finibus id, lobortis vel nibh. Sed bibendum facilisis odio, suscipit scelerisque velit dapibus a. Integer pulvinar diam risus, vel rhoncus ex consequat id. Nulla facilisi. Phasellus dolor leo, cursus sed efficitur nec, pretium in mi. Praesent egestas turpis non nulla semper commodo. Suspendisse imperdiet leo quam, ac bibendum metus malesuada facilisis. Integer et velit sed nisl vestibulum viverra et sed libero. Fusce ornare mollis facilisis. Fusce pulvinar libero eget porta tempor.",
  ],
  "pt-PT": [
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget felis justo. Aenean scelerisque feugiat finibus. Ut commodo diam at enim sodales volutpat. Sed bibendum lobortis elit sed condimentum. Interdum et malesuada fames ac ante ipsum primis in faucibus. Duis accumsan justo sed velit pharetra bibendum. Nullam consequat ipsum at lobortis vestibulum. Morbi sed tempor diam, eu interdum massa. Nam posuere pellentesque eros sit amet volutpat. Etiam suscipit felis facilisis nisl tristique, tempus cursus arcu mollis. Nam consectetur faucibus magna eu vulputate. Nullam condimentum, mauris quis condimentum aliquet, urna tellus congue eros, et luctus ipsum elit in purus. Aliquam rutrum semper neque id scelerisque.",
    "Phasellus malesuada iaculis velit tincidunt volutpat. Nullam volutpat erat quis faucibus dictum. Vestibulum dolor mauris, porta at finibus id, lobortis vel nibh. Sed bibendum facilisis odio, suscipit scelerisque velit dapibus a. Integer pulvinar diam risus, vel rhoncus ex consequat id. Nulla facilisi. Phasellus dolor leo, cursus sed efficitur nec, pretium in mi. Praesent egestas turpis non nulla semper commodo. Suspendisse imperdiet leo quam, ac bibendum metus malesuada facilisis. Integer et velit sed nisl vestibulum viverra et sed libero. Fusce ornare mollis facilisis. Fusce pulvinar libero eget porta tempor.",
  ],
};
