// Layout Types
import { BlankLayout, DefaultLayout } from "./layouts";
import { GET_USERS, GET_CENTRES, GET_CRF, GET_PATIENTS } from "./queries";

// Route Views
import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";
import Dashboard from "./pages/Dashboard";
import CustomTable from "./components/Table";
import CreateUser from "./pages/User/Create";
import CreateCentre from "./pages/Centre/Create";
import ListCRF from "./pages/CRF/List";
import CreateCRF from "./pages/CRF/Create";
import ViewCRF from "./pages/CRF/View";
import CreateVisit from "./pages/Visit/Create";
import EditVisit from "./pages/Visit/Edit";
import EditTemplate from "./pages/Template/Edit";
import EditSheetTemplate from "./pages/Template/EditSheet";
import PatientList from "./pages/Patient/List";
import PatientView from "./pages/Patient/View";
import FilledVisit from "./pages/Patient/FillVisit";
import DiffVisit from "./pages/Patient/DiffVisit";
import FileView from "./pages/Patient/FileView";
import HomeRedirector from "./pages/Redirector/HomeRedirector";
import FooterPage from "./pages/Footer";
import NotificationList from "./pages/Notifications"
import {
  ROLE_SYSADMIN,
  ROLE_ADMIN,
  ROLE_DATAMANAGER,
  ROLE_INVESTIGATOR,
  ROLE_LEAD_MONITOR,
  ROLE_READ_ONLY,
  ROLE_MONITOR,
} from "./config";

export default [
  {
    path: "/login",
    exact: true,
    layout: BlankLayout,
    component: Login,
  },
  {
    path: "/reset-password/:uid/:token",
    exact: true,
    layout: BlankLayout,
    component: ResetPassword,
  },
  {
    path: "/",
    exact: true,
    layout: BlankLayout,
    component: HomeRedirector,
  },

  {
    path: "/users",
    layout: DefaultLayout,
    component: CustomTable,
    props: {
      title: "users.title",
      tableTitle: "usersTable.tableTitle",
      tableType: "users",
      query: GET_USERS,
      entity: "user",
      createLink: "/user",
      roles: [ROLE_SYSADMIN],
    },
  },
  {
    path: "/user",
    layout: DefaultLayout,
    component: CreateUser,
    props: {
      title: "user.create.title",
      roles: [ROLE_SYSADMIN],
    },
  },
  {
    path: "/centres",
    layout: DefaultLayout,
    component: CustomTable,
    props: {
      title: "centres.title",
      tableTitle: "centresTable.tableTitle",
      tableType: "centres",
      query: GET_CENTRES,
      entity: "centre",
      createLink: "/centre",
      roles: [ROLE_SYSADMIN],
    },
  },
  {
    path: "/centre",
    layout: DefaultLayout,
    component: CreateCentre,
    props: {
      title: "centre.create.title",
      roles: [ROLE_SYSADMIN],
    },
  },
  {
    path: "/crfs",
    layout: DefaultLayout,
    component: ListCRF,
    props: {
      title: "crfs.title",
      tableTitle: "crfTable.tableTitle",
      tableType: "crfs",
      query: GET_CENTRES,
      entity: "crf",
      createLink: "/crf-create",
      roles: [
        ROLE_SYSADMIN,
        ROLE_ADMIN,
        ROLE_DATAMANAGER,
        ROLE_INVESTIGATOR,
        ROLE_READ_ONLY,
        ROLE_MONITOR,
        ROLE_LEAD_MONITOR,
      ],
    },
  },
  {
    path: "/crf-create/:id",
    layout: DefaultLayout,
    component: CreateCRF,
    props: {
      title: "crf.create.title",
      roles: [ROLE_SYSADMIN, ROLE_ADMIN],
    },
  },
  {
    path: "/crf-create",
    layout: DefaultLayout,
    exact: true,
    component: CreateCRF,
    props: {
      title: "crf.create.title",
      roles: [ROLE_SYSADMIN, ROLE_ADMIN],
    },
  },
  {
    path: "/crf/:id",
    layout: DefaultLayout,
    component: ViewCRF,
    props: {
      title: "crf.title",
      tableTitle: "crfTable.tableTitle",
      tableType: "crfs",
      query: GET_CRF,
      entity: "crf",
      roles: [
        ROLE_SYSADMIN,
        ROLE_ADMIN,
        ROLE_DATAMANAGER,
        ROLE_INVESTIGATOR,
        ROLE_READ_ONLY,
        ROLE_MONITOR,
        ROLE_LEAD_MONITOR,
      ],
    },
  },
  {
    path: "/visit/create/:id",
    layout: DefaultLayout,
    component: CreateVisit,
    props: {
      title: "visit.create.title",
      roles: [ROLE_SYSADMIN, ROLE_ADMIN],
    },
  },
  {
    path: "/visit/edit/:id",
    layout: DefaultLayout,
    component: EditVisit,
    props: {
      title: "visit.edit.title",
      roles: [ROLE_SYSADMIN, ROLE_ADMIN],
    },
  },
  {
    path: "/template/edit/:id",
    layout: DefaultLayout,
    component: EditTemplate,
    props: {
      title: "template.edit.title",
      roles: [ROLE_SYSADMIN, ROLE_ADMIN],
    },
  },
  {
    path: "/template/edit-sheet/:id",
    layout: DefaultLayout,
    component: EditSheetTemplate,
    props: {
      title: "template.edit.title",
      roles: [ROLE_SYSADMIN, ROLE_ADMIN],
    },
  },
  {
    path: "/patients/:id",
    layout: DefaultLayout,
    component: PatientList,
    props: {
      title: "patient.title",
      tableTitle: "patient.tableTitle",
      tableType: "patients",
      query: GET_PATIENTS,
      entity: "patient",
      roles: [
        ROLE_SYSADMIN,
        ROLE_ADMIN,
        ROLE_DATAMANAGER,
        ROLE_INVESTIGATOR,
        ROLE_READ_ONLY,
        ROLE_MONITOR,
        ROLE_LEAD_MONITOR,
      ],
    },
  },
  {
    path: "/patient/:id",
    layout: DefaultLayout,
    component: PatientView,
    props: {
      title: "visits",
      tableTitle: "patient",
      tableType: "filledVisits",
      entity: "patient",
      roles: [
        ROLE_SYSADMIN,
        ROLE_ADMIN,
        ROLE_DATAMANAGER,
        ROLE_INVESTIGATOR,
        ROLE_READ_ONLY,
        ROLE_MONITOR,
        ROLE_LEAD_MONITOR,
      ],
    },
  },
  {
    path: "/filled-visit/:id",
    layout: DefaultLayout,
    component: FilledVisit,
    props: {
      title: "filledVisit.title",
      tableTitle: "filledVisit",
      tableType: "filledVisit",
      entity: "filledVisit",
      roles: [
        ROLE_SYSADMIN,
        ROLE_ADMIN,
        ROLE_DATAMANAGER,
        ROLE_INVESTIGATOR,
        ROLE_READ_ONLY,
        ROLE_MONITOR,
        ROLE_LEAD_MONITOR,
      ],
    },
  },
  {
    path: "/filled-visit-diff/:id",
    layout: DefaultLayout,
    component: DiffVisit,
    props: {
      title: "filledVisitDiff.title",
      tableTitle: "filledVisit",
      tableType: "filledVisit",
      entity: "filledVisit",
      roles: [ROLE_SYSADMIN, ROLE_ADMIN],
    },
  },
  {
    path: "/footer/:group",
    layout: DefaultLayout,
    component: FooterPage,
    props: {},
  },
  {
    path: "/notifications",
    layout: DefaultLayout,
    component: NotificationList,
    props: {
      title: "notifications.title",
      tableTitle: "notifications.tableTitle",
      tableType: "notifications",
      query: GET_CENTRES,
      entity: "notification",
      roles: [
        ROLE_SYSADMIN,
        ROLE_ADMIN,
        ROLE_DATAMANAGER,
        ROLE_INVESTIGATOR,
        ROLE_READ_ONLY,
        ROLE_MONITOR,
        ROLE_LEAD_MONITOR,
      ],
    },
  },
  {
    path: "/files/:id/:key",
    layout: DefaultLayout,
    component: FileView,
    props: {
      title: "fileView.title",
      tableTitle: "fileView",
      entity: "fileView",
      roles: [
        ROLE_SYSADMIN,
        ROLE_ADMIN,
        ROLE_DATAMANAGER,
        ROLE_INVESTIGATOR,
        ROLE_READ_ONLY,
        ROLE_MONITOR,
        ROLE_LEAD_MONITOR,
      ],
    },
  },
];
