export default class StyleComponentForm {
  constructor(element) {
    this.element = element;
  }

  jsonElement() {
    return [
      {
        type: `tabs`,
        key: `custom-styles`,
        components: [
          {
            label: `Size`,
            key: `size`,
            weight: 0,
            components: [
              {
                weight: 0,
                type: `textfield`,
                input: true,
                key: `style${this.element}[height]`,
                label: `Height`,
                placeholder: `Height`,
                tooltip: `The size of the element`,
              },
              {
                weight: 10,
                type: `textfield`,
                input: true,
                key: `style${this.element}[width]`,
                label: `Width`,
                placeholder: `Width`,
                tooltip: `Element width`,
              },
              {
                weight: 20,
                type: `textfield`,
                input: true,
                key: `style${this.element}[padding-top]`,
                label: `Padding top`,
                placeholder: `Padding top`,
                tooltip: `Element Padding top`,
              },
              {
                weight: 30,
                type: `textfield`,
                input: true,
                key: `style${this.element}[padding-right]`,
                label: `Padding right`,
                placeholder: `Padding right`,
                tooltip: `Element Padding right`,
              },
              {
                weight: 40,
                type: `textfield`,
                input: true,
                key: `style${this.element}[padding-bottom]`,
                label: `Padding bottom`,
                placeholder: `Padding bottom`,
                tooltip: `Element Padding bottom`,
              },
              {
                weight: 50,
                type: `textfield`,
                input: true,
                key: `style${this.element}[padding-left]`,
                label: `Padding left`,
                placeholder: `Padding left`,
                tooltip: `Element Padding left`,
              },
              {
                weight: 60,
                type: `textfield`,
                input: true,
                key: `style${this.element}[margin-top]`,
                label: `Margin top`,
                placeholder: `Margin top`,
                tooltip: `Element Margin top`,
              },
              {
                weight: 70,
                type: `textfield`,
                input: true,
                key: `style${this.element}[margin-right]`,
                label: `Margin right`,
                placeholder: `Margin right`,
                tooltip: `Element Margin right`,
              },
              {
                weight: 80,
                type: `textfield`,
                input: true,
                key: `style${this.element}[margin-bottom]`,
                label: `Margin bottom`,
                placeholder: `Margin bottom`,
                tooltip: `Element Margin bottom`,
              },
              {
                weight: 90,
                type: `textfield`,
                input: true,
                key: `style${this.element}[margin-left]`,
                label: `Margin left`,
                placeholder: `Margin left`,
                tooltip: `Element Margin left`,
              },
            ],
          },
          {
            label: `Color`,
            key: `color`,
            weight: 5,
            components: [
              {
                weight: 0,
                type: `textfield`,
                input: true,
                key: `style${this.element}[color]`,
                label: `Color`,
                placeholder: `font color`,
                tooltip: `The font color for this component, color format example: #FFF`,
              },
              {
                weight: 10,
                type: `textfield`,
                input: true,
                key: `style${this.element}[backgroundColor]`,
                label: `Background color`,
                placeholder: `Background color`,
                tooltip: `The background color for this component, color format example: #FFF`,
              },
            ],
          },
          {
            label: `Font`,
            key: `font`,
            weight: 0,
            components: [
              {
                type: `select`,
                input: true,
                key: `style${this.element}[fontFamily]`,
                label: `Font`,
                tooltip: `Font for the component`,
                weight: 40,
                dataSrc: `values`,
                data: {
                  values: [
                    {
                      label: `Georgia, serif`,
                      value: `Georgia, serif`,
                    },
                    {
                      label:
                        "`Palatino Linotype`, `Book Antiqua`, Palatino, serif",
                      value:
                        "`Palatino Linotype`, `Book Antiqua`, Palatino, serif",
                    },
                    {
                      label: "`Times New Roman`, Times, serif",
                      value: "`Times New Roman`, Times, serif",
                    },
                    {
                      label: `Arial, Helvetica, sans-serif`,
                      value: `Arial, Helvetica, sans-serif`,
                    },
                    {
                      label: "`Arial Black`, Gadget, sans-serif",
                      value: "`Arial Black`, Gadget, sans-serif",
                    },
                    {
                      label: "`Comic Sans MS`, cursive, sans-serif",
                      value: "`Comic Sans MS`, cursive, sans-serif",
                    },
                    {
                      label: `Impact, Charcoal, sans-serif`,
                      value: `Impact, Charcoal, sans-serif`,
                    },
                    {
                      label:
                        "`Lucida Sans Unicode`, `Lucida Grande`, sans-serif",
                      value:
                        "``Lucida Sans Unicode`, `Lucida Grande`, sans-serif",
                    },
                    {
                      label: `Tahoma, Geneva, sans-serif`,
                      value: `Tahoma, Geneva, sans-serif`,
                    },
                    {
                      label: "`Trebuchet MS`, Helvetica, sans-serif",
                      value: "`Trebuchet MS`, Helvetica, sans-serif",
                    },
                    {
                      label: `Verdana, Geneva, sans-serif`,
                      value: `Verdana, Geneva, sans-serif`,
                    },
                    {
                      label: "`Courier New`, Courier, monospace",
                      value: "`Courier New`, Courier, monospace",
                    },
                    {
                      label: "`Lucida Console`, Monaco, monospace",
                      value: "`Lucida Console`, Monaco, monospace",
                    },
                    {
                      label: `Roboto,sans-serif`,
                      value: `Roboto,sans-serif`,
                    },
                  ],
                },
              },
              {
                weight: 50,
                type: `select`,
                input: true,
                key: `style${this.element}[fontWeight]`,
                label: `Font weight`,
                placeholder: `Font weight`,
                tooltip: `Font weight`,
                dataSrc: `values`,
                data: {
                  values: [
                    {
                      label: `normal`,
                      value: `normal`,
                    },
                    {
                      label: `lighter`,
                      value: `lighter`,
                    },
                    {
                      label: `bold`,
                      value: `bold`,
                    },
                    {
                      label: `bolder`,
                      value: `bolder`,
                    },
                    {
                      label: `initial`,
                      value: `initial`,
                    },
                    {
                      label: `inherit`,
                      value: `inherit`,
                    },
                  ],
                },
              },
              {
                weight: 60,
                type: `select`,
                input: true,
                key: `style${this.element}[fontStyle]`,
                label: `Font style`,
                placeholder: `Font style`,
                tooltip: `Font style`,
                dataSrc: `values`,
                data: {
                  values: [
                    {
                      label: `normal`,
                      value: `normal`,
                    },
                    {
                      label: `italic`,
                      value: `italic`,
                    },
                    {
                      label: `oblique`,
                      value: `oblique`,
                    },
                    {
                      label: `initial`,
                      value: `initial`,
                    },
                    {
                      label: `inherit`,
                      value: `inherit`,
                    },
                  ],
                },
              },
              {
                weight: 70,
                type: `textfield`,
                input: true,
                key: `style${this.element}[fontSize]`,
                label: `Font-size`,
                placeholder: `Font size`,
                tooltip: `Font size in px`,
              },
              {
                weight: 80,
                type: `textfield`,
                input: true,
                key: `style${this.element}[textAlign]`,
                label: `Text align`,
                placeholder: `Text align`,
                tooltip: `Text align for example: center, right, left`,
              },
              {
                weight: 90,
                type: `textfield`,
                input: true,
                key: `style${this.element}[textDecoration]`,
                label: `Text decoration`,
                placeholder: `Text decoration`,
                tooltip: `Text decoration: for example underline, etc...`,
              },
            ],
          },
          {
            label: `Border`,
            key: `border`,
            weight: 0,
            components: [
              {
                type: `tabs`,
                key: `borders`,
                components: [
                  {
                    label: `Border Top`,
                    key: `top`,
                    weight: 0,
                    components: [
                      {
                        weight: 0,
                        type: `select`,
                        input: true,
                        key: `style${this.element}[border-top-style]`,
                        label: `Border style`,
                        placeholder: `Border style`,
                        tooltip: `Border style`,
                        dataSrc: `values`,
                        data: {
                          values: [
                            {
                              label: `solid`,
                              value: `solid`,
                            },
                            {
                              label: `dotted`,
                              value: `dotted`,
                            },
                            {
                              label: `dashed`,
                              value: `dashed`,
                            },
                            {
                              label: `double`,
                              value: `double`,
                            },
                            {
                              label: `groove`,
                              value: `groove`,
                            },
                            {
                              label: `initial`,
                              value: `initial`,
                            },
                            {
                              label: `inherit`,
                              value: `inherit`,
                            },
                          ],
                        },
                      },
                      {
                        weight: 0,
                        type: `textfield`,
                        input: true,
                        key: `style${this.element}[border-top-width]`,
                        label: `Width`,
                        placeholder: `Width`,
                        tooltip: `Width in px`,
                      },
                      {
                        weight: 10,
                        type: `textfield`,
                        input: true,
                        key: `style${this.element}[border-top-color]`,
                        label: `Border color top`,
                        placeholder: `Color`,
                        tooltip: `Color with # format`,
                      },
                      {
                        weight: 10,
                        type: `textfield`,
                        input: true,
                        key: `style${this.element}[border-top-left-radius]`,
                        label: `Radius top-left`,
                        placeholder: `Radius`,
                        tooltip: `Radius in px`,
                      },
                    ],
                  },
                  {
                    label: `Border Right`,
                    key: `right`,
                    weight: 0,
                    components: [
                      {
                        weight: 0,
                        type: `select`,
                        input: true,
                        key: `style${this.element}[border-right-style]`,
                        label: `Border style`,
                        placeholder: `Border style`,
                        tooltip: `Border style`,
                        dataSrc: `values`,
                        data: {
                          values: [
                            {
                              label: `solid`,
                              value: `solid`,
                            },
                            {
                              label: `dotted`,
                              value: `dotted`,
                            },
                            {
                              label: `dashed`,
                              value: `dashed`,
                            },
                            {
                              label: `double`,
                              value: `double`,
                            },
                            {
                              label: `groove`,
                              value: `groove`,
                            },
                            {
                              label: `initial`,
                              value: `initial`,
                            },
                            {
                              label: `inherit`,
                              value: `inherit`,
                            },
                          ],
                        },
                      },
                      {
                        weight: 0,
                        type: `textfield`,
                        input: true,
                        key: `style${this.element}[border-right-width]`,
                        label: `Width`,
                        placeholder: `Width`,
                        tooltip: `Width in px`,
                      },
                      {
                        weight: 10,
                        type: `textfield`,
                        input: true,
                        key: `style${this.element}[border-right-color]`,
                        label: `Border color right`,
                        placeholder: `Color`,
                        tooltip: `Color with # format`,
                      },
                      {
                        weight: 10,
                        type: `textfield`,
                        input: true,
                        key: `style${this.element}[border-top-right-radius]`,
                        label: `Radius top-right`,
                        placeholder: `Radius`,
                        tooltip: `Radius in px`,
                      },
                    ],
                  },
                  {
                    label: `Border Bottom`,
                    key: `bottom`,
                    weight: 0,
                    components: [
                      {
                        weight: 0,
                        type: `select`,
                        input: true,
                        key: `style${this.element}[border-bottom-style]`,
                        label: `Border style`,
                        placeholder: `Border style`,
                        tooltip: `Border style`,
                        dataSrc: `values`,
                        data: {
                          values: [
                            {
                              label: `solid`,
                              value: `solid`,
                            },
                            {
                              label: `dotted`,
                              value: `dotted`,
                            },
                            {
                              label: `dashed`,
                              value: `dashed`,
                            },
                            {
                              label: `double`,
                              value: `double`,
                            },
                            {
                              label: `groove`,
                              value: `groove`,
                            },
                            {
                              label: `initial`,
                              value: `initial`,
                            },
                            {
                              label: `inherit`,
                              value: `inherit`,
                            },
                          ],
                        },
                      },
                      {
                        weight: 0,
                        type: `textfield`,
                        input: true,
                        key: `style${this.element}[border-bottom-width]`,
                        label: `Width`,
                        placeholder: `Width`,
                        tooltip: `Width in px`,
                      },
                      {
                        weight: 10,
                        type: `textfield`,
                        input: true,
                        key: `style${this.element}[border-bottom-color]`,
                        label: `Border color bottom`,
                        placeholder: `Color`,
                        tooltip: `Color with # format`,
                      },
                      {
                        weight: 10,
                        type: `textfield`,
                        input: true,
                        key: `style${this.element}[border-bottom-right-radius]`,
                        label: `Radius bottom-right`,
                        placeholder: `Radius`,
                        tooltip: `Radius in px`,
                      },
                    ],
                  },
                  {
                    label: `Border Left`,
                    key: `left`,
                    weight: 0,
                    components: [
                      {
                        weight: 0,
                        type: `select`,
                        input: true,
                        key: `style${this.element}[border-left-style]`,
                        label: `Border style`,
                        placeholder: `Border style`,
                        tooltip: `Border style`,
                        dataSrc: `values`,
                        data: {
                          values: [
                            {
                              label: `solid`,
                              value: `solid`,
                            },
                            {
                              label: `dotted`,
                              value: `dotted`,
                            },
                            {
                              label: `dashed`,
                              value: `dashed`,
                            },
                            {
                              label: `double`,
                              value: `double`,
                            },
                            {
                              label: `groove`,
                              value: `groove`,
                            },
                            {
                              label: `initial`,
                              value: `initial`,
                            },
                            {
                              label: `inherit`,
                              value: `inherit`,
                            },
                          ],
                        },
                      },
                      {
                        weight: 0,
                        type: `textfield`,
                        input: true,
                        key: `style${this.element}[border-left-width]`,
                        label: `Width`,
                        placeholder: `Width`,
                        tooltip: `Width in px`,
                      },
                      {
                        weight: 10,
                        type: `textfield`,
                        input: true,
                        key: `style${this.element}[border-left-color]`,
                        label: `Border color left`,
                        placeholder: `Color`,
                        tooltip: `Color with # format`,
                      },
                      {
                        weight: 10,
                        type: `textfield`,
                        input: true,
                        key: `style${this.element}[border-bottom-left-radius]`,
                        label: `Radius bottom-left`,
                        placeholder: `Radius`,
                        tooltip: `Radius in px`,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            label: `Text shadow`,
            key: `text-shadow`,
            weight: 0,
            components: [
              {
                weight: 100,
                type: `textfield`,
                input: true,
                key: `style${this.element}[h-offset-text]`,
                label: `h-offset`,
                placeholder: `h-offset`,
                tooltip: `The h-offset`,
              },
              {
                weight: 110,
                type: `textfield`,
                input: true,
                key: `style${this.element}[v-offset-text]`,
                label: `v-offset`,
                placeholder: `v-offset`,
                tooltip: `The v-offset`,
              },
              {
                weight: 120,
                type: `textfield`,
                input: true,
                key: `style${this.element}[blur-radius-text]`,
                label: `blur-radiust`,
                placeholder: `blur-radius`,
                tooltip: `The blur-radius`,
              },
              {
                weight: 130,
                type: `textfield`,
                input: true,
                key: `style${this.element}[color-text]`,
                label: `Text shadow color`,
                placeholder: `Text shadow color`,
                tooltip: `The text shadow color`,
              },
            ],
          },
          {
            label: `Box shadow`,
            key: `box-shadow`,
            weight: 0,
            components: [
              {
                weight: 100,
                type: `textfield`,
                input: true,
                key: `style${this.element}[h-offset-box]`,
                label: `h-offset`,
                placeholder: `h-offset`,
                tooltip: `The h-offset`,
              },
              {
                weight: 110,
                type: `textfield`,
                input: true,
                key: `style${this.element}[v-offset-box]`,
                label: `v-offset`,
                placeholder: `v-offset`,
                tooltip: `The v-offset`,
              },
              {
                weight: 120,
                type: `textfield`,
                input: true,
                key: `style${this.element}[blur-radius-box]`,
                label: `blur-radiust`,
                placeholder: `blur-radius`,
                tooltip: `The blur-radius`,
              },
              {
                weight: 130,
                type: `textfield`,
                input: true,
                key: `style${this.element}[spread-box]`,
                label: `spread`,
                placeholder: `spread`,
                tooltip: `The spread`,
              },
              {
                weight: 140,
                type: `textfield`,
                input: true,
                key: `style${this.element}[color-box]`,
                label: `Box shadow color`,
                placeholder: `Box shadow color`,
                tooltip: `The box shadow color`,
              },
            ],
          },
        ],
      },
    ];
  }
}
