import { Button, Dialog, Classes, Intent, Spinner } from "@blueprintjs/core";
import React, { useState } from "react";

const ModalActionCallback = ({
  t,
  isOpen,
  toggleFunction,
  isLoading,
  customText,
  customHeader,
  callback,
  secondCallback,
  primaryButtonText,
  secondaryButtonText
}) => {
  const [state, setState] = useState({
    autoFocus: true,
    canEscapeKeyClose: true,
    canOutsideClickClose: true,
    enforceFocus: true,
    usePortal: true
  });

  const handleClose = () => toggleFunction();

  return (
    <Dialog
      icon="info-sign"
      onClose={() => handleClose()}
      title={customHeader}
      isOpen={isOpen}
    >
      <div className={Classes.DIALOG_BODY}>
        <div
          className="spinner-loader-listing"
          style={isLoading ? { display: "inline" } : { display: "none" }}
        >
          <Spinner size={50} />
        </div>
        <p className="text-center pt-4">{customText}</p>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={() => toggleFunction()}>{t("close")}</Button>
          {secondCallback && (
            <Button
              intent={Intent.PRIMARY}
              onClick={() => {
                secondCallback(true);
                toggleFunction();
              }}
              target="_blank"
            >
              {secondaryButtonText}
            </Button>
          )}
          <Button
            intent={Intent.SUCCESS}
            onClick={() => {
              callback();
              toggleFunction();
            }}
            target="_blank"
          >
            {primaryButtonText}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ModalActionCallback;
