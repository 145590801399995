import { Button, Dialog, Classes, Intent } from "@blueprintjs/core";
import React, { useState } from "react";
import { client } from "../../apolloClient";

const ConfirmDeleteModal = ({
  t,
  isOpen,
  toggleFunction,
  toDeleteId,
  toDeleteName,
  toDeleteIndex,
  refetchFunction,
  mutation,
  deleteFunc,
  customText,
  customHeader
}) => {
  const [state, setState] = useState({
    autoFocus: true,
    canEscapeKeyClose: true,
    canOutsideClickClose: true,
    enforceFocus: true,
    usePortal: true
  });

  const deleteItem = id => {
    client
      .mutate({
        variables: { id },
        mutation
      })
      .then((res, err) => {
        client.resetStore().then(res => {
          toggleFunction();
          if (refetchFunction) {
            refetchFunction();
          }
        });
      });
  };

  const handleClose = () => toggleFunction();

  return (
    <Dialog
      icon="info-sign"
      onClose={handleClose}
      title={`${t("deleteModalHeader")} ${toDeleteName}?`}
      isOpen={isOpen}
    >
      <div className={Classes.DIALOG_BODY}>
        <p>{customHeader ? t(customHeader) : t("confirmDelete")}</p>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={() => toggleFunction()}>{t("cancel")}</Button>

          <Button
            intent={Intent.PRIMARY}
            onClick={() => {
              deleteFunc
                ? deleteFunc(toDeleteIndex, toDeleteId)
                : deleteItem(toDeleteId);
            }}
            target="_blank"
          >
            {customText ? t(customText) : t("table.delete")}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmDeleteModal;
