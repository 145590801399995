import React from "react";
import { withTranslation } from "react-i18next";
import { client } from "../../../apolloClient";
import {
  Dialog,
  Button,
  FormGroup,
  InputGroup,
  TextArea,
} from "@blueprintjs/core";
import { GET_CENTRE } from "../../../queries";
import { CREATE_CENTRE, UPDATE_CENTRE } from "../../../mutations";
import { DateInput } from "@blueprintjs/datetime";
import moment from "moment";
import "./_Create.scss";
import { LocaleUtils } from "react-day-picker";
import MomentLocaleUtils from "react-day-picker/moment";
import "moment/locale/es";

class CentreForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      centre: {
        id: "",
        name: "",
        code: "",
        address: "",
        inaugurated: "",
        phone: "",
        locality: "",
        province: "",
        postalCode: "",
      },
      isEdit: false,
      loading: false,
      redirect: false,
    };

    this.handleClose = this.handleClose.bind(this);
    this.resetForm = this.resetForm.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.state !== prevProps.state) {
      try {
        if (this.props.state.entity && this.props.isOpen) {
          this.setState({ loading: true });
          const variables = { id: this.props.state.entity.id };
          client
            .query({ variables: variables, query: GET_CENTRE })
            .then((res) => {
              this.setState((prevState) => ({
                centre: {
                  ...prevState.centre,
                  ...res.data.centre,
                },
                isEdit: true,
                loading: false,
              }));
            });
        }
      } catch (err) {}
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({ loading: true });
    const mutation = this.state.isEdit ? UPDATE_CENTRE : CREATE_CENTRE;
    const variables = {
      name: this.state.centre.name,
      address: this.state.centre.address,
      phone: this.state.centre.phone,
      observations: this.state.centre.observations,
      code: this.state.centre.code,
      locality: this.state.centre.locality,
      province: this.state.centre.province,
      postalCode: this.state.centre.postalCode,
    };
    if (this.state.centre.inaugurated)
      variables.inaugurated = this.state.centre.inaugurated;
    if (this.state.isEdit) {
      variables.id = this.state.centre.id;
    }
    client
      .mutate({
        variables,
        mutation,
      })
      .then((res, err) => {
        if (err) console.log(err);
        this.setState(
          { loading: false, redirect: true },
          this.props.onCreateSubmit(),
          this.resetForm()
        );
      });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      centre: {
        ...prevState.centre,
        [name]: value,
      },
    }));
  };

  handleChangeDate = (date, isUserChanged) => {
    this.setState((prevState) => ({
      centre: {
        ...prevState.centre,
        inaugurated: moment(date).format("YYYY-MM-DD"),
      },
    }));
  };

  getMomentFormatter = (format) => {
    // note that locale argument comes from locale prop and may be undefined
    return {
      formatDate: (date, locale) => moment(date).locale(locale).format(format),
      parseDate: (str, locale) =>
        moment(this.state.centre.inaugurated, format).locale(locale).toDate(),
      placeholder: this.props.t("date.pick"),
    };
  };

  handleClose() {
    this.resetForm();
    this.props.toggleFunction();
  }

  resetForm() {
    this.setState({
      centre: {
        id: "",
        name: "",
        code: "",
        address: "",
        inaugurated: "",
        phone: "",
      },
    });
  }

  render() {
    // if (!this.state.loading) {
    //   if (this.state.redirect) {
    //     return <Navigate to="/centres" />;
    //   }
    // }
    return (
      <Dialog
        icon="home"
        onClose={this.handleClose}
        title={
          Object.keys(this.props.state).length > 0
            ? this.props.t("modifyCentre")
            : this.props.t("createCentre")
        }
        isOpen={this.props.isOpen}
        style={{ width: 780, "padding-bottom": "unset" }}
      >
        <div className="container">
          {/* <Breadcrumb
            crumbs={[
              { link: "/", name: "Home", active: false },
              {
                link: "centres",
                name: this.props.t("centres.title"),
                active: false
              },
              {
                link: "",
                name: this.props.t(this.props.title),
                active: true
              }
            ]}
          /> */}
          {/* <Card interactive={true} elevation={Elevation.TWO}>
            <h4 className="mb-3">
              <a href="#">{this.props.t(this.props.title)}</a>
            </h4> */}
          <form onSubmit={this.handleSubmit}>
            <h5 className="menu-titles">{this.props.t("centre")}</h5>
            <div className="row">
              <div className="col">
                <FormGroup
                  label={this.props.t("name")}
                  labelFor="name"
                  labelInfo={`(${this.props.t("required")})`}
                >
                  <InputGroup
                    id="name"
                    name="name"
                    onChange={this.handleChange}
                    value={this.state.centre.name}
                    placeholder={this.props.t("name")}
                    required={true}
                    large={true}
                  />
                </FormGroup>
              </div>

              <div className="col">
                <FormGroup
                  label={this.props.t("code")}
                  labelFor="code"
                  labelInfo={`(${this.props.t("required")})`}
                >
                  <InputGroup
                    id="code"
                    name="code"
                    onChange={this.handleChange}
                    value={this.state.centre.code}
                    placeholder={this.props.t("code")}
                    large={true}
                    required={true}
                  />
                </FormGroup>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <FormGroup
                  label={this.props.t("centreForm.observations")}
                  labelFor="observations"
                >
                  <TextArea
                    id="observations"
                    name="observations"
                    fill={true}
                    onChange={this.handleChange}
                    value={this.state.centre.observations}
                  />
                </FormGroup>
              </div>
            </div>
            <hr className="middle-line" />
            <h5 className="menu-titles">{this.props.t("localization")}</h5>
            <div className="row">
              <div className="col">
                <FormGroup label={this.props.t("address")} labelFor="address">
                  <InputGroup
                    id="address"
                    name="address"
                    onChange={this.handleChange}
                    value={this.state.centre.address}
                    placeholder={this.props.t("address")}
                    large={true}
                  />
                </FormGroup>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <FormGroup label={this.props.t("province")} labelFor="province">
                  <InputGroup
                    id="province"
                    name="province"
                    onChange={this.handleChange}
                    value={this.state.centre.province}
                    placeholder={this.props.t("province")}
                    large={true}
                    required={false}
                  />
                </FormGroup>
              </div>
              <div className="col">
                <FormGroup label={this.props.t("city")} labelFor="locality">
                  <InputGroup
                    id="locality"
                    name="locality"
                    onChange={this.handleChange}
                    value={this.state.centre.locality}
                    placeholder={this.props.t("city")}
                    large={true}
                    required={false}
                  />
                </FormGroup>
              </div>
              <div className="col">
                <FormGroup
                  label={this.props.t("postalCode")}
                  labelFor="postalCode"
                >
                  <InputGroup
                    id="postalCode"
                    name="postalCode"
                    onChange={this.handleChange}
                    value={this.state.centre.postalCode}
                    placeholder={this.props.t("postalCode")}
                    large={true}
                    required={false}
                  />
                </FormGroup>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <FormGroup
                  label={this.props.t("inaugurated")}
                  labelFor="inaugurated"
                >
                  <DateInput
                    {...this.getMomentFormatter("LL")}
                    className="bp3-input-group bp3-large"
                    locale="es"
                    localeUtils={MomentLocaleUtils}
                    name="inaugurated"
                    id="inaugurated"
                    onChange={this.handleChangeDate}
                    large={true}
                    fill={true}
                    value={
                      this.state.centre.inaugurated
                        ? moment(
                            this.state.centre.inaugurated,
                            "YYYY-MM-DD"
                          ).toDate()
                        : null
                    }
                  />
                </FormGroup>
              </div>
              <div className="col">
                <FormGroup label={this.props.t("phone")} labelFor="phone">
                  <InputGroup
                    id="phone"
                    name="phone"
                    onChange={this.handleChange}
                    value={this.state.centre.phone}
                    placeholder={this.props.t("phone")}
                    large={true}
                  />
                </FormGroup>
              </div>
            </div>
            <hr className="footer-line" />
            <div className="row">
              <div className="col">
                <Button
                  className="close-button"
                  type="button"
                  onClick={this.handleClose}
                >
                  {this.props.t("cancel")}
                </Button>
                <Button className="addSubmit" type="submit" intent="success">
                  {Object.keys(this.props.state).length > 0
                    ? this.props.t("form.update")
                    : this.props.t("form.create")}
                </Button>
              </div>
            </div>
          </form>
          {/* </Card> */}
        </div>
      </Dialog>
    );
  }
}

export default withTranslation()(CentreForm);
