import { Button, Dialog, Classes, Intent, FormGroup } from "@blueprintjs/core";
import React, { useState } from "react";
import { client } from "../../../apolloClient";

import { UPDATE_VISIT_CODE } from "../../../mutations";
import MonacoEditor from "react-monaco-editor";

const ModalBranchingCode = ({
  t,
  isOpen,
  toggleFunction,
  visitId,
  initialCode
}) => {
  const [state, setState] = useState({
    autoFocus: true,
    canEscapeKeyClose: true,
    canOutsideClickClose: true,
    enforceFocus: true,
    usePortal: true,
    code: initialCode
  });

  const options = {
    selectOnLineNumbers: true
  };

  const handleSubmit = async e => {
    e.preventDefault();

    let variables = {
      code: state.code,
      id: visitId
    };

    client
      .mutate({
        variables,
        mutation: UPDATE_VISIT_CODE
      })
      .then((res, err) => {
        if (err) console.log(err);
        handleClose();
      });
  };

  const handleClose = () => toggleFunction();

  return (
    <Dialog
      icon="info-sign"
      onClose={handleClose}
      title={t("templateSave")}
      isOpen={isOpen}
      style={{ width: "850px" }}
    >
      <form onSubmit={handleSubmit}>
        <div className={Classes.DIALOG_BODY}>
          <FormGroup label={t("code")} labelFor="code" labelInfo="(required)">
            <MonacoEditor
              width="800"
              height="600"
              language="javascript"
              theme="vs-dark"
              value={state.code}
              options={options}
              onChange={(value, event) => {
                setState(prevState => ({
                  ...prevState,
                  code: value
                }));
              }}
            />
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={handleClose}>{t("cancel")}</Button>

            <Button intent={Intent.PRIMARY} type="submit">
              {t("save")}
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default ModalBranchingCode;
