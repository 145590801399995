import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { setContext } from "apollo-link-context";
import { createUploadLink } from "apollo-upload-client";
import { getToken, getSEK } from "./services";
import { API_URL } from "./config";

const uploadLink = createUploadLink({
  uri: API_URL + "/graphql/"
});

const authLink = setContext((_, { headers }) => {
  const token = getToken();
  const sek = getSEK();

  return {
    headers: {
      ...headers,
      authorization: token ? `${token}$_${sek}` : ""
    }
  };
});

export const client = new ApolloClient({
  link: authLink.concat(uploadLink),
  cache: new InMemoryCache()
});
