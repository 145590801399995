import Base from "formiojs/components/_classes/field/Field";
import editForm from "./ReferenceComponent.form";
import { client } from "../../apolloClient";
import { GET_FIELD_VALUE } from "../../queries";

export default class Reference extends Base {
  constructor(component, options, data) {
    super(component, options, data);
  }

  static schema() {
    return Base.schema({
      label: "Reference",
      type: "reference",
      key: "reference",
      clearOnHide: true,
      input: true,
      tree: true,
      components: [],
    });
  }

  static builderInfo = {
    title: "Reference",
    group: "reference",
    icon: "far fa-file",
    weight: 70,
    schema: Reference.schema(),
  };

  static editForm = editForm;

  /*
   * Defines the editForm when editing a component in the builder.
   */
  //static editForm = editForm;

  /**
   * Render returns an html string of the fully rendered component.
   *
   * @param children - If this class is extendended, the sub string is passed as children.
   * @returns {string}
   */
  render(children) {
    let label = "";
    if (typeof this.component.field_reference != "undefined")
      label = this.component.field_reference.label;

    // Calling super.render will wrap it html as a component.
    return super.render(`
      <p>
        ${label}: <span class="field-value">No relleno</span>
      </p>
    `);
  }

  /**
   * After the html string has been mounted into the dom, the dom element is returned here. Use refs to find specific
   * elements to attach functionality to.
   *
   * @param element
   * @returns {Promise}
   */
  attach(element) {
    if (
      this.component.patient &&
      typeof this.component.visit != "undefined" &&
      typeof this.component.visit.value != "undefined" &&
      typeof this.component.field_reference != "undefined" &&
      typeof this.component.field_reference.value != "undefined"
    )
      client
        .query({
          variables: {
            id: this.component.visit.value,
            patient: this.component.patient,
            field: this.component.field_reference.value,
          },
          query: GET_FIELD_VALUE,
          fetchPolicy: "network-only",
        })
        .then((res) => {
          const fieldValue = element.querySelector(".field-value");
          if (fieldValue) fieldValue.innerHTML = res.data.fieldValue.value;
          this.setValue(res.data.fieldValue.value);
        });

    this.stylesComponent(element);
    this.stylesName(element);
    this.stylesBox(element);

    return super.attach(element);
  }

  /**
   * Attach styles to element component
   */
  stylesComponent(element) {
    let boxShadow = ``;
    let textShadow = ``;
    if (typeof this.component.styleComponent !== "undefined") {
      Object.keys(this.component.styleComponent).forEach((key, index) => {
        if (typeof this.component.styleComponent[key] !== "undefined") {
          // special for box shadow
          if (
            key == "h-offset-box" ||
            key == "v-offset-box" ||
            key == "blur-radius-box" ||
            key == "spread-box"
          ) {
            if (this.component.styleComponent[key] !== "")
              boxShadow += `${this.component.styleComponent[key]} `;
            else boxShadow += "0px ";
          } else if (key == "color-box") {
            if (this.component.styleComponent[key] !== "")
              boxShadow += `${this.component.styleComponent[key]} `;
            else boxShadow += "#000 ";
          } else if (
            key == "h-offset-text" ||
            key == "v-offset-text" ||
            key == "blur-radius-text" ||
            key == "spread-text"
          ) {
            if (this.component.styleComponent[key] !== "")
              textShadow += `${this.component.styleComponent[key]} `;
            else textShadow += "0px ";
          } else if (key == "color-text") {
            if (this.component.styleComponent[key] !== "")
              textShadow += `${this.component.styleComponent[key]} `;
            else textShadow += "#000 ";
          } else {
            if (this.component.styleComponent[key] !== "")
              element.style[key] = this.component.styleComponent[key];
          }
        }
      });

      element.style.boxShadow = boxShadow;
      element.style.textShadow = textShadow;
    }
  }

  /**
   * Attach styles to element label
   */
  stylesName(element) {
    let boxShadow = ``;
    let textShadow = ``;
    const label = element.querySelector("label");
    if (label && typeof this.component.styleName !== "undefined") {
      Object.keys(this.component.styleName).forEach((key, index) => {
        if (typeof this.component.styleName[key] !== "undefined") {
          // special for box shadow
          if (
            key == "h-offset-box" ||
            key == "v-offset-box" ||
            key == "blur-radius-box" ||
            key == "spread-box"
          ) {
            if (this.component.styleName[key] !== "")
              boxShadow += `${this.component.styleName[key]} `;
            else boxShadow += "0px ";
          } else if (key == "color-box") {
            if (this.component.styleName[key] !== "")
              boxShadow += `${this.component.styleName[key]} `;
            else boxShadow += "#000 ";
          } else if (
            key == "h-offset-text" ||
            key == "v-offset-text" ||
            key == "blur-radius-text" ||
            key == "spread-text"
          ) {
            if (this.component.styleName[key] !== "")
              textShadow += `${this.component.styleName[key]} `;
            else textShadow += "0px ";
          } else if (key == "color-text") {
            if (this.component.styleName[key] !== "")
              textShadow += `${this.component.styleName[key]} `;
            else textShadow += "#000 ";
          } else {
            if (this.component.styleName[key] !== "")
              label.style[key] = this.component.styleName[key];
          }
        }
      });

      label.style.boxShadow = boxShadow;
      label.style.textShadow = textShadow;
    }
  }

  /**
   * Attach styles to element input box
   */
  stylesBox(element) {
    let boxShadow = ``;
    let textShadow = ``;
    const input = element.querySelector("p");
    if (input && typeof this.component.styleBox !== "undefined") {
      Object.keys(this.component.styleBox).forEach((key, index) => {
        if (typeof this.component.styleBox[key] !== "undefined") {
          // special for box shadow
          if (
            key == "h-offset-box" ||
            key == "v-offset-box" ||
            key == "blur-radius-box" ||
            key == "spread-box"
          ) {
            if (this.component.styleBox[key] !== "")
              boxShadow += `${this.component.styleBox[key]} `;
            else boxShadow += "0px ";
          } else if (key == "color-box") {
            if (this.component.styleBox[key] !== "")
              boxShadow += `${this.component.styleBox[key]} `;
            else boxShadow += "#000 ";
            // special for text shadow
          } else if (
            key == "h-offset-text" ||
            key == "v-offset-text" ||
            key == "blur-radius-text" ||
            key == "spread-text"
          ) {
            if (this.component.styleBox[key] !== "")
              textShadow += `${this.component.styleBox[key]} `;
            else textShadow += "0px ";
          } else if (key == "color-text") {
            if (this.component.styleBox[key] !== "")
              textShadow += `${this.component.styleBox[key]} `;
            else textShadow += "#000 ";
          } else {
            if (this.component.styleBox[key] !== "")
              input.style[key] = this.component.styleBox[key];
          }
        }
      });

      input.style.boxShadow = boxShadow;
      input.style.textShadow = textShadow;
    }
  }

  /**
   * Get the value of the component from the dom elements.
   *
   * @returns {Array}
   */
  getValue() {
    return super.getValue();
  }

  /**
   * Set the value of the component into the dom elements.
   *
   * @param value
   * @returns {boolean}
   */
  setValue(value) {
    super.setValue(value);
  }
}
