import baseEditForm from "formiojs/components/time/Time.form";
import StyleComponentForm from "../../StyleComponent.form";

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "display",
        components: [
          {
            weight: 5,
            type: "textfield",
            input: true,
            label: "Code",
            tooltip: "This field code.",
            key: "code",
            required: true,
          },
          {
            weight: 10,
            type: "textfield",
            input: false,
            label: "Key",
            tooltip: "This field key.",
            key: "key",
            disabled: true,
          },
          {
            weight: 700,
            type: "checkbox",
            label: "Randomizer",
            tooltip:
              "This field is used for branching, it will only be editable one time.",
            key: "randomizer",
            input: true,
          },
          {
            weight: 710,
            type: "checkbox",
            label: "Critical variable",
            tooltip:
              "This variable is critical a notification will be send to users on it's modification.",
            key: "critical",
            input: true
          }
        ],
      },
      {
        label: "Component",
        key: "component-styles",
        weight: 80,
        components: new StyleComponentForm("Component").jsonElement(),
      },
      {
        label: "Name",
        key: "label-styles",
        weight: 90,
        components: new StyleComponentForm("Name").jsonElement(),
      },
      {
        label: "Box",
        key: "box-styles",
        weight: 100,
        components: new StyleComponentForm("Box").jsonElement(),
      },
    ],
    ...extend
  );
};
