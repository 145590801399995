import React from "react";
import { Query } from "react-apollo";
import { withTranslation } from "react-i18next";
import Paginator from "../../components/Paginator/Paginator";
import TableSizeSelector from "../../components/Paginator/TableSizeSelector";
import { client } from "../../apolloClient";
import {
  mediaFilesAppend,
  DEFAULT_ELEM_PAGE,
  MONITORIZATION_TYPE_CHECKED,
  MONITORIZATION_TYPE_COMMENT,
  MONITORIZATION_TYPE_INCIDENCE,
} from "../../config";
import { Card, Spinner, Icon, InputGroup, Button, MenuItem, Elevation } from "@blueprintjs/core";
import { GET_MONITORIZATIONS_LIST, GET_INPUT_MONITORIZATION, GET_CRFS } from "../../queries";
import Breadcrumb from "../../components/Breadcrumb";
import ModalTimeline from "../../components/ModalTimeline/ModalTimeline";
import { Select } from "@blueprintjs/select";
import { Document, pdf } from '@react-pdf/renderer';
import PatientMonitorizations from '../../components/MonitorizationPDF/monitorizations'
import { downloadPDF } from '../../utils/DownloadFile'

class NotificationList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      page: 1,
      orderField: "created",
      elementsPage: DEFAULT_ELEM_PAGE,
      modalTimelineOpened: false,
      fromNotificationInput: null,
      monitorizationId: "",
      monitorizationType: "",
      monitorizationField: "",
      fixesElement: { status: "", fixes: { edges: [] } },
      crfs: [],
      search: {
        text: "",
        crf: { id: null, name: this.props.t("select.crf") },
        code: "",
        branch: "",
        state: { id: "", text: "All states" },
      },
    };

    this.states = [
      { id: "", text: "All types" },
      { id: MONITORIZATION_TYPE_CHECKED, text: "Checked" },
      { id: MONITORIZATION_TYPE_COMMENT, text: "Comment" },
      { id: MONITORIZATION_TYPE_INCIDENCE, text: "Incidence" },
    ];
    this.monitorizations = [];
  }

  componentDidMount() {
    if (this.refetch) {
      this.refetch();
    }

    client
      .query({
        query: GET_CRFS,
        variables: { elementsPerPage: 200, page: 1 },
      })
      .then((res) => {
        if (res.data.crfs.objects[0].id !== null)
          res.data.crfs.objects.unshift({
            id: null,
            name: this.props.t("select.crf"),
          });
        this.setState((prevState) => ({
          ...prevState,
          crfs: res.data.crfs.objects,
        }));
      });
  }

  componentWillReceiveProps() {
    if (this.props.reload) {
      this.refetch();
      this.props.reloadTemplates();
    }
  }

  handlerPageChange = (page) => {
    this.setState({
      page: page,
    });
  };

  handleChange = async (event) => {
    const { name, value } = event.target;
    await this.setState((prevState) => ({
      search: {
        ...prevState.search,
        [name]: value,
      },
    }));
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const search = {...this.state.search, crfId: this.state.search.crf.id};

    if (this.state.search.state) search.state = this.state.search.state.id;

    await this.setState((prevState) => ({
      ...prevState,
      page: 1,
    }));

    await this.refetch({
      ...search,
      page: 1,
    });
  };

  toggleTimelineModal = (monitorizationId, field) => {
    if (monitorizationId)
      client
        .query({
          variables: { id: monitorizationId },
          query: GET_INPUT_MONITORIZATION,
          fetchPolicy: "network-only",
        })
        .then((res) => {
          if (res.data.inputMonitorization !== null)
            this.setState((prevState) => ({
              ...prevState,
              monitorizationId: monitorizationId,
              modalTimelineOpened: !this.state.modalTimelineOpened,
              fixesElement: res.data.inputMonitorization,
              monitorizationField: field,
            }));
        });
    else
      this.setState((prevState) => ({
        ...prevState,
        monitorizationId: monitorizationId,
        modalTimelineOpened: !this.state.modalTimelineOpened,
      }));
  };

  orderTable = (field) => {
    if (this.state.orderField === field) {
      this.setState((prevState) => ({
        ...prevState,
        orderField: `-${field}`,
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        orderField: field,
      }));
    }
  };

  onChangePage(num) {
    this.setState({ elementsPage: num });
    this.refetch();
  }

  // CRF search options

  renderItem = (item, { modifiers, handleClick }) => {
    return (
      <MenuItem
        active={modifiers.active}
        key={item.id}
        label={item.code}
        onClick={handleClick}
        text={item.name}
        shouldDismissPopover={false}
      />
    );
  };

  renderItemState = (item, { modifiers, handleClick }) => {
    return (
      <MenuItem
        active={modifiers.active}
        key={item.id}
        onClick={handleClick}
        text={item.text}
        shouldDismissPopover={false}
      />
    );
  };

  areCRFEquals = (a, b) => {
    let equal = a.id === b.id ? true : false;

    return equal;
  };

  areStateEquals = (a, b) => {
    let equal = a.id === b.id ? true : false;

    return equal;
  };

  handleSelectChange = (crf) => {
    this.setState((prevState) => ({
      search: {
        ...prevState.search,
        crf,
      },
    }));
  };

  handleSelectStateChange = (state) => {
    this.setState((prevState) => ({
      search: {
        ...prevState.search,
        state,
      },
    }));
  };

  handleFilterCRF = (query, crf) =>
    crf.name.indexOf(query) >= 0

  downloadPdf = () => {
    const MyDoc = (
      <Document>
        <PatientMonitorizations monitorizations={this.monitorizations} t={this.t} code="" visit={this.t("Monitorizations")}/>
      </Document>
    );
    pdf(MyDoc).toBlob().then((blob) => {
      downloadPDF(blob, 'monitorizations')
    });
  }


  render() {
    this.t = this.props.t;
    const dataKeysTemplate = ["patientCode", "crf", "visit", "inputLabel", "fixType", "created"];

    const capitalize = (s) => {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    };

    return (

      <div className="container-fluid">
        <Breadcrumb
          crumbs={[
            { link: "/", name: "Home", active: false },
            {
              link: "/notifications",
              name: capitalize(this.t("Notifications.title")),
              active: false,
            }
          ]}
        />
        <div>
          <div className="row mt-5">
            <div className="col">
              <h3>{capitalize(this.t("notifications"))}</h3>
            </div>
          </div>

          <Card interactive={false} elevation={Elevation.TWO} className="mb-3">
            <form onSubmit={this.handleSubmit}>
              <div className="row pt-2 pb-3">
                <div className="col">
                  <Select
                    itemsEqual={this.areCRFEquals}
                    itemRenderer={this.renderItem}
                    items={this.state.crfs}
                    large={true}
                    itemPredicate={this.handleFilterCRF}
                    className="w-100"
                    noResults={
                      <MenuItem
                        icon="office"
                        disabled={true}
                        text={this.props.t("No results.")}
                      />
                    }
                    onItemSelect={this.handleSelectChange}
                  >
                    <Button
                      icon="office"
                      rightIcon="caret-down"
                      large={true}
                      fill={true}
                      text={
                        this.state.search.crf
                          ? this.state.search.crf.name
                          : this.props.t("All my crfs.")
                      }
                    />
                  </Select>
                </div>
                <div className="col">
                  <InputGroup
                    id="code"
                    name="code"
                    onChange={this.handleChange}
                    value={this.state.search.code}
                    placeholder={this.props.t("code")}
                    large={true}
                  />
                </div>
                <div className="col">
                  <InputGroup
                    id="text"
                    name="text"
                    onChange={this.handleChange}
                    value={this.state.search.text}
                    placeholder={this.props.t("search")}
                    large={true}
                  />
                </div>
                <div className="col">
                  <Select
                    itemsEqual={this.areStateEquals}
                    itemRenderer={this.renderItemState}
                    items={this.states}
                    large={true}
                    className="w-100"
                    onItemSelect={(item) => this.handleSelectStateChange(item)}
                  >
                    <Button
                      rightIcon="caret-down"
                      large={true}
                      fill={true}
                      text={
                        this.state.search.state
                          ? this.state.search.state.text
                          : this.props.t("All states")
                      }
                    />
                  </Select>
                </div>
                <div className="col-md-1 text-right">
                  <Button
                    type="submit"
                    //intent="success"
                    className="patient-search-button"
                    large={true}
                    icon="search"
                    handleClick={this.handleSubmit}
                  />
                  <Button
                      type="button"
                      intent="success"
                      large={true}
                      icon="download"
                      onClick={this.downloadPdf}
                      className="ml-1"
                      title={this.props.t("download.monitorizations")}
                    />
                </div>
              </div>
            </form>
          </Card>

          <div>
            <Query
              query={GET_MONITORIZATIONS_LIST}
              variables={{
                crfId: this.state.search.crf.id,
                state: this.state.search.state.id,
                text: this.state.search.text,
                code: this.state.search.code,
                elementsPerPage: this.state.elementsPage,
                page: this.state.page,
                orderField: this.state.orderField,
              }}
              fetchPolicy="network-only"
            >
              {({ loading, error, data, refetch }) => {
                
                if (loading)
                  return (
                    <div className="spinner-loader-listing">
                      <Spinner
                        size={80}
                        spinnerColor={"#007BFF"}
                        spinnerWidth={2}
                        visible={true}
                      />
                    </div>
                  );
                if (error) return `Error ${error.message}`;
                this.refetch = refetch;
                this.monitorizations = data.monitorizationsList?.objects;
                {
                  return (
                    <div>
                      <table className="table table-custom table-hover">
                        <thead className="bg-light">
                          <tr className="table-hover">
                            <th scope="col" className="border-0">
                              #
                            </th>
                            <th
                              key="template-name-col"
                              scope="col"
                            >
                              {this.t("table.patient")}
                            </th>
                            <th
                              key="name-col"
                              scope="col"
                            >
                              {this.t("table.crf")}
                            </th>
                            <th
                              key="name-col"
                              scope="col"
                            >
                              {this.t("table.visit")}
                            </th>
                            <th
                              key="name-col"
                              scope="col"
                            >
                              {this.t("table.inputLabel")}
                            </th>
                            <th
                              key="name-col"
                              scope="col"
                            >
                              {this.t("table.fixType")}
                            </th>
                            <th
                              key="name-col"
                              scope="col"
                            >
                              {this.t("created")}
                            </th>
                            <th scope="col" className="text-right">
                              {this.t("table.actions")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.monitorizations?.map((data, index) => (
                            <tr key={index} className="table-hover">
                              <td>{index + 1}</td>
                              {dataKeysTemplate.map((key, index) => {
                                let info = "";
                                try {
                                  info = key
                                    .split(".")
                                    .reduce(function (c, prop) {
                                      return c[prop];
                                    }, data);
                                } catch (err) {}
                                if (
                                  typeof info === "string" &&
                                  info.endsWith("png")
                                )
                                  return (
                                    <td key={index}>
                                      <img
                                        className="icon"
                                        src={mediaFilesAppend + info}
                                        alt=""
                                      />
                                    </td>
                                  );
                                else if (key === "created")
                                  return (
                                    <td key={index}>
                                      {new Date(info).toLocaleDateString()}
                                    </td>
                                  );
                                else if (key === "patientCode")
                                  return <td key={index}>{data.filledSheet.filledVisit.filledVisit.patient.code}</td>;
                                else if (key === "crf")
                                  return <td key={index}>{data.filledSheet.filledVisit.filledVisit.patient.crf.name}</td>;
                                else if (key === "visit")
                                  return <td key={index}>{data.filledSheet.filledVisit.filledVisit.visit.name}</td>;
                                else if (key === "inputLabel")
                                  return <td key={index}>{data.inputLabel}</td>;
                                else if (key === "fixType")
                                  return <td key={index} style={(() => {
                                    switch (data.status) {
                                      case MONITORIZATION_TYPE_CHECKED:
                                        return {color: 'green'};
                                      case MONITORIZATION_TYPE_COMMENT:
                                        return {color: '#ad8b1c'};
                                      case MONITORIZATION_TYPE_INCIDENCE:
                                        return {color: 'red'};
                                      default:
                                        return {};
                                    }
                                  })()}>{data.status}</td>;
                                else return <td key={index}>{info}</td>;
                              })}
                              <td className="text-right">
                                <Button
                                  minimal
                                  className="bp3-intent-info ml-2"
                                  icon="eye-open"
                                  title={this.t("view")}
                                  onClick={() =>
                                    this.toggleTimelineModal(
                                      data.id,
                                      data.inputLabel
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="row ">
                        <div className="col-8">
                          <TableSizeSelector
                            t={this.t}
                            numPages={this.state.elementsPage}
                            onChangePage={this.onChangePage.bind(this)}
                            elementsPage={this.state.elementsPage}
                            totalElements={data["monitorizationsList"].total}
                          />
                        </div>
                        <div className="col-4 text-right">
                          <Paginator
                            data={data}
                            t={this.t}
                            handlerPageChange={this.handlerPageChange}
                            refetchFunction={refetch}
                            tableType="monitorizationsList"
                            page={this.state.page}
                          />
                        </div>
                      </div>

                    </div>
                  );
                }
              }}
            </Query>
          </div>
          
        </div>
        <ModalTimeline
          isOpen={this.state.modalTimelineOpened}
          entityId={this.state.monitorizationId}
          toggleFunction={this.toggleTimelineModal}
          t={this.props.t}
          monitorization={this.state.fixesElement}
          field={this.state.monitorizationField}
        />
      </div>
    );
  }
}
export default withTranslation()(NotificationList);
