export const getItemInForm = (label, form) => {
  let item = null;
  let result;

  if (Array.isArray(form)) {
    form.forEach((element) => {
      result = getItemInForm(label, element);
      if (result) item = result;
    });
  } else if (typeof form == "object") {
    if (typeof form["components"] !== "undefined") {
      result = getItemInForm(label, form["components"]);
      if (result) item = result;
    } else if (typeof form["columns"] !== "undefined") {
      result = getItemInForm(label, form["columns"]);
      if (result) item = result;
    } else if (typeof form["rows"] !== "undefined") {
      result = getItemInForm(label, form["rows"]);
      if (result) item = result;
    } else {
      if (typeof form["label"] !== "undefined" && form["label"] == label) {
        item = form;
      }
    }
  }

  return item;
};

export const getValueInSubmission = (item, submission) => {
  let value = null;

  const keys = Object.keys(submission);
  keys.forEach((key) => {
    if (typeof submission[key] == "object") {
      if (key != item.key) {
        const subValue = getValueInSubmission(item, submission[key]);
        if (subValue) value = subValue;
      } else {
        if (item.type == "selectboxes") {
          const selectkeys = Object.keys(submission[key]);
          value = [];
          selectkeys.forEach((selectkey) => {
            if (submission[key][selectkey]) value.push(selectkey);
          });
        }
      }
    } else {
      if (key == item.key) value = submission[key];
    }
  });

  return value;
};

export const setPatientToReferenceType = (patient, form) => {
  if (Array.isArray(form)) {
    form.forEach((subform, index) => {
      setPatientToReferenceType(patient, subform);
    });
  } else if (typeof form === "object" && typeof form.type !== "undefined") {
    if (
      form.type == "container" ||
      form.type == "panel" ||
      form.type == "container_custom" ||
      form.type == "panel_custom"
    ) {
      setPatientToReferenceType(patient, form.components);
    } else if (form.type == "columns_custom" || form.type == "columns") {
      setPatientToReferenceType(patient, form.columns);
    } else if (form.type == "table_custom" || form.type == "table") {
      setPatientToReferenceType(patient, form.rows);
    } else if (form.type == "reference") {
      form.patient = patient;
    }
  } else {
    if (typeof form.components !== "undefined") {
      setPatientToReferenceType(patient, form.components);
    }
  }
};

export const getWeightInValues = (values, element) => {
  let weight = 0;
  values.forEach((value, index) => {
    if (value.value == element) {
      weight = typeof value.weight != "undefined" ? value.weight : 0;
    }
  });

  return weight;
};

function collectionHas(a, b) {
  //helper function (see below)
  for (var i = 0, len = a.length; i < len; i++) {
    if (a[i] == b) return true;
  }
  return false;
}

export const findParentBySelector = (elm, selector) => {
  var all = document.querySelectorAll(selector);
  if (elm) {
    var cur = elm.parentNode;
    while (cur && !collectionHas(all, cur)) {
      //keep going up until you find a match
      cur = cur.parentNode; //go up
    }
    return cur; //will return null if not found
  }
};

export const roundTwoDecimals = (number) => {
  return Math.round((number + Number.EPSILON) * 100) / 100;
};
