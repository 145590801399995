import React from "react";
import { Link } from "react-router-dom";
import { Query } from "react-apollo";
import { withTranslation } from "react-i18next";
import { headers } from "../../data/tablesHeaders/headers";
import Breadcrumb from "../../components/Breadcrumb";
import Paginator from "../../components/Paginator/Paginator";
import TableSizeSelector from "../../components/Paginator/TableSizeSelector";

import {
  mediaFilesAppend,
  DEFAULT_ELEM_PAGE,
  ROLE_ADMIN,
  ROLE_DATAMANAGER,
  ROLE_INVESTIGATOR,
  ROLE_LEAD_MONITOR,
  ROLE_MONITOR,
  ROLE_READ_ONLY,
  ROLE_SYSADMIN,
  ROLE_TYPES
} from "../../config";
import { client } from "../../apolloClient";
import {
  Spinner,
  Card,
  Elevation,
  Button,
  Icon,
  InputGroup,
  Intent,
  Tag
} from "@blueprintjs/core";
import ConfirmDeleteModal from "../ModalDelete/ConfirmDeleteModal";
import CentreForm from "../../pages/Centre/Create/index";
import UserForm from "../../pages/User/Create/index";
import "./_List.scss";

class CustomTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpened: false,
      toDeleteName: "",
      toDeleteIndex: "",
      toDeleteId: "",
      deleteFunc: "",
      loading: false,
      elementsPage: DEFAULT_ELEM_PAGE,
      page: 1,
      search: { text: "" },
      orderField: "id",
      modalControl: {
        centre: false,
        user: false,
        modify: false
      }
    };
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.createOpen = this.createOpen.bind(this);
    this.onCreateSubmit = this.onCreateSubmit.bind(this);
  }

  componentDidMount() {
    if (this.refetch) {
      this.refetch();
    }
  }

  toggleDeleteModal = (
    toDeleteIndex = "",
    toDeleteId = "",
    toDeleteName = ""
  ) => {
    if (this.state.modalOpened) {
      this.setState({
        modalOpened: !this.state.modalOpened
      });
    } else {
      this.setState(
        {
          toDeleteIndex,
          toDeleteId,
          toDeleteName
        },
        () => this.setState({ modalOpened: !this.state.modalOpened })
      );
    }
  };

  deleteItem = (index, id) => {
    const mutation = headers[this.props.tableType].deleteMutation;
    client
      .mutate({
        variables: { id },
        mutation
      })
      .then((res, err) => {
        this.toggleDeleteModal();
        this.refetch();
      });
  };

  handleChange = async event => {
    const { name, value } = event.target;
    await this.setState(prevState => ({
      search: {
        ...prevState.search,
        [name]: value
      }
    }));
    if (value.length > 3) {
      this.handleSubmit();
    }
  };

  handleSubmit = () => {
    const search = this.state.search;
    this.refetch({
      ...search,
      page: 1
    });
  };

  handlerPageChange = page => {
    this.setState(prevState => ({
      ...prevState,
      page: page
    }));
  };

  createOpen(create = true, entity = {}) {
    const { createLink } = this.props;
    const { modalControl } = this.state;

    if (createLink === "/centre") {
      modalControl.centre = create;
    }

    if (createLink === "/user") {
      modalControl.user = create;
    }

    if (Object.keys(entity).length > 0) {
      modalControl.modify = entity;
    } else {
      modalControl.modify = {};
    }

    this.setState({ modalControl });
  }

  onCreateSubmit() {
    this.refetch();
    this.createOpen(false);
  }

  orderTable = field => {
    if (this.state.orderField === field) {
      this.setState(prevState => ({
        ...prevState,
        orderField: `-${field}`
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        orderField: field
      }));
    }
    this.refetch();
  };

  onChangePage(num) {
    this.setState(prevState => ({
      ...prevState, elementsPage: num }));
    this.refetch();
  }

  render() {
    this.t = this.props.t;
    const graphQuery = this.props.query;
    const tableHeaders = headers[this.props.tableType].headers;
    const dataKeys = headers[this.props.tableType].data;

    const capitalize = s => {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    };

    return (
      <div className="container-fluid">
        <Breadcrumb
          crumbs={[
            { link: "/", name: "Home", active: false },
            {
              link: "",
              name: capitalize(this.t(this.props.title)),
              active: true
            }
          ]}
        />
        <div className="row">
          <div className="col">
            <h3>{capitalize(this.t(this.props.title))}</h3>
          </div>
          <div className="col">
            <Button
              className="addButton"
              type="button"
              onClick={this.createOpen.bind(this)}
            >
              {capitalize(this.t("new"))}
            </Button>
          </div>
        </div>

        <div interactive={false} elevation={Elevation.TWO}>
          <form
            onSubmit={e => {
              e.preventDefault();
              this.handleSubmit();
            }}
            ref={elem => (this.form = elem)}
          >
            <div className="row pt-2 pb-3">
              <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 offset-md-6 offset-lg-8 offset-xl-9 text-right">
                <InputGroup
                  id="text"
                  name="text"
                  onChange={this.handleChange}
                  value={this.state.text}
                  placeholder={this.props.t("search")}
                  leftIcon="search"
                />
              </div>
            </div>
          </form>

          <Query
            query={graphQuery}
            variables={{
              elementsPerPage: this.state.elementsPage,
              page: this.state.page,
              orderField: this.state.orderField,
              text: this.state.search.text
            }}
          >
            {({ loading, error, data, refetch, updateQuery }) => {
              if (loading)
                return (
                  <div className="spinner-loader-listing">
                    <Spinner
                      size={80}
                      spinnerColor={"#007BFF"}
                      spinnerWidth={2}
                      visible={true}
                    />
                  </div>
                );

              if (typeof data[this.props.tableType] == "undefined") {
                data[this.props.tableType] = { objects: [] };
              }
              if (error) return `Error ${error.message}`;
              this.refetch = refetch;
              {
                return (
                  <div>
                    <table className="table table-custom table-hover table-bordered">
                      <thead className="bg-light">
                        <tr className="">
                          <th scope="col">#</th>
                          {tableHeaders.map((header, index) => (
                            <th
                              key={index}
                              scope="col"
                              className="clickable"
                              onClick={e => this.orderTable(dataKeys[index])}
                              style={{
                                width: header === "table.comment" ? "39%" : ""
                              }}
                            >
                              {this.t(header)}
                              {this.state.orderField == dataKeys[index] && (
                                <Icon
                                  className="ml-2"
                                  icon="caret-down"
                                  size="16"
                                ></Icon>
                              )}
                              {this.state.orderField ==
                                `-${dataKeys[index]}` && (
                                <Icon
                                  className="ml-2"
                                  icon="caret-up"
                                  size="16"
                                ></Icon>
                              )}
                            </th>
                          ))}
                          <th scope="col" className="text-right">
                            {this.t("table.actions")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data[this.props.tableType]["objects"].map(
                          (data, index) => (
                            <tr key={index} className="r">
                              <td>{index + ((this.state.page - 1) * this.state.elementsPage) + 1}</td>
                              {dataKeys.map((key, index) => {
                                let info = "";
                                try {
                                  info = key
                                    .split(".")
                                    .reduce(function(c, prop) {
                                      return c[prop];
                                    }, data);
                                } catch (err) {}
                                if (
                                  typeof info === "string" &&
                                  info.endsWith("png")
                                )
                                  return (
                                    <td key={index}>
                                      <img
                                        className="icon"
                                        src={mediaFilesAppend + info}
                                        alt=""
                                      />
                                    </td>
                                  );
                                else if (key === "created")
                                  return (
                                    <td key={index}>
                                      {new Date(info).toLocaleDateString()}
                                    </td>
                                  );
                                else if (key === "role")
                                  return (
                                    <td key={index}>
                                      <Tag
                                        round={true}
                                        intent={(() => {
                                          switch (info) {
                                            case ROLE_READ_ONLY:
                                              return Intent.NONE;
                                            case ROLE_MONITOR:
                                              return Intent.WARNING;
                                            case ROLE_DATAMANAGER:
                                              return Intent.DANGER;
                                            case ROLE_SYSADMIN:
                                              return Intent.SUCCESS;
                                            case ROLE_ADMIN:
                                              return Intent.PRIMARY;
                                            case ROLE_INVESTIGATOR:
                                              return Intent.PRIMARY;
                                            default:
                                              return Intent.PRIMARY;
                                          }
                                        })()}
                                      >
                                        {ROLE_TYPES[info]}
                                      </Tag>
                                    </td>
                                  );
                                else return <td key={index}>{info}</td>;
                              })}
                              <td className="text-right">
                                <Button
                                  className="bp3-button"
                                  icon="edit"
                                  minimal
                                  onClick={() =>
                                    this.createOpen(true, {
                                      entity: { fetch: true, id: data.id }
                                    })
                                  }
                                />
                                <Button
                                  className=" ml-2"
                                  icon="trash"
                                  minimal
                                  onClick={() =>
                                    this.toggleDeleteModal(
                                      index,
                                      data.id,
                                      data.name
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>

                    <div className="row ">
                      <div className="col-8">
                        <TableSizeSelector
                          t={this.t}
                          numPages={this.state.elementsPage}
                          onChangePage={this.onChangePage.bind(this)}
                          elementsPage={this.state.elementsPage}
                          totalElements={data[this.props.tableType].total}
                        />
                      </div>
                      <div className="col-4 text-right">
                        <Paginator
                          data={data}
                          t={this.t}
                          handlerPageChange={this.handlerPageChange}
                          refetchFunction={refetch}
                          tableType={this.props.tableType}
                          page={this.state.page}
                        />
                      </div>
                    </div>
                  </div>
                );
              }
            }}
          </Query>
        </div>
        <ConfirmDeleteModal
          isOpen={this.state.modalOpened}
          toggleFunction={this.toggleDeleteModal}
          toDeleteId={this.state.toDeleteId}
          refetchFunction={this.refetch}
          toDeleteName={this.state.toDeleteName}
          mutation={headers[this.props.tableType].deleteMutation}
          t={this.t}
        />
        <CentreForm
          isOpen={this.state.modalControl.centre}
          state={this.state.modalControl.modify}
          t={this.t}
          toggleFunction={this.createOpen.bind(this, false)}
          onCreateSubmit={this.onCreateSubmit.bind(this)}
        />
        <UserForm
          isOpen={this.state.modalControl.user}
          state={this.state.modalControl.modify}
          t={this.t}
          toggleFunction={this.createOpen.bind(this, false)}
          onCreateSubmit={this.onCreateSubmit.bind(this)}
        />
      </div>
    );
  }
}
export default withTranslation()(CustomTable);
