import React from "react";
import { Navigate  } from "react-router-dom";
import { Query } from "react-apollo";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { client } from "../../../apolloClient";
import Breadcrumb from "../../../components/Breadcrumb";
import Paginator from "../../../components/Paginator/Paginator";
import TableSizeSelector from "../../../components/Paginator/TableSizeSelector";
import {
  mediaFilesAppend,
  DEFAULT_ELEM_PAGE,
  PATIENT_STATUS,
  PATIENT_STATUS_CLOSED,
  PATIENT_STATUS_NEW,
  PATIENT_STATUS_INPROGRESS,
  PATIENT_STATUS_SIGNED,
  PATIENT_STATUS_MONITORING,
  ROLE_ADMIN,
  ROLE_DATAMANAGER,
  ROLE_INVESTIGATOR,
  ROLE_LEAD_MONITOR,
  ROLE_MONITOR,
  ROLE_READ_ONLY,
  ROLE_SYSADMIN,
} from "../../../config";
import {
  Spinner,
  Card,
  Elevation,
  Button,
  Menu,
  MenuItem,
  MenuDivider,
  InputGroup,
  Position,
  Popover,
  Icon,
  Intent,
  Tag,
} from "@blueprintjs/core";
import ConfirmDeleteModal from "../../../components/ModalDelete/ConfirmDeleteModal";
import ChangeStatusModal from "../../../components/ModalChangeStatus/ModalChangeStatus";
import { GET_PATIENTS, GET_CRF, GET_CENTRES, GET_PATIENTS_MONITORIZATIONS } from "../../../queries";
import {
  DELETE_PATIENT,
  CREATE_PATIENT,
  CHANGE_STATUS_PATIENT,
} from "../../../mutations";
import { Select } from "@blueprintjs/select";
import ButtonAuthorized from "../../../components/ButtonAuthorized/ButtonAuthorized";
import "./_List.scss";
import { Document, pdf } from '@react-pdf/renderer';
import PatientMonitorizations from '../../../components/MonitorizationPDF/monitorizations'
import { downloadPDF } from '../../../utils/DownloadFile'

class PatientTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpened: false,
      toDeleteName: "",
      toDeleteIndex: "",
      toDeleteId: "",
      deleteFunc: "",
      changeId: "",
      modalStatusOpened: false,
      status: "",
      loading: false,
      elementsPage: DEFAULT_ELEM_PAGE,
      page: 1,
      orderField: "created_on",
      search: {
        text: "",
        centre: { id: null, name: this.props.t("select.centre") },
        code: "",
        branch: "",
        state: { id: "", text: "All states" },
      },
      customHeader: "confirmDelete",
      customText: "table.delete",
      crf: { name: "" },
      centres: [],
      redirect: false,
    };
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.roles = [
      ROLE_ADMIN,
      ROLE_SYSADMIN,
      ROLE_DATAMANAGER,
      ROLE_INVESTIGATOR,
    ];
    this.states = [
      { id: "", text: "All States" },
      { id: PATIENT_STATUS_NEW, text: "New" },
      { id: PATIENT_STATUS_INPROGRESS, text: "In Progress" },
      { id: PATIENT_STATUS_MONITORING, text: "Monitoring" },
      { id: PATIENT_STATUS_CLOSED, text: "Closed" },
      { id: PATIENT_STATUS_SIGNED, text: "Signed" },
    ];
    this.data = [];
  }

  componentDidMount() {
    client
      .query({
        query: GET_CRF,
        variables: { id: window.location.href.split('/')[4] },
      })
      .then((res) => {
        this.setState((prevState) => ({
          ...prevState,
          crf: res.data.crf,
        }));
      });

    client
      .query({
        query: GET_CENTRES,
        variables: { elementsPerPage: 100, page: 1 },
      })
      .then((res) => {
        if(res.data.centres.objects[0]) {
          if (res.data.centres.objects[0].id !== null) 
            res.data.centres.objects.unshift({
              id: null,
              name: this.props.t("select.centre"),
            });
          this.setState((prevState) => ({
            ...prevState,
            centres: res.data.centres.objects,
          }));
        }
      });
    if (this.refetch) {
      this.refetch();
    }
  }

  toggleDeleteModal = (
    toDeleteIndex = "",
    toDeleteId = "",
    toDeleteName = "",
    mutation,
    customText,
    customHeader
  ) => {
    if (this.state.modalOpened) {
      this.setState({
        modalOpened: !this.state.modalOpened,
        customText: customText,
        customHeader: customHeader,
      });
    } else {
      this.setState(
        {
          toDeleteIndex,
          toDeleteId,
          toDeleteName,
          customText: customText,
          customHeader: customHeader,
        },
        () => this.setState({ modalOpened: !this.state.modalOpened })
      );
    }
  };

  handlerPageChange = (page) => {
    this.setState((prevState) => ({
      ...prevState,
      page: page,
    }));
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      search: {
        ...prevState.search,
        [name]: value,
      },
    }));
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const search = { ...this.state.search };
    if (this.state.search.centre) search.centre = this.state.search.centre.id;
    if (this.state.search.state) search.state = this.state.search.state.id;

    await this.setState((prevState) => ({
      ...prevState,
      page: 1,
    }));

    await this.refetch({
      ...search,
      page: 1,
    });
  };

  renderMenu = () => {
    return (
      <Menu>
        {this.state.centres.map((data, index) => (
          <MenuItem
            key={index}
            text={data.name}
            icon="document"
            onClick={(e) => {
              this.onClickMenuHandler(data.id);
            }}
          />
        ))}
        <MenuDivider />
        <MenuItem text="Exit" icon="cross" />
      </Menu>
    );
  };

  onClickMenuHandler = (centre) => {
    this.setState({ loading: true });
    const variables = {
      id: window.location.href.split('/')[4],
      centreId: centre,
    };

    client
      .mutate({
        variables,
        mutation: CREATE_PATIENT,
      })
      .then((res, err) => {
        if (err) console.log(err);
        if (res.data.createPatient.ok)
          this.setState({
            loading: false,
            redirect: `/patient/${res.data.createPatient.patient.id}`,
          });
        else console.log("Error creando paciente");
      });
  };

  // Centre search options

  renderItem = (item, { modifiers, handleClick }) => {
    return (
      <MenuItem
        active={modifiers.active}
        key={item.id}
        label={item.code}
        onClick={handleClick}
        text={item.name}
        shouldDismissPopover={false}
      />
    );
  };

  renderItemState = (item, { modifiers, handleClick }) => {
    return (
      <MenuItem
        active={modifiers.active}
        key={item.id}
        onClick={handleClick}
        text={item.text}
        shouldDismissPopover={false}
      />
    );
  };

  areCentreEquals = (a, b) => {
    let equal = a.id === b.id ? true : false;

    return equal;
  };

  areStateEquals = (a, b) => {
    let equal = a.id === b.id ? true : false;

    return equal;
  };

  handleSelectChange = (centre) => {
    this.setState((prevState) => ({
      search: {
        ...prevState.search,
        centre,
      },
    }));
  };

  handleSelectStateChange = (state) => {
    this.setState((prevState) => ({
      search: {
        ...prevState.search,
        state,
      },
    }));
  };

  handleFilterCentre = (query, centre) =>
    centre.name.indexOf(query) >= 0

  toggleStatusModal = (changeId = "", status, customText, customHeader) => {
    this.setState({
      changeId: changeId,
      status: status,
      modalStatusOpened: !this.state.modalStatusOpened,
      customText: customText,
      customHeader: customHeader,
    });
  };

  orderTable = (field) => {
    if (this.state.orderField === field) {
      this.setState((prevState) => ({
        ...prevState,
        orderField: `-${field}`,
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        orderField: field,
      }));
    }
  };

  onChangePage(num) {
    this.setState({ elementsPage: num });
    this.refetch();
  }

  downloadPdf = () => {
    client
      .query({
        query: GET_PATIENTS_MONITORIZATIONS,
        variables: {
          crfId: window.location.href.split('/')[4],
          elementsPerPage: this.state.elementsPage,
          centre: this.state.search.centre.id,
          state: this.state.search.state.id,
          branch: this.state.search.branch,
          text: this.state.search.text,
          code: this.state.search.code,
          page: this.state.page,
          orderField: this.state.orderField,
        },
      })
      .then((res) => {
        const MyDoc = (
          <Document>
            {res.data.monitorizationsPatients.map((patient, index) => {
              return (
                <PatientMonitorizations monitorizations={patient.monitorizations} t={this.t} code={patient.code} visit={this.state.crf.name}/>
              )
            })}
          </Document>
        );
        pdf(MyDoc).toBlob().then((blob) => {
          downloadPDF(blob, `${this.state.crf.name}-monitorizations`)
        });
      });
  }

  render() {
    this.t = this.props.t;
    const dataKeys = [
      "code",
      "status",
      "branch",
      "centre",
      "created_on",
      "pendingMonitorizations",
    ];
    const headers = [
      this.t("patient"),
      this.t("status"),
      this.t("branch"),
      this.t("centre"),
      this.t("date"),
      this.t("pendingMonitorizations"),
    ];

    const capitalize = (s) => {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    };

    if (!this.state.loading) {
      if (this.state.redirect) {
        return <Navigate to={this.state.redirect} />;
      }
    }

    return (
      <div className="container-fluid">
        <Breadcrumb
          crumbs={[
            { link: "/", name: "Home", active: false },
            {
              link: "/crfs",
              name: capitalize(this.t("crfs.title")),
              active: false,
            },
            {
              link: `/crf/${window.location.href.split('/')[4]}`,
              name: this.state.crf.name,
              active: true,
            },
          ]}
        />

        <div className="row">
          <div className="col">
            <h3>{capitalize(this.t(this.props.title))}</h3>
          </div>
          {!this.state.crf.deleted && this.roles.includes(this.props.role) && (
            <div className="col">
              <Popover
                className="float-right"
                content={this.renderMenu()}
                position={Position.BOTTOM_LEFT}
              >
                <Button
                  className="bp3-button new-button"
                  rightIcon="caret-down"
                  text={capitalize(this.t("new"))}
                />
              </Popover>
            </div>
          )}
        </div>

        <Card interactive={false} elevation={Elevation.TWO} className="mb-3">
          <form onSubmit={this.handleSubmit}>
            <div className="row flex-center">
              <div className="col">
                <Select
                  itemsEqual={this.areCentreEquals}
                  itemRenderer={this.renderItem}
                  items={this.state.centres}
                  large={true}
                  itemPredicate={this.handleFilterCentre}
                  className="w-100"
                  noResults={
                    <MenuItem
                      icon="office"
                      disabled={true}
                      text={this.props.t("No results.")}
                    />
                  }
                  onItemSelect={this.handleSelectChange}
                >
                  <Button
                    icon="office"
                    rightIcon="caret-down"
                    large={true}
                    fill={true}
                    text={
                      this.state.search.centre
                        ? this.state.search.centre.name
                        : this.props.t("All my centres.")
                    }
                  />
                </Select>
              </div>
              <div className="col">
                <InputGroup
                  id="code"
                  name="code"
                  onChange={this.handleChange}
                  value={this.state.search.code}
                  placeholder={this.props.t("code")}
                  large={true}
                />
              </div>
              <div className="col">
                <InputGroup
                  id="branch"
                  name="branch"
                  onChange={this.handleChange}
                  value={this.state.search.branch}
                  placeholder={this.props.t("branch")}
                  large={true}
                />
              </div>
              <div className="col">
                <InputGroup
                  id="text"
                  name="text"
                  onChange={this.handleChange}
                  value={this.state.search.text}
                  placeholder={this.props.t("search")}
                  large={true}
                />
              </div>
              <div className="col">
                <Select
                  itemsEqual={this.areStateEquals}
                  itemRenderer={this.renderItemState}
                  items={this.states}
                  large={true}
                  className="w-100"
                  onItemSelect={(item) => this.handleSelectStateChange(item)}
                >
                  <Button
                    rightIcon="caret-down"
                    large={true}
                    fill={true}
                    text={
                      this.state.search.state
                        ? this.state.search.state.text
                        : this.props.t("All states")
                    }
                  />
                </Select>
              </div>
              <div className="col-md-1 text-right" style={{display:'flex'}}>
                <Button
                  type="submit"
                  //intent="success"
                  className="patient-search-button"
                  large={true}
                  icon="search"
                />
                <Button
                  type="button"
                  intent="success"
                  large={true}
                  icon="download"
                  onClick={this.downloadPdf}
                  className="ml-1"
                  title={this.props.t("download.monitorizations")}
                />
              </div>
            </div>
          </form>
        </Card>

        <div>
          <Query
            query={GET_PATIENTS}
            variables={{
              crfId: window.location.href.split('/')[4],
              elementsPerPage: this.state.elementsPage,
              centre: this.state.search.centre.id,
              state: this.state.search.state.id,
              branch: this.state.search.branch,
              text: this.state.search.text,
              code: this.state.search.code,
              page: this.state.page,
              orderField: this.state.orderField,
            }}
          >
            {({ loading, error, data, refetch }) => {
              if (loading)
                return (
                  <div className="spinner-loader-listing">
                    <Spinner
                      size={80}
                      spinnerColor={"#007BFF"}
                      spinnerWidth={2}
                      visible={true}
                    />
                  </div>
                );
              if (error) return `Error ${error.message}`;
              this.refetch = refetch;
              this.data = data;
              {
                return (
                  <div>
                    <table className="table table-custom table-hover">
                      <thead className="bg-light">
                        <tr>
                          <th scope="col">#</th>
                          {headers.map((header, index) => (
                            <th
                              key={index}
                              scope="col"
                              className="clickable"
                              onClick={(e) => this.orderTable(dataKeys[index])}
                              style={{
                                width: header === "table.comment" ? "39%" : "",
                              }}
                            >
                              {this.t(header)}
                              {this.state.orderField == dataKeys[index] && (
                                <Icon
                                  className="ml-2"
                                  icon="caret-down"
                                  size="16"
                                ></Icon>
                              )}
                              {this.state.orderField ==
                                `-${dataKeys[index]}` && (
                                <Icon
                                  className="ml-2"
                                  icon="caret-up"
                                  size="16"
                                ></Icon>
                              )}
                            </th>
                          ))}
                          <th scope="col" className="text-right">
                            {this.t("table.actions")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data[this.props.tableType]["objects"].map(
                          (data, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              {dataKeys.map((key, index) => {
                                let info = "";
                                try {
                                  info = key
                                    .split(".")
                                    .reduce(function (c, prop) {
                                      return c[prop];
                                    }, data);
                                } catch (err) {}
                                if (
                                  typeof info === "string" &&
                                  info.endsWith("png")
                                )
                                  return (
                                    <td key={index}>
                                      <img
                                        className="icon"
                                        src={mediaFilesAppend + info}
                                        alt=""
                                      />
                                    </td>
                                  );
                                else if (key === "created_on")
                                  return (
                                    <td key={index}>
                                      {`${new Date(
                                        data['createdOn']
                                      ).toLocaleDateString()} ${new Date(
                                        data['createdOn']
                                      ).toLocaleTimeString()}`}
                                    </td>
                                  );
                                else if (key === "status")
                                  return (
                                    <td key={index}>
                                      <Tag
                                        round={true}
                                        intent={(() => {
                                          switch (info) {
                                            case PATIENT_STATUS_NEW:
                                              return Intent.NONE;
                                            case PATIENT_STATUS_INPROGRESS:
                                              return Intent.WARNING;
                                            case PATIENT_STATUS_MONITORING:
                                              return Intent.DANGER;
                                            case PATIENT_STATUS_CLOSED:
                                              return Intent.SUCCESS;
                                            default:
                                              return Intent.PRIMARY;
                                          }
                                        })()}
                                      >
                                        {PATIENT_STATUS[info]}
                                      </Tag>
                                    </td>
                                  );
                                else if (key === "centre")
                                  return <td key={index}>{info.name}</td>;
                                else return <td key={index}>{info}</td>;
                              })}
                              <td className="text-right">
                                <ButtonAuthorized
                                  //intent={Intent.SUCCESS}
                                  href={`/patient/${data.id}`}
                                  minimal
                                  icon="edit"
                                  roles={[
                                    ROLE_SYSADMIN,
                                    ROLE_ADMIN,
                                    ROLE_MONITOR,
                                    ROLE_READ_ONLY,
                                    ROLE_DATAMANAGER,
                                    ROLE_INVESTIGATOR,
                                    ROLE_LEAD_MONITOR,
                                  ]}
                                  anchor={true}
                                />
                                {data.status === PATIENT_STATUS_SIGNED ? (
                                  <ButtonAuthorized
                                    //intent={Intent.PRIMARY}
                                    minimal
                                    className="ml-2"
                                    icon="reset"
                                    roles={[ROLE_SYSADMIN, ROLE_ADMIN]}
                                    title={this.t("Unsign")}
                                    onClick={() =>
                                      this.toggleStatusModal(
                                        data.id,
                                        PATIENT_STATUS_CLOSED,
                                        `${this.t("changeStatus")} ${
                                          data.code
                                        }?`,
                                        this.t("changeStatusHeader")
                                      )
                                    }
                                  />
                                ) : (
                                  ""
                                )}

                                {data.status === PATIENT_STATUS_CLOSED && (
                                  <ButtonAuthorized
                                    //intent={Intent.PRIMARY}
                                    minimal
                                    className=" ml-2"
                                    icon={"tick-circle"}
                                    title={this.t("sign")}
                                    roles={[
                                      ROLE_SYSADMIN,
                                      ROLE_ADMIN,
                                      ROLE_INVESTIGATOR,
                                    ]}
                                    onClick={() =>
                                      this.toggleStatusModal(
                                        data.id,
                                        PATIENT_STATUS_SIGNED,
                                        `${this.t("changeStatus")} ${
                                          data.code
                                        }?`,
                                        this.t("changeStatusHeader")
                                      )
                                    }
                                  />
                                )}
                                {/* {data.status === PATIENT_STATUS_CLOSED && (
                                  <ButtonAuthorized
                                    //intent={Intent.PRIMARY}
                                    minimal
                                    className=" ml-2"
                                    icon={"unlock"}
                                    title={this.t("reopenMonitorization")}
                                    roles={[
                                      ROLE_SYSADMIN,
                                      ROLE_ADMIN,
                                      ROLE_INVESTIGATOR,
                                    ]}
                                    onClick={() =>
                                      this.toggleStatusModal(
                                        data.id,
                                        PATIENT_STATUS_MONITORING,
                                        `${this.t("changeStatus")} ${
                                          data.code
                                        }?`,
                                        this.t("changeStatusHeader")
                                      )
                                    }
                                  />
                                )} */}
                                {data.status === PATIENT_STATUS_MONITORING && (
                                  <ButtonAuthorized
                                    //intent={Intent.PRIMARY}
                                    minimal
                                    className="ml-2"
                                    icon={"confirm"}
                                    title={this.t("close")}
                                    roles={[
                                      ROLE_SYSADMIN,
                                      ROLE_ADMIN,
                                      ROLE_MONITOR,
                                      ROLE_LEAD_MONITOR,
                                    ]}
                                    onClick={() =>
                                      this.toggleStatusModal(
                                        data.id,
                                        PATIENT_STATUS_CLOSED,
                                        `${this.t("changeStatus")} ${
                                          data.code
                                        }?`,
                                        this.t("changeStatusHeader")
                                      )
                                    }
                                  />
                                )}
                                {/* {data.status === PATIENT_STATUS_INPROGRESS && (
                                  <ButtonAuthorized
                                    //intent={Intent.PRIMARY}
                                    minimal
                                    className="ml-2"
                                    icon={"eye-on"}
                                    title={this.t("monitoring")}
                                    roles={[
                                      ROLE_SYSADMIN,
                                      ROLE_ADMIN,
                                      ROLE_DATAMANAGER,
                                      ROLE_INVESTIGATOR,
                                    ]}
                                    onClick={() =>
                                      this.toggleStatusModal(
                                        data.id,
                                        PATIENT_STATUS_MONITORING,
                                        `${this.t("changeStatus")} ${
                                          data.code
                                        }?`,
                                        this.t("changeStatusHeader")
                                      )
                                    }
                                  />
                                )} */}
                                <ButtonAuthorized
                                  className=" ml-2"
                                  //intent={Intent.DANGER}
                                  minimal
                                  icon="trash"
                                  roles={[ROLE_SYSADMIN, ROLE_ADMIN]}
                                  title={this.t("table.delete")}
                                  onClick={() =>
                                    this.toggleDeleteModal(
                                      index,
                                      data.id,
                                      data.name,
                                      DELETE_PATIENT,
                                      "table.delete",
                                      "confirmDelete"
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                    <div className="row ">
                      <div className="col-8">
                        <TableSizeSelector
                          t={this.t}
                          numPages={this.state.elementsPage}
                          onChangePage={this.onChangePage.bind(this)}
                          elementsPage={this.state.elementsPage}
                          totalElements={data[this.props.tableType].total}
                        />
                      </div>
                      <div className="col-4 text-right">
                        <Paginator
                          data={data}
                          t={this.t}
                          handlerPageChange={this.handlerPageChange}
                          refetchFunction={refetch}
                          tableType={this.props.tableType}
                          page={this.state.page}
                        />
                      </div>
                    </div>
                  </div>
                );
              }
            }}
          </Query>
        </div>
        <ConfirmDeleteModal
          isOpen={this.state.modalOpened}
          toggleFunction={this.toggleDeleteModal}
          toDeleteId={this.state.toDeleteId}
          refetchFunction={this.refetch}
          toDeleteName={this.state.toDeleteName}
          mutation={DELETE_PATIENT}
          customText={this.state.customText}
          customHeader={this.state.customHeader}
          t={this.t}
        />
        <ChangeStatusModal
          isOpen={this.state.modalStatusOpened}
          entityId={this.state.changeId}
          status={this.state.status}
          toggleFunction={this.toggleStatusModal}
          refetchFunction={this.refetch}
          mutation={CHANGE_STATUS_PATIENT}
          customText={this.state.customText}
          customHeader={this.state.customHeader}
          t={this.t}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  username: state.user.username,
  name: state.user.name,
  lastname: state.user.lastname,
  role: state.user.role,
  avatar: state.user.avatar,
});

const bindActions = (dispatch) => ({});
export default withTranslation()(
  connect(mapStateToProps, bindActions)(PatientTable)
);
