import gql from "graphql-tag";

export const GET_USERS = gql`
  query users(
    $text: String
    $elementsPerPage: Int
    $page: Int
    $orderField: String
  ) {
    users(
      text: $text
      elementsPerPage: $elementsPerPage
      page: $page
      orderField: $orderField
    ) {
      page
      pages
      hasNext
      hasPrev
      total
      objects {
        id
        username
        name
        role
      }
    }
  }
`;

export const GET_USER = gql`
  query user($id: String!) {
    user(id: $id) {
      id
      username
      firstName
      lastName
      role
      email
      avatar
      recieveNotifications
      recieveNotificationsPatients
      active
      centres {
        edges {
          node {
            id
            name
            address
            code
          }
        }
      }
      crfs {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_CENTRES = gql`
  query centres(
    $text: String
    $elementsPerPage: Int
    $page: Int
    $orderField: String
  ) {
    centres(
      text: $text
      elementsPerPage: $elementsPerPage
      page: $page
      orderField: $orderField
    ) {
      page
      pages
      hasNext
      hasPrev
      total
      objects {
        id
        name
        address
        locality
        code
        phone
        inaugurated
      }
    }
  }
`;

export const GET_CENTRE = gql`
  query centre($id: String!) {
    centre(id: $id) {
      id
      name
      address
      code
      locality
      province
      postalCode
      phone
      inaugurated
    }
  }
`;

export const GET_CRFS = gql`
  query crfs(
    $text: String
    $elementsPerPage: Int
    $page: Int
    $orderField: String
  ) {
    crfs(
      text: $text
      elementsPerPage: $elementsPerPage
      page: $page
      orderField: $orderField
    ) {
      page
      pages
      hasNext
      hasPrev
      total
      objects {
        id
        name
        deleted
      }
    }
  }
`;

export const GET_CRF = gql`
  query crf($id: String!) {
    crf(id: $id) {
      id
      name
      description
      suffix
      preffix
      logoImage
      fingerprint
      patientCoding
      monitorization
      deleted
    }
  }
`;

export const GET_CRF_FULL = gql`
  query crf($id: String!) {
    crf(id: $id) {
      id
      name
      visits {
        edges {
          node {
            id
            name
            sheets {
              edges {
                node {
                  id
                  name
                  formBuild
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_VISITS = gql`
  query visits(
    $crfId: String!
    $text: String
    $elementsPerPage: Int
    $page: Int
    $orderField: String
  ) {
    visits(
      crfId: $crfId
      text: $text
      elementsPerPage: $elementsPerPage
      page: $page
      orderField: $orderField
    ) {
      page
      pages
      hasNext
      hasPrev
      total
      objects {
        id
        name
      }
    }
  }
`;

export const GET_VISIT = gql`
  query visit($id: String!) {
    visit(id: $id) {
      id
      name
      observations
      branch
      finishingVisit
      codeBranch
      crf {
        id
        name
      }
      centres {
        edges {
          node {
            id
            name
            address
            code
          }
        }
      }
      sheets {
        edges {
          node {
            id
            name
            formBuild
          }
        }
      }
    }
  }
`;

export const GET_TEMPLATES = gql`
  query templates(
    $crfId: String!
    $text: String
    $elementsPerPage: Int
    $page: Int
    $orderField: String
  ) {
    templates(
      crfId: $crfId
      text: $text
      elementsPerPage: $elementsPerPage
      page: $page
      orderField: $orderField
    ) {
      page
      pages
      hasNext
      hasPrev
      total
      objects {
        id
        name
        templateType
      }
    }
  }
`;

export const GET_TEMPLATE = gql`
  query template($id: String!) {
    template(id: $id) {
      id
      name
      templateType
      formBuild
      crf {
        id
        name
      }
      templateSheets {
        edges {
          node {
            id
            name
            formBuild
          }
        }
      }
    }
  }
`;

export const GET_PATIENTS = gql`
  query patients(
    $crfId: String!
    $text: String
    $centre: String
    $branch: String
    $state: String
    $code: String
    $elementsPerPage: Int
    $page: Int
    $orderField: String
  ) {
    patients(
      crfId: $crfId
      text: $text
      centre: $centre
      branch: $branch
      state: $state
      code: $code
      elementsPerPage: $elementsPerPage
      page: $page
      orderField: $orderField
    ) {
      page
      pages
      hasNext
      hasPrev
      total
      objects {
        id
        code
        status
        created
        createdOn
        branch
        pendingMonitorizations
        centre {
          id
          name
        }
      }
    }
  }
`;

export const GET_PATIENTS_ON_BRANCH = gql`
  query patientsOnBranch($id: String!, $branch: String, $centre: String) {
    patientsOnBranch(id: $id, branch: $branch, centre: $centre) {
      count
    }
  }
`;

export const COUNT_PATIENT_WITH_VALUE = gql`
  query valuesOnCrf(
    $id: String!
    $visitId: String!
    $key: String
    $value: String
    $branch: String
    $centre: String
  ) {
    valuesOnCrf(
      id: $id
      visitId: $visitId
      key: $key
      value: $value
      branch: $branch
      centre: $centre
    ) {
      count
    }
  }
`;

export const GET_PATIENT = gql`
  query patient($id: String!) {
    patient(id: $id) {
      id
      code
      branch
      crf {
        id
        name
      }
    }
  }
`;

export const GET_FILLED_VISITS = gql`
  query filledVisits(
    $patientId: String!
    $text: String
    $elementsPerPage: Int
    $page: Int
  ) {
    filledVisits(
      patientId: $patientId
      text: $text
      elementsPerPage: $elementsPerPage
      page: $page
    ) {
      page
      pages
      hasNext
      hasPrev
      total
      objects {
        id
        status
        pendingMonitorizations
        visit {
          name
        }
        lastFilledVisit {
          id
        }
      }
    }
  }
`;

export const GET_FILLED_VISITS_VERSIONS = gql`
  query filledVisitsVersions($id: String!) {
    filledVisits(id: $id) {
      id
      version
      last
      created
      user {
        username
      }
      filledVisit {
        id
        status
        visit {
          name
          codeBranch
        }
      }
    }
  }
`;

export const GET_FILLED_VISIT = gql`
  query filledVisit($id: String!) {
    filledVisit(id: $id) {
      id
      version
      versionDate
      last
      user {
        username
      }
      filledVisit {
        id
        status
        visit {
          id
          name
          codeBranch
        }
        patient {
          id
          code
          branch
          crf {
            id
            name
          }
          centre {
            id
            name
            code
          }
        }
        versions {
          edges {
            node {
              id
              created
              version
              versionDate
              last
              user {
                username
              }
            }
          }
        }
      }
      filledSheets {
        id
        uncriptedFilledForm
        monitorizations {
          edges {
            node {
              id
              status
              inputRef
              inputType
            }
          }
        }
        sheet {
          name
          formBuild
        }
      }
    }
  }
`;

export const GET_FIELD_VALUE = gql`
  query fieldValue($id: Int!, $field: String!, $patient: String!) {
    fieldValue(id: $id, field: $field, patient: $patient) {
      field
      value
    }
  }
`;

export const GET_FIELD_VALUE_PATIENT = gql`
  query fieldValuePatient($field: String!, $patient: String!, $visit: String) {
    fieldValuePatient(field: $field, patient: $patient, visit: $visit) {
      field
      value
    }
  }
`;

export const GET_PATIENTS_MONITORIZATIONS = gql`
  query monitorizationPatients(
    $crfId: String!
    $text: String
    $centre: String
    $branch: String
    $state: String
    $code: String
    $elementsPerPage: Int
    $page: Int
    $orderField: String
  ) {
    monitorizationsPatients(
      crfId: $crfId
      text: $text
      centre: $centre
      branch: $branch
      state: $state
      code: $code
      elementsPerPage: $elementsPerPage
      page: $page
      orderField: $orderField
    ) {

      id
      code
      status
      created
      branch
      monitorizations {
        id
        status
        inputRef
        inputLabel
        inputType
        filledSheet {
          sheet {
            name
          }
          filledVisit {
            filledVisit {
              visit {
                name
              }
            }
          }
        }
        fixes {
          edges {
            node {
              id
              created
              message
              fixType
              inputValue
              user {
                username
              }
            }
          }
        }
      }

    }
  }
`;

export const GET_MONITORIZATIONS = gql`
  query monitorizations($id: String!) {
    monitorizations(id: $id) {
      id
      status
      inputRef
      inputLabel
      inputType
      filledSheet {
        sheet {
          name
        }
        filledVisit {
          filledVisit {
            visit {
              name
            }
          }
        }
      }
      fixes {
        edges {
          node {
            id
            created
            message
            fixType
            inputValue
            user {
              username
            }
          }
        }
      }
      filledSheet {
        id
      }
    }
  }
`;

export const GET_MONITORIZATIONS_LIST = gql`
  query monitorizationsList(
    $crfId: String
    $text: String
    $state: String
    $code: String
    $elementsPerPage: Int
    $page: Int
    $orderField: String
  ) {
    monitorizationsList(
      crfId: $crfId
      text: $text
      state: $state
      code: $code
      elementsPerPage: $elementsPerPage
      page: $page
      orderField: $orderField
    ) {
      page
      pages
      hasNext
      hasPrev
      total
      objects {
        id
        inputRef
        inputLabel
        status
        created
        filledSheet {
          id
          sheet {
            id
            name
          }
          filledVisit {
            id
            last
            filledVisit {
              id
              visit {
                id
                name
              }
              patient {
                id
                code
                crf {
                  id
                  name
                }
              }
            }
          }
        }
        fixes {
          edges {
            node {
              id
              created
              message
              fixType
              inputValue
              user {
                id
                username
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_INPUT_MONITORIZATION = gql`
  query inputMonitorization($id: String!) {
    inputMonitorization(id: $id) {
      id
      status
      inputRef
      inputType
      fixes {
        edges {
          node {
            id
            created
            message
            fixType
            inputValue
            user {
              id
              username
            }
          }
        }
      }
      filledSheet {
        id
      }
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query notifications {
    notifications {
      incidences {
        id
        created
        inputFix {
          fixType
          monitorization {
            id
            inputRef
            inputLabel
            filledSheet {
              filledVisit {
                id
                last
                filledVisit {
                  id
                  visit {
                    name
                  }
                  patient {
                    code
                    crf {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
      comments {
        id
        created
        inputFix {
          fixType
          monitorization {
            id
            inputRef
            inputLabel
            filledSheet {
              filledVisit {
                id
                last
                filledVisit {
                  id
                  visit {
                    name
                  }
                  patient {
                    code
                    crf {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }

      checks {
        id
        created
        inputFix {
          fixType
          monitorization {
            id
            inputRef
            inputLabel
            filledSheet {
              filledVisit {
                id
                last
                filledVisit {
                  id
                  visit {
                    id
                    name
                  }
                  patient {
                    id
                    code
                    crf {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_VISIT_FIELDS = gql`
  query visitFields($id: String!) {
    visitFields(id: $id) {
      fields {
        label
        value
      }
    }
  }
`;

export const GET_FIELD_PATIENT_HISTORY = gql`
  query fieldPatientHistory($id: String!, $field: String!) {
    fieldPatientHistory(id: $id, field: $field) {
      history {
        field
        value
        date
        user
      }
    }
  }
`;

export const GET_FILE = gql`
  query getFile($id: String!, $key: String!) {
    getFile(id: $id, key: $key) {
      patient {
        id
        code
        crf {
          id
          name
        }
      }
      label
      value
    }
  }
`;
