import React from "react";
import { Menu, MenuItem } from "@blueprintjs/core";

const LanguageMenu = () => {
  const changeLan = lan => {
    localStorage.setItem("i18nextLng", lan);
    window.location.reload();
  };

  return (
    <Menu>
      <MenuItem
        icon="globe"
        text="Spanish"
        onClick={() => changeLan("es-ES")}
      />
      <MenuItem
        icon="globe"
        text="English"
        onClick={() => changeLan("en-EN")}
      />
      <MenuItem icon="globe" text="German" onClick={() => changeLan("de-DE")} />
      <MenuItem icon="globe" text="French" onClick={() => changeLan("fr-FR")} />
      <MenuItem
        icon="globe"
        text="Portuguese"
        onClick={() => changeLan("pt-PT")}
      />
      <MenuItem
        icon="globe"
        text="Italian"
        onClick={() => changeLan("it-IT")}
      />
    </Menu>
  );
};

export default LanguageMenu;
