import baseEditForm from "formiojs/components/_classes/component/Component.form";
import { API_URL } from "../../config";
import StyleComponentForm from "../StyleComponent.form";

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "display",
        components: [
          {
            weight: 0,
            type: "textfield",
            input: false,
            label: "Key",
            tooltip: "This field key.",
            key: "key",
            disabled: true,
          },
          {
            weight: 1,
            type: "select",
            label: "Visit",
            input: true,
            tooltip: "The visit of the reference.",
            key: "visit",
            dataSrc: "url",
            lazyLoad: true,
            data: {
              url: `${API_URL}/visits/{{ data.crf }}`,
            },
            authenticate: true,
          },
          {
            weight: 2,
            type: "select",
            label: "Field",
            input: true,
            tooltip: "The field of the reference.",
            key: "field_reference",
            dataSrc: "url",
            lazyLoad: true,
            data: {
              url: `${API_URL}/visit/{{ data.visit.value }}`,
            },
            authenticate: true,
          },
          {
            key: "labelPosition",
            ignore: false,
          },
          {
            key: "description",
            ignore: true,
          },
          {
            key: "tooltip",
            ignore: true,
          },
          {
            key: "hideLabel",
            ignore: false,
            value: false,
          },
          {
            key: "placeholder",
            ignore: true,
          },
          {
            key: "autofocus",
            ignore: true,
          },
          {
            key: "tabindex",
            ignore: true,
          },
        ],
      },
      {
        key: "data",
        ignore: true,
      },
      {
        key: "validation",
        ignore: true,
      },
      {
        key: "logic",
        ignore: true,
      },
      {
        label: "Component",
        key: "component-styles",
        weight: 80,
        components: new StyleComponentForm("Component").jsonElement(),
      },
      {
        label: "Name",
        key: "label-styles",
        weight: 90,
        components: new StyleComponentForm("Name").jsonElement(),
      },
      {
        label: "Box",
        key: "box-styles",
        weight: 100,
        components: new StyleComponentForm("Box").jsonElement(),
      },
    ],
    ...extend
  );
};
