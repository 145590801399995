import axios from "axios";
import { API_URL } from "./config";

export const login = async (username, password) => {
  try {
    console.log("URL:", `${API_URL}/auth/`);
    const res = await axios.post(`${API_URL}/auth/`, {
      headers: {
        "Content-Type": "application/json",
      },
      username,
      password,
    });
    setToken(res.data.token);
    setSEK(res.data.SEK);
    setFormioToken(`${res.data.token}$_${res.data.SEK}`);
    return { ok: true };
  } catch (err) {
    return false;
  }
};

export const loggedIn = () => {
  const token = getToken();
  return !!token;
};

export const verifyLogin = async () => {
  const token = getToken();
  try {
    const res = await axios.post(`${API_URL}/auth-verify/`, {
      headers: {
        "Content-Type": "application/json",
      },
      token,
    });
    return res.data;
  } catch (err) {
    return false;
  }
};

const setToken = (token) => {
  localStorage.setItem("id_token", token);
};

const setSEK = (sek) => {
  localStorage.setItem("sek", sek);
};

const setFormioToken = (token) => {
  localStorage.setItem("formioToken", token);
};

export const getToken = () => {
  return localStorage.getItem("id_token");
};

export const getSEK = () => {
  return localStorage.getItem("sek");
};

export const getFormioToken = () => {
  return localStorage.getItem("formioToken");
};

export const logout = () => {
  localStorage.removeItem("id_token");
  localStorage.removeItem("sek");
  localStorage.removeItem("formioToken");
};
