import {
  Button,
  Dialog,
  Classes,
  Intent,
  FormGroup,
  InputGroup
} from "@blueprintjs/core";
import React, { useState } from "react";
import { client } from "../../apolloClient";

const ModalCloneTemplate = ({
  t,
  isOpen,
  toggleFunction,
  refetchFunction,
  cloneId,
  mutation
}) => {
  const [state, setState] = useState({
    autoFocus: true,
    canEscapeKeyClose: true,
    canOutsideClickClose: true,
    enforceFocus: true,
    usePortal: true,
    template: { name: "" }
  });

  const handleTemplateSubmit = async e => {
    e.preventDefault();

    let variables = {
      name: state.template.name,
      id: cloneId
    };

    client
      .mutate({
        variables,
        mutation: mutation
      })
      .then((res, err) => {
        if (err) console.log(err);
        handleClose();
        refetchFunction();
      });
  };

  const handleClose = () => toggleFunction();

  return (
    <Dialog
      icon="info-sign"
      onClose={handleClose}
      title={t("templateSave")}
      isOpen={isOpen}
    >
      <form onSubmit={handleTemplateSubmit}>
        <div className={Classes.DIALOG_BODY}>
          <FormGroup label={t("name")} labelFor="name" labelInfo="(required)">
            <InputGroup
              required={true}
              id="name"
              name="name"
              onChange={event => {
                const { name, value } = event.target;
                setState(prevState => ({
                  template: {
                    ...prevState.template,
                    [name]: value
                  }
                }));
              }}
              value={state.template.name}
              placeholder={t("name")}
              large={true}
            />
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={() => handleClose()}>{t("cancel")}</Button>

            <Button intent={Intent.PRIMARY} type="submit">
              {t("save")}
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default ModalCloneTemplate;
