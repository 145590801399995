import { Icon, Card, Elevation, Intent } from "@blueprintjs/core";
import React from "react";
import {
  MONITORIZATION_TYPE_CHECKED,
  MONITORIZATION_TYPE_INCIDENCE,
  MONITORIZATION_TYPE_COMMENT,
} from "../../../config";
import { Link } from "react-router-dom";

const Notification = ({ t, notification }) => {
  return (
    <Card className="p-2" interactive={false} elevation={Elevation.ZERO}>
      <div className="row">
        <div className="col-sm-2 pr-0 text-center">
          {(() => {
            switch (notification.inputFix.fixType) {
              case MONITORIZATION_TYPE_INCIDENCE:
                return (
                  <Icon icon="help" iconSize={30} intent={Intent.DANGER} />
                );
              case MONITORIZATION_TYPE_COMMENT:
                return (
                  <Icon icon="comment" iconSize={30} intent={Intent.WARNING} />
                );
              case MONITORIZATION_TYPE_CHECKED:
                return (
                  <Icon icon="tick" iconSize={30} intent={Intent.SUCCESS} />
                );
              default:
                return (
                  <Icon icon="help" iconSize={30} intent={Intent.DANGER} />
                );
            }
          })()}
        </div>
        <div className="col-sm-10">
          <h6>
            <Link
              title={
                notification.inputFix.monitorization.filledSheet.filledVisit
                  .filledVisit.patient.code
              }
              to={{
                pathname: `/filled-visit/${notification.inputFix.monitorization.filledSheet.filledVisit.id}`,
                state: {
                  entity: {
                    fetch: true,
                    id: notification.inputFix.monitorization.id,
                  },
                },
              }}
            >
              {
                notification.inputFix.monitorization.filledSheet.filledVisit
                  .filledVisit.patient.code
              }
            </Link>
          </h6>
          <p>{`${notification.inputFix.monitorization.inputLabel} - ${notification.inputFix.monitorization.filledSheet.filledVisit.filledVisit.visit.name} - ${notification.inputFix.monitorization.filledSheet.filledVisit.filledVisit.patient.crf.name}`}</p>
        </div>
      </div>
    </Card>
  );
};

export default Notification;
