import { Button, Dialog, Classes, Spinner } from "@blueprintjs/core";
import React, { useState, useEffect } from "react";

const ModalAction = ({
  t,
  isOpen,
  toggleFunction,
  isLoading,
  customText,
  customHeader,
  time = null,
}) => {
  const [state, setState] = useState({
    autoFocus: true,
    canEscapeKeyClose: true,
    canOutsideClickClose: true,
    enforceFocus: true,
    usePortal: true,
  });

  const handleClose = () => {
    toggleFunction();
  };

  useEffect(() => {
    if (time && isOpen) {
      setTimeout(function () {
        if (isOpen) {
          handleClose();
        }
        time = null;
      }, time);
    }
  });

  return (
    <Dialog
      icon="info-sign"
      onClose={handleClose}
      title={customHeader}
      isOpen={isOpen}
    >
      <div className={Classes.DIALOG_BODY}>
        <div
          className="spinner-loader-listing"
          style={isLoading ? { display: "inline" } : { display: "none" }}
        >
          <Spinner size={50} />
        </div>
        <p className="text-center pt-4 white-lines">{customText}</p>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={() => toggleFunction()}>{t("close")}</Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ModalAction;
