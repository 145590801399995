import { Button, Dialog, Classes, Icon, Colors } from "@blueprintjs/core";
import React, { useState } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "./_Timeline.scss";
import {
  MONITORIZATION_TYPE_CHECKED,
  MONITORIZATION_TYPE_INCIDENCE,
  MONITORIZATION_TYPE_COMMENT
} from "../../config";

const ModalTimeline = ({
  t,
  isOpen,
  toggleFunction,
  monitorization,
  field
}) => {
  const [state, setState] = useState({
    autoFocus: true,
    canEscapeKeyClose: true,
    canOutsideClickClose: true,
    enforceFocus: true,
    usePortal: true
  });

  const handleClose = () => toggleFunction();

  return (
    <Dialog
      icon="info-sign"
      onClose={handleClose}
      title={t("history")}
      isOpen={isOpen}
    >
      <div className={Classes.DIALOG_BODY}>
        <VerticalTimeline layout="1-column">
          {monitorization.fixes.edges.map((element, index) => {
            return (
              <VerticalTimelineElement
                className="vertical-timeline-element"
                date={new Date(element.node.created).toLocaleString()}
                iconStyle={(() => {
                  switch (element.node.fixType) {
                    case MONITORIZATION_TYPE_INCIDENCE:
                      return { background: `${Colors.RED4}`, color: "#fff" };
                    case MONITORIZATION_TYPE_COMMENT:
                      return { background: `${Colors.ORANGE4}`, color: "#fff" };
                    case MONITORIZATION_TYPE_CHECKED:
                      return { background: `${Colors.GREEN4}`, color: "#fff" };
                    default:
                      return { background: `${Colors.RED4}`, color: "#fff" };
                  }
                })()}
                icon={(() => {
                  switch (element.node.fixType) {
                    case MONITORIZATION_TYPE_INCIDENCE:
                      return <Icon icon="help" iconSize={30} />;
                    case MONITORIZATION_TYPE_COMMENT:
                      return <Icon icon="comment" iconSize={30} />;
                    case MONITORIZATION_TYPE_CHECKED:
                      return <Icon icon="tick" iconSize={30} />;
                    default:
                      return <Icon icon="help" iconSize={30} />;
                  }
                })()}
                position="right"
              >
                <h5 className="vertical-timeline-element-title">{field}</h5>
                <h6 className="vertical-timeline-element-subtitle">
                  {`${t("user")}: ${element.node.user.username}`}
                </h6>
                <h6>{`${t("message")}: ${element.node.message}`}</h6>
                <h6>{`${t("value")}: ${element.node.inputValue}`}</h6>
              </VerticalTimelineElement>
            );
          })}
        </VerticalTimeline>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={() => toggleFunction()}>{t("close")}</Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ModalTimeline;
