export const downloadFile = async (data, fileName) => {
  const json = JSON.stringify(data);
  const blob = new Blob([json], { type: "application/json" });
  const href = await URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = href;
  link.download = fileName + ".json";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadCsvFile = async (csvData, fileName) => {
    // Splitting the CSV string into rows
    const rows = csvData.trim().split('\n');
  
    // Converting each row into a column matrix and handling quotes
    const data = rows.map(row => {
      const columns = row.split(';').map(value => value.trim());
      /// Replace "" with " " in the column
      return columns.map(value => value === '""' ? '" "' : value);
    });
    
    // Converting the data into a CSV string format with added quotes
    const csvContent = data.map(row => row.join(';')).join('\n');
    
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    
    const href = await URL.createObjectURL(blob);
    
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName + ".csv";
    
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
};



export const downloadSpsFile = async (data, fileName) => {
  const blob = new Blob([data], { type: "text/plain" });
  const href = await URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = href;
  link.download = fileName + ".sps";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadPDF = async (blob, fileName) => {
  const href = await URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = href;
  link.download = fileName + ".pdf";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
