import React, { useState } from "react";
import { Icon } from "@blueprintjs/core";
import "./_Paginator.scss";

const Paginator = ({
  t,
  data,
  handlerPageChange,
  refetchFunction,
  tableType,
  page,
}) => {
  const refetchPagination = (newPage) => {
    refetchFunction({
      page: newPage,
    });

    handlerPageChange(newPage);
  };

  return (
    <nav aria-label="Page navigation">
      <ul className="pagination">
        <li
          className={
            data[tableType].hasPrev ? "page-item" : "page-item disabled"
          }
        >
          <a
            className="page-link"
            aria-label="First"
            onClick={() =>
              data[tableType].hasPrev ? refetchPagination(1) : "#"
            }
          >
            <Icon
              // className="ml-2"
              minimal
              icon="chevron-left"
              size="16"
            />
            <Icon
              // className="ml-2"
              minimal
              icon="chevron-left"
              size="16"
            />
          </a>
        </li>
        <li
          className={
            data[tableType].hasPrev ? "page-item" : "page-item disabled"
          }
        >
          <a
            className="page-link"
            aria-label="Previous"
            onClick={() =>
              data[tableType].hasPrev ? refetchPagination(page - 1) : "#"
            }
          >
            <Icon
              // className="ml-2"
              minimal
              icon="chevron-left"
              size="16"
            />
          </a>
        </li>
        {[...Array(data[tableType].pages)].map((value, i) => {
          if (i >= page - 3 && i <= page + 2)
            return (
              <li
                key={`page-${i}`}
                className={i + 1 === page ? "page-item active" : "page-item"}
              >
                <a
                  className="page-link"
                  onClick={() => refetchPagination(i + 1)}
                >
                  {i + 1}
                </a>
              </li>
            );
        })}
        <li
          className={
            data[tableType].hasNext ? "page-item" : "page-item disabled"
          }
        >
          <a
            className="page-link"
            aria-label="Next"
            onClick={() =>
              data[tableType].hasNext ? refetchPagination(page + 1) : "#"
            }
          >
            <Icon
              // className="ml-2"
              minimal
              icon="chevron-right"
              size="16"
            />
          </a>
        </li>
        <li
          className={
            data[tableType].hasNext ? "page-item" : "page-item disabled"
          }
        >
          <a
            className="page-link"
            aria-label="Last"
            onClick={() =>
              data[tableType].hasNext
                ? refetchPagination([...Array(data[tableType].pages)].length)
                : "#"
            }
          >
            <Icon
              // className="ml-2"
              minimal
              icon="chevron-right"
              size="16"
            />
            <Icon
              // className="ml-2"
              minimal
              icon="chevron-right"
              size="16"
            />
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Paginator;
