import baseEditForm from "formiojs/components/html/HTML.form";
import StyleComponentForm from "./StyleComponent.form";

export default (...extend) => {
  return baseEditForm(
    [
      {
        label: "Component",
        key: "component-styles",
        weight: 80,
        components: new StyleComponentForm("Component").jsonElement()
      }
    ],
    ...extend
  );
};
