import React from "react";
import { Card, Text, Icon, Popover, Button, Position } from "@blueprintjs/core";
import { connect } from "react-redux";
import { client } from "../../../apolloClient";
import { GET_NOTIFICATIONS } from "../../../queries";
import "./_MainNavBar.scss";
import HideableMenu from "./HideableMenu";
import LanguageMenu from "./LanguageMenu";
import NotificationsNav from "./NotificationsNav";

class MainNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: { incidences: [], comments: [], checks: [] },
      isFull: false,
    };

    this.setFullScreen = this.setFullScreen.bind(this);
  }

  setFullScreen() {
    const isFull = !window.screenTop && !window.screenY;

    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }

    this.setState({ isFull: window.screenTop && window.screenY });
  }

  render() {
    if(this.props.username === ''){
      window.location.reload();
    }
    const { isFull } = this.state;

    return (
      <nav
        className="bp3-navbar navbar-expand-lg navbar-light bg-light p-0 m-0 row custom-nav"
        style={{ height: "75px" }}
      >
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <Card className="navbar-nav ml-auto pull-right nav-card-item p-3 crd-card col-lg-9 col-md-8 col-sm-7">
          <div className="icon-box">
            <Popover content={<LanguageMenu />} position={Position.BOTTOM_LEFT}>
              <Icon
                style={{ display: "in-line-block" }}
                icon="globe"
                size={20}
                className="p-2"
              />
              {/* <Button icon="globe" minimal={true} className="ml-3 mt-2" /> */}
            </Popover>
            <Icon
              style={{ display: "in-line-block" }}
              icon={isFull ? "minimize" : "fullscreen"}
              size={20}
              className="p-2"
              onClick={() => {
                this.setFullScreen();
              }}
            />
            <Popover
              content={
                <NotificationsNav
                  notifications={this.state.notifications}
                  t={this.props.t}
                />
              }
              position={Position.BOTTOM}
              portalClassName="notification-popover"
            >
              <button
                type="button"
                className="btn btn-sm btn-link btn-header p-2 d-flex"
              >
                <Icon
                  icon="notifications"
                  size={20}
                ></Icon>
                {this.props.notifications > 0 && (
                  <span className="badge badge-danger">
                    {this.props.notifications}
                  </span>
                )}
              </button>
            </Popover>
          </div>
        </Card>
        <Card className="navbar-nav ml-auto pull-right nav-card-item p-2 profile-card col-lg-3 col-md-4 col-sm-5 g-0">
          <img
            height="55"
            src={this.props.avatar ? this.props.avatar : "/img/no-user.png"}
            className="rounded-circle imgProfile ml-3 mt-2"
            alt="User Avatar"
          />
          <Text className="nav-card-item ml-3 mt-2 d-inline">
            <span className="text-left d-block profile-name">
              {`${this.props.name} ${this.props.lastname}`}
            </span>
            <span className="text-left d-block profile-position">
              {this.props.role}
            </span>
          </Text>
          <Popover content={<HideableMenu />} position={Position.BOTTOM_LEFT}>
            <Button icon="menu" minimal={true} className="ml-3 mt-2" />
          </Popover>
        </Card>


      </nav>
    );
  }
}

const mapStateToProps = (state) => {
  return{
  username: state.user.username,
  name: state.user.name,
  lastname: state.user.lastname,
  role: state.user.role,
  avatar: state.user.avatar,
  notifications: state.user.notifications,};
};

const bindActions = (dispatch) => ({});

export default connect(mapStateToProps, bindActions)(MainNavbar);
