import React from "react";
import { withTranslation } from "react-i18next";
import { client } from "../../../apolloClient";
import {
  Dialog,
  Button,
  FormGroup,
  InputGroup,
  TextArea,
  Icon,
} from "@blueprintjs/core";
import { CREATE_VISIT } from "../../../mutations";
import "./_Create.scss";
import Dropzone from "react-dropzone";

class VisitForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visit: {
        id: "",
        name: "",
        observations: "",
        template: null,
      },
      isEdit: false,
      loading: false,
      redirect: false,
      submitting: false,
      template: {
        name: "",
        kind: "visit",
        template: [],
        fileName: "",
        error: "",
      },
    };

    this.handleClose = this.handleClose.bind(this);
    this.resetForm = this.resetForm.bind(this);
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.state.submitting !== true) {
      this.setState({ loading: true, submitting: true });
      const mutation = CREATE_VISIT;
      const variables = {
        name: this.state.visit.name,
        observations: this.state.visit.observations,
        template: this.state.visit.template ? this.state.visit.template : null,
        id: this.props.id,
      };

      client
        .mutate({
          variables,
          mutation,
        })
        .then((res, err) => {
          if (err) console.log(err);
          console.log("submiteadi");
          this.setState(
            { loading: false, redirect: true, submitting: false },
            this.props.onCreateSubmit(),
            this.resetForm()
          );
        });
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      visit: {
        ...prevState.visit,
        [name]: value,
      },
    }));
  };

  handleClose() {
    this.resetForm();
    this.props.toggleFunction();
  }

  resetForm() {
    this.setState({
      visit: {
        id: "",
        name: "",
        observations: "",
      },
    });
  }

  onDropTemplate = async (template) => {
    await this.setState((prevState) => ({
      template: {
        ...prevState.template,
        template: template,
        fileName: template[0].name,
      },
    }));

    this.handleAddTemplate();
  };

  handleAddTemplate = () => {
    const fr = new FileReader();
    const that = this;
    fr.onload = function (e) {
      let result = JSON.parse(e.target.result);

      // VALIDATE json format is compatible with formio (For now only checks if the template has an attribute formBuild, improve this validation?)
      if (typeof result.formBuild !== "undefined") {
        let formatted = JSON.stringify(result, null, 2);

        let template = {
          kind: that.state.template.kind,
          template: formatted,
        };
        that.setState((prevState) => ({
          visit: {
            ...prevState.visit,
            template: template,
          },
        }));
      } else {
        that.setState((prevState) => ({
          template: {
            ...prevState.template,
            error: that.props.t("error.template.add"),
          },
        }));
      }
    };

    fr.readAsText(this.state.template.template[0]);
  };

  render() {
    const capitalize = (s) => {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    };

    return (
      <Dialog
        icon="diagnosis"
        onClose={this.handleClose}
        title={this.props.t("createVisit")}
        isOpen={this.props.isOpen}
        style={{ "padding-bottom": "unset" }}
      >
        <div className="container">
          <form onSubmit={this.handleSubmit}>
            <div className="row mt-3">
              <div className="col-md-12">
                <FormGroup
                  label={this.props.t("name")}
                  labelFor="name"
                  labelInfo="(required)"
                >
                  <InputGroup
                    id="name"
                    name="name"
                    onChange={this.handleChange}
                    value={this.state.visit.name}
                    placeholder={this.props.t("name")}
                    large={true}
                  />
                </FormGroup>
              </div>

              <div className="col-md-12">
                <FormGroup
                  label={this.props.t("centreForm.observations")}
                  labelFor="observations"
                >
                  <TextArea
                    id="observations"
                    name="observations"
                    fill={true}
                    onChange={this.handleChange}
                    value={this.state.visit.observations}
                  />
                </FormGroup>
              </div>
            </div>

            <div className="col-md-12 p-0">
              <FormGroup
                label={this.props.t("import.from.template")}
                labelFor=""
              >
                <Dropzone
                  className="w-100"
                  accept="application/json, text/plain"
                  multiple={false}
                  onDrop={this.onDropTemplate}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className="drop-zone">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {((Array.isArray(this.state.template.template) &&
                          this.state.template.template.length === 0) ||
                          !this.state.template.template) && (
                          <div>
                            <Icon icon="cloud-upload" iconSize={40} />
                            <p className="dropzone-solid-blue">
                              {this.props.t("uploadText")}
                            </p>
                          </div>
                        )}
                        {this.state.template.template.length !== 0 &&
                          typeof this.state.template.template === "object" && (
                            <div>
                              <p className="dropzone-solid-blue">
                                {this.state.template.fileName}
                              </p>
                            </div>
                          )}
                      </div>
                    </section>
                  )}
                </Dropzone>
              </FormGroup>
            </div>

            <div className="columns">
              <div className="column is-3">
                <Button
                  className="listAddButton"
                  type="submit"
                  intent="success"
                >
                  {this.props.t("form.create")}
                </Button>
              </div>
            </div>
          </form>
          {/* </Card> */}
        </div>
      </Dialog>
    );
  }
}

export default withTranslation()(VisitForm);
