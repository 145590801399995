import Template from "./TemplateComponent/TemplateComponent";
import CheckMatrix from "./CheckMatrix";
import TextfieldCustom from "./InputComponents/Textfield/TextfieldCustom";
import TextareaCustom from "./InputComponents/Textarea/TextareaCustom";
import NumberCustom from "./InputComponents/Number/NumberCustom";
import DateCustom from "./InputComponents/Date/DateCustom";
import TimeCustom from "./InputComponents/Time/TimeCustom";
import CurrencyCustom from "./InputComponents/Currency/CurrencyCustom";
import EmailCustom from "./InputComponents/Email/EmailCustom";
import SelectCustom from "./SelectComponents/Select/SelectCustom";
import SelectBoxesCustom from "./SelectComponents/SelectBoxes/SelectBoxesCustom";
import RadioCustom from "./SelectComponents/Radio/RadioCustom";
import CustomContainer from "./ContainerCustom";
import ColumnsContainer from "./ColumnsCustom";
import TableContainer from "./TableCustom";
import PanelContainer from "./PanelCustom";
import Reference from "./ReferenceComponent/ReferenceComponent";
import Sumatory from "./SumatoryComponent/SumatoryComponent";
import HtmlCustom from "./HTMLElementCustom";
import FileCustomComponent from "./SelectComponents/File/FileCustom";
import ContentCustom from "./ContentCustom";

export default {
  template: Template,
  reference: Reference,
  textfield_custom: TextfieldCustom,
  textarea_custom: TextareaCustom,
  number_custom: NumberCustom,
  datetime_custom: DateCustom,
  time_custom: TimeCustom,
  currency_custom: CurrencyCustom,
  email_custom: EmailCustom,
  select_custom: SelectCustom,
  selectboxes_custom: SelectBoxesCustom,
  radio_custom: RadioCustom,
  checkmatrix: CheckMatrix,
  container_custom: CustomContainer,
  columns_custom: ColumnsContainer,
  table_custom: TableContainer,
  panel_custom: PanelContainer,
  html_custom: HtmlCustom,
  file_custom: FileCustomComponent,
  content_custom: ContentCustom,
  sumatory: Sumatory
};
