import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../../components/Breadcrumb";
import { client } from "../../../apolloClient";
import { GET_CRF } from "../../../queries";
import TemplateList from "./TemplateList";
import VisitList from "./VisitList";
import { useParams } from "react-router-dom";

const ViewCRF = ({ t }) => {
  const { id } = useParams();
  const [crf, setCrf] = useState({ name: t("crf.title") });
  const [reload, setReload] = useState(false);

  useEffect(() => {
    client
      .query({ query: GET_CRF, variables: { id: id } })
      .then((res) => {
        setCrf(res.data.crf);
      });
  }, [id]);

  const reloadTemplates = () => {
    setReload(!reload);
  };

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  return (
    <div className="container-fluid">
      <Breadcrumb
        crumbs={[
          { link: "/", name: "Home", active: false },
          {
            link: "/crfs",
            name: capitalize(t("crfs.title")),
            active: false,
          },
          {
            link: `/crf/${id}`,
            name: crf.name,
            active: true,
          },
        ]}
      />

      <VisitList crfId={id} reloadTemplates={reloadTemplates} />

      <TemplateList
        crfId={id}
        reload={reload}
        reloadTemplates={reloadTemplates}
      />
    </div>
  );
};

export default withTranslation()(ViewCRF);
