import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Footer from "./../components/Footer/Footer";
import MainNavbar from "../components/layout/MainNavBar/MainNavBar";
import MainSidebar from "../components/layout/MainSidebar/MainSidebar";

const DefaultLayout = ({ children, noNavbar, noFooter }) => {
  const [visible, setVisible] = useState(false);

  return (
    <div className=" position-relative min-vh-100 pb-4">
      <div className="row ">
        <MainSidebar onVisible={v => setVisible(v)} />
        <div className="col-sm-11 col-md-12 col-lg p-0 content min-vh-100">
          {!noNavbar && <MainNavbar />}
          <div
            className="pt-5 pb-5"
            style={!visible ? { marginLeft: 114 + "px" } : {}}
          >
            {children}
          </div>
        </div>
      </div>

      <Footer visible={visible} />
    </div>
  );
};

DefaultLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool
};

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: false
};

export default DefaultLayout;
