import { SET_USER_DATA, SET_LOGOUT } from "../actions/userActions";

const initialState = {
  username: "",
  email: "",
  id: 0,
  name: "", // Added to store the first name
  lastname: "", // Added to store the surname
  avatar: "", // Added to store the avatar
  role: "", // Added to store the function/role
  notifications: [], // Added to store notifications
  done: false, // Changed to false, as this is the initial state
  logout: false, // Added to control logout
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        username: action.payload.username,
        email: action.payload.email,
        name: action.payload.first_name,
        lastname: action.payload.last_name,
        avatar: action.payload.avatar,
        role: action.payload.role,
        notifications: action.payload.notifications,
        done: true,
      };
    case SET_LOGOUT:
      return {
        ...state,
        logout: action.logout,
      };
    default:
      return state; 
  }
}
