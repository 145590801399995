import React from "react";
import { withTranslation } from "react-i18next";
import { client } from "../../../apolloClient";
import {
  Card,
  Spinner,
  Elevation,
  AnchorButton,
} from "@blueprintjs/core";
import Breadcrumb from "../../../components/Breadcrumb";
import { Navigate  } from "react-router-dom";
import { GET_FILE } from "../../../queries";

import "moment/locale/es";

class FileView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      file: {
        label: "",
        value: null,
        patient: {
          code: "",
          id: "",
          crf: {
            id: "",
            name: ""
          }
        }
      },
      loading: false,
      redirect: false,
    };
  }

  componentDidMount() {
    try {
      if (window.location.href.split('/')[4] && this.props.match.params.key) {
        this.setState({ loading: true });
        const variables = { id: window.location.href.split('/')[4], key:this.props.match.params.key };
        client
          .query({ variables: variables, query: GET_FILE })
          .then((res) => {
            let value = res.data.getFile.value.replace(/'/g, '"');
            value = JSON.parse(value);
            this.setState((prevState) => ({
              ...prevState,
              file: {
                label: res.data.getFile.label,
                value: value,
                patient: res.data.getFile.patient
              },
              loading: false,
            }));
          });
      }
    } catch (err) {}
  }

  render() {
    const capitalize = (s) => {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    };

    if (this.state.loading) {
      return (
        <div className="container-fluid">
          <div className="spinner-loader-listing">
            <Spinner
              size={80}
              spinnerColor={"#007BFF"}
              spinnerWidth={2}
              visible={true}
            />
          </div>
        </div>
      );
    }

    if (this.state.redirect) {
      this.setState({
        redirect: false,
      });
      return <Navigate to={`/patient/${this.state.file.patient.id}`} />;
    }
    return (
      <div className="container-fluid" style={{ width: "99%" }}>
        <Breadcrumb
          crumbs={[
            { link: "/", name: "Home", active: false },
            {
              link: "/crfs",
              name: capitalize(this.props.t("crfs.title")),
              active: false,
            },
            {
              link: `/patients/${this.state.file.patient.crf.id}`,
              name: this.state.file.patient.crf.name,
              active: false,
            },
            {
              link: `/patient/${this.state.file.patient.id}`,
              name: this.state.file.patient.code,
              active: false,
            },
            {
              link: '',
              name: this.props.t("file.view"),
              active: true,
            },
          ]}
        />
        <div className="row">
          <div className="col">
            <h3>{this.props.t('FileView.title')}</h3>
          </div>
        </div>
        <Card
          interactive={false}
          elevation={Elevation.TWO}
          style={{ background: "#f4f4f4" }}
        >
          <h4 className="mb-3 w-100">
            {this.state.file.label}
          </h4>
          <p>{this.props.t('filename')}: {this.state.file.value?.name}</p>
          <p>{this.props.t('size')}: {this.state.file.value?.size}</p>
          <p>{this.props.t('type')}: {this.state.file.value?.type}</p>
          <AnchorButton
            download={this.state.file.value?.name}
            icon="download"
            title={this.props.t("visit.diff")}
            href={this.state.file.value?.url}
          >
            {this.props.t('download')}
          </AnchorButton>
          
        </Card>
      </div>
    );
  }
}

export default withTranslation()(FileView);