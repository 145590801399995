import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import SidebarMainNavbar from "./SidebarMainNavbar";
import SidebarNavItems from "./SidebarNavItems";
import { Store } from "../../../flux";
import { Icon } from "@blueprintjs/core";

import "./_MainSideBar.scss";

class MainSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuVisible: false,
      sidebarNavItems: Store.getSidebarItems()
    };

    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    this.setState({ menuVisible: this.props.hideLogoText });
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      menuVisible: Store.getMenuState(),
      sidebarNavItems: Store.getSidebarItems()
    });
  }

  toggleSideBarText() {
    this.setState({ menuVisible: !this.state.menuVisible });
    this.props.onVisible(this.state.menuVisible);
  }

  render() {
    //const visibleText = this.state.menuVisible;
    const { menuVisible } = this.state;

    return (
      <aside
        className={` sidebar_main ${
          menuVisible ? "min col-lg-0.5" : "col-md-4  col-lg-3 col-xl-2"
        }`}
      >
        <SidebarMainNavbar hideLogoText={menuVisible} />
        <SidebarNavItems hideLogoText={menuVisible} t={this.props.t} />
        <Icon
          icon={menuVisible ? "chevron-right" : "chevron-left"}
          iconSize={25}
          className={
            menuVisible ? "visible-text-icon ml-4" : "hidden-text-icon ml-3"
          }
          onClick={() => this.toggleSideBarText()}
        />
      </aside>
    );
  }
}

MainSidebar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

MainSidebar.defaultProps = {
  hideLogoText: true
};

export default withTranslation()(MainSidebar);
