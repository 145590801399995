import React from "react";
import { Menu, MenuItem } from "@blueprintjs/core";
import { setLogout } from "../../../actions/userActions";
import { logout } from "../../../services";

const HideableMenu = () => {
  const handleLogout = e => {
    logout();
    setLogout(true);
  };

  return (
    <Menu>
      {/* <MenuItem icon="user" text="Perfil" />
       */}
      <MenuItem href="/notifications" icon="notifications" text="notifications" />
      <MenuItem
        href="/login"
        icon="log-out"
        text="Logout"
        onClick={e => handleLogout(e)}
      />
    </Menu>
  );
};

export default HideableMenu;
