import React from "react";
import { Query } from "react-apollo";
import { withTranslation } from "react-i18next";
import Paginator from "../../../components/Paginator/Paginator";
import TableSizeSelector from "../../../components/Paginator/TableSizeSelector";
import {
  mediaFilesAppend,
  DEFAULT_ELEM_PAGE,
  TEMPLATE_VISIT,
  API_URL,
  ROLE_SYSADMIN,
  ROLE_ADMIN,
} from "../../../config";
import { Spinner, Icon, InputGroup } from "@blueprintjs/core";
import ConfirmDeleteModal from "../../../components/ModalDelete/ConfirmDeleteModal";
import { GET_TEMPLATES } from "../../../queries";
import { DELETE_TEMPLATE } from "../../../mutations";
import { getToken, getSEK } from "../../../services";
import { downloadFile } from "../../../utils/DownloadFile";
import ButtonAuthorized from "../../../components/ButtonAuthorized/ButtonAuthorized";

class TemplateList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpenedTemplate: false,
      toDeleteName: "",
      toDeleteIndex: "",
      toDeleteId: "",
      loading: false,
      page: 1,
      orderField: "created",
      elementsPage: DEFAULT_ELEM_PAGE,
      search: { text: "" },
    };

    this.toggleDeleteTemplateModal = this.toggleDeleteTemplateModal.bind(this);
  }

  componentDidMount() {
    if (this.refetch) {
      this.refetch();
    }
  }

  componentWillReceiveProps() {
    if (this.props.reload) {
      this.refetch();
      this.props.reloadTemplates();
    }
  }

  handlerPageChange = (page) => {
    this.setState({
      page: page,
    });
  };

  handleChange = async (event) => {
    const { name, value } = event.target;
    await this.setState((prevState) => ({
      search: {
        ...prevState.search,
        [name]: value,
      },
    }));
    if (value.length > 3) {
      this.handleSubmit();
    }
  };

  handleSubmit = () => {
    const search = this.state.search;
    this.refetch({
      ...search,
      page: 1,
    });
  };

  downloadTemplate = async (id, name) => {
    fetch(`${API_URL}/crf/download-template/${id}`, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        authorization: `${getToken()}$_${getSEK()}`,
      },
    })
      .then((response) => {
        const contentType = response.headers.get("content-type");
        if (!contentType || !contentType.includes("application/json")) {
          throw new TypeError("Oops, we haven't got JSON!");
        }
        return response.json();
      })
      .then((data) => {
        if (data.ok) downloadFile(data.json, name.trim().replace(/\s/g, "-"));
      })
      .catch((error) => console.error(error));
  };

  toggleDeleteTemplateModal = (
    toDeleteIndex = "",
    toDeleteId = "",
    toDeleteName = ""
  ) => {
    if (this.state.modalOpenedTemplate) {
      this.setState({
        modalOpenedTemplate: !this.state.modalOpenedTemplate,
      });
    } else {
      this.setState(
        {
          toDeleteIndex,
          toDeleteId,
          toDeleteName,
        },
        () =>
          this.setState({
            modalOpenedTemplate: !this.state.modalOpenedTemplate,
          })
      );
    }
  };

  orderTable = (field) => {
    if (this.state.orderField === field) {
      this.setState((prevState) => ({
        ...prevState,
        orderField: `-${field}`,
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        orderField: field,
      }));
    }
  };

  onChangePage(num) {
    this.setState({ elementsPage: num });
    this.refetch();
  }

  render() {
    this.t = this.props.t;
    const dataKeysTemplate = ["name", "templateType"];

    const capitalize = (s) => {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    };

    return (
      <div>
        <div className="row mt-5">
          <div className="col">
            <h3>{capitalize(this.t("templates"))}</h3>
          </div>
        </div>

        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.handleSubmit();
            }}
            ref={(elem) => (this.form = elem)}
          >
            <div className="row pt-2 pb-3">
              <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 offset-md-6 offset-lg-8 offset-xl-9 text-right">
                <InputGroup
                  id="text"
                  name="text"
                  onChange={this.handleChange}
                  value={this.state.text}
                  placeholder={this.props.t("search")}
                  leftIcon="search"
                />
              </div>
            </div>
          </form>
          <Query
            query={GET_TEMPLATES}
            variables={{
              crfId: this.props.crfId,
              elementsPerPage: this.state.elementsPage,
              page: this.state.page,
              orderField: this.state.orderField,
            }}
          >
            {({ loading, error, data, refetch }) => {
              if (loading)
                return (
                  <div className="spinner-loader-listing">
                    <Spinner
                      size={80}
                      spinnerColor={"#007BFF"}
                      spinnerWidth={2}
                      visible={true}
                    />
                  </div>
                );
              if (error) return `Error ${error.message}`;
              this.refetch = refetch;
              {
                return (
                  <div>
                    <table className="table table-custom table-hover">
                      <thead className="bg-light">
                        <tr className="table-hover">
                          <th scope="col" className="border-0">
                            #
                          </th>
                          <th
                            key="template-name-col"
                            scope="col"
                            className="clickable"
                            onClick={(e) => this.orderTable("name")}
                          >
                            {this.t("table.name")}
                            {this.state.orderField == "name" && (
                              <Icon
                                className="ml-2"
                                icon="caret-down"
                                size="16"
                              ></Icon>
                            )}
                            {this.state.orderField == `-name` && (
                              <Icon
                                className="ml-2"
                                icon="caret-up"
                                size="16"
                              ></Icon>
                            )}
                          </th>
                          <th
                            key="name-col"
                            scope="col"
                            className="clickable"
                            onClick={(e) => this.orderTable("template_type")}
                          >
                            {this.t("table.type")}
                            {this.state.orderField == "template_type" && (
                              <Icon
                                className="ml-2"
                                icon="caret-down"
                                size="16"
                              ></Icon>
                            )}
                            {this.state.orderField == `-template_type` && (
                              <Icon
                                className="ml-2"
                                icon="caret-up"
                                size="16"
                              ></Icon>
                            )}
                          </th>
                          <th scope="col" className="text-right">
                            {this.t("table.actions")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data["templates"]["objects"].map((data, index) => (
                          <tr key={index} className="table-hover">
                            <td>{index + 1}</td>
                            {dataKeysTemplate.map((key, index) => {
                              let info = "";
                              try {
                                info = key
                                  .split(".")
                                  .reduce(function (c, prop) {
                                    return c[prop];
                                  }, data);
                              } catch (err) {}
                              if (
                                typeof info === "string" &&
                                info.endsWith("png")
                              )
                                return (
                                  <td key={index}>
                                    <img
                                      className="icon"
                                      src={mediaFilesAppend + info}
                                      alt=""
                                    />
                                  </td>
                                );
                              else if (key === "created")
                                return (
                                  <td key={index}>
                                    {new Date(info).toLocaleDateString()}
                                  </td>
                                );
                              else return <td key={index}>{info}</td>;
                            })}
                            <td className="text-right">
                              <ButtonAuthorized
                                //intent={Intent.PRIMARY}
                                minimal
                                href={
                                  data.templateType.toLowerCase() ==
                                  TEMPLATE_VISIT
                                    ? `/template/edit/${data.id}`
                                    : `/template/edit-sheet/${data.id}`
                                }
                                icon="edit"
                                roles={[ROLE_SYSADMIN, ROLE_ADMIN]}
                                anchor={true}
                              />
                              <ButtonAuthorized
                                minimal
                                className="ml-2"
                                icon="download"
                                intent="primary"
                                onClick={() =>
                                  this.downloadTemplate(data.id, data.name)
                                }
                                roles={[ROLE_SYSADMIN, ROLE_ADMIN]}
                              />
                              <ButtonAuthorized
                                minimal
                                className="bp3-intent-danger ml-2"
                                icon="trash"
                                title={this.t("remove")}
                                roles={[ROLE_SYSADMIN, ROLE_ADMIN]}
                                onClick={() =>
                                  this.toggleDeleteTemplateModal(
                                    index,
                                    data.id,
                                    data.name
                                  )
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="row ">
                      <div className="col-8">
                        <TableSizeSelector
                          t={this.t}
                          numPages={this.state.elementsPage}
                          onChangePage={this.onChangePage.bind(this)}
                          elementsPage={this.state.elementsPage}
                          totalElements={data["templates"].total}
                        />
                      </div>
                      <div className="col-4 text-right">
                        <Paginator
                          data={data}
                          t={this.t}
                          handlerPageChange={this.handlerPageChange}
                          refetchFunction={refetch}
                          tableType="templates"
                          page={this.state.page}
                        />
                      </div>
                    </div>
                  </div>
                );
              }
            }}
          </Query>
        </div>
        <ConfirmDeleteModal
          isOpen={this.state.modalOpenedTemplate}
          toggleFunction={this.toggleDeleteTemplateModal}
          toDeleteId={this.state.toDeleteId}
          refetchFunction={this.refetch}
          toDeleteName={this.state.toDeleteName}
          mutation={DELETE_TEMPLATE}
          t={this.t}
        />
      </div>
    );
  }
}
export default withTranslation()(TemplateList);
