import baseEditForm from "formiojs/components/_classes/component/Component.form";
import { API_URL } from "../../config";

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "display",
        components: [
          {
            weight: 0,
            type: "select",
            label: "Template",
            input: true,
            tooltip: "The HTML template for the result data items.",
            key: "template",
            dataSrc: "url",
            lazyLoad: true,
            data: {
              url: `${API_URL}/templates/{{ data.crf }}`
            },
            authenticate: true
          },
          {
            key: "label",
            ignore: false
          },
          {
            key: "labelPosition",
            ignore: false
          },
          {
            key: "placeholder",
            ignore: true
          },
          {
            key: "description",
            ignore: true
          },
          {
            key: "tooltip",
            ignore: true
          },
          {
            key: "hideLabel",
            ignore: true
          },
          {
            key: "autofocus",
            ignore: true
          },
          {
            key: "disabled",
            ignore: true
          },
          {
            key: "tabindex",
            ignore: true
          },
          {
            weight: 700,
            type: "checkbox",
            label: "Refresh On Change",
            tooltip: "Rerender the field whenever a value on the form changes.",
            key: "refreshOnChange",
            input: true
          }
        ]
      },
      {
        key: "data",
        ignore: true
      },
      {
        key: "validation",
        ignore: true
      },
      {
        key: "logic",
        ignore: true
      }
    ],
    ...extend
  );
};
