
const config = require('../../../config')
exports.default = void 0;

var _default = [
  {
    weight: 5,
    type: "textfield",
    input: true,
    label: "Code",
    tooltip: "This field code.",
    key: "code",
    required: true,
  },
  {
    weight: 10,
    type: "textfield",
    input: false,
    label: "Key",
    tooltip: "This field key.",
    key: "key",
    disabled: true,
  },
  {
    key: "placeholder",
    ignore: true,
  },
  {
    type: "select",
    input: true,
    label: "Options Label Position",
    key: "optionsLabelPosition",
    tooltip: "Position for the label for options for this field.",
    dataSrc: "values",
    weight: 32,
    defaultValue: "right",
    data: {
      values: [
        {
          label: "Top",
          value: "top",
        },
        {
          label: "Left",
          value: "left",
        },
        {
          label: "Right",
          value: "right",
        },
        {
          label: "Bottom",
          value: "bottom",
        },
      ],
    },
  },
  {
    type: "checkbox",
    input: true,
    key: "inline",
    label: "Inline Layout",
    tooltip: "Displays the checkboxes/radios horizontally.",
    weight: 650,
  },
  {
    weight: 700,
    type: "checkbox",
    label: "Randomizer",
    tooltip:
      "This field is used for branching, it will only be editable one time.",
    key: "randomizer",
    input: true,
  },
  {
    weight: 710,
    type: "checkbox",
    label: "Critical variable",
    tooltip:
      "This variable is critical a notification will be send to users on it's modification.",
    key: "critical",
    input: true
  },
  {
    weight: 720,
    type: "select",
    multiple: true,
    label: "Users to notify",
    input: true,
    tooltip: "Users who should be notified if variable critical.",
    key: "users",
    dataSrc: "url",
    lazyLoad: true,
    data: {
      url: `${config.API_URL}/users/{{ data.crf }}`
    },
    authenticate: true,
    conditional: {
      json: {
        '==': [{
          var: 'data.critical'
        }, 1]
      }
    }
  },
];
exports.default = _default;
