import React from "react";
import { Navigate  } from "react-router-dom";
import { connect } from "react-redux";

class HomeRedirector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      go: false,
      isStaff: false
    };
  }

  async componentDidMount() {
    this.setState({ isStaff: this.props.isStaff, go: true });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ isStaff: nextProps.isStaff, go: true });
    
  }

  render() {
    if (this.state.go) {
      return <Navigate to="/crfs" />;
    } else {
      return <div></div>;
    }
  }
}

const mapStateToProps = state => ({
  isStaff: state.user.isStaff
});


const bindActions = dispatch => ({});



export default connect(mapStateToProps, bindActions)(HomeRedirector);
