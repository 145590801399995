import React, { useEffect, useState } from "react";
import { Tabs, Tab, Card, Elevation, Spinner } from "@blueprintjs/core";
import { withTranslation } from "react-i18next";
import Notification from "./Notification";
import { client } from "../../../apolloClient";
import { GET_NOTIFICATIONS } from "../../../queries";

import "./_MainNavBar.scss";

const NotificationsNav = ({ t }) => {
  const [notificationsState, setNotificationsState] = useState({
    incidences: [],
    comments: [],
    checks: []
  });
  const [queryDone, setQueryDone] = useState(false);

  useEffect(() => {
    if (!queryDone) {
      try {
        client
          .query({
            query: GET_NOTIFICATIONS,
            fetchPolicy: "no-cache"
          })
          .then(res => {
            setNotificationsState({
              incidences: res.data.notifications.incidences,
              comments: res.data.notifications.comments,
              checks: res.data.notifications.checks
            });
            setQueryDone(true);
          });
      } catch (err) {
        console.log("NotificacionesError");
      }
    }
  });

  return queryDone ? (
    <Tabs
      className="notification-tabs"
      animate={true}
      id="notifications"
      large={true}
    >
      <Tab
        id="incidence"
        title={t("incidence")}
        panel={
          notificationsState.incidences.length > 0 ? (
            notificationsState.incidences.map((data, index) => (
              <Notification
                key={`notification-${data.id}`}
                notification={data}
                t={t}
              />
            ))
          ) : (
            <Card interactive={false} elevation={Elevation.ZERO}>
              <div className="row">
                <div className="col-sm-12">
                  <p>{t("no.notifications")}</p>
                </div>
              </div>
            </Card>
          )
        }
      />
      <Tab
        id="comments"
        title={t("comments")}
        panel={
          notificationsState.comments.length > 0 ? (
            notificationsState.comments.map((data, index) => (
              <Notification
                key={`notification-${data.id}`}
                notification={data}
                t={t}
              />
            ))
          ) : (
            <Card interactive={false} elevation={Elevation.ZERO}>
              <div className="row">
                <div className="col-sm-12">
                  <p>{t("no.notifications")}</p>
                </div>
              </div>
            </Card>
          )
        }
      />
      <Tab
        id="checks"
        title={t("checks")}
        panel={
          notificationsState.checks.length > 0 ? (
            notificationsState.checks.map((data, index) => (
              <Notification
                key={`notification-${data.id}`}
                notification={data}
                t={t}
              />
            ))
          ) : (
            <Card interactive={false} elevation={Elevation.ZERO}>
              <div className="row">
                <div className="col-sm-12">
                  <p>{t("no.notifications")}</p>
                </div>
              </div>
            </Card>
          )
        }
      />
    </Tabs>
  ) : (
    <div className="spinner-loader p-4">
      <Spinner
        size={80}
        spinnerColor={"#007BFF"}
        spinnerWidth={2}
        visible={true}
      />
    </div>
  );
};

export default withTranslation()(NotificationsNav);
