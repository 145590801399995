import React from "react";
import { withTranslation } from "react-i18next";

import "./_Dashboard.scss";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.t = this.props.t;
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="notification">
          This container is <strong>fluid</strong>: it will have a 32px gap on
          either side, on any viewport size.
        </div>
      </div>
    );
  }
}

export default withTranslation()(Dashboard);
