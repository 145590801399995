export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_LOGOUT = 'SET_LOGOUT';

export function setUserData(userData) {
  return dispatch => {
    dispatch({
      type: SET_USER_DATA,
      payload: userData
    })
  }
};

export function setLogout(logout) {
  return dispatch => {
    dispatch({
      type: SET_LOGOUT,
      payload: logout
    })
  }
}