import {
  Button,
  Dialog,
  Classes,
  Intent,
  FormGroup,
  InputGroup,
} from "@blueprintjs/core";
import React, { useState } from "react";
import { client } from "../../apolloClient";
import { MONITORIZATION_TYPES } from "../../config";
import { ADD_INPUT_FIX, CREATE_INPUT_MONITORIZATION } from "../../mutations";

const ModalMonitorization = ({
  t,
  isOpen,
  toggleFunction,
  refetchFunction,
  monitorizationId,
  type,
  monitorizationRef,
  inputType,
  value,
  label,
  mutation,
}) => {
  const [state, setState] = useState({
    autoFocus: true,
    canEscapeKeyClose: true,
    canOutsideClickClose: true,
    enforceFocus: true,
    usePortal: true,
    text: "",
    submitting: false,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (state.submitting === false) {
      setState({ ...state, submitting: true });
      const variables =
        mutation == CREATE_INPUT_MONITORIZATION
          ? {
              text: state.text,
              id: monitorizationId,
              fixType: type,
              reference: monitorizationRef,
              inputType: inputType,
              inputValue: value,
              inputLabel: label,
            }
          : {
              text: state.text,
              id: monitorizationId,
              fixType: type,
              inputValue: value,
            };

      client
        .mutate({
          variables,
          mutation: mutation,
        })
        .then((res, err) => {
          if (err) console.log(err);
          handleClose();
          setState({ ...state, text: "", submitting: false });
          refetchFunction();
        });
    }
  };

  const handleClose = () => {
    setState({ ...state, text: "" });
    toggleFunction();
  };

  return (
    <Dialog
      icon="info-sign"
      onClose={handleClose}
      title={t(MONITORIZATION_TYPES[type])}
      isOpen={isOpen}
    >
      <form onSubmit={handleSubmit}>
        <div className={Classes.DIALOG_BODY}>
          <FormGroup
            label={t("message")}
            labelFor="monitorization_text"
            labelInfo="(required)"
          >
            <InputGroup
              required={true}
              id="monitorization_text"
              name="monitorization_text"
              onChange={(event) => {
                const { name, value } = event.target;
                setState({ ...state, text: value });
              }}
              value={state.text}
              placeholder={t("message")}
              large={true}
            />
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={handleClose}>{t("cancel")}</Button>

            <Button intent={Intent.PRIMARY} type="submit">
              {t("send")}
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default ModalMonitorization;
