import { Button, Dialog, Classes, Intent } from "@blueprintjs/core";
import React, { useState } from "react";
import { client } from "../../apolloClient";

const ModalChangeStatus = ({
  t,
  isOpen,
  toggleFunction,
  refetchFunction,
  entityId,
  status,
  mutation,
  customText,
  customHeader
}) => {
  const [state, setState] = useState({
    autoFocus: true,
    canEscapeKeyClose: true,
    canOutsideClickClose: true,
    enforceFocus: true,
    usePortal: true,
    entity: { name: "" }
  });

  const handleSubmit = async e => {
    e.preventDefault();

    let variables = {
      status: status,
      id: entityId
    };

    client
      .mutate({
        variables,
        mutation: mutation
      })
      .then((res, err) => {
        if (err) console.log(err);
        handleClose();
        refetchFunction();
      });
  };

  const handleClose = () => toggleFunction();

  return (
    <Dialog
      icon="info-sign"
      onClose={handleClose}
      title={customHeader ? t(customHeader) : t("confirmStatusChange")}
      isOpen={isOpen}
    >
      <div className={Classes.DIALOG_BODY}>
        <p>{customText ? t(customText) : t("confirmStatusChange")}</p>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={() => toggleFunction()}>{t("cancel")}</Button>

          <Button intent={Intent.PRIMARY} onClick={handleSubmit}>
            {t("change")}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ModalChangeStatus;
