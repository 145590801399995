import Base from "formiojs/components/_classes/nested/NestedComponent";
import editForm from "./TemplateComponent.form";
import { makeTemplateDialog } from "../MakeTemplateDialog";

export default class Template extends Base {
  constructor(component, options, data) {
    super(component, options, data);
    this.crf = component.crf;
  }

  static schema() {
    return Base.schema({
      label: "Template",
      type: "template",
      key: "template",
      clearOnHide: true,
      input: true,
      tree: true,
      hideLabel: true,
      components: [],
    });
  }

  static builderInfo = {
    title: "Template",
    group: "template",
    icon: "far fa-file",
    weight: 70,
    documentation: "http://help.form.io/userguide/#table",
    schema: Template.schema(),
  };

  static editForm = editForm;

  /*
   * Defines the editForm when editing a component in the builder.
   */
  //static editForm = editForm;

  /**
   * Render returns an html string of the fully rendered component.
   *
   * @param children - If this class is extendended, the sub string is passed as children.
   * @returns {string}
   */
  render(children) {
    // To make this dynamic, we could call this.renderTemplate('templatename', {}).

    if (
      typeof this.component.template !== "undefined" &&
      typeof this.component.template != ""
    ) {
      // JSON Parse ERROR triggering here, but without any sense because it parses the json. That's why we catch the error
      try {
        const components = JSON.parse(this.component.template.value).components;
        components.forEach((component) => {
          this.addComponent(component);
        });
        this.component.components = components;
        this.component.type = "container_custom";
        this.component.template = "";
      } catch (err) {}
    } else {
      this.destroyComponents();
    }

    return super.render();
  }

  /**
   * After the html string has been mounted into the dom, the dom element is returned here. Use refs to find specific
   * elements to attach functionality to.
   *
   * @param element
   * @returns {Promise}
   */
  attach(element) {
    if (element.children[0].className == "component-btn-group") {
      let buttonElement = document.createElement("div");
      buttonElement.className =
        "btn btn-xxs btn-info component-settings-button component-settings-button-template";
      buttonElement.innerHTML = '<i class="fa fa-clone"></i>';
      buttonElement.onclick = (e) => {
        e.preventDefault();
        // Creates dialog
        makeTemplateDialog(this.component, this.crf);
      };

      element.children[0].append(buttonElement);
    }

    return super.attach(element);
  }

  /**
   * Get the value of the component from the dom elements.
   *
   * @returns {Array}
   */
  getValue() {
    return super.getValue();
  }

  /**
   * Set the value of the component into the dom elements.
   *
   * @param value
   * @returns {boolean}
   */
  setValue(value) {
    super.setValue(value);
  }
}
