import baseEditForm from "formiojs/components/container/Container.form";
import StyleComponentForm from "./StyleComponent.form";

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "display",
        components: [
          {
            weight: 10,
            type: "textfield",
            input: false,
            label: "Key",
            tooltip: "This field key.",
            key: "key",
            disabled: true,
          },
        ],
      },
      {
        label: "Component",
        key: "component-styles",
        weight: 80,
        components: new StyleComponentForm("Component").jsonElement(),
      },
      {
        label: "Name",
        key: "label-styles",
        weight: 90,
        components: new StyleComponentForm("Name").jsonElement(),
      },
    ],
    ...extend
  );
};
