import { CREATE_TEMPLATE } from "../mutations";
import { TEMPLATE_CONTAINER } from "../config";
import { client } from "../apolloClient";
import i18n from "../i18n";

const handleCloseDialog = e => {
  e.preventDefault();
  const element = document.getElementById("template-container-id");
  element.remove();
};

// GraphQl request
const handleSubmit = (component, crf) => {
  let name = document.getElementById("template-container-name");

  let variables = {
    name: name.value,
    id: crf,
    templateType: TEMPLATE_CONTAINER
  };

  variables.formBuild = `{"display": "form", "components":[${JSON.stringify(
    component
  )}]}`;

  client
    .mutate({
      variables,
      mutation: CREATE_TEMPLATE
    })
    .then((res, err) => {
      if (err) console.log(err);
      const element = document.getElementById("template-container-id");
      element.remove();

      // Create Success Toast
      const toast = document.createElement("div");
      toast.id = "template-toast-id";

      const toastOverlay = document.createElement("div");
      toastOverlay.className =
        "bp3-overlay bp3-overlay-inline bp3-toast-container bp3-toast-container-top recipe-toaster";

      const toastMessage = document.createElement("div");
      toastMessage.className =
        "bp3-toast bp3-intent-success bp3-overlay-content";
      toastMessage.innerHTML = `<span class="bp3-toast-message">${i18n.t(
        "template.saved"
      )}.</span>`;

      const toastButtonContainerClose = document.createElement("div");
      toastButtonContainerClose.className = "bp3-button-group bp3-minimal";

      const toastButtonClose = document.createElement("button");
      toastButtonClose.setAttribute("type", "button");
      toastButtonClose.className = "bp3-button";
      toastButtonClose.onclick = e => {
        e.preventDefault();
        toast.remove();
      };
      toastButtonClose.innerHTML =
        '<span icon="cross" class="bp3-icon bp3-icon-cross"><svg data-icon="cross" width="16" height="16" viewBox="0 0 16 16"><desc>cross</desc><path d="M9.41 8l3.29-3.29c.19-.18.3-.43.3-.71a1.003 1.003 0 00-1.71-.71L8 6.59l-3.29-3.3a1.003 1.003 0 00-1.42 1.42L6.59 8 3.3 11.29c-.19.18-.3.43-.3.71a1.003 1.003 0 001.71.71L8 9.41l3.29 3.29c.18.19.43.3.71.3a1.003 1.003 0 00.71-1.71L9.41 8z" fill-rule="evenodd"></path></svg></span>';

      toastButtonContainerClose.append(toastButtonClose);
      toastMessage.append(toastButtonContainerClose);
      toastOverlay.append(toastMessage);
      toast.append(toastOverlay);

      document.body.append(toast);

      setTimeout(function() {
        toast.remove();
      }, 10000);
    });
};

export const makeTemplateDialog = (component, crf) => {
  // Creates the whole DOM for the dialog by hand (Should be refactored)
  let container = document.createElement("div");
  container.className = "bp3-portal";
  container.id = "template-container-id";

  let overlay = document.createElement("div");
  overlay.className =
    "bp3-overlay bp3-overlay-open bp3-overlay-scroll-container";

  let overlayBackdrop = document.createElement("div");
  overlayBackdrop.className =
    "bp3-overlay-backdrop bp3-overlay-appear-done bp3-overlay-enter-done";

  let dialogContainer = document.createElement("div");
  dialogContainer.className =
    "bp3-dialog-container bp3-overlay-content bp3-overlay-appear-done bp3-overlay-enter-done";

  let dialog = document.createElement("div");
  dialog.className = "bp3-dialog";

  let dialogHeader = document.createElement("div");
  dialogHeader.className = "bp3-dialog-header";
  dialogHeader.innerHTML = `<span icon="info-sign" class="bp3-icon bp3-icon-info-sign"><svg data-icon="info-sign" width="20" height="20" viewBox="0 0 20 20"><desc>info-sign</desc><path d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zM9 4h2v2H9V4zm4 12H7v-1h2V8H8V7h3v8h2v1z" fill-rule="evenodd"></path></svg></span><h4 class="bp3-heading">${i18n.t(
    "templateSave"
  )}</h4>`;

  let closeButtonHeader = document.createElement("button");
  closeButtonHeader.className =
    "bp3-button bp3-minimal bp3-dialog-close-button";
  closeButtonHeader.setAttribute("type", "button");
  closeButtonHeader.innerHTML =
    '<span icon="small-cross" class="bp3-icon bp3-icon-small-cross"><svg data-icon="small-cross" width="20" height="20" viewBox="0 0 20 20"><desc>small-cross</desc><path d="M11.41 10l3.29-3.29c.19-.18.3-.43.3-.71a1.003 1.003 0 00-1.71-.71L10 8.59l-3.29-3.3a1.003 1.003 0 00-1.42 1.42L8.59 10 5.3 13.29c-.19.18-.3.43-.3.71a1.003 1.003 0 001.71.71l3.29-3.3 3.29 3.29c.18.19.43.3.71.3a1.003 1.003 0 00.71-1.71L11.41 10z" fill-rule="evenodd"></path></svg></span>';
  closeButtonHeader.onclick = handleCloseDialog;
  dialogHeader.append(closeButtonHeader);

  let form = document.createElement("form");
  form.onsubmit = e => {
    e.preventDefault();
    handleSubmit(component, crf);
  };

  let dialogBody = document.createElement("div");
  dialogBody.className = "bp3-dialog-body";
  dialogBody.innerHTML = `<div class="bp3-form-group"><label class="bp3-label" for="name">${i18n.t(
    "name"
  )} <span class="bp3-text-muted">(${i18n.t(
    "required"
  )})</span></label><div class="bp3-form-content"><div class="bp3-input-group bp3-large"><input type="text" required="required" id="template-container-name" name="name" placeholder="${i18n.t(
    "name"
  )}" class="bp3-input" style="padding-right: 10px;" value=""></div></div></div>`;

  let dialogFooter = document.createElement("div");
  dialogFooter.className = "bp3-dialog-footer";

  let dialogFooterActions = document.createElement("div");
  dialogFooterActions.className = "bp3-dialog-footer-actions";

  let dialogFooterClose = document.createElement("button");
  dialogFooterClose.className = "bp3-button";
  dialogFooterClose.setAttribute("type", "button");
  dialogFooterClose.innerHTML = `<span class="bp3-button-text">${i18n.t(
    "cancel"
  )}</span>`;
  dialogFooterClose.onclick = handleCloseDialog;

  let dialogFooterSave = document.createElement("button");
  dialogFooterSave.className = "bp3-button bp3-intent-primary";
  dialogFooterSave.setAttribute("type", "submit");
  dialogFooterSave.innerHTML = `<span class="bp3-button-text">${i18n.t(
    "save"
  )}</span>`;

  dialogFooterActions.append(dialogFooterSave);
  dialogFooterActions.append(dialogFooterClose);

  dialogFooter.append(dialogFooterActions);
  form.append(dialogBody);
  form.append(dialogFooter);

  dialog.append(dialogHeader);
  dialog.append(form);

  dialogContainer.append(dialog);
  overlayBackdrop.append(dialogContainer);
  overlay.append(overlayBackdrop);
  container.append(overlay);

  document.body.append(container);
};
