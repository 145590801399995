import React from "react";
import { connect } from "react-redux";

import SidebarNavItem from "./SidebarNavItem";
import { Store } from "../../../flux";
import { ROLE_SYSADMIN } from "../../../config";

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navItems: Store.getSidebarItems()
    };

    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      navItems: Store.getSidebarItems()
    });
  }

  render() {
    const { navItems: items } = this.state;
    const { hideLogoText: onlyIcon } = this.props;

    return (
      <div className="nav-wrapper d-flex mt-4 mr-3">
        <div className="nav--no-borders flex-column">
          {items.map((item, idx) => {
            if (
              (item.superAdmin && this.props.role == ROLE_SYSADMIN) ||
              !item.superAdmin
            )
              return (
                <SidebarNavItem
                  onlyIcon={onlyIcon}
                  key={idx}
                  item={{ ...item, onlyIcon }}
                />
              );
          })}
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  role: state.user.role
});

const bindActions = dispatch => ({});

export default connect(mapStateToProps, bindActions)(SidebarNavItems);
