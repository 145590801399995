import React from "react";
import { withTranslation } from "react-i18next";
import "./_Footer.scss";

const Footer = ({ t, visible }) => {
  const logoPath = process.env.PUBLIC_URL + "/img/Logotipo.png";

  return (
    <div className="footer row pt-3">
      <div className={visible ? "sidebarZone" : "sidebarZoneMin"} />
      <div className="logo col-md-1 col-sm-2">
        <img className="img-fluid" src={logoPath} />
      </div>
      <div className="col-sm-2 p-3">
        <small>Version 1.5</small>
      </div>
      <div className="col-sm-6 p-3 link-group text-right">
        <a className="menu" href="/footer/about">
          {t("about")}
        </a>
        <a className="menu" href="/footer/info">
          {t("info")}
        </a>
        {/* <a className="menu" href="/footer/help">
          {t("help")}
        </a>
        <a className="menu" href="/footer/contact">
          {t("contact")}
        </a> */}
      </div>
    </div>
  );
};

export default withTranslation()(Footer);
