export default function() {
  return [
    /*{
      title: "Analíticas",
      to: "/dashboard",
      htmlBefore: '<i class="material-icons">dashboard</i>',
      htmlAfter: "",
      staffOnly: true
    },*/
    {
      title: "inicio",
      to: "/",
      icon: "home",
      bottomSeparate: true,
      bottomCategory: "Gestión"
    },
    {
      title: "centros",
      to: "/centres",
      icon: "office",
      bottomSeparate: false,
      superAdmin: true
    },
    {
      title: "usuarios",
      to: "/users",
      icon: "people",
      bottomSeparate: true,
      bottomCategory: "Investigación",
      superAdmin: true
    },
    {
      title: "cuadernos",
      to: "/crfs",
      icon: "folder-open",
      bottomSeparate: false
    }
    // {
    //   title: "Visitas",
    //   to: "/visitas",
    //   icon: "diagnosis",
    //   bottomSeparate: false
    // }
  ];
}
