import React from "react";
import { withTranslation } from "react-i18next";
import { Navigate  } from "react-router-dom";
import { login } from "../../services";
import { Alert } from "@blueprintjs/core";
import { RECOVER_PASSWORD } from "../../mutations";
import { client } from "../../apolloClient";

import "./_Login.scss";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      emailRecover: "",
      email: "",
      loading: false,
      errors: { username: "", password: "", emailRecover: "" },
      formIsValid: false,
      loggedIn: false,
      stateSwitch: false,
      modal: { open: false },
      mode: "login",
    };

    this.t = this.props.t;
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { username, password } = this.state;
    this.validate(username, password);
    if (this.isFormValid()) {
      login(username, password).then((res) => {
        if (!res.ok) {
          return alert("WRONG LOGIN OR USER DISABLED");
        } else {
          this.setState({ loggedIn: true });
        }
      });
    }
  };

  handleRecover = (event) => {
    event.preventDefault();
    const { emailRecover } = this.state;
    this.validateRecovery(emailRecover);
    if (this.isFormRecoverValid()) {
      client
        .mutate({
          variables: { email: this.state.emailRecover },
          mutation: RECOVER_PASSWORD,
        })
        .then((res, err) => {
          if (err) console.log(err);
          if (!res.data.recoverPassword.ok) {
            return alert(res.data.recoverPassword.errors);
          } else {
            this.handleErrorOpen();
            this.setState({ emailRecover: "", mode: "login" });
          }
        });
    }
  };

  async validate(username, password) {
    let errors = this.state.errors;
    errors.username = !username ? this.t("username.required") : "";
    errors.password = !password ? this.t("password.required") : "";

    await this.setState({ errors });
  }

  async validateRecovery(emailRecover) {
    let errors = this.state.errors;
    errors.emailRecover = !emailRecover ? this.t("email.required") : "";

    await this.setState({ errors });
  }

  isFormValid() {
    return (
      this.state.errors.username === "" && this.state.errors.password === ""
    );
  }

  isFormRecoverValid() {
    return this.state.errors.emailRecover === "";
  }

  handleErrorOpen = () =>
    this.setState({
      modal: {
        open: true,
        message: (
          <span>
            {this.t("instruction_recovery")} {this.state.email}.
            <br />
            <br />
            {this.t("spam")}
          </span>
        ),
      },
    });

  handleErrorClose = () =>
    this.setState({ modal: { open: false, message: "" } });

  render() {
    const { ...alertProps } = this.state;

    if (this.state.loggedIn) {
      return <Navigate to="/" />;
    } else {
      return (
        <div id="login">
          <div className="form-wrapper">
            <div className="title">
              <img
                src={process.env.PUBLIC_URL + "/img/Logotipo@2x.png"}
                alt="HIDUP"
              />
              <span>
                {this.state.mode === "login"
                  ? this.t("login.text")
                  : this.t("login.recover")}
              </span>
            </div>
            {this.state.mode === "login" ? (
              <>
                <LoginForm
                  handleOnSubmit={this.handleSubmit}
                  handleOnChange={this.handleChange}
                  username={this.state.username}
                  inputErrors={this.state.errors}
                  password={this.state.password}
                  translations={this.t}
                />
              </>
            ) : (
              <>
                <p className="tip">{this.t("recover_text")}</p>
                <RecoveryForm
                  handleOnSubmit={this.handleRecover}
                  handleOnChange={this.handleChange}
                  inputErrors={this.state.errors}
                  emailRecover={this.state.emailRecover}
                  translations={this.t}
                />
              </>
            )}
          </div>
          <div className="state-switch">
            {this.state.mode === "login" ? (
              <p>
                {this.t("problems_access")} <br />
                <span onClick={(e) => this.setState({ mode: "recovery" })}>
                  {this.t("obtain_password")}
                </span>
              </p>
            ) : (
              <p>
                <span onClick={(e) => this.setState({ mode: "login" })}>
                  {this.t("Volver")}
                </span>
              </p>
            )}
          </div>

          {this.state.modal && (
            <Alert
              {...alertProps}
              confirmButtonText="Okay"
              isOpen={this.state.modal.open}
              onClose={this.handleErrorClose}
            >
              <p>{this.state.modal.message}</p>
            </Alert>
          )}
        </div>
      );
    }
  }
}

export default withTranslation()(Login);

const LoginForm = ({
  handleOnChange,
  handleOnSubmit,
  username,
  password,
  inputErrors,
  translations,
}) => {
  return (
    <form onSubmit={handleOnSubmit}>
      <div className="form-group-login">
        <div className="form-control-login">
          <input
            name="username"
            value={username}
            type="text"
            onChange={handleOnChange}
            placeholder={translations("placeholder.username")}
          />
          <div className="errors">
            <span key={"username_" + new Date().getTime()} className="error">
              {inputErrors.username}
            </span>
          </div>
        </div>
      </div>
      <div className="form-group-login">
        <div className="form-control-login">
          <input
            name="password"
            type="password"
            value={password}
            onChange={handleOnChange}
            placeholder={translations("placeholder.password")}
          />
          <div className="errors">
            <span key={"password_" + new Date().getTime()} className="error">
              {inputErrors.password}
            </span>
          </div>
        </div>
      </div>
      <button className="btn" type="submit">
        {translations("login.button")}
      </button>
    </form>
  );
};

const RecoveryForm = ({
  handleOnChange,
  handleOnSubmit,
  emailRecover,
  inputErrors,
  translations,
}) => {
  return (
    <form onSubmit={handleOnSubmit}>
      <div className="form-group-login">
        <div className="form-control-login">
          <input
            name="emailRecover"
            value={emailRecover}
            type="email"
            onChange={handleOnChange}
            placeholder={translations("email")}
          />
          <div className="errors">
            <span
              key={"emailRecover_" + new Date().getTime()}
              className="error"
            >
              {inputErrors.emailRecover}
            </span>
          </div>
        </div>
      </div>
      <button className="btn" type="submit">
        {translations("send")}
      </button>
    </form>
  );
};
