import {
  Button,
  Dialog,
  Classes,
  Icon,
  Colors,
  Spinner,
} from "@blueprintjs/core";
import React, { useState } from "react";
import { Query } from "react-apollo";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "./_Timeline.scss";
import {
  MONITORIZATION_TYPE_CHECKED,
  MONITORIZATION_TYPE_INCIDENCE,
  MONITORIZATION_TYPE_COMMENT,
} from "../../config";
import { GET_MONITORIZATIONS } from "../../queries";
import { Document, PDFDownloadLink } from "@react-pdf/renderer";
import { client } from "../../apolloClient";
import PatientMonitorizations from "../MonitorizationPDF/monitorizations";

const ModalTimelinePatient = ({
  t,
  isOpen,
  toggleFunction,
  entityId,
  code,
  crdName,
}) => {
  const [state, setState] = useState({
    autoFocus: true,
    canEscapeKeyClose: true,
    canOutsideClickClose: true,
    enforceFocus: true,
    usePortal: true,
    ready: false,
    fetched: false,
    monitorizations: [],
  });

  const handleClose = () => toggleFunction();

  const readyPDF = () => {
    setState({ ...state, ready: true });
  };
  if (!state.fetched)
    client
      .query({
        query: GET_MONITORIZATIONS,
        variables: { id: entityId },
      })
      .then((res) => {
        setState({
          ...state,
          fetched: true,
          monitorizations: res.data.monitorizations,
          visit: res.data.monitorizations.filled,
        });
      });

  // Create Document Component
  const MyDocument = (
    <Document>
      <PatientMonitorizations
        monitorizations={state.monitorizations}
        t={t}
        code={code}
        visit={crdName}
      />
    </Document>
  );

  return (
    <Dialog
      icon="info-sign"
      onClose={handleClose}
      title={`${t("history")} - ${code}`}
      isOpen={isOpen}
    >
      <div className={Classes.DIALOG_BODY}>
        <Query
          query={GET_MONITORIZATIONS}
          variables={{
            id: entityId,
          }}
        >
          {({ loading, error, data, refetch, updateQuery }) => {
            if (loading)
              return (
                <div className="spinner-loader-listing">
                  <Spinner
                    size={80}
                    spinnerColor={"#007BFF"}
                    spinnerWidth={2}
                    visible={true}
                  />
                </div>
              );

            if (error) return `Error ${error.message}`;

            if (data.monitorizations.length == 0)
              return (
                <div className="spinner-loader-listing">
                  <p>{t("no.history")}</p>
                </div>
              );
            else
              return (
                <div>
                  {data.monitorizations.map((monitorization, index) => {
                    return (
                      <VerticalTimeline layout="1-column">
                        {monitorization.fixes.edges.map((element, index) => {
                          return (
                            <VerticalTimelineElement
                              className="vertical-timeline-element"
                              date={new Date(
                                element.node.created
                              ).toLocaleString()}
                              iconStyle={(() => {
                                switch (element.node.fixType) {
                                  case MONITORIZATION_TYPE_INCIDENCE:
                                    return {
                                      background: `${Colors.RED4}`,
                                      color: "#fff",
                                    };
                                  case MONITORIZATION_TYPE_COMMENT:
                                    return {
                                      background: `${Colors.ORANGE4}`,
                                      color: "#fff",
                                    };
                                  case MONITORIZATION_TYPE_CHECKED:
                                    return {
                                      background: `${Colors.GREEN4}`,
                                      color: "#fff",
                                    };
                                  default:
                                    return {
                                      background: `${Colors.RED4}`,
                                      color: "#fff",
                                    };
                                }
                              })()}
                              icon={(() => {
                                switch (element.node.fixType) {
                                  case MONITORIZATION_TYPE_INCIDENCE:
                                    return <Icon icon="help" iconSize={30} />;
                                  case MONITORIZATION_TYPE_COMMENT:
                                    return (
                                      <Icon icon="comment" iconSize={30} />
                                    );
                                  case MONITORIZATION_TYPE_CHECKED:
                                    return <Icon icon="tick" iconSize={30} />;
                                  default:
                                    return <Icon icon="help" iconSize={30} />;
                                }
                              })()}
                              position="right"
                            >
                              <h5 className="vertical-timeline-element-title">
                                {monitorization.inputLabel}
                              </h5>
                              <h6 className="vertical-timeline-element-subtitle">
                                {`${t("user")}: ${element.node.user.username}`}
                              </h6>
                              <h6>{`${t("message")}: ${
                                element.node.message
                              }`}</h6>
                              <h6>{`${t("value")}: ${
                                element.node.inputValue
                              }`}</h6>
                            </VerticalTimelineElement>
                          );
                        })}
                      </VerticalTimeline>
                    );
                  })}
                </div>
              );
          }}
        </Query>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={() => toggleFunction()}>{t("close")}</Button>
          {state.ready && (
            <PDFDownloadLink
              document={MyDocument}
              fileName={`${code}-monitorizations`}
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  "Loading document..."
                ) : (
                  <Button onClick={() => setState({ ...state, ready: false })}>
                    download pdf
                  </Button>
                )
              }
            </PDFDownloadLink>
          )}
          {!state.ready && (
            <Button onClick={() => readyPDF()}>generate pdf</Button>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default ModalTimelinePatient;
