const config = require('../../../config')
exports.default = void 0;
var _default = [
  {
    weight: 5,
    type: "textfield",
    input: true,
    label: "Code",
    tooltip: "This field code.",
    key: "code",
    required: true,
  },
  {
    weight: 10,
    type: "textfield",
    input: false,
    label: "Key",
    tooltip: "This field key.",
    key: "key",
    disabled: true,
  },
  {
    key: "placeholder",
    ignore: true,
  },
  {
    type: "textfield",
    input: true,
    weight: 20,
    tooltip: "Select the type of widget you'd like to use.",
    key: "widget",
    calculateValue: {
      _camelCase: ["html5"],
    },
    required: true,
    label: "Widget Type",
    ignore: true,
  },
  {
    weight: 700,
    type: "checkbox",
    label: "Randomizer",
    tooltip:
      "This field is used for branching, it will only be editable one time.",
    key: "randomizer",
    input: true,
  },
  {
    weight: 710,
    type: "checkbox",
    label: "Critical variable",
    tooltip:
      "This variable is critical a notification will be send to users on it's modification.",
    key: "critical",
    input: true
  },
  {
    weight: 720,
    type: "select",
    multiple: true,
    label: "Users to notify",
    input: true,
    tooltip: "Users who should be notified if variable critical.",
    key: "users",
    dataSrc: "url",
    lazyLoad: true,
    data: {
      url: `${config.API_URL}/users/{{ data.crf }}`
    },
    authenticate: true,
    conditional: {
      json: {
        '==': [{
          var: 'data.critical'
        }, 1]
      }
    }
  },
];
exports.default = _default;
