import baseEditForm from "formiojs/components/_classes/component/Component.form";
import radioData from "./RadioCustom.data.form";
import radioDisplay from "./RadioCustom.display.form";
import StyleComponentForm from "../../StyleComponent.form";

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "display",
        components: radioDisplay.default
      },
      {
        key: "data",
        components: radioData.default
      },
      {
        key: "validation",
        components: [
          {
            key: "validateOn",
            ignore: true
          },
          {
            key: "unique",
            ignore: true
          }
        ]
      },
      {
        key: "api",
        ignore: true
      },
      {
        label: "Component",
        key: "component-styles",
        weight: 80,
        components: new StyleComponentForm("Component").jsonElement()
      },
      {
        label: "Name",
        key: "label-styles",
        weight: 90,
        components: new StyleComponentForm("Name").jsonElement()
      },
      {
        label: "Box",
        key: "box-styles",
        weight: 100,
        components: new StyleComponentForm("Box").jsonElement()
      }
    ],
    ...extend
  );
};
