import React from "react";
import { Navigate , Link } from "react-router-dom";
import { Query } from "react-apollo";
import { withTranslation } from "react-i18next";
import { client } from "../../../apolloClient";
import Breadcrumb from "../../../components/Breadcrumb";
import Paginator from "../../../components/Paginator/Paginator";
import TableSizeSelector from "../../../components/Paginator/TableSizeSelector";
import {
  mediaFilesAppend,
  DEFAULT_ELEM_PAGE,
  FILLED_VISIT_STATUS_NEW,
  FILLED_VISIT_STATUS_COMPLETED,
  FILLED_VISIT_STATUS_MONITORING,
  FILLED_VISIT_STATUS_INPROGRESS,
  FILLED_VISIT_STATUS,
  ROLE_ADMIN,
  ROLE_DATAMANAGER,
  ROLE_INVESTIGATOR,
  ROLE_LEAD_MONITOR,
  ROLE_MONITOR,
  ROLE_READ_ONLY,
  ROLE_SYSADMIN,
} from "../../../config";
import {
  Spinner,
  Button,
  InputGroup,
  Intent,
  Tag,
  Icon,
} from "@blueprintjs/core";
import ConfirmDeleteModal from "../../../components/ModalDelete/ConfirmDeleteModal";
import ChangeStatusModal from "../../../components/ModalChangeStatus/ModalChangeStatus";
import ModalTimelinePatient from "../../../components/ModalTimeline/ModalTimelinePatient";
import ButtonAuthorized from "../../../components/ButtonAuthorized/ButtonAuthorized";
import { GET_PATIENT, GET_FILLED_VISITS } from "../../../queries";
import { CHANGE_STATUS_FILLED_VISIT } from "../../../mutations";


class ViewPatient extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpened: false,
      toDeleteName: "",
      toDeleteIndex: "",
      toDeleteId: "",
      deleteFunc: "",
      changeId: "",
      modalStatusOpened: false,
      modalTimelineOpened: false,
      status: "",
      loading: false,
      elementsPage: 50,
      page: 1,
      orderField: "created",
      search: { text: "" },
      customHeader: "confirmDelete",
      customText: "table.delete",
      patient: { name: "", crf: { name: "" } },
      redirect: false,
    };
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
  }

  componentDidMount() {
    
    client
      .query({
        query: GET_PATIENT,
        variables: { id: window.location.href.split('/')[4] },
      })
      .then((res) => {
        this.setState((prevState) => ({
          ...prevState,
          patient: res.data.patient,
        }));
      });

    if (this.refetch) {
      this.refetch();
    }
  }

  toggleDeleteModal = (
    toDeleteIndex = "",
    toDeleteId = "",
    toDeleteName = "",
    mutation,
    customText,
    customHeader
  ) => {
    if (this.state.modalOpened) {
      this.setState({
        modalOpened: !this.state.modalOpened,
        mutation: mutation,
        customText: customText,
        customHeader: customHeader,
      });
    } else {
      this.setState(
        {
          toDeleteIndex,
          toDeleteId,
          toDeleteName,
          mutation,
          customText: customText,
          customHeader: customHeader,
        },
        () => this.setState({ modalOpened: !this.state.modalOpened })
      );
    }
  };

  toggleStatusModal = (changeId = "", status, customText, customHeader) => {
    this.setState({
      changeId: changeId,
      status: status,
      modalStatusOpened: !this.state.modalStatusOpened,
      customText: customText,
      customHeader: customHeader,
    });
  };

  toggleTimelineModal = () => {
    this.setState({
      modalTimelineOpened: !this.state.modalTimelineOpened,
    });
  };

  handlerPageChange = (page) => {
    this.setState({
      page: page,
    });
  };

  onChangePage(num) {
    this.setState({ elementsPage: num });
    this.refetch();
  }

  handleChange = async (event) => {
    const { name, value } = event.target;
    await this.setState((prevState) => ({
      search: {
        ...prevState.search,
        [name]: value,
      },
    }));
    if (value.length > 3) {
      this.handleSubmit();
    }
  };

  handleSubmit = () => {
    const search = this.state.search;
    this.refetch({
      ...search,
      page: 1,
    });
  };

  orderTable = (field) => {
    if (this.state.orderField === field) {
      this.setState((prevState) => ({
        ...prevState,
        orderField: `-${field}`,
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        orderField: field,
      }));
    }
  };

  render() {
    this.t = this.props.t;
    const dataKeys = ["visit", "status", "pendingMonitorizations"];
    const headers = [
      this.t("visit"),
      this.t("status"),
      this.t("pendingMonitorizations"),
    ];

    const capitalize = (s) => {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    };

    if (!this.state.loading) {
      if (this.state.redirect) {
        return <Navigate to="/crfs" />;
      }
    }

    return (
      <div className="container-fluid">
        <Breadcrumb
          crumbs={[
            { link: "/", name: "Home", active: false },
            {
              link: "/crfs",
              name: capitalize(this.t("crfs.title")),
              active: false,
            },
            {
              link: `/patients/${this.state.patient.crf.id}`,
              name: this.state.patient.crf.name,
              active: false,
            },
            {
              link: `/patient/${window.location.href.split('/')[4]}`,
              name: this.state.patient.code,
              active: true,
            },
          ]}
        />

        <div className="row">
          <div className="col">
            <h3>{`${capitalize(this.t(this.props.title))} ${
              this.state.patient.code
            }`}</h3>
          </div>
          <div className="col">
            <Button
              className="float-right primary-button"
              type="submit"
              intent="primary"
              icon="history"
              onClick={(e) => this.toggleTimelineModal()}
            >
              {this.props.t("history")}
            </Button>
            {this.state.patient.branch && (<h4 className="float-right mr-5">{this.state.patient.branch}</h4>) }
          </div>
        </div>

        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.handleSubmit();
            }}
            ref={(elem) => (this.form = elem)}
          >
            <div className="row pt-2 pb-3">
              <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 offset-md-6 offset-lg-8 offset-xl-9 text-right">
                <InputGroup
                  id="text"
                  name="text"
                  onChange={this.handleChange}
                  value={this.state.text}
                  placeholder={this.props.t("search")}
                  leftIcon="search"
                />
              </div>
            </div>
          </form>
          <Query
            query={GET_FILLED_VISITS}
            variables={{
              patientId: window.location.href.split('/')[4],
              elementsPerPage: this.state.elementsPage,
              page: this.state.page,
              orderField: this.state.orderField,
            }}
          >
            {({ loading, error, data, refetch }) => {
              if (loading)
                return (
                  <div className="spinner-loader-listing">
                    <Spinner
                      size={80}
                      spinnerColor={"#007BFF"}
                      spinnerWidth={2}
                      visible={true}
                    />
                  </div>
                );
              if (error) return `Error ${error.message}`;
              this.refetch = refetch;
              {
                return (
                  <div>
                    <table className="table table-custom table-hover">
                      <thead className="bg-light">
                        <tr>
                          <th scope="col">#</th>
                          {headers.map((header, index) => (
                            <th
                              key={index}
                              scope="col"
                              className="clickable"
                              onClick={(e) => this.orderTable(dataKeys[index])}
                            >
                              {this.t(header)}
                              {this.state.orderField == dataKeys[index] && (
                                <Icon
                                  className="ml-2"
                                  icon="caret-down"
                                  size="16"
                                ></Icon>
                              )}
                              {this.state.orderField ==
                                `-${dataKeys[index]}` && (
                                <Icon
                                  className="ml-2"
                                  icon="caret-up"
                                  size="16"
                                ></Icon>
                              )}
                            </th>
                          ))}
                          <th scope="col" className="text-right">
                            {this.t("table.actions")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data[this.props.tableType]["objects"].map(
                          (data, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              {dataKeys.map((key, index) => {
                                let info = "";
                                try {
                                  info = key
                                    .split(".")
                                    .reduce(function (c, prop) {
                                      return c[prop];
                                    }, data);
                                } catch (err) {}
                                if (
                                  typeof info === "string" &&
                                  info.endsWith("png")
                                )
                                  return (
                                    <td key={index}>
                                      <img
                                        className="icon"
                                        src={mediaFilesAppend + info}
                                        alt=""
                                      />
                                    </td>
                                  );
                                else if (key === "status")
                                  return (
                                    <td key={index}>
                                      <Tag
                                        round={true}
                                        intent={(() => {
                                          switch (info) {
                                            case FILLED_VISIT_STATUS_NEW:
                                              return Intent.NONE;
                                            case FILLED_VISIT_STATUS_INPROGRESS:
                                              return Intent.WARNING;
                                            case FILLED_VISIT_STATUS_MONITORING:
                                              return Intent.DANGER;
                                            default:
                                              return Intent.SUCCESS;
                                          }
                                        })()}
                                      >
                                        {FILLED_VISIT_STATUS[info]}
                                      </Tag>
                                    </td>
                                  );
                                else if (key === "created")
                                  return (
                                    <td key={index}>
                                      {new Date(info).toLocaleDateString()}
                                    </td>
                                  );
                                else if (key === "visit") {
                                  return <td key={index}>{info.name}</td>;
                                } else return <td key={index}>{info}</td>;
                              })}
                              <td className="text-right">
                                <ButtonAuthorized
                                  intent={Intent.SUCCESS}
                                  href={`/filled-visit/${data.lastFilledVisit.id}`}
                                  minimal={true}
                                  icon="edit"
                                  roles={[
                                    ROLE_SYSADMIN,
                                    ROLE_ADMIN,
                                    ROLE_MONITOR,
                                    ROLE_READ_ONLY,
                                    ROLE_DATAMANAGER,
                                    ROLE_INVESTIGATOR,
                                    ROLE_LEAD_MONITOR,
                                  ]}
                                  anchor={true}
                                />
                                {(data.status == FILLED_VISIT_STATUS_NEW ||
                                  data.status ==
                                    FILLED_VISIT_STATUS_INPROGRESS) && (
                                  <ButtonAuthorized
                                    className="ml-2"
                                    intent={Intent.PRIMARY}
                                    minimal={true}
                                    icon={"eye-on"}
                                    title={this.t("monitor")}
                                    onClick={() =>
                                      this.toggleStatusModal(
                                        data.id,
                                        FILLED_VISIT_STATUS_MONITORING,
                                        `${this.t("changeInProgressMonitorization")} ${this.t("changeStatus")} ${
                                          data.visit.name
                                        }?`,
                                        this.t("changeStatusHeader")
                                      )
                                    }
                                    roles={[
                                      ROLE_SYSADMIN,
                                      ROLE_ADMIN,
                                      ROLE_DATAMANAGER,
                                      ROLE_INVESTIGATOR,
                                    ]}
                                  />
                                )}
                                {data.status ==
                                  FILLED_VISIT_STATUS_MONITORING && (
                                  <ButtonAuthorized
                                    className="ml-2"
                                    intent={Intent.PRIMARY}
                                    icon="lock"
                                    title={this.t("lock")}
                                    minimal={true}
                                    onClick={() =>
                                      this.toggleStatusModal(
                                        data.id,
                                        FILLED_VISIT_STATUS_COMPLETED,
                                        `${this.t("changeStatus")} ${
                                          data.visit.name
                                        }?`,
                                        this.t("changeStatusHeader")
                                      )
                                    }
                                    roles={[
                                      ROLE_SYSADMIN,
                                      ROLE_ADMIN,
                                      ROLE_MONITOR,
                                      ROLE_LEAD_MONITOR,
                                    ]}
                                  />
                                )}
                                {data.status ===
                                  FILLED_VISIT_STATUS_MONITORING && (
                                  <ButtonAuthorized
                                    className="ml-2"
                                    icon={"reset"}
                                    intent={Intent.WARNING}
                                    title={this.t("reset")}
                                    minimal={true}
                                    onClick={() =>
                                      this.toggleStatusModal(
                                        data.id,
                                        FILLED_VISIT_STATUS_INPROGRESS,
                                        `${this.t("changeStatus")} ${
                                          data.visit.name
                                        }?`,
                                        this.t("changeStatusHeader")
                                      )
                                    }
                                    roles={[
                                      ROLE_SYSADMIN,
                                      ROLE_ADMIN,
                                      ROLE_MONITOR,
                                      ROLE_LEAD_MONITOR,
                                    ]}
                                  />
                                )}
                                {data.status ===
                                  FILLED_VISIT_STATUS_COMPLETED && (
                                  <ButtonAuthorized
                                    className="ml-2"
                                    icon={"unlock"}
                                    intent={Intent.WARNING}
                                    title={this.t("reopenMonitorization")}
                                    minimal={true}
                                    onClick={() =>
                                      this.toggleStatusModal(
                                        data.id,
                                        FILLED_VISIT_STATUS_MONITORING,
                                        `${this.t("changeStatus")} ${
                                          data.visit.name
                                        }?`,
                                        this.t("changeStatusHeader")
                                      )
                                    }
                                    roles={[
                                      ROLE_SYSADMIN,
                                      ROLE_ADMIN,
                                    ]}
                                  />
                                )}
                                
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                    <div className="row ">
                      <div className="col-8">
                        <TableSizeSelector
                          t={this.t}
                          numPages={this.state.elementsPage}
                          onChangePage={this.onChangePage.bind(this)}
                          elementsPage={this.state.elementsPage}
                          totalElements={data[this.props.tableType].total}
                        />
                      </div>
                      <div className="col-4 text-right">
                        <Paginator
                          data={data}
                          t={this.t}
                          handlerPageChange={this.handlerPageChange}
                          refetchFunction={refetch}
                          tableType={this.props.tableType}
                          page={this.state.page}
                        />
                      </div>
                    </div>
                  </div>
                );
              }
            }}
          </Query>
        </div>
        <ConfirmDeleteModal
          isOpen={this.state.modalOpened}
          toggleFunction={this.toggleDeleteModal}
          toDeleteId={this.state.toDeleteId}
          refetchFunction={this.refetch}
          toDeleteName={this.state.toDeleteName}
          mutation={this.state.mutation}
          customText={this.state.customText}
          customHeader={this.state.customHeader}
          t={this.t}
        />
        <ChangeStatusModal
          isOpen={this.state.modalStatusOpened}
          entityId={this.state.changeId}
          status={this.state.status}
          toggleFunction={this.toggleStatusModal}
          refetchFunction={this.refetch}
          mutation={CHANGE_STATUS_FILLED_VISIT}
          customText={this.state.customText}
          customHeader={this.state.customHeader}
          t={this.t}
        />
        <ModalTimelinePatient
          isOpen={this.state.modalTimelineOpened}
          entityId={window.location.href.split('/')[4]}
          code={this.state.patient.code}
          toggleFunction={this.toggleTimelineModal}
          t={this.props.t}
          crdName={this.state.patient.crf.name}
        />
      </div>
    );
  }
}
export default withTranslation()(ViewPatient);
