import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { verifyLogin } from "./services";
import { setUserData } from "./actions/userActions";

const AuthRequired = ({ render, setUserData }) => {
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function checkLogin() {
      setLoading(true);
      const verifyRes = await verifyLogin();
      if (
        !verifyRes.token &&
        window.location.pathname !== "/login" &&
        !window.location.pathname.includes("/reset-password")
      ) {
        setRedirectToLogin(true);
      } else if (verifyRes.token) {
        setUserData(verifyRes.user);
      }

      setLoading(false);
    }

    checkLogin();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  } else {
    if (redirectToLogin) {
      return <Navigate to="/login" />;
    } else {
      // If render is a component, render it; otherwise, consider render as a function and call it.
      return typeof render === "function" ? render() : render;
    }
  }
};

const mapStateToProps = (state) => ({
  done: state.user.done,
});

const mapDispatchToProps = (dispatch) => ({
  setUserData: (userData) => dispatch(setUserData(userData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthRequired);