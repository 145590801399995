import Base from "formiojs/components/table/Table";
import * as editForm from "./TableCustom.form";
import { makeTemplateDialog } from "./MakeTemplateDialog";

export default class TableCustom extends Base {
  constructor(component, options, data) {
    // data = { crf: component.crf };
    super(component, options, data);
    this.crf = component.crf;
  }

  static schema() {
    return Base.schema({
      type: "table_custom",
    });
  }

  /**
   * After the html string has been mounted into the dom, the dom element is returned here. Use refs to find specific
   * elements to attach functionality to.
   *
   * @param element
   * @returns {Promise}
   */
  attach(element) {
    if (
      element.children[0] &&
      element.children[0].className == "component-btn-group"
    ) {
      let buttonElement = document.createElement("div");
      buttonElement.className =
        "btn btn-xxs btn-info component-settings-button component-settings-button-template";
      buttonElement.innerHTML = '<i class="fa fa-clone"></i>';
      buttonElement.onclick = (e) => {
        e.preventDefault();
        // Creates dialog
        makeTemplateDialog(this.component, this.crf);
      };

      element.children[0].append(buttonElement);
    }

    this.stylesComponent(element);
    this.stylesName(element);

    return super.attach(element);
  }

  /**
   * Attach styles to element component
   */
  stylesComponent(element) {
    let boxShadow = ``;
    let textShadow = ``;
    if (typeof this.component.styleComponent !== "undefined") {
      Object.keys(this.component.styleComponent).forEach((key, index) => {
        if (typeof this.component.styleComponent[key] !== "undefined") {
          // special for box shadow
          if (
            key == "h-offset-box" ||
            key == "v-offset-box" ||
            key == "blur-radius-box" ||
            key == "spread-box"
          ) {
            if (this.component.styleComponent[key] !== "")
              boxShadow += `${this.component.styleComponent[key]} `;
            else boxShadow += "0px ";
          } else if (key == "color-box") {
            if (this.component.styleComponent[key] !== "")
              boxShadow += `${this.component.styleComponent[key]} `;
            else boxShadow += "#000 ";
          } else if (
            key == "h-offset-text" ||
            key == "v-offset-text" ||
            key == "blur-radius-text" ||
            key == "spread-text"
          ) {
            if (this.component.styleComponent[key] !== "")
              textShadow += `${this.component.styleComponent[key]} `;
            else textShadow += "0px ";
          } else if (key == "color-text") {
            if (this.component.styleComponent[key] !== "")
              textShadow += `${this.component.styleComponent[key]} `;
            else textShadow += "#000 ";
          } else {
            if (this.component.styleComponent[key] !== "")
              element.style[key] = this.component.styleComponent[key];
          }
        }
      });

      element.style.boxShadow = boxShadow;
      element.style.textShadow = textShadow;
    }
  }

  /**
   * Attach styles to element label
   */
  stylesName(element) {
    let boxShadow = ``;
    let textShadow = ``;
    const label = element.querySelector("label");
    if (label && typeof this.component.styleName !== "undefined") {
      Object.keys(this.component.styleName).forEach((key, index) => {
        if (typeof this.component.styleName[key] !== "undefined") {
          // special for box shadow
          if (
            key == "h-offset-box" ||
            key == "v-offset-box" ||
            key == "blur-radius-box" ||
            key == "spread-box"
          ) {
            if (this.component.styleName[key] !== "")
              boxShadow += `${this.component.styleName[key]} `;
            else boxShadow += "0px ";
          } else if (key == "color-box") {
            if (this.component.styleName[key] !== "")
              boxShadow += `${this.component.styleName[key]} `;
            else boxShadow += "#000 ";
          } else if (
            key == "h-offset-text" ||
            key == "v-offset-text" ||
            key == "blur-radius-text" ||
            key == "spread-text"
          ) {
            if (this.component.styleName[key] !== "")
              textShadow += `${this.component.styleName[key]} `;
            else textShadow += "0px ";
          } else if (key == "color-text") {
            if (this.component.styleName[key] !== "")
              textShadow += `${this.component.styleName[key]} `;
            else textShadow += "#000 ";
          } else {
            if (this.component.styleName[key] !== "")
              label.style[key] = this.component.styleName[key];
          }
        }
      });

      label.style.boxShadow = boxShadow;
      label.style.textShadow = textShadow;
    }
  }
}

TableCustom.editForm = editForm.default;
