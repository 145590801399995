import baseEditForm from "formiojs/components/file/File.form";
import StyleComponentForm from "../../StyleComponent.form";

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "display",
        components: [
          {
            weight: 5,
            type: "textfield",
            input: true,
            label: "Code",
            tooltip: "This field code.",
            key: "code",
            required: true
          },
          {
            weight: 10,
            type: "textfield",
            input: false,
            label: "Key",
            tooltip: "This field key.",
            key: "key",
            disabled: true
          }
        ]
      },
      {
        key: "file",
        components: [
          {
            key: "storage",
            ignore: false
          }
        ]
      },
      {
        key: "data",
        components: [
          {
            key: "encrypted",
            ignore: true
          },
          {
            key: "persistent",
            ignore: true
          }
        ]
      },
      {
        key: "api",
        ignore: true
      },
      {
        label: "Component",
        key: "component-styles",
        weight: 80,
        components: new StyleComponentForm("Component").jsonElement()
      },
      {
        label: "Name",
        key: "label-styles",
        weight: 90,
        components: new StyleComponentForm("Name").jsonElement()
      }
    ],
    ...extend
  );
};
