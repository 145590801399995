import baseEditForm from "formiojs/components/_classes/component/Component.form";
import selectData from "./SelectCustom.data.form";
import selectDisplay from "./SelectCustom.display.form";
import StyleComponentForm from "../../StyleComponent.form";

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "display",
        components: selectDisplay.default,
      },
      {
        key: "data",
        components: selectData.default,
      },
      {
        key: "api",
        ignore: true,
      },
      {
        label: "Component",
        key: "component-styles",
        weight: 80,
        components: new StyleComponentForm("Component").jsonElement(),
      },
      {
        label: "Name",
        key: "label-styles",
        weight: 90,
        components: new StyleComponentForm("Name").jsonElement(),
      },
      {
        label: "Box",
        key: "box-styles",
        weight: 100,
        components: new StyleComponentForm("Box").jsonElement(),
      },
    ],
    ...extend
  );
};

// "use strict";

// require("core-js/modules/es.array.concat");

// Object.defineProperty(exports, "__esModule", {
//   value: true,
// });
// exports.default = _default;

// var _Component = _interopRequireDefault(
//   require("formiojs/components/_classes/component/Component.form")
// );
// var _SelectEdit = _interopRequireDefault(require("./SelectCustom.data.form"));
// var _SelectEdit2 = _interopRequireDefault(
//   require("./SelectCustom.display.form")
// );

// var _SelectEdit3 = _interopRequireDefault(
//   require("formiojs/components/select/editForm/Select.edit.validation")
// );

// function _interopRequireDefault(obj) {
//   return obj && obj.__esModule ? obj : { default: obj };
// }

// function _default() {
//   console.log(_SelectEdit.default);
//   for (
//     var _len = arguments.length, extend = new Array(_len), _key = 0;
//     _key < _len;
//     _key++
//   ) {
//     extend[_key] = arguments[_key];
//   }

//   return _Component.default.apply(
//     void 0,
//     [
//       [
//         {
//           key: "display",
//           components: _SelectEdit2.default,
//         },
//         {
//           key: "data",
//           components: _SelectEdit.default,
//         },
//         {
//           key: "validation",
//           components: _SelectEdit3.default,
//         },
//       ],
//     ].concat(extend)
//   );
// }
