import {
  DELETE_USER,
  DELETE_CENTRE,
  DELETE_CRF,
  CLOSE_CRF
} from "../../mutations";

export const headers = {
  users: {
    headers: ["table.username", "table.name", "userTable.role"],
    data: ["username", "name", "role"],
    formComponent: "user",
    deleteMutation: DELETE_USER
  },
  centres: {
    headers: ["table.name", "address", "phone", "code", "inaugurated"],
    data: ["name", "address", "phone", "code", "inaugurated"],
    formComponent: "centre",
    deleteMutation: DELETE_CENTRE
  },
  crfs: {
    data: ["name"],
    formComponent: "crf",
    deleteMutation: CLOSE_CRF
  }
};
