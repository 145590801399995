import React from "react";
import { withTranslation } from "react-i18next";
import { mediaFilesAppend } from "../../../config";
import { client } from "../../../apolloClient";
import {
  Dialog,
  Switch,
  Button,
  FormGroup,
  InputGroup,
  MenuItem,
  Icon,
  Callout,
  Intent,
  Spinner,
} from "@blueprintjs/core";
import { MultiSelect } from "@blueprintjs/select";
import { Navigate  } from "react-router-dom";
import Dropzone from "react-dropzone";
import Breadcrumb from "../../../components/Breadcrumb";
import { GET_USER, GET_CENTRES, GET_CRFS } from "../../../queries";
import { CREATE_USER, UPDATE_USER } from "../../../mutations";
import "./_Create.scss";

class UserForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        id: "",
        username: "",
        avatar: [],
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        repeatPassword: "",
        oldPassword: "",
        role: "",
        recieveNotifications: false,
        recieveNotificationsPatients: false,
        active: true,
        centres: [],
        crfs: [],
      },
      isEdit: false,
      loading: false,
      redirect: false,
      errorMsg: null,
      notEqualsPasswords: false,
      centres: [],
      crfs: [],
    };

    this.handleClose = this.handleClose.bind(this);
    this.resetForm = this.resetForm.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.state !== prevProps.state && this.props.isOpen) {
      try {
        client
          .query({
            query: GET_CENTRES,
            variables: { elementsPerPage: 1000 },
            fetchPolicy: "network-only",
          })
          .then((res) => {
            let centres = [];
            res.data.centres.objects.forEach((value, index) => {
              centres.push(value);
            });
            this.setState({ centres: centres });
          });

        client
          .query({
            query: GET_CRFS,
            variables: { elementsPerPage: 1000 },
            fetchPolicy: "network-only",
          })
          .then((res) => {
            let crfs = [];
            res.data.crfs.objects.forEach((value, index) => {
              crfs.push(value);
            });
            this.setState({ crfs: crfs });
          });

        if (this.props.state.entity) {
          this.setState({ loading: true });
          const variables = { id: this.props.state.entity.id };
          client
            .query({
              variables: variables,
              query: GET_USER,
              fetchPolicy: "network-only",
            })
            .then((res) => {
              let userCentres = [];
              let userCRFS = [];
              res.data.user.centres.edges.forEach((value, index) => {
                userCentres.push(value.node);
              });

              res.data.user.crfs.edges.forEach((value, index) => {
                userCRFS.push(value.node);
              });

              this.setState((prevState) => ({
                user: {
                  ...prevState.user,
                  ...res.data.user,
                  centres: userCentres,
                  crfs: userCRFS,
                },
                isEdit: true,
                loading: false,
                errorMsg: null
              }));
            });
        } else {
          this.setState((prevState) => ({...prevState, isEdit:false, errorMsg: null}));
        }
      } catch (err) {}
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.state.user.username && this.state.user.email && this.state.user.role) {
      if (this.state.user.password !== this.state.user.repeatPassword) {
        this.setState({ notEqualsPasswords: true });
      } else {
        let centresId = [];
        let crfsId = [];
        this.state.user.centres.forEach((value) => {
          centresId.push(value.id);
        });
  
        this.state.user.crfs.forEach((value) => {
          crfsId.push(value.id);
        });
  
        this.setState({ loading: true });
        const mutation = this.state.isEdit ? UPDATE_USER : CREATE_USER;
        const variables = {
          username: this.state.user.username,
          avatar:
            typeof this.state.user.avatar === "object"
              ? this.state.user.avatar[0]
              : this.state.user.avatar,
          firstName: this.state.user.firstName,
          lastName: this.state.user.lastName,
          email: this.state.user.email,
          password: this.state.user.password,
          role: this.state.user.role,
          recieveNotifications: this.state.user.recieveNotifications,
          recieveNotificationsPatients: this.state.user
            .recieveNotificationsPatients,
          active: this.state.user.active,
          centres: centresId,
          crfs: crfsId,
        };
  
        if (this.state.isEdit) {
          variables.id = this.state.user.id;
          variables.oldPassword = this.state.user.oldPassword;
        }
        client
          .mutate({
            variables,
            mutation,
          })
          .then((res, err) => {
            if (err) console.log("error");
            if (
              (this.state.isEdit == false && res.data.createUser.ok) ||
              (this.state.isEdit && res.data.updateUser.ok)
            ) {
              this.setState(
                { loading: false, errorMsg: null, redirect: true },
                this.props.onCreateSubmit(),
                this.resetForm()
              );
            } else {
              this.setState({
                loading: false,
                errorMsg: res.data.updateUser.msg,
              });
            }
          }).catch((err) => {
            this.setState({
              loading: false,
              errorMsg: err.message,
            });
          });
      }
    } else {
      this.setState({ errorMsg: this.props.t("form.required") });
    }
    
  };

  onDropAvatar = (avatar) => {
    this.setState((prevState) => ({ user: { ...prevState.user, avatar } }));
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        [name]: value,
      },
    }));
  };

  handleChangeSwitch = (event) => {
    const { name, value } = event.target;

    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        [name]: !this.state.user[name],
      },
    }));
  };

  /******* multiselect functions *************/

  renderItem = (item, { modifiers, handleClick }) => {
    return (
      <MenuItem
        active={modifiers.active}
        key={item.id}
        label={item.code}
        onClick={handleClick}
        text={item.name}
        shouldDismissPopover={false}
      />
    );
  };

  handleTagRemove = (_tag, index) => {
    this.deselectCentre(index);
  };

  getSelectedCentreIndex(centre) {
    let index = -1;
    this.state.user.centres.forEach((value, key) => {
      if (value.id == centre.id) index = key;
    });
    return index;
  }

  isCentreSelected(centre) {
    return this.getSelectedCentreIndex(centre) !== -1;
  }

  selectCentre(centre) {
    this.selectCentres([centre]);
  }

  selectCentres(centresToSelect) {
    const centres = this.state.user.centres;
    let nextCentres = centres.slice();

    centresToSelect.forEach((centre) => {
      if (!this.isCentreSelected(centre)) {
        nextCentres.push(centre);
      }
    });

    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        centres: nextCentres,
      },
    }));
  }

  areCentreEquals = (a, b) => {
    let equal = a.id === b.id ? true : false;

    return equal;
  };

  deselectCentre(index) {
    const centres = this.state.user.centres;

    centres.splice(index, 1);

    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        centres: centres,
      },
    }));
  }

  handleCentreSelect = (centre) => {
    if (!this.isCentreSelected(centre)) {
      this.selectCentre(centre);
    } else {
      this.deselectCentre(this.getSelectedCentreIndex(centre));
    }
  };

  handleTagRemove = (_tag, index) => {
    this.deselectCentre(index);
  };

  handleFilterCentre = (query, centre) =>
    centre.name.indexOf(query) >= 0

  /******  CRF multiselect   ****/

  getSelectedCRFIndex(crf) {
    let index = -1;
    this.state.user.crfs.forEach((value, key) => {
      if (value.id == crf.id) index = key;
    });
    return index;
  }

  isCRFSelected(crf) {
    return this.getSelectedCRFIndex(crf) !== -1;
  }

  selectCRF(crf) {
    this.selectCRFS([crf]);
  }

  selectCRFS(crfToSelect) {
    const crfs = this.state.user.crfs;
    let nextCrfs = crfs.slice();

    crfToSelect.forEach((crf) => {
      if (!this.isCRFSelected(crf)) {
        nextCrfs.push(crf);
      }
    });

    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        crfs: nextCrfs,
      },
    }));
  }

  deselectCRF(index) {
    const crfs = this.state.user.crfs;

    crfs.splice(index, 1);

    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        crfs: crfs,
      },
    }));
  }

  handleCRFSelect = (crf) => {
    if (!this.isCRFSelected(crf)) {
      this.selectCRF(crf);
    } else {
      this.deselectCRF(this.getSelectedCentreIndex(crf));
    }
  };

  handleTagCRFRemove = (_tag, index) => {
    this.deselectCRF(index);
  };

  handleFilterCRF = (query, crf) =>
    crf.name.indexOf(query) >= 0

  handleClose() {
    this.resetForm();
    this.props.toggleFunction();
  }

  resetForm() {
    this.setState({
      user: {
        id: "",
        username: "",
        avatar: [],
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        repeatPassword: "",
        oldPassword: "",
        recieveNotifications: false,
        recieveNotificationsPatients: false,
        role: "",
        centres: [],
        crfs: [],
        active: true,
      },
    });
  }

  render() {
    if (this.state.loading) {
      if (this.state.redirect) {
        return <Navigate to="/users" />;
      }
    }

    return (
      <Dialog
        icon="home"
        onClose={this.handleClose}
        title={this.props.t("createUser")}
        title={
          Object.keys(this.props.state).length > 0
            ? this.props.t("modifyUser")
            : this.props.t("createUser")
        }
        isOpen={this.props.isOpen}
        style={{ width: 780, "padding-bottom": "unset" }}
      >
        <div className="container">
          {this.state.loading && (
            <div className="spinner-loader-listing">
              <Spinner
                size={80}
                spinnerColor={"#007BFF"}
                spinnerWidth={2}
                visible={true}
              />
            </div>
          )}
          {!this.state.loading && (
            <form onSubmit={this.handleSubmit}>
              <div className="row">
                {this.state.errorMsg && (
                  <Callout intent={Intent.DANGER}>
                    {this.state.errorMsg}
                  </Callout>
                )}
                <div className="first-cont col">
                  <h5 className="menu-titles">{this.props.t("datas")}</h5>
                  <div>
                    <FormGroup
                      label={this.props.t("userForm.username")}
                      labelFor="username"
                      labelInfo={`(${this.props.t("required")})`}
                    >
                      <InputGroup
                        id="username"
                        name="username"
                        autoComplete="off"
                        onChange={this.handleChange}
                        value={this.state.user.username}
                        placeholder={this.props.t("userForm.username")}
                        large={true}
                      />
                    </FormGroup>
                    <FormGroup
                      label={this.props.t("userForm.firstName")}
                      labelFor="firstName"
                    >
                      <InputGroup
                        id="firstName"
                        name="firstName"
                        onChange={this.handleChange}
                        value={this.state.user.firstName}
                        placeholder={this.props.t("userForm.firstName")}
                        large={true}
                      />
                    </FormGroup>
                    <FormGroup
                      label={this.props.t("userForm.lastName")}
                      labelFor="lastName"
                    >
                      <InputGroup
                        id="lastName"
                        name="lastName"
                        onChange={this.handleChange}
                        value={this.state.user.lastName}
                        placeholder={this.props.t("userForm.lastName")}
                        large={true}
                      />
                    </FormGroup>
                    <FormGroup
                      label={this.props.t("userForm.email")}
                      labelFor="email"
                      labelInfo={`(${this.props.t("required")})`}
                    >
                      <InputGroup
                        id="email"
                        name="email"
                        type="email"
                        onChange={this.handleChange}
                        value={this.state.user.email}
                        placeholder={this.props.t("userForm.email")}
                        large={true}
                      />
                    </FormGroup>
                    <Dropzone
                      className="w-100"
                      accept="image/jpeg, image/png"
                      multiple={false}
                      onDrop={this.onDropAvatar}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section className="drop-zone">
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            {((Array.isArray(this.state.user.avatar) &&
                              this.state.user.avatar.length === 0) ||
                              !this.state.user.avatar) && (
                              <div>
                                <Icon icon="cloud-upload" iconSize={40} />
                                <p className="dropzone-solid-blue">
                                  {this.props.t("uploadText")}
                                </p>
                              </div>
                            )}
                            {typeof this.state.user.avatar === "object" && (
                              <img
                                style={{ width: "50px" }}
                                src={window.URL.createObjectURL(
                                  new File(this.state.user.avatar, {
                                    type: "image/png",
                                  })
                                )}
                                alt=""
                              />
                            )}
                            {typeof this.state.user.avatar === "string" && (
                              <img
                                style={{ width: "50px" }}
                                src={mediaFilesAppend + this.state.user.avatar}
                                alt=""
                              />
                            )}
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                </div>
                <div className="second-cont col">
                  <h5 className="menu-titles">{this.props.t("security")}</h5>
                  <div>
                    {this.state.isEdit != false && (
                      <FormGroup
                        label={this.props.t("userForm.oldPassword")}
                        labelFor="oldPassword"
                      >
                        <InputGroup
                          id="oldPassword"
                          name="oldPassword"
                          type="password"
                          onChange={this.handleChange}
                          placeholder={this.props.t("userForm.oldPassword")}
                          value={this.state.user.oldPassword}
                          large={true}
                        />
                      </FormGroup>
                    )}
                    <FormGroup
                      label={this.props.t("userForm.password")}
                      labelFor="password"
                      labelInfo={
                        !this.state.isEdit
                          ? `(${this.props.t("required")})`
                          : ""
                      }
                    >
                      <InputGroup
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="off"
                        onChange={this.handleChange}
                        placeholder={this.props.t("userForm.password")}
                        value={this.state.user.password}
                        large={true}
                      />
                    </FormGroup>
                    <FormGroup
                      label={this.props.t("userForm.repeatPassword")}
                      labelFor="repeatPassword"
                      labelInfo={
                        !this.state.isEdit
                          ? `(${this.props.t("required")})`
                          : ""
                      }
                    >
                      <InputGroup
                        id="repeatPassword"
                        name="repeatPassword"
                        type="password"
                        onChange={this.handleChange}
                        placeholder={this.props.t("userForm.repeatPassword")}
                        value={this.state.user.repeatPassword}
                        large={true}
                      />
                    </FormGroup>
                  </div>

                  <hr className="user-middle-line" />
                  <h5 className="menu-titles">{this.props.t("permissions")}</h5>
                  <div className="">
                    <div className="bp3-select bp3-fill bp3-large dropdown">
                      <select
                        id="role"
                        onChange={this.handleChange}
                        name="role"
                        value={this.state.user.role}
                        required={true}
                      >
                        <option selected>Choose an item...</option>
                        <option value="SA">Sysadmin</option>
                        <option value="AD">Admin</option>
                        <option value="LM">Lead monitor</option>
                        <option value="MO">Monitor</option>
                        <option value="DM">Data manager</option>
                        <option value="IN">Investigator</option>
                        <option value="RO">Read only</option>
                      </select>
                    </div>

                    <FormGroup
                      label={this.props.t("centres")}
                      labelFor="centres"
                    >
                      <MultiSelect
                        className="bp3-large"
                        items={this.state.centres}
                        itemRenderer={this.renderItem}
                        itemsEqual={this.areCentreEquals}
                        onItemSelect={this.handleCentreSelect}
                        tagRenderer={(item) => item.name}
                        selectedItems={this.state.user.centres}
                        tagInputProps={{ onRemove: this.handleTagRemove }}
                        itemPredicate={this.handleFilterCentre}
                        fill={true}
                        large={true}
                      />
                    </FormGroup>
                    <FormGroup label={this.props.t("crfs")} labelFor="crfs">
                      <MultiSelect
                        className="bp3-large"
                        items={this.state.crfs}
                        itemRenderer={this.renderItem}
                        itemsEqual={this.areCentreEquals}
                        onItemSelect={this.handleCRFSelect}
                        tagRenderer={(item) => item.name}
                        selectedItems={this.state.user.crfs}
                        tagInputProps={{ onRemove: this.handleTagCRFRemove }}
                        itemPredicate={this.handleFilterCRF}
                        fill={true}
                        large={true}
                      />
                    </FormGroup>
                    <div>
                      <Switch
                        checked={this.state.user.active}
                        label={this.props.t("active")}
                        onChange={this.handleChangeSwitch}
                        name="active"
                      />
                      <Switch
                        checked={this.state.user.recieveNotifications}
                        label={this.props.t("recieveNotifications")}
                        onChange={this.handleChangeSwitch}
                        name="recieveNotifications"
                      />
                      <Switch
                        checked={this.state.user.recieveNotificationsPatients}
                        label={this.props.t("recieveNotificationsPatients")}
                        onChange={this.handleChangeSwitch}
                        name="recieveNotificationsPatients"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <hr className="user-footer-line" />
              <div className="row">
                <div className="col">
                  {this.state.notEqualsPasswords && (
                    <p className="error-required-icon">
                      {this.props.t("passwordsNotEquals")}
                    </p>
                  )}
                </div>
                <div className="col">
                  <Button
                    className="user-close-button"
                    type="button"
                    onClick={this.handleClose}
                  >
                    {this.props.t("cancel")}
                  </Button>
                  <Button className="addSub" type="submit" intent="success">
                    {Object.keys(this.props.state).length > 0
                      ? this.props.t("form.update")
                      : this.props.t("form.create")}
                  </Button>
                </div>
              </div>
            </form>
          )}
          {/* </Card> */}
        </div>
      </Dialog>
    );
  }
}

export default withTranslation()(UserForm);
