import React from "react";
import { withTranslation } from "react-i18next";
import { client } from "../../../apolloClient";
import {
  Card,
  Elevation,
  Button,
  FormGroup,
  InputGroup,
  MenuItem,
  Icon,
  Checkbox,
  Spinner,
  Dialog,
  Classes,
  Intent,
} from "@blueprintjs/core";
import { MultiSelect } from "@blueprintjs/select";
import { Navigate  } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import {
  UPDATE_VISIT,
  DELETE_SHEET,
  CREATE_TEMPLATE,
} from "../../../mutations";
import { GET_VISIT, GET_CENTRES } from "../../../queries";
import {
  TEMPLATE_CONTAINER,
  TEMPLATE_SHEET,
  TEMPLATE_VISIT,
} from "../../../config";
import { FormBuilder, Components } from "react-formio";
import options from "../../../data/formio-options.json";
import ConfirmDeleteModal from "../../../components/ModalDelete/ConfirmDeleteModal";
import { AppToaster } from "../../../components/Toaster";
import components from "../../../formioCustom";
import utils from "formiojs/utils";
import ModalBranchingCode from "../BranchingCode";
import html2pdf from 'html2pdf.js'

import "./_Edit.scss";

Components.setComponents(components);

class VisitForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visit: {
        id: "",
        name: "",
        codeBranch: "",
        branch: "",
        centres: [],
        sheets: [],
        crf: { id: "" },
      },
      template: {
        name: "",
        templateType: "",
      },
      isEdit: false,
      loading: true,
      redirect: false,
      selectedSheet: 0,
      centres: [],
      toDeleteName: "",
      toDeleteIndex: "",
      toDeleteId: "",
      deleteFunc: "",
      showBranch: false,
      isModalOpen: false,
      isModalCodeOpen: false,
      formioKey: "",
      options: options,
      submitting: false,
    };

    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
  }

  

  componentDidMount() {
    this.fetch();
  }

  fetch = () => {
    try {
      client.query({ query: GET_CENTRES }).then((res) => {
        let centres = [];
        res.data.centres.objects.forEach((value, index) => {
          centres.push(value);
        });
        this.setState({ centres: centres });
      });
      if (window.location.href.split('/')[5]) {
        this.setState({ loading: true });
        const variables = { id: window.location.href.split('/')[5] };

        client
          .query({
            variables: variables,
            query: GET_VISIT,
            fetchPolicy: "network-only",
          })
          .then((res) => {
            let visitCentres = [];
            let visitSheets = [];
            res.data.visit.centres.edges.forEach((value, index) => {
              visitCentres.push(value.node);
            });

            // Sets the crf id to the options schema so it only gets templates from that crf and saves templates on that CRF
            options.builder.template.components.crf.schema.crf =
              res.data.visit.crf.id;
            options.builder.containers.components.container_custom.schema.crf =
              res.data.visit.crf.id;
            options.builder.containers.components.columns_custom.schema.crf =
              res.data.visit.crf.id;
            options.builder.containers.components.table_custom.schema.crf =
              res.data.visit.crf.id;
            options.builder.containers.components.panel_custom.schema.crf =
              res.data.visit.crf.id;
            options.builder.custom.components.reference.schema.crf =
              res.data.visit.crf.id;

            options.builder.customBasic.components.textfield_custom.schema.crf =
              res.data.visit.crf.id;
            options.builder.customBasic.components.textarea_custom.schema.crf =
              res.data.visit.crf.id;
            options.builder.customBasic.components.number_custom.schema.crf =
              res.data.visit.crf.id;
            options.builder.customBasic.components.currency_custom.schema.crf =
              res.data.visit.crf.id;
            options.builder.customBasic.components.datetime_custom.schema.crf =
              res.data.visit.crf.id;
            options.builder.customBasic.components.time_custom.schema.crf =
              res.data.visit.crf.id;
            options.builder.custom.components.select_custom.schema.crf =
              res.data.visit.crf.id;
            options.builder.custom.components.radio_custom.schema.crf =
              res.data.visit.crf.id;

            if (res.data.visit.sheets.edges.length == 0) {
              const sheet = {
                name: `Sheet ${this.state.visit.sheets.length + 1}`,
                formBuild: { display: "form" },
              };
              visitSheets.push(sheet);
            } else {
              res.data.visit.sheets.edges.forEach((value, index) => {
                if (value.node.formBuild) {
                  // JSON Parse ERROR triggering here, but without any sense because it parses the json. That's why we catch the error
                  try {
                    value.node.formBuild = JSON.parse(value.node.formBuild);
                  } catch (err) {}
                }

                visitSheets.push(value.node);
              });
            }

            this.setState((prevState) => ({
              visit: {
                ...prevState.visit,
                ...res.data.visit,
                centres: visitCentres,
                sheets: visitSheets,
              },
              isEdit: true,
              loading: false,
              showBranch: res.data.visit.branch ? true : false,
              loading: false,
            }));
          });
      }
    } catch (err) {}
  };

  // Delete section

  toggleDeleteModal = (
    toDeleteIndex = "",
    toDeleteId = "",
    toDeleteName = ""
  ) => {
    if (this.state.modalOpened) {
      this.setState({
        modalOpened: !this.state.modalOpened,
      });
    } else {
      this.setState(
        {
          toDeleteIndex,
          toDeleteId,
          toDeleteName,
        },
        () => this.setState({ modalOpened: !this.state.modalOpened })
      );
    }
  };

  deleteItem = (index, id) => {
    if (id && typeof id != "undefined") {
      client.mutate({
        variables: { id },
        mutation: DELETE_SHEET,
      });
    }
    this.toggleDeleteModal();
    let sheets = this.state.visit.sheets.slice();
    sheets.splice(index, 1);

    this.setState((prevState) => ({
      visit: {
        ...prevState.visit,
        sheets: sheets,
      },
      selectedSheet: 0,
    }));
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    if (this.state.submitting === false) {
      this.setState({ submitting: true });
      const sheets = this.state.visit.sheets.map((value, index) => {
        let sheet = {
          name: value.name,
          formBuild: JSON.stringify(value.formBuild),
        };
        if (value.id) sheet.id = value.id;

        return sheet;
      });

      let centresId = [];

      this.state.visit.centres.forEach((value) => {
        centresId.push(value.id);
      });

      const mutation = UPDATE_VISIT;
      const variables = {
        name: this.state.visit.name,
        observations: this.state.visit.observations,
        branch: this.state.visit.branch,
        finishingVisit: this.state.visit.finishingVisit,
        id: window.location.href.split('/')[5],
        sheets: sheets,
        centres: centresId,
      };

      client
        .mutate({
          variables,
          mutation,
          errorPolicy: "none",
        })
        .then((res, err) => {
          if (err) {
            this.showToast(this.props.t("visit.error"), "danger");
          } else {
            this.showToast(this.props.t("visit.saved"));
            this.fetch();
          }
          this.setState({ submitting: false });
        })
        .catch((err) => {
          this.showToast(this.props.t("visit.error"), "danger");
          this.setState({ submitting: false });
        });
    }
  };

  showToast = (message, intent = "success") => {
    // create toasts in response to interactions.
    // in most cases, it's enough to simply create and forget (thanks to timeout).
    AppToaster.show({
      message: message,
      intent: intent,
      timeout: 10000,
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      visit: {
        ...prevState.visit,
        [name]: value,
      },
    }));
  };

  handleChangeCheckbox = (event) => {
    let { name, value } = event.target;
    this.setState((prevState) => ({
      visit: {
        ...prevState.visit,
        [name]: !this.state.visit[name],
      },
    }));
  };

  handleNewSheet = (event) => {
    const sheets = this.state.visit.sheets.slice();
    const sheet = {
      name: `Sheet ${this.state.visit.sheets.length + 1}`,
      formBuild: { display: "form" },
    };
    sheets.push(sheet);
    this.setState((prevState) => ({
      visit: {
        ...prevState.visit,
        sheets: sheets,
      },
    }));
  };

  handleSheetNameChange = (event) => {
    const { name, value } = event.target;
    const sheets = this.state.visit.sheets.slice();
    sheets[this.state.selectedSheet].name = value;
    this.setState((prevState) => ({
      visit: {
        ...prevState.visit,
        sheets: sheets,
      },
    }));
  };

  handleSheetChange = (index) => {
    this.setState({ selectedSheet: index });
  };

  handleSheetFormChange = (schema) => {
    if (this.state.visit.sheets.length > 0) {
      const sheets = this.state.visit.sheets.slice();
      sheets[this.state.selectedSheet].formBuild = schema;

      // Hack for custom template formio
      if (this.state.formioKey !== "") {
        let component = utils.getComponent(
          schema.components,
          this.state.formioKey
        );
        if (typeof component !== "undefined") {
          component.template = "";
          component.type = "container";
        }
      }

      this.setState((prevState) => ({
        visit: {
          ...prevState.visit,
          sheets: sheets,
        },
      }));
    }
  };

  handleSheetComponentSave = (component) => {
    if (component.type === "template") {
      component.template = "";
      component.type = "container";
      this.setState({ formioKey: component.key });
    } else this.setState({ formioKey: "" });
  };

  /********* Template saving **********/

  handleSaveTemplate = (type) => {
    this.setState((prevState) => ({
      template: { ...prevState.template, templateType: type },
    }));
    this.handleOpen();
  };

  handleTemplateSubmit = async (e) => {
    e.preventDefault();

    let variables = {
      name: this.state.template.name,
      id: this.state.visit.crf.id,
      templateType: this.state.template.templateType,
    };

    if (this.state.template.templateType == TEMPLATE_VISIT) {
      const sheets = this.state.visit.sheets.map((value, index) => {
        let sheet = {
          name: value.name,
          formBuild: JSON.stringify(value.formBuild),
        };
        if (value.id) sheet.id = value.id;

        return sheet;
      });

      variables.sheets = sheets;
    } else {
      variables.formBuild = JSON.stringify(
        this.state.visit.sheets[this.state.selectedSheet].formBuild
      );
    }

    client
      .mutate({
        variables,
        mutation: CREATE_TEMPLATE,
      })
      .then((res, err) => {
        if (err) console.log(err);
        this.setState({ loading: false, redirect: false });
        this.showToast(this.props.t("template.saved"));
        this.handleClose();
      });
  };

  handleOpen = () =>
    this.setState((prevState) => ({ ...prevState, isModalOpen: true }));
  handleClose = () =>
    this.setState((prevState) => ({ ...prevState, isModalOpen: false }));

  /********* Save code for visit *************/

  toogleCodeModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      isModalCodeOpen: !this.state.isModalCodeOpen,
    }));
  };

  /******* multiselect functions *************/

  renderItem = (item, { modifiers, handleClick }) => {
    return (
      <MenuItem
        active={modifiers.active}
        key={item.id}
        label={item.name}
        onClick={handleClick}
        text={item.name}
        shouldDismissPopover={false}
      />
    );
  };

  handleTagRemove = (_tag, index) => {
    this.deselectCentre(index);
  };

  getSelectedCentreIndex(centre) {
    let index = -1;
    this.state.visit.centres.forEach((value, key) => {
      if (value.id == centre.id) index = key;
    });
    return index;
  }

  isCentreSelected(centre) {
    return this.getSelectedCentreIndex(centre) !== -1;
  }

  selectCentre(centre) {
    this.selectCentres([centre]);
  }

  selectCentres(centresToSelect) {
    const centres = this.state.visit.centres;
    let nextCentres = centres.slice();

    centresToSelect.forEach((centre) => {
      if (!this.isCentreSelected(centre)) {
        nextCentres.push(centre);
      }
    });

    this.setState((prevState) => ({
      visit: {
        ...prevState.visit,
        centres: nextCentres,
      },
    }));
  }

  areCentreEquals = (a, b) => {
    let equal = a.id === b.id ? true : false;

    return equal;
  };

  deselectCentre(index) {
    const centres = this.state.visit.centres;

    centres.splice(index, 1);

    this.setState((prevState) => ({
      visit: {
        ...prevState.visit,
        centres: centres,
      },
    }));
  }

  handleCentreSelect = (centre) => {
    if (!this.isCentreSelected(centre)) {
      this.selectCentre(centre);
    } else {
      this.deselectCentre(this.getSelectedCentreIndex(centre));
    }
  };

  handleTagRemove = (_tag, index) => {
    this.deselectCentre(index);
  };

  handleDownloadPdf = () => {
    var elements = document.getElementsByClassName('formio-form');
    var opt = {
      margin:       0.5,
      filename:     'myfile.pdf',
      image:        { type: 'jpeg', quality: 0.98 },
      html2canvas:  { scale: 2, scrollX: 0, scrollY:0 },
      jsPDF:        { unit: 'cm', orientation: 'portrait', precision: 1 },
      pagebreak:    { after: '.page-break' },
    };
    // New Promise-based usage:
    html2pdf().set(opt).from(elements[0]).save();
  }

  render() {
    const capitalize = (s) => {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    };

    if (!this.state.loading) {
      if (this.state.redirect) {
        return <Navigate to={`/crf/${this.state.visit.crf.id}`} />;
      }
    } else {
      return (
        <div className="container-fluid pt-5">
          <div className="spinner-loader-listing pt-5">
            <Spinner
              size={160}
              spinnerColor={"#007BFF"}
              spinnerWidth={10}
              visible={true}
            />
          </div>
        </div>
      );
    }
    return (
      <div className="container-fluid">
        <Breadcrumb
          crumbs={[
            { link: "/", name: "Home", active: false },
            {
              link: "/crfs",
              name: this.props.t("crfs.title"),
              active: false,
            },
            {
              link: `/crf/${this.state.visit.crf.id}`,
              name: capitalize(this.state.visit.crf.name),
              active: false,
            },
            {
              link: "",
              name: this.props.t(this.props.title),
              active: true,
            },
          ]}
        />
        <Card interactive={true} elevation={Elevation.TWO} id="visit">
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-sm-9">
                <h4 className="mb-3">
                  <a href="#">{this.props.t(this.props.title)}</a>
                </h4>
              </div>

              <div className="col-sm-3 text-right">
                <Button
                  className="mr-2"
                  minimal
                  large={true}
                  icon="floppy-disk"
                  title={this.props.t("save")}
                  type="submit"
                />
                <Button
                  className="mr-2"
                  minimal
                  large={true}
                  icon="code"
                  title={this.props.t("add.code")}
                  onClick={() => this.toogleCodeModal()}
                />
                <Button
                  minimal
                  large={true}
                  icon="duplicate"
                  title={this.props.t("convert.template")}
                  onClick={() => this.handleSaveTemplate(TEMPLATE_VISIT)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <FormGroup
                  label={this.props.t("name")}
                  labelFor="name"
                  labelInfo="(required)"
                >
                  <InputGroup
                    id="name"
                    name="name"
                    onChange={this.handleChange}
                    value={this.state.visit.name}
                    placeholder={this.props.t("name")}
                    large={true}
                  />
                </FormGroup>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <MultiSelect
                  className="bp3-large"
                  items={this.state.centres}
                  itemRenderer={this.renderItem}
                  itemsEqual={this.areCentreEquals}
                  onItemSelect={this.handleCentreSelect}
                  tagRenderer={(item) => item.name}
                  selectedItems={this.state.visit.centres}
                  tagInputProps={{ onRemove: this.handleTagRemove }}
                  fill={true}
                  large={true}
                />
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col">
                <Checkbox
                  name="finishingVisit"
                  id="finishingVisit"
                  large={true}
                  checked={this.state.visit.finishingVisit}
                  label={this.props.t("formVisit.finishingVisit")}
                  onChange={this.handleChangeCheckbox}
                />
              </div>
              <div className="col">
                <Checkbox
                  name="showBranch"
                  id="showBranch"
                  large={true}
                  checked={this.state.showBranch}
                  label={this.props.t("formVisit.branch")}
                  onChange={(e) => {
                    this.setState({ showBranch: !this.state.showBranch });
                  }}
                />
              </div>
              <div className="col">
                <FormGroup
                  style={
                    this.state.showBranch
                      ? { display: "block" }
                      : { display: "none" }
                  }
                  label={this.props.t("branch")}
                  labelFor="branch"
                >
                  <InputGroup
                    id="branch"
                    name="branch"
                    onChange={this.handleChange}
                    value={this.state.visit.branch ? this.state.visit.branch: "" }
                    placeholder={this.props.t("branch")}
                    large={true}
                  />
                </FormGroup>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col">
                <Button
                  className="mr-3"
                  minimal
                  large={false}
                  onClick={this.handleNewSheet}
                >
                  <Icon icon="plus" />
                </Button>
                {this.state.visit.sheets.map((sheet, index) => (
                  <Button
                    key={`sheet-${index}`}
                    className="mr-3 mb-2"
                    large={false}
                    intent={
                      this.state.selectedSheet === index ? "none" : "primary"
                    }
                    style={{ fontSize: "14px" }}
                    onClick={() => this.handleSheetChange(index)}
                  >
                    {sheet.name}
                  </Button>
                ))}
              </div>
            </div>
          </form>
        </Card>
        <Card className="mt-4" interactive={true} elevation={Elevation.TWO}>
          <div className="row pt-2 pb-3">
            <div className="col-sm-9">
              <InputGroup
                id="sheet-name"
                name="name"
                onChange={this.handleSheetNameChange}
                value={
                  this.state.visit.sheets.length > 0
                    ? this.state.visit.sheets[this.state.selectedSheet].name
                    : ""
                }
                placeholder={this.props.t("name")}
                large={true}
              />
            </div>
            <div className="col text-right">
              <Button
                className="mr-2"
                minimal
                large={true}
                icon="download"
                title={this.props.t("download.pdf")}
                onClick={this.handleDownloadPdf}
              />

              <Button
                className="mr-2"
                minimal
                large={true}
                icon="duplicate"
                title={this.props.t("convert.template")}
                onClick={() => this.handleSaveTemplate(TEMPLATE_SHEET)}
              />

              <Button
                minimal
                large={true}
                icon="remove"
                title={this.props.t("table.delete")}
                onClick={() =>
                  this.toggleDeleteModal(
                    this.state.selectedSheet,
                    this.state.visit.sheets[this.state.selectedSheet].id,
                    this.state.visit.sheets[this.state.selectedSheet].name
                  )
                }
              />
            </div>
          </div>
          <FormBuilder
            form={
              this.state.visit.sheets.length > 0 &&
              this.state.visit.sheets[this.state.selectedSheet].formBuild
                ? this.state.visit.sheets[this.state.selectedSheet].formBuild
                : '{ display: "form" }'
            }
            options={options}
            onChange={this.handleSheetFormChange}
            onSaveComponent={this.handleSheetComponentSave}
          />
        </Card>
        <ConfirmDeleteModal
          isOpen={this.state.modalOpened}
          toggleFunction={this.toggleDeleteModal}
          toDeleteId={this.state.toDeleteId}
          toDeleteIndex={this.state.toDeleteIndex}
          refetchFunction={this.refetch}
          toDeleteName={this.state.toDeleteName}
          deleteFunc={this.deleteItem}
          mutation={DELETE_SHEET}
          t={this.props.t}
        />
        <ModalBranchingCode
          isOpen={this.state.isModalCodeOpen}
          toggleFunction={this.toogleCodeModal}
          initialCode={this.state.visit.codeBranch}
          visitId={window.location.href.split('/')[5]}
          t={this.props.t}
        />

        <Dialog
          icon="info-sign"
          onClose={this.handleClose}
          title={this.props.t("templateSave")}
          isOpen={this.state.isModalOpen}
        >
          <form onSubmit={this.handleTemplateSubmit}>
            <div className={Classes.DIALOG_BODY}>
              <FormGroup
                label={this.props.t("name")}
                labelFor="name"
                labelInfo="(required)"
              >
                <InputGroup
                  required={true}
                  id="name"
                  name="name"
                  onChange={(event) => {
                    const { name, value } = event.target;
                    this.setState((prevState) => ({
                      template: {
                        ...prevState.template,
                        [name]: value,
                      },
                    }));
                  }}
                  value={this.state.template.name}
                  placeholder={this.props.t("name")}
                  large={true}
                />
              </FormGroup>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button onClick={() => this.handleClose()}>
                  {this.props.t("cancel")}
                </Button>

                <Button intent={Intent.PRIMARY} type="submit">
                  {this.props.t("save")}
                </Button>
              </div>
            </div>
          </form>
        </Dialog>
      </div>
    );
  }
}

export default withTranslation()(VisitForm);
