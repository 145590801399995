import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import { Alert } from "@blueprintjs/core";
import { RESET_PASSWORD } from "../../mutations";
import { client } from "../../apolloClient";
import "./_Reset.scss";

function ResetPasswordWrapper(props) {
  const { token, uid } = useParams();
  return <ResetPassword {...props} token={token} uid={uid} />;
}

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: props.token,
      uid: props.uid,
      password: "",
      passwordRepeat: "",
      loading: false,
      errors: { password: "", passwordRepeat: "" },
      redirect: false,
      formIsValid: false,
      modal: { open: false },
      mode: "login"
    };
    this.t = props.t;
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { password, passwordRepeat } = this.state;
    this.validate(password, passwordRepeat);
    if (this.isFormValid()) {
      client.mutate({
        variables: {
          password: this.state.password,
          token: this.state.token,
          uid: this.state.uid
        },
        mutation: RESET_PASSWORD
      }).then((res, err) => {
        if (err) console.log(err);
        if (!res.data.resetPassword.ok) {
          return alert(res.data.resetPassword.errors);
        } else {
          this.setState({ redirect: true });
        }
      });
    }
  };

  async validate(password, passwordRepeat) {
    let errors = this.state.errors;
    errors.password = !password ? this.t("username.required") : "";
    errors.passwordRepeat = !passwordRepeat ? this.t("password.required") : "";
    errors.passwordRepeat = passwordRepeat !== password ? this.t("passwordRepeat.equal") : "";
    await this.setState({ errors });
  }

  isFormValid() {
    return this.state.errors.password === "" && this.state.errors.passwordRepeat === "";
  }

  handleErrorOpen = () => {
    this.setState({
      modal: { open: true, message: (<span>{this.t("instruction_recovery")} {this.state.email}.<br /><br />{this.t("spam")}</span>) }
    });
  };

  handleErrorClose = () => {
    this.setState({ modal: { open: false, message: "" } });
  };

  render() {
    const { ...alertProps } = this.state;

    if (this.state.redirect) {
      return <Navigate to="/login" />;
    } else {
      return (
        <div id="login">
          <div className="form-wrapper">
            <div className="title">
              <img src={process.env.PUBLIC_URL + "/img/Logotipo@2x.png"} alt="HIDUP"/>
              <span>{this.t("login.reset")}</span>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="form-group-login">
                <div className="form-control-login">
                  <input name="password" value={this.state.password} type="password" onChange={this.handleChange} placeholder={this.t("userForm.password")}/>
                  <div className="errors">
                    <span key={"password_" + new Date().getTime()} className="error">{this.state.errors.password}</span>
                  </div>
                </div>
              </div>
              <div className="form-group-login">
                <div className="form-control-login">
                  <input name="passwordRepeat" type="password" value={this.state.passwordRepeat} onChange={this.handleChange} placeholder={this.t("userForm.repeatPassword")}/>
                  <div className="errors">
                    <span key={"password_repeat_" + new Date().getTime()} className="error">{this.state.errors.passwordRepeat}</span>
                  </div>
                </div>
              </div>
              <button className="btn" type="submit">{this.t("reset.button")}</button>
            </form>
          </div>
          {this.state.modal.open && <Alert {...alertProps} confirmButtonText="Okay" isOpen={this.state.modal.open} onClose={this.handleErrorClose}><p>{this.state.modal.message}</p></Alert>}
        </div>
      );
    }
  }
}

export default withTranslation()(ResetPasswordWrapper);
