import React from "react";
import PropTypes from "prop-types";

import { Dispatcher, Constants } from "../../../flux";

class SidebarMainNavbar extends React.Component {
  constructor(props) {
    super(props);

    this.handleToggleSidebar = this.handleToggleSidebar.bind(this);
  }

  handleToggleSidebar() {
    Dispatcher.dispatch({
      actionType: Constants.TOGGLE_SIDEBAR
    });
  }

  render() {
    const { hideLogoText } = this.props;
    const logoPath =
      process.env.PUBLIC_URL +
      (hideLogoText ? "/img/Isotipo@2x.png" : "/img/Logotipo@2x.png");

    return (
      <nav
        className={`navbar${hideLogoText ? "-hidden" : ""}`}
        role="navigation"
        aria-label="main navigation"
      >
        <div
          className={
            hideLogoText
              ? "brand-favicon-container ml-3 mt-4"
              : "brand-logo-container"
          }
        >
          <img className={hideLogoText ? "img-logo" : ""} src={logoPath} />
        </div>
      </nav>
    );
  }
}

SidebarMainNavbar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

SidebarMainNavbar.defaultProps = {
  hideLogoText: false
};

export default SidebarMainNavbar;
