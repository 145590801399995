import {
  Button,
  Dialog,
  Classes,
  Intent,
  Spinner,
  Radio,
  RadioGroup,
} from "@blueprintjs/core";
import React, { useState } from "react";

const ModalImportRewrite = ({
  t,
  isOpen,
  toggleFunction,
  isLoading,
  customText,
  customHeader,
  callback,
  primaryButtonText,
}) => {
  const [state, setState] = useState({
    autoFocus: true,
    canEscapeKeyClose: true,
    canOutsideClickClose: true,
    enforceFocus: true,
    usePortal: true,
    rewritePolicy: "RE",
  });

  const handleClose = () => toggleFunction();

  const handleRewritePolicy = (e) =>
    setState({ ...state, rewritePolicy: e.currentTarget.value });

  return (
    <Dialog
      icon="info-sign"
      onClose={() => handleClose()}
      title={customHeader}
      isOpen={isOpen}
    >
      <div className={Classes.DIALOG_BODY}>
        <div
          className="spinner-loader-listing"
          style={isLoading ? { display: "inline" } : { display: "none" }}
        >
          <Spinner size={50} />
        </div>
        <p className="pt-4 white-lines">{customText}</p>
        <RadioGroup
          label={t("rewrite.policy")}
          onChange={handleRewritePolicy}
          selectedValue={state.rewritePolicy}
          inline={true}
        >
          <Radio label={t("rewrite")} value="RE" />
          <Radio label={t("add")} value="AD" />
          <Radio label={t("ignore")} value="IG" />
        </RadioGroup>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={() => toggleFunction()}>{t("cancel")}</Button>
          <Button
            intent={Intent.SUCCESS}
            onClick={() => {
              callback(state.rewritePolicy);
              toggleFunction();
            }}
            target="_blank"
          >
            {primaryButtonText}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ModalImportRewrite;
