import React from "react";
import { Query } from "react-apollo";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { headers } from "../../../data/tablesHeaders/headers";
import Breadcrumb from "../../../components/Breadcrumb";
import Paginator from "../../../components/Paginator/Paginator";
import TableSizeSelector from "../../../components/Paginator/TableSizeSelector";
import {
  VIRTUAL_HOST,
  mediaFilesAppend,
  DEFAULT_ELEM_PAGE,
  ROLE_ADMIN,
  ROLE_DATAMANAGER,
  ROLE_INVESTIGATOR,
  ROLE_LEAD_MONITOR,
  ROLE_MONITOR,
  ROLE_READ_ONLY,
  ROLE_SYSADMIN,
  API_URL,
  EXPORT_TYPE_SECTION_ENCODED,
  EXPORT_TYPE_VARIABLE_ENCODED,
  EXPORT_TYPE_SECTION_LABEL,
  EXPORT_TYPE_VARIABLE_LABEL,
  EXPORT_SINTAX,
  EXPORT_MAP,
} from "../../../config";
import {
  Spinner,
  Button,
  InputGroup,
  Menu,
  MenuItem,
  MenuDivider,
  Popover,
  Position,
  Icon,
} from "@blueprintjs/core";
import ConfirmDeleteModal from "../../../components/ModalDelete/ConfirmDeleteModal";
import CRFForm from "../Create/index";
import { GET_CRFS } from "../../../queries";
import {
  CLONE_CRF,
  CLOSE_CRF,
  DELETE_CRF,
  IMPORT_CRF,
} from "../../../mutations";
import CloneTemplateModal from "../../../components/ModalTemplate/ModalTemplate";
import ModalAction from "../../../components/ModalAction/ModalAction";
import ButtonAuthorized from "../../../components/ButtonAuthorized/ButtonAuthorized";
import { getToken, getSEK } from "../../../services";
import { downloadCsvFile, downloadSpsFile } from "../../../utils/DownloadFile";
import { client } from "../../../apolloClient";
import "./_List.scss";
import ModalActionCallback from "../../../components/ModalAction/ModalActionCallback";
import ModalImportRewrite from "../../../components/ModalAction/ModalImportRewrite";
import ModalExport from "../../../components/ModalExport/ModalExport";

class CRFTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpened: false,
      toDeleteName: "",
      toDeleteIndex: "",
      toDeleteId: "",
      deleteFunc: "",
      loading: false,
      cloneId: "",
      modalCloneOpened: false,
      modalActionOpened: false,
      modalActionLoading: false,
      modalActionText: this.props.t("importing"),
      modalActionHeader: this.props.t("crf.import"),
      modalImportPolicy: false,
      modalExportOpen: false,
      modalExportType: '',
      modalExportName: '',
      exportId: "",
      elementsPage: DEFAULT_ELEM_PAGE,
      page: 1,
      search: { text: "" },
      mutation: CLOSE_CRF,
      customHeader: "confirmDelete",
      orderField: "created",
      modalControl: {
        crd: false,
        modify: false,
      },
      importId: "",
      importFile: null,
      exportType: "",
    };
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.createOpen = this.createOpen.bind(this);
    this.onCreateSubmit = this.onCreateSubmit.bind(this);
    this.handleSubmitImport = this.handleSubmitImport.bind(this);
    this.toggleExportModal = this.toggleExportModal.bind(this);
  }

  componentDidMount() {
    if (this.refetch) {
      this.refetch();
    }
  }

  toggleDeleteModal = (
    toDeleteIndex = "",
    toDeleteId = "",
    toDeleteName = "",
    mutation,
    customText,
    customHeader
  ) => {
    if (this.state.modalOpened) {
      this.setState({
        modalOpened: !this.state.modalOpened,
        mutation: mutation,
        customText: customText,
        customHeader: customHeader,
      });
    } else {
      this.setState(
        {
          toDeleteIndex,
          toDeleteId,
          toDeleteName,
          mutation,
          customText: customText,
          customHeader: customHeader,
        },
        () => this.setState({ modalOpened: !this.state.modalOpened })
      );
    }
  };

  handlerPageChange = (page) => {
    this.setState({
      page: page,
    });
  };

  handleChange = async (event) => {
    const { name, value } = event.target;
    await this.setState((prevState) => ({
      search: {
        ...prevState.search,
        [name]: value,
      },
    }));
    if (value.length > 3) {
      this.handleSubmit();
    }
  };

  handleSubmit = () => {
    const search = this.state.search;
    this.refetch({
      ...search,
      page: 1,
    });
  };

  toggleCloneModal = (cloneId = "") => {
    this.setState({
      cloneId: cloneId,
      modalCloneOpened: !this.state.modalCloneOpened,
    });
  };

  toggleActionModal = () => {
    this.setState({
      modalActionOpened: !this.state.modalActionOpened,
    });
  };

  toggleImportPolicyModal = () => {
    this.setState({
      modalImportPolicy: !this.state.modalImportPolicy,
    });
  };

  toggleExportModal = (id, name, type) => {
    this.setState({
      modalExportOpen: !this.state.modalExportOpen,
      exportId: id,
      modalActionText: this.props.t("exporting"),
      modalActionHeader: this.props.t("exporting.crf"),
      exportType: type
    });
  };

  downloadCsv = async (id, name, type) => {
    this.toggleActionModal();
    this.setState({
      modalActionLoading: true,
      modalActionText: this.props.t("exporting"),
      modalActionHeader: this.props.t("exporting.crf"),
    });

    fetch(`${API_URL}/crf/export/${id}/${type}`, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        authorization: `${getToken()}$_${getSEK()}`,
      },
    })
      .then((response) => {
        const contentType = response.headers.get("content-type");
        if (
          !contentType ||
          !(
            contentType.includes("text/csv") ||
            contentType.includes("text/plain")
          )
        ) {
          throw new TypeError("Oops, we haven't got CSV!");
        }
        return response.text();
      })
      .then((data) => {
        this.toggleActionModal();
        if (type == EXPORT_SINTAX) {
          const filename = `${name
            .trim()
            .replace(/\s/g, "-")}-SPS-${new Date().getTime()}`;
          downloadSpsFile(data, filename);
        } else if (type == EXPORT_MAP) {
          const filename = `${name
            .trim()
            .replace(/\s/g, "-")}-MAP-${new Date().getTime()}`;
          downloadCsvFile(data, filename);
        } else {
          let filename = name.trim().replace(/\s/g, "-");
          filename =
            type == EXPORT_TYPE_VARIABLE_ENCODED ||
            type == EXPORT_TYPE_VARIABLE_LABEL
              ? filename + "-SPSS"
              : filename;
          filename =
            type == EXPORT_TYPE_VARIABLE_ENCODED ||
            type == EXPORT_TYPE_SECTION_ENCODED
              ? filename + "-ENCODED"
              : filename;
          filename = filename + `-${new Date().getTime()}`;
          downloadCsvFile(data, filename);
        }
      })
      .catch((error) => console.error(error));
  };

  importHandle = async (id) => {
    await this.setState({ importId: id });
    this._inputFile.click();
  };

  handleChangeImport = async (e) => {
    e.preventDefault();
    const files = e.target.files;
    await this.setState({ importFile: files });
    if (files) this.toggleImportPolicyModal();
  };

  handleSubmitImport = (rewritePolicy = "RE") => {
    this.setState({ loading: true });
    const mutation = IMPORT_CRF;
    this.toggleActionModal();
    this.setState({
      modalActionLoading: true,
      modalActionText: this.props.t("importing"),
      modalActionHeader: this.props.t("import.crf"),
    });
    const variables = {
      id: this.state.importId,
      csvData:
        typeof this.state.importFile === "object"
          ? this.state.importFile[0]
          : this.state.importFile,
      rewritePolicy: rewritePolicy,
    };

    client.mutate({variables, mutation,}).then((res, err) => {
        console.log("res import csv: ", res);
        if (err)
          this.setState({ modalActionText: this.props.t("import.fail") });
        res.data.importCrf.ok
          ? this.setState({ modalActionText: this.props.t("import.success") })
          : this.setState({
              modalActionText: `${this.props.t("import.fail")} \n ${
                res.data.importCrf.message
              }`,
            });
        this.setState({ modalActionLoading: false });
        this._inputFile.value = null;
      })
      .catch((err) => {
        console.log("catch import csv: ", err);
        this.setState({ modalActionText: this.props.t("import.fail") });
        this.setState({ modalActionLoading: false });
        this._inputFile.value = null;
      });
  };

  shareHandle = async (id) => {
    const url = `${VIRTUAL_HOST}/patients/${id}`;
    this.setState({
      modalActionText: url,
      modalActionHeader: this.props.t("crf.link"),
    });
    this.toggleActionModal();
  };

  createOpen(create = true, id = null) {
    const { createLink } = this.props;
    const { modalControl } = this.state;

    if (createLink === "/crf-create") {
      modalControl.crd = create;
    }

    if (id !== null) {
      modalControl.modify = id;
    } else {
      modalControl.modify = null;
    }

    this.setState({ modalControl });
  }

  onCreateSubmit() {
    this.createOpen(false);
    this.refetch();
  }

  orderTable = (field) => {
    if (this.state.orderField === field) {
      this.setState((prevState) => ({
        ...prevState,
        orderField: `-${field}`,
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        orderField: field,
      }));
    }
  };

  onChangePage(num) {
    this.setState({ elementsPage: num });
    this.refetch();
  }

  render() {
    this.t = this.props.t;
    const dataKeys = headers[this.props.tableType].data;

    const capitalize = (s) => {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    };

    return (
      <div className="container-fluid">

        <Breadcrumb
          crumbs={[
            { link: "/", name: "Home", active: false },
            {
              link: "",
              name: capitalize(this.t(this.props.title)),
              active: true,
            },
          ]}
        />

        <div className="row">
          <div className="col">
            <h3>{capitalize(this.t(this.props.title))}</h3>
          </div>
          <div className="col">
            {(this.props.role == "SA" || this.props.role == "AD") && (
              <Button
                className="addButton"
                type="button"
                onClick={this.createOpen.bind(this)}
              >
                {capitalize(this.t("new"))}
              </Button>
            )}
          </div>
        </div>

        <div className="d-none">
          <form onSubmit={this.handleSubmitImport}>
            <input
              ref={(c) => (this._inputFile = c)}
              type="file"
              onChange={(e) => this.handleChangeImport(e)}
            />
          </form>
        </div>

        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.handleSubmit();
            }}
            ref={(elem) => (this.form = elem)}
          >
            <div className="row pt-2 pb-3">
              <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 offset-md-6 offset-lg-8 offset-xl-9 text-right">
                <InputGroup
                  id="text"
                  name="text"
                  onChange={this.handleChange}
                  value={this.state.text}
                  placeholder={this.props.t("search")}
                  leftIcon="search"
                />
              </div>
            </div>
          </form>
          <Query
            query={GET_CRFS}
            variables={{
              elementsPerPage: this.state.elementsPage,
              page: this.state.page,
              orderField: this.state.orderField,
            }}
          >
            {({ loading, error, data, refetch }) => {
              if (loading)
                return (
                  <div className="spinner-loader-listing">
                    <Spinner
                      size={80}
                      spinnerColor={"#007BFF"}
                      spinnerWidth={2}
                      visible={true}
                    />
                  </div>
                );
              if (error) return `Error ${error.message}`;
              this.refetch = refetch;
              {
                return (
                  <div>
                    <table className="table table-custom table-hover">
                      <thead className="bg-light">
                        <tr className="table-hover">
                          <th scope="col">#</th>
                          <th
                            key="name-col"
                            scope="col"
                            className="clickable"
                            onClick={(e) => this.orderTable("name")}
                          >
                            {this.t("table.name")}
                            {this.state.orderField == "name" && (
                              <Icon
                                className="ml-2"
                                icon="caret-down"
                                size="16"
                              ></Icon>
                            )}
                            {this.state.orderField == `-name` && (
                              <Icon
                                className="ml-2"
                                icon="caret-up"
                                size="16"
                              ></Icon>
                            )}
                          </th>
                          <th scope="col">{this.t("design")}</th>
                          <th scope="col">{this.t("patients")}</th>
                          <th scope="col" className="text-right">
                            {this.t("table.actions")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data[this.props.tableType]["objects"].map(
                          (data, index) => (
                            <tr key={index} className="table-hover">
                              <td>{index + 1}</td>
                              {dataKeys.map((key, index) => {
                                let info = "";
                                try {
                                  info = key
                                    .split(".")
                                    .reduce(function(c, prop) {
                                      return c[prop];
                                    }, data);
                                } catch (err) {}
                                if (
                                  typeof info === "string" &&
                                  info.endsWith("png")
                                )
                                  return (
                                    <td key={index}>
                                      <img
                                        className="icon"
                                        src={mediaFilesAppend + info}
                                        alt=""
                                      />
                                    </td>
                                  );
                                else if (key === "created")
                                  return (
                                    <td key={index}>
                                      {new Date(info).toLocaleDateString()}
                                    </td>
                                  );
                                else return <td key={index}>{info}</td>;
                              })}
                              <td>
                                {!data.deleted && (
                                  <ButtonAuthorized
                                    //intent={Intent.PRIMARY}
                                    minimal
                                    href={`/crf/${data.id}`}
                                    icon="edit"
                                    roles={[ROLE_SYSADMIN, ROLE_ADMIN]}
                                    anchor={true}
                                  />
                                )}
                              </td>
                              <td>
                                <ButtonAuthorized
                                  //intent={Intent.PRIMARY}
                                  minimal
                                  href={`/patients/${data.id}`}
                                  icon="user"
                                  roles={[
                                    ROLE_SYSADMIN,
                                    ROLE_ADMIN,
                                    ROLE_DATAMANAGER,
                                    ROLE_INVESTIGATOR,
                                    ROLE_MONITOR,
                                    ROLE_READ_ONLY,
                                    ROLE_LEAD_MONITOR,
                                  ]}
                                  anchor={true}
                                />
                              </td>
                              <td className="text-right">
                                {!data.deleted && (
                                  <ButtonAuthorized
                                    //intent={Intent.SUCCESS}
                                    //href={`${this.props.createLink}/${data.id}`}
                                    onClick={() =>
                                      this.createOpen(true, data.id)
                                    }
                                    icon="edit"
                                    roles={[ROLE_SYSADMIN, ROLE_ADMIN]}
                                    minimal
                                    //anchor={true}
                                  />
                                )}
                                {!data.deleted && (
                                  <ButtonAuthorized
                                    //intent={Intent.PRIMARY}
                                    minimal
                                    icon="duplicate"
                                    roles={[ROLE_SYSADMIN, ROLE_ADMIN]}
                                    onClick={() =>
                                      this.toggleCloneModal(data.id)
                                    }
                                    title={this.t("table.clone")}
                                    className="ml-2"
                                  />
                                )}
                                <Popover
                                  content={
                                    <Menu>
                                      <MenuItem
                                        text={this.t("sections.encoded")}
                                        onClick={() =>
                                          this.toggleExportModal(
                                            data.id,
                                            data.name,
                                            EXPORT_TYPE_SECTION_ENCODED
                                          )
                                        }
                                      />
                                      <MenuItem
                                        text={this.t("sections.label")}
                                        onClick={() =>
                                          this.toggleExportModal(
                                            data.id,
                                            data.name,
                                            EXPORT_TYPE_SECTION_LABEL
                                          )
                                        }
                                      />
                                      <MenuItem
                                        text={this.t("variable.encoded")}
                                        onClick={() =>
                                          this.toggleExportModal(
                                            data.id,
                                            data.name,
                                            EXPORT_TYPE_VARIABLE_ENCODED
                                          )
                                        }
                                      />
                                      <MenuItem
                                        text={this.t("variable.label")}
                                        onClick={() =>
                                          this.toggleExportModal(
                                            data.id,
                                            data.name,
                                            EXPORT_TYPE_VARIABLE_LABEL
                                          )
                                        }
                                      />
                                      <MenuDivider />
                                      <MenuItem
                                        text={this.t("map.visits")}
                                        onClick={() =>
                                          this.downloadCsv(
                                            data.id,
                                            data.name,
                                            EXPORT_MAP
                                          )
                                        }
                                      />
                                      <MenuItem
                                        text={this.t("sintaxis")}
                                        onClick={() =>
                                          this.downloadCsv(
                                            data.id,
                                            data.name,
                                            EXPORT_SINTAX
                                          )
                                        }
                                      />
                                    </Menu>
                                  }
                                  position={Position.LEFT}
                                >
                                  <ButtonAuthorized
                                    //intent={Intent.PRIMARY}
                                    minimal
                                    icon="export"
                                    roles={[
                                      ROLE_SYSADMIN,
                                      ROLE_ADMIN,
                                      ROLE_INVESTIGATOR,
                                    ]}
                                    title={this.t("table.export")}
                                    className="ml-2"
                                  />
                                </Popover>
                                <ButtonAuthorized
                                  minimal
                                  //intent={Intent.PRIMARY}
                                  icon="import"
                                  roles={[ROLE_SYSADMIN, ROLE_ADMIN]}
                                  onClick={() => this.importHandle(data.id)}
                                  title={this.t("table.import")}
                                  className="ml-2"
                                />
                                <ButtonAuthorized
                                  minimal
                                  //intent={Intent.PRIMARY}
                                  icon="globe"
                                  roles={[
                                    ROLE_SYSADMIN,
                                    ROLE_ADMIN,
                                    ROLE_DATAMANAGER,
                                    ROLE_INVESTIGATOR,
                                    ROLE_MONITOR,
                                  ]}
                                  onClick={() => this.shareHandle(data.id)}
                                  title={this.t("table.crfurl")}
                                  className="ml-2"
                                />
                                <ButtonAuthorized
                                  //intent={Intent.DANGER}
                                  minimal
                                  icon={data.deleted ? "trash" : "disable"}
                                  roles={[ROLE_SYSADMIN, ROLE_ADMIN]}
                                  onClick={() =>
                                    data.deleted
                                      ? this.toggleDeleteModal(
                                          index,
                                          data.id,
                                          data.name,
                                          DELETE_CRF,
                                          "table.delete",
                                          "confirmDelete"
                                        )
                                      : this.toggleDeleteModal(
                                          index,
                                          data.id,
                                          data.name,
                                          CLOSE_CRF,
                                          "table.close",
                                          "confirmClose"
                                        )
                                  }
                                  title={
                                    data.deleted
                                      ? this.t("table.delete")
                                      : this.t("table.close")
                                  }
                                  className="ml-2"
                                />
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                    <div className="row ">
                      <div className="col-8">
                        <TableSizeSelector
                          t={this.t}
                          numPages={this.state.elementsPage}
                          onChangePage={this.onChangePage.bind(this)}
                          elementsPage={this.state.elementsPage}
                          totalElements={data[this.props.tableType].total}
                        />
                      </div>
                      <div className="col-4 text-right">
                        <Paginator
                          data={data}
                          t={this.t}
                          handlerPageChange={this.handlerPageChange}
                          refetchFunction={refetch}
                          tableType={this.props.tableType}
                          page={this.state.page}
                        />
                      </div>
                    </div>
                  </div>
                );
              }
            }}
          </Query>
        </div>
        <ConfirmDeleteModal
          isOpen={this.state.modalOpened}
          toggleFunction={this.toggleDeleteModal}
          toDeleteId={this.state.toDeleteId}
          refetchFunction={this.refetch}
          toDeleteName={this.state.toDeleteName}
          mutation={this.state.mutation}
          customText={this.state.customText}
          customHeader={this.state.customHeader}
          t={this.t}
        />
        <CloneTemplateModal
          isOpen={this.state.modalCloneOpened}
          cloneId={this.state.cloneId}
          toggleFunction={this.toggleCloneModal}
          refetchFunction={this.refetch}
          mutation={CLONE_CRF}
          t={this.t}
        />
        <ModalAction
          isOpen={this.state.modalActionOpened}
          isLoading={this.state.modalActionLoading}
          toggleFunction={this.toggleActionModal}
          customText={this.state.modalActionText}
          customHeader={this.state.modalActionHeader}
          t={this.t}
        />
        <CRFForm
          isOpen={this.state.modalControl.crd}
          t={this.t}
          id={this.state.modalControl.modify}
          toggleFunction={this.createOpen.bind(this, false)}
          onCreateSubmit={this.onCreateSubmit.bind(this)}
        />
        <ModalImportRewrite
          isOpen={this.state.modalImportPolicy}
          t={this.t}
          customText={this.t("import.rewrite.policy.text")}
          customHeader={this.t("import.rewrite.policy")}
          toggleFunction={this.toggleImportPolicyModal}
          callback={this.handleSubmitImport.bind(this)}
          primaryButtonText={this.t("import")}
        />
        <ModalExport
          isOpen={this.state.modalExportOpen}
          t={this.t}
          entityId={this.state.exportId}
          toggleFunction={this.toggleExportModal}
          exportType={this.state.exportType}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  username: state.user.username,
  name: state.user.name,
  lastname: state.user.lastname,
  role: state.user.role,
  avatar: state.user.avatar,
});

const bindActions = (dispatch) => ({});

export default connect(
  mapStateToProps,
  bindActions
)(withTranslation()(CRFTable));
