const API_URL = getVariable("REACT_APP_API_URL", "https://api.dev.crf.hidup.io/");
const PUBLIC_KEY = getVariable("VIRTUAL_HOST", "crf.hidup.io");
const MEDIA_APPEND = getVariable(
  "REACT_APP_MEDIA_APPEND",
  "http://crf.hidup.io/media/"
);

module.exports = {
  API_URL: API_URL,
  VIRTUAL_HOST: PUBLIC_KEY,
  mediaFilesAppend: MEDIA_APPEND,
  DEFAULT_ELEM_PAGE: 10,
  TEMPLATE_VISIT: "visit",
  TEMPLATE_SHEET: "sheet",
  TEMPLATE_CONTAINER: "container",
  FILLED_VISIT_STATUS: {
    NE: "New",
    IP: "In Progress",
    MO: "Monitoring",
    CO: "Completed"
  },
  FILLED_VISIT_STATUS_NEW: "NE",
  FILLED_VISIT_STATUS_INPROGRESS: "IP",
  FILLED_VISIT_STATUS_MONITORING: "MO",
  FILLED_VISIT_STATUS_COMPLETED: "CO",
  PATIENT_STATUS: {
    NE: "New",
    IP: "In Progress",
    MO: "Monitoring",
    CL: "Completed",
    SI: "Signed"
  },
  PATIENT_STATUS_NEW: "NE",
  PATIENT_STATUS_INPROGRESS: "IP",
  PATIENT_STATUS_MONITORING: "MO",
  PATIENT_STATUS_CLOSED: "CL",
  PATIENT_STATUS_SIGNED: "SI",
  INPUTS_TYPES: [
    "textfield",
    "email",
    "datetime",
    "textarea",
    "select",
    "phoneNumber",
    "url",
    "number",
    "radio",
    "selectboxes",
    "currency",
    "time",
    "textfield_custom",
    "email_custom",
    "datetime_custom",
    "textarea_custom",
    "select_custom",
    "phoneNumber_custom",
    "url_custom",
    "number_custom",
    "radio_custom",
    "selectboxes_custom",
    "currency_custom",
    "time_custom"
  ],
  MONITORIZATION_TYPES: {
    CH: "checked",
    CO: "correction",
    IN: "incidence"
  },
  MONITORIZATION_TYPE_CHECKED: "CH",
  MONITORIZATION_TYPE_COMMENT: "CO",
  MONITORIZATION_TYPE_INCIDENCE: "IN",
  ROLE_SYSADMIN: "SA",
  ROLE_ADMIN: "AD",
  ROLE_MONITOR: "MO",
  ROLE_LEAD_MONITOR: "LM",
  ROLE_INVESTIGATOR: "IN",
  ROLE_READ_ONLY: "RO",
  ROLE_DATAMANAGER: "DM",
  ROLE_TYPES: {
    SA: "Super admin",
    AD: "Admin",
    MO: "Monitor",
    LM: "Leader monitor",
    IN: "Investigator",
    RO: "Read only",
    DM: "Data manager"
  },
  EXPORT_TYPE_SECTION_ENCODED: "SE",
  EXPORT_TYPE_SECTION_LABEL: "SL",
  EXPORT_TYPE_VARIABLE_ENCODED: "VE",
  EXPORT_TYPE_VARIABLE_LABEL: "VL",
  EXPORT_SINTAX: "SI",
  EXPORT_MAP: "MA"
};

function getVariable(name, defaultValue) {
  if (window._env_ && window._env_[name]) {
    return window._env_[name];
  } else if (process.env[name]) {
    return process.env[name];
  } else {
    return defaultValue;
  }
}
