import React from "react";
import { withTranslation } from "react-i18next";
import "./_FooterPage.scss";
import { ABOUT, INFO, HELP, CONTACT } from "./footerText";

const FooterPage = ({ t }) => {

  const type = window.location.href.split('/')[4];

  const lan = localStorage.getItem("i18nextLng");

  return (
    <div className="container footerPage">
      <h1>{t(type)}</h1>
      <div>
        {type === "about" && (
          <div class="row">
            <div class="col text-center">
              <img src={"/img/etiqueta_hidup.png"} />
            </div>
          </div>
        )}
        {type === "info" && (
          <>
            {INFO[lan].map((p) => {
              
              return <p>{p}</p>;
            })}
          </>
        )}
        {type === "help" && (
          <>
            {HELP[lan].map((p) => {
              return <p>{p}</p>;
            })}
          </>
        )}
        {type === "contact" && (
          <>
            {CONTACT[lan].map((p) => {
              return <p>{p}</p>;
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(FooterPage);
