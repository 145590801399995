import React from "react";
import { withTranslation } from "react-i18next";
import { client } from "../../../apolloClient";
import {
  Card,
  Elevation,
  Button,
  Spinner,
  Menu,
  MenuItem,
  MenuDivider,
  Position,
  Popover,
  Intent,
  Icon,
  Colors,
} from "@blueprintjs/core";
import {
  GET_FILLED_VISIT,
  GET_VISIT_FIELDS,
  GET_FIELD_PATIENT_HISTORY,
} from "../../../queries";
import { Navigate  } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import ReactDiffViewer from "react-diff-viewer";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

import stringify from "json-stringify-pretty-compact";

class DiffVisit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filledVisit: {
        id: null,
        filledSheets: [],
        user: { username: "" },
        versionDate: "",
        filledVisit: {
          visit: { name: "" },
          versions: { edges: [] },
          patient: { name: "", crf: { name: "", id: "" } },
        },
      },
      secondFilledVisit: {
        id: null,
        filledSheets: [],
        user: { username: "" },
        versionDate: "",
        filledVisit: {
          visit: { name: "" },
          versions: { edges: [] },
          patient: { name: "", crf: { name: "", id: "" } },
        },
      },
      submissions: [],
      oldSubmissions: [],
      isEdit: false,
      loading: true,
      redirect: false,
      selectedSheet: 0,
      selectedVersion1: {
        id: null,
        filledSheets: [],
        user: { username: "" },
        versionDate: "",
        filledVisit: {
          visit: { name: "" },
          versions: { edges: [] },
          patient: { name: "", crf: { name: "", id: "" } },
        },
      },
      selectedVersion2: null,
      submitting: false,
      compare: false,
      fields: [],
      field: null,
      showHistory: false,
      history: [],
    };
  }

  componentDidMount() {
    this.fetch(window.location.href.split('/')[4]);
  }

  fetch = async (id, main = true) => {
    const variables = { id: id };
    await client
      .query({
        variables: variables,
        query: GET_FILLED_VISIT,
        fetchPolicy: "network-only",
      })
      .then((res) => {
        const submissions = res.data.filledVisit.filledSheets.map(
          (filledSheet) => {
            const filledForm = filledSheet.uncriptedFilledForm
              ? JSON.parse(filledSheet.uncriptedFilledForm)
              : {};

            if (filledForm.data)
              return stringify(filledForm.data, { maxLength: 0, indent: 2 });
            return stringify(filledForm);
          }
        );
        if (main)
          this.setState({
            loading: false,
            selectedVersion1: res.data.filledVisit,
            filledVisit: res.data.filledVisit,
            submissions: submissions,
          });
        else
          this.setState({
            loading: false,
            oldSubmissions: submissions,
          });

        client
          .query({
            variables: { id: res.data.filledVisit.filledVisit.visit.id },
            query: GET_VISIT_FIELDS,
            fetchPolicy: "network-only",
          })
          .then((fields) => {
            this.setState({ fields: fields.data.visitFields.fields });
          });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  };

  handleSubmit = async () => {
    if (this.state.submitting === false) {
      this.setState({ submitting: true });
      // get the submissions forms to compare
      if (this.state.filledVisit.id !== this.state.selectedVersion1)
        this.fetch(this.state.selectedVersion1.id, true);

      await this.fetch(this.state.selectedVersion2.id, false);

      this.setState({ compare: true, showHistory: false, submitting: false });
    }
  };

  handleFieldSubmit = async () => {
    if (this.state.field !== null) {
      this.setState({ submitting: true });

      await client
        .query({
          variables: {
            id: this.state.selectedVersion1.filledVisit.id,
            field: this.state.field.value,
          },
          query: GET_FIELD_PATIENT_HISTORY,
          fetchPolicy: "network-only",
        })
        .then((history) => {
          this.setState({ history: history.data.fieldPatientHistory.history });
        });

      this.setState({ compare: false, showHistory: true, submitting: false });
    }
  };

  renderMenu = (main = true) => {
    return (
      <Menu>
        {this.state.filledVisit.filledVisit.versions.edges.map(
          (data, index) => (
            <MenuItem
              key={index}
              text={`${data.node.user?.username} - ${new Date(
                data.node.versionDate
              ).toLocaleString()}`}
              icon={
                data.node.id === this.state.filledVisit.id
                  ? "tick"
                  : "git-branch"
              }
              label={data.node.last ? this.props.t("actual") : ""}
              onClick={(e) => {
                main
                  ? this.setState({ selectedVersion1: data.node })
                  : this.setState({ selectedVersion2: data.node });
              }}
            />
          )
        )}
        <MenuDivider />
        <MenuItem text="Exit" icon="cross" />
      </Menu>
    );
  };

  renderField = () => {
    return (
      <Menu>
        {this.state.fields.map((data, index) => (
          <MenuItem
            key={index}
            text={`${data.label}`}
            onClick={(e) => {
              console.log(data);
              this.setState({ field: data });
            }}
          />
        ))}
        <MenuDivider />
        <MenuItem text="Exit" icon="cross" />
      </Menu>
    );
  };

  render() {
    const capitalize = (s) => {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    };

    if (this.state.loading) {
      return (
        <div className="container-fluid">
          <div className="spinner-loader-listing">
            <Spinner
              size={80}
              spinnerColor={"#007BFF"}
              spinnerWidth={2}
              visible={true}
            />
          </div>
        </div>
      );
    }

    if (this.state.redirect) {
      this.setState({
        redirect: false,
      });
      return <Navigate to={`/patient/${this.state.filledVisit.patient.id}`} />;
    }
    return (
      <div className="container-fluid" style={{ width: "99%" }}>
        <Breadcrumb
          crumbs={[
            { link: "/", name: "Home", active: false },
            {
              link: "/crfs",
              name: capitalize(this.props.t("crfs.title")),
              active: false,
            },
            {
              link: `/patients/${this.state.filledVisit.filledVisit.patient.crf.id}`,
              name: this.state.filledVisit.filledVisit.patient.crf.name,
              active: false,
            },
            {
              link: `/patient/${this.state.filledVisit.filledVisit.patient.id}`,
              name: this.state.filledVisit.filledVisit.patient.code,
              active: false,
            },
            {
              link: `/filled-visit/${window.location.href.split('/')[4]}`,
              name: this.state.filledVisit.filledVisit.visit.name,
              active: false,
            },
            {
              link: `/filled-visit-diff/${window.location.href.split('/')[4]}`,
              name: this.props.t("visit.diff"),
              active: true,
            },
          ]}
        />
        <div className="row mb-3">
          <div className="col">
            <Popover
              className="mb-3"
              content={this.renderMenu(true)}
              position={Position.BOTTOM_LEFT}
            >
              <Button
                rightIcon="caret-down"
                intent={Intent.DEFAULT}
                text={`${
                  this.state.selectedVersion1.user?.username
                } - ${new Date(
                  this.state.selectedVersion1.versionDate
                ).toLocaleString()}`}
              />
            </Popover>
          </div>
          <div className="col">
            <Popover
              className="mb-3"
              content={this.renderMenu(false)}
              position={Position.BOTTOM_LEFT}
            >
              <Button
                rightIcon="caret-down"
                intent={Intent.DEFAULT}
                text={
                  this.state.selectedVersion2
                    ? `${
                        this.state.selectedVersion2.user?.username
                      } - ${new Date(
                        this.state.selectedVersion2.versionDate
                      ).toLocaleString()}`
                    : this.props.t("select.compare")
                }
              />
            </Popover>
          </div>

          <div className="col">
            {this.state.selectedVersion2 && (
              <Button
                className="mr-2"
                icon="changes"
                title={this.props.t("compare.changes")}
                onClick={() => this.handleSubmit()}
              >
                {this.props.t("compare.changes")}
              </Button>
            )}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">{this.props.t("audit.field")}</div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <Popover
              className="mb-3"
              content={this.renderField()}
              position={Position.BOTTOM_LEFT}
            >
              <Button
                rightIcon="caret-down"
                intent={Intent.DEFAULT}
                text={
                  this.state.field
                    ? this.state.field.label
                    : this.props.t("select.field")
                }
              />
            </Popover>
          </div>
          <div className="col">
            {this.state.field && (
              <Button
                className="mr-2"
                icon="changes"
                title={this.props.t("field.changes")}
                onClick={() => this.handleFieldSubmit()}
              >
                {this.props.t("field.changes")}
              </Button>
            )}
          </div>
        </div>
        <Card
          interactive={false}
          elevation={Elevation.TWO}
          style={{ background: "#f4f4f4" }}
        >
          <h4 className="mb-3 w-100">
            {this.state.filledVisit.filledVisit.visit.name}
          </h4>

          {this.state.compare ? (
            this.state.filledVisit.filledSheets.map((filledSheet, index) => {
              return (
                <ReactDiffViewer
                  oldValue={this.state.submissions[index]}
                  newValue={`${this.state.oldSubmissions[index]}`}
                  leftTitle={filledSheet.sheet.name}
                  rightTitle={filledSheet.sheet.name}
                  useDarkTheme={true}
                  splitView={true}
                />
              );
            })
          ) : (
            <div>{this.props.t("help.diff")}</div>
          )}
          {this.state.showHistory ? (
            <VerticalTimeline layout="1-column">
              {this.state.history.map((history, index) => {
                return (
                  <VerticalTimelineElement
                    className="vertical-timeline-element"
                    date={new Date(history.date).toLocaleString()}
                    iconStyle={{
                      background: `${Colors.GREEN4}`,
                      color: "#fff",
                    }}
                    icon={<Icon icon="edit" iconSize={30} />}
                    position="right"
                  >
                    <h5 className="vertical-timeline-element-title">
                      {this.state.field.label}
                    </h5>
                    <h6 className="vertical-timeline-element-subtitle">
                      {`${this.props.t("user")}: ${history.user}`}
                    </h6>
                    <h6>{`${this.props.t("value")}: ${history.value}`}</h6>
                  </VerticalTimelineElement>
                );
              })}
            </VerticalTimeline>
          ) : (
            <div></div>
          )}
        </Card>
      </div>
    );
  }
}

export default withTranslation()(DiffVisit);
